<mat-card>
    <mat-card-header>
        <h3 matCardTitle>{{'quote.objectValueChange.title' | translate}}</h3>
    </mat-card-header>
    <mat-card-content>
        <div fxFlex
             fxLayout="row wrap"
             fxLayoutGap="16px">
            <div fxFlex="100"
                 fxLayout="column">
                <l7-banner *ngIf="isPurchasePriceChangedInactive"
                           [appearance]="'outlined'"
                           [color]="'info'"
                           [icon]="'Info'"
                           [iconFontSet]="'l7'"
                           [content]="'contract_management.slbPriceChangedInactiveMessage' | translate">
                </l7-banner>
                <l7-spacer [thickness]="['top']"
                           [density]="'comfortable'"></l7-spacer>
            </div>
            <fieldset fxFlex="0 1 calc(25% - 16px)"
                      fxFlex.lt-md="calc(50% - 16px)"
                      fxLayout="column">
                <legend>{{ 'settlement.details.new_object_value' | translate }}</legend>
                <l7-text-block [truncate]="true"
                               [text]="calculationValues.totalLeasingValueNet | currency | na">
                </l7-text-block>
            </fieldset>
            <fieldset fxFlex="0 1 calc(25% - 16px)"
                      fxFlex.lt-md="calc(50% - 16px)">
                <legend>{{ 'quote.objectValueChange.newInsurance' | translate }}</legend>
                <l7-text-block [truncate]="true"
                               [text]="(('states.insurance.' + insuranceValues?.insuranceType) | translate) + ': ' + (insuranceValues?.insuranceValue | currency | na)">
                </l7-text-block>
            </fieldset>
            <!-- only in TA/VA/KFZ -->
            <ng-container *ngIf="contractType === 'VA' || contractType === 'KFZ' || contractType === 'TA'">
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'settlement.details.new_residual_value' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="calculationValues.residualValue | currency | na">
                    </l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'settlement.details.new_lease_rate' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="calculationValues.instalment | currency | na">
                    </l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'quote.objectValueChange.downPayment' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="calculationValues.downPayment | currency | na">
                    </l7-text-block>
                </fieldset>
            </ng-container>
            <ng-container *ngIf="slbCalculation">
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'global.taxes_percent' | translate }}</legend>
                    <l7-text-block *ngIf="contractType !== 'MKN'; else noVatPercent"
                                   [truncate]="true"
                                   [text]="slbCalculation.vatPercentage + ' %' | na">
                    </l7-text-block>
                    <ng-template #noVatPercent>
                        <l7-text-block [truncate]="true"
                                       [text]="'global.omitted' | translate">
                        </l7-text-block>
                    </ng-template>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'quote.objectValueChange.vatAmount' | translate }}</legend>
                    <l7-text-block *ngIf="contractType !== 'MKN'; else noVatAmount"
                                   [truncate]="true"
                                   [text]="slbCalculation.vatAmount | currency | na">
                    </l7-text-block>
                    <ng-template #noVatAmount>
                        <l7-text-block [truncate]="true"
                                       [text]="'global.omitted' | translate">
                        </l7-text-block>
                    </ng-template>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'quote.objectValueChange.totalLeasingValueGross' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="slbCalculation.totalLeasingValueGross | currency | na">
                    </l7-text-block>
                </fieldset>
            </ng-container>
            <!-- MIETKAUF and MKN -->
            <ng-container *ngIf="contractType === 'MIETKAUF' || contractType === 'MKN'">
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'quote.objectValueChange.downPayment' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="calculationValues.downPayment | currency | na">
                    </l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'quote.objectValueChange.firstInstalment' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="calculationValues.firstInstalment | currency | na"></l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'Rate (neu)' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="calculationValues.instalment | currency | na"></l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'quote.objectValueChange.lastInstalment' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="calculationValues?.lastInstalment | currency | na"></l7-text-block>
                </fieldset>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>
