<h2 mat-dialog-title>{{'contract_management.purchase_entry.object_value_dialog_title' | translate}}</h2>
<mat-divider></mat-divider>
<mat-dialog-content fxLayout="column"
                    fxLayoutGap="16px"
                    style="margin-bottom: 20px;">
    <p>{{'contract_management.purchase_entry.object_value_dialog_text' | translate}}</p>
    <h2>Objektwert anpassen</h2>
    <form *ngIf="data"
          fxFill
          fxFlex="100"
          fxLayout="column"
          fxLayoutGap="16px"
          [formGroup]="form">
        <div fxLayoutGap="0"
             fxLayoutGap.gt-sm="16px"
             fxLayout="column"
             fxLayout.gt-sm="row">
            <mat-form-field fxFlex
                            appearance="fill">
                <mat-label>Alter Objektwert</mat-label>
                <input matInput
                       type="text"
                       formControlName="original_value"
                       currencyMask
                       l7SelectOnDblClick>
            </mat-form-field>
            <mat-form-field fxFlex
                            appearance="fill">
                <mat-label>Neuer Objektwert</mat-label>
                <input matInput
                       type="text"
                       formControlName="object_value"
                       currencyMask
                       l7SelectOnDblClick
                       (keyup)="onChange($event, form)">
                <mat-error>
                    <span *ngIf="form.get('object_value').getError('required')">Neue Objektwert erforderlich</span>
                    <span *ngIf="form.get('object_value').getError('isNotLower')">
                        {{'error.purchase_entry_object_value' | translate: {param0: form.getRawValue().original_value | currency: 'EUR'} }}
                    </span>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button
            (click)="this.onClose(true)">Abbrechen</button>
    <button mat-flat-button
            color="primary"
            [disabled]="form.invalid || form.getRawValue().object_value===data.purchase_entry.object_value"
            (click)="this.onSave(false)">{{'global.save' | translate}}</button>
</mat-dialog-actions>