<l7-dialog-header [title]="(title | async) | translate"
                  [supportiveTitle]="(supportiveTitle | async) | translate"
                  [closeable]="true"
                  (closed)="onClose()">
</l7-dialog-header>
<mat-dialog-content fxLayout="column">
    <ng-container *cdkBreakpoint="let bp">
        <mat-stepper #stepper
                     class="no-header"
                     [selectedIndex]="0"
                     [orientation]="bp('lt-md') ? 'vertical' : 'horizontal'">
            @if ((presenter.isIdentifySignerCompleted |async) === false) {
                <mat-step [label]="'IDENTIFY_SIGNER'">
                    <l7-identify-signer [signerForm]="presenter.form.controls.signer"
                                        [bankAccountForm]="presenter.form.controls.bankAccount"
                                        (bankAccountIsShowed)="presenter.setBankAccountIsShowed($event)">
                    </l7-identify-signer>
                    <l7-spacer [thickness]="'bottom'"
                               [multiplier]="24">
                    </l7-spacer>
                </mat-step>
            }
            <mat-step [label]="'CHOOSE_SIGNING_TYPE'">
                <div fxLayout="column"
                     fxLayoutGap="16px">
                    <l7-tile fxFlex
                             [inline]="true"
                             [attr.test-id]="'chooselocal'"
                             (click)="chooseSigningType('LOCAL')">
                        <div fxLayoutAlign="start start"
                             fxLayout="row"
                             fxLayoutGap="16px">
                            <mat-icon [color]="'primary'"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Pin_2'"></mat-icon>
                            <div fxFlex>
                                <l7-text-block [wrap]="true"
                                               [text]="'Jetzt unterschreiben' | translate"></l7-text-block>
                                <l7-text-block [readonly]="true"
                                               [wrap]="true"
                                               [text]="'Der Vertrag wird vom Kunden jetzt auf Ihrem Gerät unterschrieben' | translate"></l7-text-block>
                            </div>
                            <div fxLayoutAlign="center center">
                                <mat-icon [fontSet]="'l7'"
                                          [color]="'primary'"
                                          [fontIcon]="'Chevron_Right'"></mat-icon>
                            </div>
                        </div>
                    </l7-tile>
                    <l7-tile fxFlex
                             [inline]="true"
                             [attr.test-id]="'chooseRemote'"
                             (click)="chooseSigningType('REMOTE')">
                        <div fxLayoutAlign="start start"
                             fxLayout="row"
                             fxLayoutGap="16px">
                            <mat-icon [color]="'primary'"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Globe'"></mat-icon>
                            <div fxFlex>
                                <l7-text-block [wrap]="true"
                                               [text]="'Remote unterschreiben' | translate"></l7-text-block>
                                <l7-text-block [readonly]="true"
                                               [wrap]="true"
                                               [text]="'Der Kunde bekommt den Link zur Unterschrift per E-Mail' | translate"></l7-text-block>
                            </div>
                            <div fxLayoutAlign="center center">
                                <mat-icon [fontSet]="'l7'"
                                          [color]="'primary'"
                                          [fontIcon]="'Chevron_Right'"></mat-icon>
                            </div>
                        </div>
                    </l7-tile>
                </div>
            </mat-step>
            <mat-step [label]="'SIGN_LOCAL'">
                <l7-retailer-sign-contract-view [quoteDetails]="presenter.quoteDetails | async"
                                                [signer]="presenter.form.controls.signer.getRawValue()"
                                                [bankAccount]="presenter.form.controls.bankAccount.getRawValue()"
                                                [lessee]="presenter.lessee | async"
                                                [type]="presenter.type | async"
                                                [id]="presenter.quoteId | async"
                                                (contractClosed)="closeOnSuccess($event)">
                </l7-retailer-sign-contract-view>
            </mat-step>
            <mat-step [label]="'SIGN_REMOTE'">
                <form [formGroup]="presenter.form">
                    <div fxLayout="row"
                         fxLayout.lt-sm="column"
                         fxLayoutGap="16px"
                         [style.margin-top]="'16px'">
                        <mat-form-field appearance="outline"
                                        style="width: 80%"
                                        [ngClass]="{'warning': presenter.form.get('email').hasError('emailInvaid') }">
                            <mat-label>{{ 'E-mail Adresse' | translate }}</mat-label>
                            <input matInput
                                   l7MarkAsTouched
                                   type="text"
                                   [attr.test-id]="'email'"
                                   [formControlName]="'email'">
                            <div *ngIf="(presenter.form.get('email').statusChanges | async) === 'PENDING'"
                                 matSuffix>
                                <mat-progress-spinner [diameter]="16"
                                                      [strokeWidth]="2"
                                                      [color]="'primary'"
                                                      [mode]="'indeterminate'"></mat-progress-spinner>
                            </div>
                            <mat-error *ngIf="presenter.form.get('email').hasError('required')">
                                <p>{{ 'administration.retailers.create.input.email.error.required' | translate }}</p>
                            </mat-error>
                            <mat-error *ngIf="presenter.form.get('email').hasError('email')">
                                <p>{{ 'administration.retailers.create.input.email.error.email' | translate }}</p>
                            </mat-error>
                            <mat-error *ngIf="presenter.form.get('email').hasError('emailInvaid')">
                                <p>{{ 'administration.retailers.create.input.email.error.emailInvaid' | translate }}</p>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </mat-step>
        </mat-stepper>
    </ng-container>
</mat-dialog-content>
@if (stepperCreateContract?.selected.label === 'SIGN_REMOTE') {
    <mat-dialog-actions>
        <button mat-stroked-button
                color="primary"
                [attr.test-id]="'btnBack'"
                (click)="onBackToDecision()">{{'contract_management.back' | translate}}</button>
        <button mat-flat-button
                color="primary"
                [attr.test-id]="'btnSubmit'"
                (click)="presenter.onSubmit()">{{'contract_management.send_button' | translate}}</button>
    </mat-dialog-actions>
}

@if (stepperCreateContract?.selected.label === 'CHOOSE_SIGNING_TYPE' && stepperCreateContract?._steps.first.label === 'IDENTIFY_SIGNER') {
    <mat-dialog-actions>
        <button mat-stroked-button
                color="primary"
                [attr.test-id]="'btnBack'"
                (click)="onBackToDecision()">{{'contract_management.back' | translate}}
        </button>
    </mat-dialog-actions>
}

@if (stepperCreateContract?.selected.label === 'IDENTIFY_SIGNER') {
    <mat-dialog-actions>
        <button mat-flat-button
                color="primary"
                [attr.test-id]="'btnNext'"
                (click)="nextStep()">{{'global.further' | translate}}</button>
    </mat-dialog-actions>
}

