<l7-page>
  <l7-page-header [title]="'Identifizierung nach dem Geldwäschegesetz'"
                  [subTitle]="'Personalausweis des Unterzeichners - ' + (textEmitter$ | async)"></l7-page-header>
  <l7-page-content>
    <ng-container *ngIf="!isFeatureUploadFileActive">
      <router-outlet name="tab2"></router-outlet>
    </ng-container>

    <ng-container *ngIf="isFeatureUploadFileActive">
      <mat-tab-group mat-stretch-tabs
                     [dynamicHeight]="true"
                     [selectedIndex]="selectedTab"
                     [color]="'accent'">
        <mat-tab #uploadTab>
          <ng-template mat-tab-label>
            <div fxLayout="row"
                 fxLayoutAlign="start center"
                 fxLayoutGap="16px">
              <mat-icon *ngIf="!uploadTab.isActive"
                        [inline]="true"
                        [fontSet]="'l7'"
                        [fontIcon]="'Export_2'">
              </mat-icon>
              <mat-icon *ngIf="uploadTab.isActive"
                        color="primary"
                        [inline]="true"
                        [fontSet]="'l7'"
                        [fontIcon]="'Upload'">
              </mat-icon>
              <span [ngClass]="{'activeTab': uploadTab.isActive}">Ausweis hochladen</span>
            </div>
          </ng-template>
          <router-outlet name="tab1"></router-outlet>
        </mat-tab>
        <mat-tab #cameraTab
                 [disabled]="!cameraEnabled">
          <ng-template mat-tab-label>
            <div fxLayout="row"
                 fxLayoutAlign="start center"
                 fxLayoutGap="16px"
                 class="camera-indicator-icon">
              <mat-icon *ngIf=" !cameraTab.isActive"
                        [inline]="true"
                        [fontSet]="'l7'"
                        [fontIcon]="'Camera'">
              </mat-icon>
              <mat-icon *ngIf="cameraTab.isActive"
                        color="primary"
                        [inline]="true"
                        [fontSet]="'l7'"
                        [fontIcon]="'Camera_Filled'">
              </mat-icon>
              <span [ngClass]="{'activeTab': cameraTab.isActive}">Ausweis aufnehmen</span>
            </div>
          </ng-template>
          <router-outlet name="tab2"></router-outlet>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </l7-page-content>
</l7-page>