@let retailer = presenter.retailer();

<l7-page fxFlex>
    @if(retailer) {
        <l7-page-header #header
                        [title]="retailer?.retailer.name"
                        [hasBackNavigation]="true"></l7-page-header>
        <l7-page-content fxFlex>
            <form #form="ngForm"
                  [formGroup]="presenter.form"
                  (ngSubmit)="presenter.onSubmit()"
                  (keydown.enter)="$event.preventDefault()">
                    <mat-tab-group [selectedIndex]="presenter.selectedTabIndex()"
                                   (selectedIndexChange)="presenter.onTabIndexChanged($event)">
                        <mat-tab [label]="'Profil' | translate">
                            <div cdkScrollable
                                 fxLayout="column"
                                 fxLayoutGap="24px">
                                <mat-card appearance="outlined"
                                          class="mat-card-outlined">
                                    <mat-card-header>
                                        <mat-card-title>{{ 'administration.retailers.create.step.contact.section.address.title' | translate }}</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content fxLayout="column"
                                                      [formGroupName]="'retailer'">
                                        <div fxLayout="row"
                                             fxLayout.lt-sm="column"
                                             fxLayoutGap="16px">
                                            <mat-form-field fxFlex
                                                            appearance="outline">
                                                <mat-label>{{ 'administration.retailers.create.input.name.label' | translate }}</mat-label>
                                                <input matInput
                                                       required
                                                       type="text"
                                                       [formControlName]="'name'">
                                                <mat-error *ngIf="presenter.form.get('retailer.name')?.hasError('required')">
                                                    <p>{{ 'administration.retailers.create.input.name.error.required' | translate }}</p>
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field fxFlex
                                                            appearance="outline">
                                                <mat-label>{{ 'administration.retailers.create.input.streetAndHousenumber.label' | translate }}</mat-label>
                                                <input matInput
                                                       required
                                                       type="text"
                                                       [formControlName]="'streetAndHousenumber'">
                                                <mat-error *ngIf="presenter.form.get('retailer.streetAndHousenumber').hasError('required')">
                                                    <p>{{ 'administration.retailers.create.input.streetAndHousenumber.error.required' | translate }}</p>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="row"
                                             fxLayout.lt-sm="column"
                                             fxLayoutGap="16px">
                                            <mat-form-field fxFlex
                                                            appearance="outline">
                                                <mat-label>{{ 'administration.retailers.create.input.postalCode.label' | translate }}</mat-label>
                                                <input matInput
                                                       required
                                                       type="text"
                                                       [formControlName]="'postalCode'">
                                                <mat-error *ngIf="presenter.form.get('retailer.postalCode').hasError('required')">
                                                    <p>{{ 'administration.retailers.create.input.postalCode.error.required' | translate }}</p>
                                                </mat-error>
                                                <mat-error *ngIf="presenter.form.get('retailer.postalCode').hasError('postCode')">
                                                    <p>{{ 'administration.retailers.create.input.postalCode.error.postCode' | translate }}</p>
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field fxFlex
                                                            appearance="outline">
                                                <mat-label>{{ 'administration.retailers.create.input.city.label' | translate }}</mat-label>
                                                <input matInput
                                                       required
                                                       type="text"
                                                       [formControlName]="'city'">
                                                <mat-error *ngIf="presenter.form.get('retailer.city')?.hasError('required')">
                                                    <p>{{ 'administration.retailers.create.input.city.error.required' | translate }}</p>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                                <mat-card appearance="outlined"
                                          class="mat-card-outlined">
                                    <mat-card-header>
                                        <mat-card-title>{{ 'administration.retailers.create.step.contact.section.partnerInfo.title' | translate }}</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content fxLayout="column"
                                                      [formGroupName]="'retailer'">
                                        <div fxLayout="row"
                                             fxLayout.lt-sm="column"
                                             fxLayoutGap="16px">
                                            <mat-form-field fxFlex
                                                            appearance="outline">
                                                <mat-label>{{ 'administration.retailers.create.input.partnerNumber.label' | translate }}</mat-label>
                                                <input matInput
                                                       required
                                                       type="text"
                                                       [formControlName]="'partnerNumber'">
                                                <div *ngIf="(presenter.form.get('retailer.partnerNumber')?.statusChanges | async) === 'PENDING'"
                                                     matSuffix>
                                                    <mat-progress-spinner [diameter]="16"
                                                                          [strokeWidth]="2"
                                                                          [color]="'primary'"
                                                                          [mode]="'indeterminate'"></mat-progress-spinner>
                                                </div>
                                                <mat-error *ngIf="presenter.form.get('retailer.partnerNumber').hasError('required')">
                                                    <p>{{ 'administration.retailers.create.input.partnerNumber.error.required' | translate }}</p>
                                                </mat-error>
                                                <mat-error *ngIf="presenter.form.get('retailer.partnerNumber').hasError('maxlength')">
                                                    <p>{{ 'administration.retailers.create.input.partnerNumber.error.maxlength' | translate }}</p>
                                                </mat-error>
                                                <mat-error *ngIf="presenter.form.get('retailer.partnerNumber').hasError('minlength')">
                                                    <p>{{ 'administration.retailers.create.input.partnerNumber.error.minlength' | translate }}</p>
                                                </mat-error>
                                                <mat-error *ngIf="presenter.form.get('retailer.partnerNumber').hasError('partnerNumberExists')">
                                                    <p>{{ 'administration.retailers.create.input.partnerNumber.error.partnerNumberExists' | translate }}</p>
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field fxFlex
                                                            appearance="outline">
                                                <mat-label>{{ 'administration.retailers.create.input.contactNumber.label' | translate}}</mat-label>
                                                <input matInput
                                                       required
                                                       type="text"
                                                       [formControlName]="'contactNumber'">
                                                <div *ngIf="(presenter.form.get('retailer.contactNumber')?.statusChanges | async) === 'PENDING'"
                                                     matSuffix>
                                                    <mat-progress-spinner [diameter]="16"
                                                                          [strokeWidth]="2"
                                                                          [color]="'primary'"
                                                                          [mode]="'indeterminate'"></mat-progress-spinner>
                                                </div>
                                                <mat-error *ngIf="presenter.form.get('retailer.contactNumber').hasError('required')">
                                                    <p>{{ 'administration.retailers.create.input.contactNumber.error.required' | translate }}</p>
                                                </mat-error>
                                                <mat-error *ngIf="presenter.form.get('retailer.contactNumber').hasError('contactNumberExists')">
                                                    <p>{{ 'administration.retailers.create.input.contactNumber.error.contactNumberExists' | translate }}</p>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="row"
                                             fxLayout.lt-sm="column"
                                             fxLayoutGap="16px">
                                            <mat-form-field fxFlex
                                                            appearance="outline">
                                                <mat-label>{{ 'administration.retailers.create.input.client.label' | translate }}</mat-label>
                                                <mat-select required
                                                            [formControlName]="'client'"
                                                            (selectionChange)="presenter.onClientChanged()">
                                                    <mat-option *ngFor="let client of presenter.clients | async"
                                                                [value]="client">{{ 'client.' + client | translate }}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="presenter.form.get('retailer.client').hasError('required')">
                                                    <p>{{ 'administration.retailers.create.input.client.error.required' | translate }}</p>
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field fxFlex
                                                            appearance="outline">
                                                <mat-label>{{ 'administration.retailers.create.input.factor.label' | translate}}</mat-label>
                                                <mat-select required
                                                            [formControlName]="'factor'">
                                                    <mat-option *ngFor="let factor of presenter.factors | async"
                                                                [value]="factor">{{ 'factor.' + factor | translate }}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="presenter.form.get('retailer.factor')?.hasError('required')">
                                                    <p>{{ 'administration.retailers.create.input.factor.error.required' | translate }}</p>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                                <mat-card appearance="outlined"
                                          class="mat-card-outlined">
                                    <mat-card-header>
                                        <mat-card-title>{{ 'Status des Fachhändlers' | translate }}</mat-card-title>
                                        <mat-card-subtitle>{{ 'Wenn Sie den Fachhändler deaktivieren hat dieser keinen Zugriff mehr auf Lease Seven.' | translate }}</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content [formGroupName]="'retailerConfig'">
                                        <mat-slide-toggle [formControlName]="'active'">{{ 'aktiv' | translate }}</mat-slide-toggle>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </mat-tab>
                        <mat-tab [label]="'Einstellungen' | translate">
                            <div cdkScrollable
                                 fxLayout="column"
                                 fxLayoutGap="24px">
                                <mat-card appearance="outlined"
                                          class="mat-card-outlined">
                                    <mat-card-header>
                                        <mat-card-title>{{'Vertragsarten' | translate }}</mat-card-title>
                                        <mat-card-subtitle>{{ 'Bitte wählen Sie hier, welche Vertragsarten dem Fachhändler zur Verfügung stehen' | translate }}</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content [formGroupName]="'retailerConfig'">
                                        <l7-checkbox-group [formControlName]="'contractTypes'">
                                            <div fxLayout="column"
                                                 fxLayoutGap="16px">
                                                @for(type of presenter.availableContractTypes() | keyvalue; track type.key;) {
                                                    <mat-checkbox [value]="type.key">
                                                        <p>{{ 'contractType.' + type.key | translate }} <span class="secondary-text">{{type.value}}</span></p>
                                                    </mat-checkbox>
                                                }
                                            </div>
                                        </l7-checkbox-group>
                                        <mat-error *ngIf="presenter.form.get('retailerConfig.contractTypes')?.hasError('required')"
                                                   class="mat-error-standalone">
                                            <p>{{ 'administration.retailers.create.input.contractTypes.error.required' | translate }}</p>
                                        </mat-error>
                                    </mat-card-content>
                                </mat-card>
                                <mat-card appearance="outlined"
                                          class="mat-card-outlined">
                                    <mat-card-header>
                                        <mat-card-title>{{ 'Objektarten' | translate }}</mat-card-title>
                                        <mat-card-subtitle>{{ 'Bitte wählen Sie hier, welche Objektarten dem Fachhändler zur Verfügung stehen' | translate }}</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content fxLayout="column"
                                                      [formGroupName]="'retailerConfig'">
                                        @if(!!presenter.objectGroups()?.length) {
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{ 'Objektarten' | translate }}</mat-label>
                                                <mat-chip-grid #chipList
                                                               [formControlName]="'objectGroupCodes'">
                                                    <mat-chip-row *ngFor="let code of presenter.form.get('retailerConfig.objectGroupCodes')?.value"
                                                                  [color]="'primary'"
                                                                  [value]="code"
                                                                  (removed)="presenter.onObjectGroupRemove(presenter.getObjectGroupFromCode(code))">
                                                        <span>{{ presenter.getObjectGroupFromCode(code)?.name }}</span>
                                                        <button matChipRemove>
                                                            <mat-icon [fontSet]="'l7'"
                                                                      [fontIcon]="'Cross_Circle_Filled'"></mat-icon>
                                                        </button>
                                                    </mat-chip-row>
                                                    <input #autoCompleteInput
                                                           #matAutocompleteTrigger="matAutocompleteTrigger"
                                                           required
                                                           l7AutocompleteScrollHandler
                                                           [placeholder]="'Weitere Kategorie...' | translate"
                                                           [matAutocomplete]="autocomplete"
                                                           [matChipInputFor]="chipList"
                                                           [autocompleteTriggerRef]="matAutocompleteTrigger"
                                                           (input)="presenter.onFilterObjectGroups($event.target.value)">
                                                </mat-chip-grid>
                                                <mat-autocomplete #autocomplete
                                                                  class="autocomplete-options"
                                                                  (optionSelected)="presenter.onObjectGroupSelected($event.option.value, matAutocompleteTrigger, autoCompleteInput)">
                                                    <mat-option *ngFor="let group of presenter.filteredObjectGroups()"
                                                                [value]="group">{{ group.code + ': ' + group.name }}</mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        }
                                    </mat-card-content>
                                </mat-card>
                                <mat-card appearance="outlined"
                                          class="mat-card-outlined">
                                    <mat-card-header>
                                        <mat-card-title>Bearbeitungsgebühr <span *ngIf="presenter.clientHandlingFee | async as clientHandlingFee">({{clientHandlingFee | currency}})</span></mat-card-title>
                                        <mat-card-subtitle>{{ 'Bitte wählen Sie hier, ob der Fachhändler eine Bearbeitungsgebühr erheben darf' | translate }}</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content [formGroupName]="'retailerConfig'">
                                        <mat-radio-group fxLayout="column"
                                                         fxLayoutGap="16px"
                                                         [formControlName]="'handlingFeeOption'">
                                            <mat-radio-button [value]="'ALWAYS_CHARGE'">
                                                <l7-text-block [wrap]="true"
                                                               [text]="'Ja, der Fachhändler ist verpflichtet eine Bearbeitungsgebühr zu erheben' | translate"></l7-text-block>
                                            </mat-radio-button>
                                            <mat-radio-button [value]="'NEVER_CHARGE'">
                                                <l7-text-block [wrap]="true"
                                                               [text]="'Nein, der Fachhändler darf keine Bearbeitungsgebühr erheben' | translate"></l7-text-block>
                                            </mat-radio-button>
                                            <mat-radio-button [value]="'CUSTOMIZABLE'">
                                                <l7-text-block [wrap]="true"
                                                               [text]="'Der Fachhändler darf frei wählen, ob er eine Bearbeitungsgebühr erhebt' | translate"></l7-text-block>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </mat-card-content>
                                </mat-card>
                                <mat-card appearance="outlined"
                                          class="mat-card-outlined">
                                    <mat-card-header>
                                        <mat-card-title>{{ 'Provision' | translate }}</mat-card-title>
                                        <mat-card-subtitle>{{ 'Bitte wählen Sie hier, ob der Fachhändler die Höhe der voreingestellten Provision (0 - 5 %) selbst festlegen darf' | translate }}</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content [formGroupName]="'retailerConfig'">
                                        <div fxLayout="column"
                                             fxLayoutGap="16px">
                                            <mat-radio-group fxLayout="column"
                                                             fxLayoutGap="16px"
                                                             [formControlName]="'adjustableProvision'">
                                                <mat-radio-button [value]="true">
                                                    <p>{{ 'global.yes' | translate }}</p>
                                                </mat-radio-button>
                                                <mat-radio-button [value]="false">
                                                    <p>{{ 'global.no' | translate }}</p>
                                                </mat-radio-button>
                                            </mat-radio-group>
                                            <p>{{ 'Voreingestellter Wert für Fachhändler' | translate }}</p>
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{ 'Provision' | translate }}</mat-label>
                                                <input matInput
                                                       required
                                                       currencyMask
                                                       [options]="{suffix: ' %', precision: 1}"
                                                       [formControlName]="'provision'">
                                                <mat-error *ngIf="presenter.form.get('retailerConfig.provision')?.hasError('required')">
                                                    <p>{{ 'administration.retailers.create.input.provision.error.required' | translate }}</p>
                                                </mat-error>
                                                <mat-error *ngIf="presenter.form.get('retailerConfig.provision')?.hasError('min')">
                                                    <p>{{ 'administration.retailers.create.input.provision.error.min' | translate }}</p>
                                                </mat-error>
                                                <mat-error *ngIf="presenter.form.get('retailerConfig.provision')?.hasError('max')">
                                                    <p>{{ 'administration.retailers.create.input.provision.error.max' | translate }}</p>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                                <mat-card appearance="outlined"
                                          class="mat-card-outlined">
                                    <mat-card-header>
                                        <mat-card-title>{{ 'Vormiete' | translate }}</mat-card-title>
                                        <mat-card-subtitle>{{ 'Bitte wählen Sie, ob der Fachhändler eine Vormiete erheben darf' | translate }}</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content [formGroupName]="'retailerConfig'">
                                        <mat-radio-group fxLayout="column"
                                                         fxLayoutGap="16px"
                                                         [formControlName]="'preRent'">
                                            <mat-radio-button [value]="true">
                                                <p>{{'global.yes' | translate}}</p>
                                            </mat-radio-button>
                                            <mat-radio-button [value]="false">
                                                <p>{{'global.no' | translate}}</p>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </mat-tab>
                        <mat-tab [label]="'Nutzer' | translate">
                            <div fxLayout="row"
                                 fxLayoutAlign="start start"
                                 fxLayoutGap="16px">
                                <mat-form-field fxFlex.lt-md="100"
                                                fxFlex="40"
                                                appearance="outline">
                                    <mat-label>{{ 'administration.retailers.overview.search' | translate }}</mat-label>
                                    <input #term="matInput"
                                           matInput
                                           type="search"
                                           [attr.test-id]="'TermInput'"
                                           [value]="this.presenter.condition()?.term"
                                           (keyup.enter)="onTermChanged(term.value)">
                                    <button mat-icon-button
                                            matSuffix
                                            type="button"
                                            (click)="this.presenter.onTermChanged(term.value)">
                                        <mat-icon [fontSet]="'l7'"
                                                  [fontIcon]="'Search'"></mat-icon>
                                    </button>
                                </mat-form-field>
                                <div fxFlex
                                     class="clear-all-container">
                                    <button *ngIf="(this.presenter.isDefaultSearchCondition | async) === false"
                                            mat-button
                                            type="button"
                                            [color]="'warn'"
                                            [attr.test-id]="'ResetConditionButton'"
                                            (click)="this.presenter.onResetConditions()">{{ 'administration.retailers.overview.filter.reset' | translate }}</button>
                                </div>
                                <div class="quick-action-container">
                                    <button mat-flat-button
                                            type="button"
                                            [color]="'primary'"
                                            [attr.test-id]="'CreateRetailerButton'"
                                            (click)="this.presenter.onCreateRetailerUser()">{{ 'Neuer Nutzer' | translate }}</button>
                                </div>
                            </div>
                            <mat-card appearance="outlined"
                                      class="mat-card-outlined">
                                <mat-card-content>
                                    <mat-table [attr.test-id]="'OverviewTable'"
                                               [dataSource]="this.presenter.dataSource()">
                                        <ng-container *ngFor="let column of this.presenter.columns"
                                                      [matColumnDef]="column">
                                            <mat-header-cell *matHeaderCellDef>
                                                <l7-text-block [truncate]="true"
                                                               [text]="'administration.retailers.edit.table.' + column | translate"></l7-text-block>
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let row">
                                                <ng-container [ngSwitch]="column">
                                                    <ng-container *ngSwitchCase="'active'">
                                                        <mat-chip-listbox>
                                                            <mat-chip-option class="text-truncate"
                                                                             [color]="row[column] ? 'primary' : 'default'">
                                                                <l7-text-block [truncate]="true"
                                                                               [text]="(row[column] ? 'administration.global.active' : 'administration.global.inactive') | translate"></l7-text-block>
                                                            </mat-chip-option>
                                                        </mat-chip-listbox>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'groups'">
                                                        <l7-text-block [truncate]="true"
                                                                       [text]="presenter.translateRoles(row[column])"></l7-text-block>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        <l7-text-block [truncate]="true"
                                                                       [text]="row | memberValue:column"></l7-text-block>
                                                    </ng-container>
                                                </ng-container>
                                            </mat-cell>
                                        </ng-container>
                                        <mat-header-row *matHeaderRowDef="this.presenter.columns"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: this.presenter.columns;"
                                                 matRipple
                                                 (click)="presenter.onRowSelected(row)"></mat-row>
                                    </mat-table>
                                    <mat-paginator #paginator
                                                   fxLayout="row"
                                                   fxLayoutAlign="center center"
                                                   showFirstLastButtons
                                                   [length]="this.presenter.dataSourceTotal()"
                                                   [pageSize]="this.presenter.pageSize()"
                                                   [pageSizeOptions]="this.presenter.pageSizes | async"
                                                   (page)="this.presenter.onPageChanged($event)">
                                    </mat-paginator>
                                </mat-card-content>
                            </mat-card>
                        </mat-tab>
                        <mat-tab [label]="'Ansprechpartner' | translate">
                            <div cdkScrollable
                                 fxLayout="column"
                                 fxLayoutGap="24px">
                                <mat-card appearance="outlined"
                                          class="mat-card-outlined">
                                    <mat-card-header>
                                        <mat-card-title>Innendienst</mat-card-title>
                                        <mat-card-subtitle>Ansprechpartner bei der abcfinance</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content [formGroupName]="'contact'">
                                        <mat-card-content [formGroupName]="'officeService'">
                                            <div fxLayout="row"
                                                 fxLayout.lt-sm="column"
                                                 fxLayoutGap="16px">
                                                <mat-form-field fxFlex
                                                                appearance="outline">
                                                    <mat-label>Vorname</mat-label>
                                                    <input matInput
                                                           [formControlName]="'givenName'">
                                                    <mat-error *ngIf="presenter.form.get('contact.officeService.givenName')?.invalid">
                                                        <p *ngFor="let error of presenter.getInputErrors('contact.officeService.givenName')">
                                                            {{ this.presenter.getErrorMessage('givenName', error) }}
                                                        </p>
                                                    </mat-error>
                                                </mat-form-field>
                                                <mat-form-field fxFlex
                                                                appearance="outline">
                                                    <mat-label>Nachname</mat-label>
                                                    <input matInput
                                                           [formControlName]="'familyName'">
                                                    <mat-error *ngIf="presenter.form.get('contact.officeService.familyName')?.invalid">
                                                        <p *ngFor="let error of presenter.getInputErrors('contact.officeService.familyName')">
                                                            {{ this.presenter.getErrorMessage('familyName', error) }}
                                                        </p>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row"
                                                 fxLayout.lt-sm="column"
                                                 fxLayoutGap="16px">
                                                <mat-form-field fxFlex
                                                                appearance="outline">
                                                    <mat-label>E-Mail</mat-label>
                                                    <input matInput
                                                           [formControlName]="'email'">
                                                    <div *ngIf="(presenter.form.get('contact.officeService.email')?.statusChanges | async) === 'PENDING'"
                                                         matSuffix>
                                                        <mat-progress-spinner [diameter]="16"
                                                                              [strokeWidth]="2"
                                                                              [color]="'primary'"
                                                                              [mode]="'indeterminate'"></mat-progress-spinner>
                                                    </div>
                                                    @if(presenter.allowedTopLevelDomains()) {
                                                        <mat-hint>Zulässig: {{ presenter.allowedTopLevelDomains() }}</mat-hint>
                                                    }
                                                    <mat-error *ngIf="presenter.form.get('contact.officeService.email')?.invalid">
                                                        <p *ngFor="let error of presenter.getInputErrors('contact.officeService.email')">
                                                            {{ this.presenter.getErrorMessage('email', error) }}
                                                        </p>
                                                    </mat-error>
                                                </mat-form-field>
                                                <mat-form-field fxFlex
                                                                appearance="outline">
                                                    <mat-label>Telefonnummer</mat-label>
                                                    <input matInput
                                                           type="tel"
                                                           [formControlName]="'phoneNumber'">
                                                    <mat-error *ngIf="presenter.form.get('contact.officeService.phoneNumber')?.hasError('invalidPhoneNumber')">
                                                        <p>{{ 'Die Telefonnummer ist ungültig' | translate }}</p>
                                                    </mat-error>
                                                    <mat-error *ngIf="presenter.form.get('contact.officeService.phoneNumber')?.hasError('maxLengthExceeded')">
                                                        <p>{{ 'Die Telefonnummer ist zu lang' | translate }}</p>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </mat-card-content>
                                    </mat-card-content>
                                </mat-card>

                                <mat-card appearance="outlined"
                                          class="mat-card-outlined">
                                    <mat-card-header>
                                        <mat-card-title>Außendienst</mat-card-title>
                                        <mat-card-subtitle>Ansprechpartner bei der abcfinance</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content [formGroupName]="'contact'">
                                        <mat-card-content [formGroupName]="'fieldService'">
                                            <div fxLayout="row"
                                                 fxLayout.lt-sm="column"
                                                 fxLayoutGap="16px">
                                                <mat-form-field fxFlex
                                                                appearance="outline">
                                                    <mat-label>Vorname</mat-label>
                                                    <input matInput
                                                           [formControlName]="'givenName'">
                                                    <mat-error *ngIf="presenter.form.get('contact.fieldService.givenName')?.invalid">
                                                        <p *ngFor="let error of presenter.getInputErrors('contact.fieldService.givenName')">
                                                            {{ this.presenter.getErrorMessage('givenName', error) }}
                                                        </p>
                                                    </mat-error>
                                                </mat-form-field>
                                                <mat-form-field fxFlex
                                                                appearance="outline">
                                                    <mat-label>Nachname</mat-label>
                                                    <input matInput
                                                           [formControlName]="'familyName'">
                                                    <mat-error *ngIf="presenter.form.get('contact.fieldService.familyName')?.invalid">
                                                        <p *ngFor="let error of presenter.getInputErrors('contact.fieldService.familyName')">
                                                            {{ this.presenter.getErrorMessage('familyName', error) }}
                                                        </p>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row"
                                                 fxLayout.lt-sm="column"
                                                 fxLayoutGap="16px">
                                                <mat-form-field fxFlex
                                                                appearance="outline">
                                                    <mat-label>E-Mail</mat-label>
                                                    <input matInput
                                                           [formControlName]="'email'">
                                                    <div *ngIf="(presenter.form.get('contact.fieldService.email')?.statusChanges | async) === 'PENDING'"
                                                         matSuffix>
                                                        <mat-progress-spinner [diameter]="16"
                                                                              [strokeWidth]="2"
                                                                              [color]="'primary'"
                                                                              [mode]="'indeterminate'"></mat-progress-spinner>
                                                    </div>
                                                    @if(presenter.allowedTopLevelDomains()) {
                                                        <mat-hint>Zulässig: {{ presenter.allowedTopLevelDomains() }}</mat-hint>
                                                    }
                                                    <mat-error *ngIf="presenter.form.get('contact.fieldService.email')?.invalid">
                                                        <p *ngFor="let error of presenter.getInputErrors('contact.fieldService.email')">
                                                            {{ this.presenter.getErrorMessage('email', error) }}
                                                        </p>
                                                    </mat-error>
                                                </mat-form-field>
                                                <mat-form-field fxFlex
                                                                appearance="outline">
                                                    <mat-label>Telefonnummer</mat-label>
                                                    <input matInput
                                                           type="tel"
                                                           [formControlName]="'phoneNumber'">
                                                    <mat-error *ngIf="presenter.form.get('contact.fieldService.phoneNumber')?.hasError('invalidPhoneNumber')">
                                                        <p>{{ 'Die Telefonnummer ist ungültig' | translate }}</p>
                                                    </mat-error>
                                                    <mat-error *ngIf="presenter.form.get('contact.fieldService.phoneNumber')?.hasError('maxLengthExceeded')">
                                                        <p>{{ 'Die Telefonnummer ist zu lang' | translate }}</p>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </mat-card-content>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
            </form>
        </l7-page-content>
        <l7-page-footer fxLayoutAlign="end"
                        fxLayout="row"
                        fxLayoutGap="16px">
            <button mat-button
                    type="button"
                    [color]="'accent'"
                    (click)="presenter.onSubmit(true)">Abbrechen</button>
            <button mat-flat-button
                    type="button"
                    [color]="'accent'"
                    (click)="form.onSubmit($event)">Speichern</button>
        </l7-page-footer>
    }
</l7-page>