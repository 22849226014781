// #region Imports

import { authGuard } from '@abcfinlab/auth';
import { Route } from '@angular/router';
import { ConfigurationOverviewView } from '../Components/configuration-overview-view/ConfigurationOverviewView';
import { CONFIGURATION_OVERVIEW_ROUTE_PATH } from './RoutePaths';

// #endregion

/**
 * @internal
 */
export const CONFIGURATION_OVERVIEW_ROUTE: Route = {
    path: CONFIGURATION_OVERVIEW_ROUTE_PATH,
    component: ConfigurationOverviewView,
    canActivate: [authGuard],
};
