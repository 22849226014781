import { RetailerCalculationResult } from '@abcfinlab/api-global';
import { Component, computed, inject, input, output } from '@angular/core';
import {
    RetailerCalculateLeasingQuoteFormService,
} from '../../Services/forms/retailer-calculate-leasing-quote-form/retailer-calculate-leasing-quote-form.service';

@Component({
    selector: 'l7-retailer-calculation-rates-selection',
    templateUrl: './retailer-calculation-rates-selection.component.html',
    styleUrl: './retailer-calculation-rates-selection.component.scss',
    standalone: false,
})
export class RetailerCalculationRatesSelectionComponent {

    private readonly _retailerCalculateQuoteFormService = inject(RetailerCalculateLeasingQuoteFormService, { skipSelf: true });

    rateOptions = computed(() => this._retailerCalculateQuoteFormService.calculationSelection()?.results);
    isIdle = computed(() => this._retailerCalculateQuoteFormService.calculationSelectionStatus() === 0);
    isLoading = this._retailerCalculateQuoteFormService.isCalculationSelectionLoading;
    readonly rateFormControl = this._retailerCalculateQuoteFormService.calculationSelectionControl;

    public onClick(rate: RetailerCalculationResult): void {
        this.rateFormControl.setValue(rate);
    }

}
