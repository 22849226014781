<l7-page motion
         [motionPreset]="'fadeSlideIn'">
    <l7-page-header [title]="'configuration.tile' | translate"></l7-page-header>
    <l7-page-content>
        <l7-spacer [thickness]="'all'">
            <l7-page-section>
                <mat-tab-group>
                    <mat-tab [label]="'configuration.tabs.profile' | translate">
                        <div fxLayout="column"
                             fxLayoutGap="24px">
                            <mat-card>
                                <mat-card-header>
                                    <mat-card-title>
                                        <div>{{'configuration.logo.title_info' | translate}}</div>
                                    </mat-card-title>
                                </mat-card-header>
                                <mat-card-content fxLayout="column"
                                                  fxLayoutGap="8px">
                                    <div fxLayout="row"
                                         fxLayoutGap="5px">
                                        <mat-card appearance="outlined" fxFlex="50"
                                                  class="mat-card-outlined">
                                            <mat-card-header>
                                                <h4 mat-card-title>{{'configuration.section.address.title' | translate}}</h4>
                                            </mat-card-header>

                                            <mat-card-content>
                                                <div *ngFor="let row of addressRows"
                                                     fxLayout="row"
                                                     fxLayoutAlign="center center"
                                                     class="info-row">
                                                    <l7-text-block class="contactInfo"
                                                                   fxFlex="50"
                                                                   [text]="'configuration.section.address.' + row | translate"></l7-text-block>
                                                    <ng-container [ngSwitch]="row">
                                                        <ng-container *ngSwitchCase="'company'">
                                                            <l7-text-block class="bold"
                                                                           fxFlex="50"
                                                                           fxLayoutAlign="flex-end"
                                                                           [text]="(presenter.retailerInfo | async)?.retailer.name"
                                                                           [truncate]="true"></l7-text-block>
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="'street'">
                                                            <l7-text-block class="bold"
                                                                           fxFlex="50"
                                                                           fxLayoutAlign="flex-end"
                                                                           [text]="(presenter.retailerInfo | async)?.retailer.streetAndHousenumber"
                                                                           [truncate]="true"></l7-text-block>
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="'postcode'">
                                                            <l7-text-block class="bold"
                                                                           fxFlex="50"
                                                                           fxLayoutAlign="flex-end"
                                                                           [text]="(presenter.retailerInfo | async)?.retailer.postalCode"
                                                                           [truncate]="true"></l7-text-block>
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="'city'">
                                                            <l7-text-block class="bold"
                                                                           fxFlex="50"
                                                                           fxLayoutAlign="flex-end"
                                                                           [text]="(presenter.retailerInfo | async)?.retailer.city"
                                                                           [truncate]="true"></l7-text-block>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                        <mat-card appearance="outlined" fxFlex="50"
                                                  class="mat-card-outlined">
                                            <mat-card-header>
                                                <h4 mat-card-title>{{'configuration.section.partnerInfo.title' | translate}}</h4>
                                            </mat-card-header>

                                            <mat-card-content>
                                                <div *ngFor="let row of partnerInfoRows"
                                                     fxLayout="row"
                                                     fxLayoutAlign="center flex-end"
                                                     class="info-row">
                                                    <l7-text-block class="contactInfo"
                                                                   fxFlex="50"
                                                                   [text]="'configuration.section.partnerInfo.' + row | translate"></l7-text-block>
                                                    <ng-container [ngSwitch]="row">
                                                        <ng-container *ngSwitchCase="'partnerNumber'">
                                                            <l7-text-block class="bold"
                                                                           fxFlex="50"
                                                                           fxLayoutAlign="flex-end"
                                                                           [text]="(presenter.retailerInfo | async)?.retailer.partnerNumber"></l7-text-block>
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="'contactNumber'">
                                                            <l7-text-block class="bold"
                                                                           fxFlex="50"
                                                                           fxLayoutAlign="flex-end"
                                                                           [text]="(presenter.retailerInfo | async)?.retailer.contactNumber"></l7-text-block>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                    <l7-spacer [thickness]="'horizontal'"
                                               [multiplier]="8"></l7-spacer>
                                    <p>
                                        {{'configuration.logo.description_info_part1' | translate}}
                                        {{(presenter.retailerInfo | async)?.contact.officeService.givenName}} {{(presenter.retailerInfo | async)?.contact.officeService.familyName}}
                                        <a class="contactMail"
                                           [href]="'mailto:' + presenter.officeServiceEmail">{{presenter.officeServiceEmail}}</a>
                                        {{'configuration.logo.description_info_part2' | translate}}
                                    </p>
                                </mat-card-content>

                            </mat-card>
                            <mat-card>
                                <mat-card-header>
                                    <mat-card-title>
                                        <div>{{'configuration.logo.title_logo' | translate}}</div>
                                    </mat-card-title>
                                    <p mat-card-subtitle>
                                        {{'configuration.logo.description_logo' | translate}}
                                    </p>
                                </mat-card-header>
                                <mat-card-content>
                                    <div fxFlex="100"
                                         fxLayout="row"
                                         fxLayoutGap="32px">
                                        <div fxLayout="column">
                                            <div class="image-container">
                                                <img *ngIf="(presenter.logoImageURL | async)"
                                                     alt="image"
                                                     [width]="defaultLogoConfig.width"
                                                     [height]="defaultLogoConfig.height"
                                                     [src]="(presenter.logoImageURL | async) | safe: 'resource'" />
                                            </div>
                                        </div>
                                        <div fxFlex="0 1 calc(60% - 16px)"
                                             fxFlex.lt-md="calc(50% - 16px)"
                                             fxFlexAlign="center"
                                             fxLayoutGap="16px"
                                             fxLayout="column">
                                            <div fxFlex="100"
                                                 fxLayout="row wrap">
                                                <p>{{'configuration.logo.recommended_size' | translate: {height: defaultLogoConfig.height, width: defaultLogoConfig.width } }}</p>
                                            </div>
                                            <div>
                                                <p>{{"configuration.logo.recommended_format" | translate}}</p>
                                            </div>
                                            <div fxFlex="100"
                                                 fxLayout="row"
                                                 fxLayoutGap="8px"
                                                 fxFlexAlign="center left">
                                                <input #fileInput
                                                       hidden
                                                       type="file"
                                                       id="uploaded-file"
                                                       [accept]="'image/*'"
                                                       (change)="uploadNewLogo($event)">
                                                <button *ngIf="!isCustomLogoUsed"
                                                        mat-flat-button
                                                        [color]="'accent'"
                                                        [attr.test-id]="'buttonChangeLogo'"
                                                        (click)="triggerFileUpload()">{{"configuration.logo.button_logo_change" | translate}}
                                                </button>
                                                <button *ngIf="isCustomLogoUsed"
                                                        mat-flat-button
                                                        [color]="'accent'"
                                                        [attr.test-id]="'buttonResetLogo'"
                                                        (click)="resetCustomLogo()">{{"configuration.logo.button_logo_reset" | translate}}
                                                </button>
                                                <button mat-button
                                                        [color]="'accent'"
                                                        [attr.test-id]="'buttonPdfPreview'"
                                                        (click)="showContractDraft()">{{"configuration.logo.pdf_preview" | translate}}</button>
                                            </div>

                                        </div>
                                    </div>
                                </mat-card-content>

                            </mat-card>
                        </div>
                    </mat-tab>
                    <mat-tab [label]="'configuration.tabs.settings' | translate">
                        <form fxLayout="column"
                              fxLayoutGap="24px"
                              [formGroup]="presenter.settingsForm">
                            <mat-card *ngIf="presenter.isAdjustableUserProvision">
                                <mat-card-header>
                                    <mat-card-title>
                                        <div>{{'configuration.settings.provision_title' | translate}}</div>
                                    </mat-card-title>
                                    <p mat-card-subtitle>
                                        {{'configuration.settings.provision_text' | translate}}
                                    </p>
                                </mat-card-header>
                                <mat-card-content fxLayout="row">
                                    <div fxLayout="column"
                                         fxLayoutGap="8px">
                                        <mat-form-field fxFlex
                                                        appearance="outline"
                                                        class="provision-input">
                                            <mat-label>{{'configuration.settings.provision_title' | translate}} in %</mat-label>
                                            <input matInput
                                                   required
                                                   currencyMask
                                                   type="text"
                                                   [options]="{suffix: ' %', precision: 1}"
                                                   [formControlName]="'provision'"
                                                   [attr.test-id]="'settings_provision'">
                                            <mat-error *ngIf="presenter.settingsForm.get('provision').valid !== true || presenter.settingsForm.get('provision').hasError('required')">{{'configuration.settings.provision_error' | translate}}</mat-error>
                                        </mat-form-field>
                                        <p class="secondary-text">
                                            {{'configuration.settings.provision_radio_text' | translate}}
                                        </p>
                                        <mat-radio-group fxLayout="column"
                                                         fxLayoutGap="8px"
                                                         [formControlName]="'adjustableUserProvision'">
                                            <mat-radio-button [color]="'primary'"
                                                              [value]="true"
                                                              [attr.test-id]="'adjustableUserProvisionYes'">
                                                <span class="radio-label">{{ 'global.yes' | translate }}</span>
                                            </mat-radio-button>
                                            <mat-radio-button [color]="'primary'"
                                                              [value]="false"
                                                              [attr.test-id]="'adjustableUserProvisionNO'">
                                                <span class="radio-label">{{ 'global.no' | translate }}</span>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                            <mat-card>
                                <mat-card-header>
                                    <mat-card-title>
                                        <div>{{'configuration.settings.object_value' | translate}}</div>
                                    </mat-card-title>
                                    <p mat-card-subtitle>
                                        {{'configuration.settings.object_value_text' | translate}}
                                    </p>
                                </mat-card-header>
                                <mat-card-content fxLayout="column"
                                                  fxLayoutGap="8px">
                                    <mat-radio-group fxLayout="column"
                                                     fxLayoutGap="8px"
                                                     [formControlName]="'showObjectValueOnOffer'">
                                        <mat-radio-button [color]="'primary'"
                                                          [value]="true"
                                                          [attr.test-id]="'showObjectValueOnOfferYes'">
                                            <span class="radio-label">{{ 'global.yes' | translate }}</span>
                                        </mat-radio-button>
                                        <mat-radio-button [color]="'primary'"
                                                          [value]="false"
                                                          [attr.test-id]="'showObjectValueOnOfferNo'">
                                            <span class="radio-label">{{ 'global.no' | translate }}</span>
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </mat-card-content>
                            </mat-card>
                            <mat-card>
                                <mat-card-header>
                                    <mat-card-title>
                                        <div>{{'configuration.settings.bank_account_title' | translate}}</div>
                                    </mat-card-title>
                                </mat-card-header>
                                <mat-card-content fxLayoutGap="8px">
                                    <div fxLayout="row"
                                         fxLayoutGap="24px">
                                        <mat-form-field appearance="outline"
                                                        [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'">
                                            <mat-label>{{'configuration.settings.bank_account_owner' | translate}}</mat-label>
                                            <input l7InputRef
                                                   matInput
                                                   required
                                                   l7MarkAsTouched
                                                   placeholder="Kontoinhaber"
                                                   formControlName="accountOwner"
                                                   id="input_bank-account-checker-account-owner">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline"
                                                        [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'">
                                            <mat-label>{{'configuration.settings.iban' | translate}}</mat-label>
                                            <input l7InputRef
                                                   required
                                                   matInput
                                                   l7MarkAsTouched
                                                   l7ValidateBankAccount
                                                   type="text"
                                                   placeholder="IBAN"
                                                   mask="AAAA AAAA AAAA AAAA AAAA AA"
                                                   formControlName="iban"
                                                   id="input_bank-account-checker-input_iban"
                                                   [validation]="false"
                                                   (keyup)="presenter.transformToUpperCase($event)"
                                                   (bankAccount)="presenter.handleBankAccountInfo($event)">
                                            <div *ngIf="(presenter.settingsForm.get('iban').statusChanges | async) === 'PENDING'"
                                                 matSuffix>
                                                <mat-progress-spinner [diameter]="16"
                                                                      [strokeWidth]="2"
                                                                      [color]="'primary'"
                                                                      [mode]="'indeterminate'"></mat-progress-spinner>
                                            </div>
                                            <mat-error *ngIf="presenter.settingsForm.get('iban').getError('invalidIban')">
                                                <p>{{ 'iban.errors.' + presenter.settingsForm.get('iban').errors.error | translate }}</p>
                                            </mat-error>
                                            <mat-error *ngIf="presenter.settingsForm.get('iban').getError('required')">
                                                <p>{{ 'iban.errors.required' | translate }}</p>
                                            </mat-error>
                                            <mat-icon *ngIf="presenter.settingsForm.get('iban').valid && (presenter.settingsForm.get('iban').statusChanges | async) !== 'PENDING'"
                                                      matSuffix
                                                      [color]="'success'"
                                                      [fontSet]="'l7'"
                                                      [fontIcon]="'Check'">
                                            </mat-icon>
                                        </mat-form-field>
                                    </div>
                                    <l7-text-block *ngIf="(presenter.bankAccountInfo | async)?.bic"
                                                   fxLayout="column"
                                                   class="bold bicInfo"
                                                   [text]="'BIC: ' + (presenter.bankAccountInfo | async)?.bic + ' / ' + (presenter.bankAccountInfo | async)?.bank_name"></l7-text-block>
                                </mat-card-content>
                            </mat-card>
                        </form>
                        <l7-spacer [thickness]="'all'">
                            <div *ngIf="(presenter.isSettingsChanged | async)"
                                 fxLayoutGap="8"
                                 fxLayout="row"
                                 fxLayoutAlign="flex-end center">
                                <button type="submit"
                                        mat-button
                                        [color]="'accent'"
                                        [attr.test-id]="'SettingsSaveButton'"
                                        (click)="resetChangedSettings()">
                                    {{ 'global.cancel' | translate }}
                                </button>
                                <button type="submit"
                                        mat-flat-button
                                        [color]="'accent'"
                                        [disabled]="presenter.settingsForm.valid === false"
                                        [attr.test-id]="'SettingsCancelButton'"
                                        (click)="updateRetailerSettings()">
                                    {{ 'global.save_changes' | translate }}
                                </button>
                            </div>
                        </l7-spacer>
                    </mat-tab>
                    <mat-tab [label]="'configuration.tabs.user' | translate">
                        <div fxLayout="column"
                             fxLayoutGap="24px">
                            <mat-card class="banner-border">
                                <mat-card-content fxLayout="row"
                                                  fxLayoutGap="24px">
                                    <mat-icon class="contactBanner"
                                              [inline]="false"
                                              [fontSet]="'l7'"
                                              [fontIcon]="'Alert_Triangle'"></mat-icon>
                                    <div class="banner-label-container">
                                        <p>
                                            {{'configuration.users.description_info_part1' | translate}}
                                            {{(presenter.retailerInfo | async)?.contact.officeService.givenName}} {{(presenter.retailerInfo | async)?.contact.officeService.familyName}}
                                            <a class="contactMail"
                                               [href]="sanitizedMailTo | async"
                                               (click)="getSanitizedMailTo()">{{presenter.officeServiceEmail}}</a>
                                            {{ 'configuration.users.description_info_part2' | translate}}
                                        </p>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                            <mat-card>
                                <mat-card-content>
                                    <mat-table [attr.test-id]="'OverviewTable'"
                                               [dataSource]="this.presenter.dataSource">
                                        <ng-container *ngFor="let column of this.presenter.columns"
                                                      [matColumnDef]="column">
                                            <mat-header-cell *matHeaderCellDef>
                                                <l7-text-block [truncate]="true"
                                                               [text]="'configuration.users.table.' + column | translate"></l7-text-block>
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let row">
                                                <ng-container [ngSwitch]="column">
                                                    <ng-container *ngSwitchCase="'active'">
                                                        <mat-chip-listbox>
                                                            <mat-chip-option class="text-truncate"
                                                                      [color]="row[column] ? 'primary' : 'default'">
                                                                <l7-text-block [truncate]="true"
                                                                               [text]="(row[column] ? 'administration.global.active' : 'administration.global.inactive') | translate"></l7-text-block>
                                                            </mat-chip-option>
                                                        </mat-chip-listbox>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'groups'">
                                                        <l7-text-block [truncate]="true"
                                                                       [text]="translateRoles(row[column])"></l7-text-block>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        <l7-text-block [truncate]="true"
                                                                       [text]="row | memberValue:column"></l7-text-block>
                                                    </ng-container>
                                                </ng-container>
                                            </mat-cell>
                                        </ng-container>
                                        <mat-header-row *matHeaderRowDef="this.presenter.columns"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: this.presenter.columns;"
                                                 matRipple></mat-row>
                                    </mat-table>
                                    <mat-paginator fxLayout="row"
                                                   fxLayoutAlign="center center"
                                                   showFirstLastButtons
                                                   [length]="this.presenter.dataSourceTotal | async"
                                                   [pageSize]="this.presenter.pageSize | async"
                                                   [pageSizeOptions]="this.presenter.pageSizes | async"
                                                   (page)="this.presenter.onPageChanged($event)">
                                    </mat-paginator>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </l7-page-section>
        </l7-spacer>
    </l7-page-content>
</l7-page>