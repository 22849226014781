/* libs/administration/src/Views/EditRetailerView.scss */
:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host .clear-all-container {
  margin: 16px 0;
}
:host .quick-action-container {
  margin: 16px 0;
}
.secondary-text {
  color: var(--mat-card-subtitle-text-color, var(--mat-sys-on-surface));
}
/*# sourceMappingURL=EditRetailerView.css.map */
