<l7-page motion
         [motionPreset]="'fadeSlideIn'">
    <l7-page-header [title]="'Unterzeichnete Verträge'"></l7-page-header>
    <l7-page-pre-content>
        <l7-page-section>
            <l7-filter *cdkBreakpoint="let bp"
                       [title]="'Suchen und Filtern'"
                       [expandable]="bp('lt-md') ? true : false"
                       [defaultCondition]="presenter.defaultCondition| async"
                       [condition]="presenter.condition | async"
                       [conditionComparer]="presenter.conditionComparer | async">
                <mat-form-field l7FilterPart
                                fxFlex.lt-md="100"
                                fxFlex="40"
                                appearance="outline">
                    <mat-label>{{ 'settlement.overview.filter.search' | translate }}</mat-label>
                    <input #term="matInput"
                           matInput
                           [attr.test-id]="'OverviewConditionTermInput'"
                           [value]="(this.presenter.condition | async).term"
                           (keyup.enter)="this.presenter.onTermChanged($event.target.value)">
                    <button mat-icon-button
                            matSuffix
                            (click)="this.presenter.onTermChanged(term.value)">
                        <mat-icon [fontSet]="'l7'"
                                  [fontIcon]="'Search'"></mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field l7FilterPart
                                appearance="outline"
                                fxFlex="20">
                    <mat-label>{{ 'Filter' | translate }}</mat-label>
                    <mat-select #select
                                multiple
                                [value]="(this.presenter.condition | async).firstOpenType"
                                [attr.test-id]="'OverviewConditionStatusSelect'"
                                (selectionChange)="this.presenter.onConditionChanged($event.value)">
                        <mat-select-trigger>
                            {{select.value ? ('contract_management.retailers.filterStates.' + select.value[0] | translate) : ''}}
                            <span *ngIf="select.value?.length > 1"
                                  style="opacity: 0.75; font-size: 0.75em;">
                                (+{{select.value.length - 1}} {{ 'settlement.overview.filter.more' | translate }})
                            </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let status of this.presenter.firstOpenType | async"
                                    [attr.test-id]="(status | lowercase) + 'Option'"
                                    [value]="status">{{ 'contract_management.retailers.filterStates.' + (status) | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <button *ngIf="(this.presenter.isDefaultSearchCondition | async) === false"
                        l7FilterClearAll
                        mat-button
                        color="warn"
                        [attr.test-id]="'ResetConditionButton'"
                        (click)="this.presenter.onResetConditions()">{{ 'settlement.overview.filter.reset' | translate }}</button>
            </l7-filter>
        </l7-page-section>
        <l7-page-section fxLayoutAlign="end">
            <button mat-icon-button
                    [color]="activeView === 'CARD' ? 'accent' : ''"
                    (click)="changeViewType('CARD')">
                <mat-icon [fontSet]="'l7'"
                          [fontIcon]="'Elements'"></mat-icon>
            </button>
            <button mat-icon-button
                    (click)="changeViewType('TABLE')">
                <mat-icon [color]="activeView === 'TABLE' ? 'accent' : ''"
                          [fontSet]="'l7'"
                          [fontIcon]="'Grid_15'"></mat-icon>
            </button>
        </l7-page-section>
    </l7-page-pre-content>
    <l7-page-content>
        <l7-page-section fxLayout="column">
            <div *ngIf="activeView === 'TABLE'">
                <mat-card>
                    <mat-card-content>
                        <mat-table [attr.test-id]="'OverviewTable'"
                                   [dataSource]="this.presenter.dataSource">
                            <ng-container *ngFor="let column of this.presenter.columns"
                                          [matColumnDef]="column">
                                <mat-header-cell *matHeaderCellDef>
                                    <span *ngIf="column !== 'state'"
                                          class="text-truncate">
                                        {{'quote.retailers.contractManagements.table.' + column | translate}}
                                    </span>
                                    <span *ngIf="column === 'state'"
                                          [matTooltip]="'quote.retailers.contractManagements.tooltips.state' | translate">
                                        {{'quote.retailers.contractManagements.table.' + column | translate}}
                                    </span>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <ng-container [ngSwitch]="column">
                                        <ng-container *ngSwitchCase="'state'">
                                            <mat-chip-listbox>
                                                <mat-chip class="text-truncate contract-state"
                                                          fxLayout="row"
                                                          fxLayoutAlign="space-between center"
                                                          fxLayoutGap="8px"
                                                          [ngClass]="'contract-state-'+ row.workflowState">
                                                    <mat-icon *ngIf="row.workflowState === 'OPEN'"
                                                              matChipAvatar
                                                              [fontSet]="'l7'"
                                                              [color]="'info'"
                                                              [fontIcon]="'Checkbox_Square'">
                                                    </mat-icon>
                                                    <mat-icon *ngIf="row.workflowState === 'COMPLETED'"
                                                              matChipAvatar
                                                              [fontSet]="'l7'"
                                                              [color]="'success'"
                                                              [fontIcon]="'Checkbox_Square'">
                                                    </mat-icon>
                                                    <mat-icon *ngIf="row.workflowState === 'PENDING'"
                                                              matChipAvatar
                                                              [fontSet]="'l7'"
                                                              [color]="'highlight'"
                                                              [fontIcon]="'Time'">
                                                    </mat-icon>
                                                    <mat-icon *ngIf="row.workflowState === 'ERROR'"
                                                              matChipAvatar
                                                              [fontSet]="'l7'"
                                                              [color]="'danger'"
                                                              [fontIcon]="'Cross_Circle'">
                                                    </mat-icon>
                                                    <l7-text-block [truncate]="true"
                                                                   [text]="row.tasksCompleted + '/'+ row.tasksTotal">
                                                    </l7-text-block>
                                                </mat-chip>
                                            </mat-chip-listbox>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'signingDate'">

                                            <l7-text-block [truncate]="true"
                                                           [text]="row[column] | date: 'shortDate'"></l7-text-block>
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            <l7-text-block [truncate]="true"
                                                           [text]="row | memberValue:column"></l7-text-block>
                                        </ng-container>
                                    </ng-container>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="this.presenter.columns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: this.presenter.columns;"
                                     matRipple
                                     (click)="presenter.showContractDetails(row)"></mat-row>
                        </mat-table>
                        <mat-paginator fxLayout="row"
                                       fxLayoutAlign="center center"
                                       showFirstLastButtons
                                       [length]="this.presenter.dataSourceTotal | async"
                                       [pageSize]="this.presenter.pageSize | async"
                                       [pageIndex]="presenter.pageIndex | async"
                                       [pageSizeOptions]="this.presenter.pageSizes | async"
                                       (page)="this.presenter.onPageChanged($event)">
                        </mat-paginator>
                    </mat-card-content>
                </mat-card>
            </div>
            <div *ngIf="activeView === 'CARD'">
                <l7-spacer [thickness]="'bottom'"
                           [multiplier]="24">
                    <p>
                        {{'quote.retailers.contractManagements.contractsList' | translate : {length: (presenter.retailerContractsList$ | async).length } }}
                    </p>
                </l7-spacer>
                <div fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     fxLayoutGap="24">
                    <mat-card *ngFor="let retailerContract of (presenter.retailerContractsList$ | async); let i = index"
                              fxFlex="30"
                              class="contractItem more-padding"
                              (click)="presenter.showContractDetails(retailerContract)">
                        <div fxFill
                             fxLayout="column">
                            <mat-card-title fxFlex="30"
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center"
                                            fxLayoutGap="8px">
                                <div fxFlex="70"
                                     class="contractItemTitle">
                                    {{ retailerContract.lesseeName }}
                                </div>
                                <mat-chip-listbox fxFlex="30"
                                               fxLayoutAlign="end end">
                                    <mat-chip class="text-truncate contract-state"
                                              fxLayout="row"
                                              fxLayoutAlign="center center"
                                              fxLayoutGap="8px"
                                              [ngClass]="'contract-state-'+ retailerContract.workflowState">
                                        <mat-icon *ngIf="retailerContract.workflowState === 'OPEN'"
                                                  matChipAvatar
                                                  [fontSet]="'l7'"
                                                  [color]="'info'"
                                                  [fontIcon]="'Checkbox_Square'">
                                        </mat-icon>
                                        <mat-icon *ngIf="retailerContract.workflowState === 'COMPLETED'"
                                                  matChipAvatar
                                                  [fontSet]="'l7'"
                                                  [color]="'success'"
                                                  [fontIcon]="'Checkbox_Square'">
                                        </mat-icon>
                                        <mat-icon *ngIf="retailerContract.workflowState === 'PENDING'"
                                                  matChipAvatar
                                                  [fontSet]="'l7'"
                                                  [color]="'highlight'"
                                                  [fontIcon]="'Time'">
                                        </mat-icon>
                                        <mat-icon *ngIf="retailerContract.workflowState === 'ERROR'"
                                                  matChipAvatar
                                                  [fontSet]="'l7'"
                                                  [color]="'danger'"
                                                  [fontIcon]="'Cross_Circle'">
                                        </mat-icon>
                                        <l7-text-block [truncate]="true"
                                                       [text]="retailerContract.tasksCompleted + '/'+ retailerContract.tasksTotal">
                                        </l7-text-block>
                                    </mat-chip>
                                </mat-chip-listbox>
                            </mat-card-title>
                            <l7-spacer [thickness]="'bottom'"
                                       [multiplier]="12"></l7-spacer>
                            <mat-card-subtitle fxFlex="70">{{ ('quote.retailers.contractManagements.card.contractNumber' | translate) + retailerContract.contractNumber | translate }}</mat-card-subtitle>
                        </div>
                        <mat-card-content class="padding-top-only">
                            <div class="info-row"
                                 [fxLayout]="!isMobile? 'row': 'column'"
                                 [fxLayoutAlign]="!isMobile? 'center center': 'start start'">
                                <l7-text-block class="contactInfo"
                                               fxFlex="50"
                                               [text]="'quote.retailers.contractManagements.card.objectDescription'| translate">
                                </l7-text-block>
                                <l7-text-block fxFlex="50"
                                               fxLayoutAlign="flex-end"
                                               [text]="retailerContract.objectDescription"
                                               [truncate]="true"></l7-text-block>
                            </div>
                            <div class="info-row"
                                 [fxLayout]="!isMobile? 'row': 'column'"
                                 [fxLayoutAlign]="!isMobile? 'center center': 'start start'">
                                <l7-text-block class="contactInfo"
                                               fxFlex="50"
                                               [text]="'quote.retailers.contractManagements.card.contractType'| translate"></l7-text-block>
                                <l7-text-block fxFlex="50"
                                               fxLayoutAlign="flex-end"
                                               [text]="retailerContract.contractType"></l7-text-block>
                            </div>
                            <div class="info-row"
                                 [fxLayout]="!isMobile? 'row': 'column'"
                                 [fxLayoutAlign]="!isMobile? 'center center': 'start start'">
                                <l7-text-block class="contactInfo"
                                               fxFlex="50"
                                               [text]="'quote.retailers.contractManagements.card.objectValue'| translate"></l7-text-block>
                                <l7-text-block fxFlex="50"
                                               fxLayoutAlign="flex-end"
                                               [text]="retailerContract.objectValue | currency"></l7-text-block>
                            </div>
                            <div class="info-row"
                                 [fxLayout]="!isMobile? 'row': 'column'"
                                 [fxLayoutAlign]="!isMobile? 'center center': 'start start'">
                                <l7-text-block class="contactInfo"
                                               fxFlex="50"
                                               [text]="'quote.retailers.contractManagements.card.signingDate'| translate"></l7-text-block>
                                <l7-text-block fxFlex="50"
                                               fxLayoutAlign="flex-end"
                                               [text]="(retailerContract.signingDate | date: 'shortDate')"></l7-text-block>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </l7-page-section>
    </l7-page-content>
</l7-page>
