<mat-card fxFlex
          fxLayout="column"
          [appearance]="'outlined'">
    <l7-text matCardTitle
             class="padding"
             [text]="'Das Dashboard ist da!'| translate"
             [truncate]="true"></l7-text>
    <mat-card-content fxFlex
                      fxLayout="column"
                      fxLayoutAlign="center">
        <!-- <l7-text-block [text]="'Du hast Ideen für weitere Dashboard-Kacheln oder einfach einen Verbesserungsvorschlag?' | translate"
                       [wrap]="true"></l7-text-block> -->
        <!-- <l7-text-block [text]="'Sende uns jetzt schnelles und einfaches Feedback mit einem Klick auf den \'Give feedback\'-Button am rechten Bildschirmrand!' | translate"
                       [wrap]="true"></l7-text-block> -->
        <span>Du hast Ideen für weitere Dashboard-Kacheln oder einfach einen Verbesserungsvorschlag?<br><br>Sende uns jetzt schnelles und einfaches Feedback<br>mit einem Klick auf den <a class="link"
               (click)="presenter.onFeedback()">'Give feedback'</a>-Button am rechten Bildschirmrand!</span>
    </mat-card-content>
    <!-- <mat-card-actions fxLayoutAlign="end center">
        <button class="feedback-button"
                mat-button
                [color]="'warn'"
                (click)="presenter.onFeedback()">{{ 'Give feedback' }}</button>
    </mat-card-actions> -->
</mat-card>
