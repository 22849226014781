/**
 * A utility class for parsing and formatting strings using a custom pseudo-language.
 */
export class PseudoStringFormatter {

    /**
     * Formats a string using the custom pseudo-language.
     *
     * Supported syntax:
     * - `${fieldName}:truthy:+N`: Adds N spaces if the value is truthy.
     * - `${fieldName}:falsy:-N`: Removes N spaces if the value is falsy.
     * - `${fieldName}:default:DefaultValue`: Uses DefaultValue if the field is falsy.
     * - `${fieldName}:if:ConditionValue:Output`: Outputs "Output" if the field matches ConditionValue.
     * - `${fieldName}:uppercase`: Converts the value to uppercase.
     * - `${fieldName}:lowercase`: Converts the value to lowercase.
     * - `${fieldName}:date:YYYY-MM-DD`: Formats a date value using a provided format.
     * - `${fieldName}:number:0.00`: Formats a numeric value using a provided format.
     *
     * @param template - The pseudo-template string to format.
     * @param data - The object containing the fields to interpolate.
     * @returns The formatted string.
     */
    public static format(template: string, data: Record<string, unknown>): string {
        return template
            .replace(/\${(.*?)}(:[a-z]+:[^\}]*)?/g, (_, expression, modifier) => {
                const value = PseudoStringFormatter.evaluateExpression(expression, data);
                return PseudoStringFormatter.applyModifier(value, modifier);
            })
            .replace(/ +,/, ',') // Clean up spaces before commas.
            .replace(/ +\|/, '|') // Clean up spaces before pipes.
            .trim(); // Remove leading and trailing spaces.
    }

    /**
     * Evaluates the field expression within the given data object.
     * Supports nested fields (e.g., "parent.child").
     *
     * @param expression - The field expression to evaluate.
     * @param data - The data object containing field values.
     * @returns The evaluated value or undefined if the field does not exist.
     */
    private static evaluateExpression(expression: string, data: Record<string, unknown>): unknown {
        return expression.split('.').reduce((obj, key) => (obj as Record<string, unknown>)?.[key], data);
    }

    /**
     * Applies a modifier (e.g., :truthy:+N, :default:Value, :uppercase) to a value.
     *
     * @param value - The field value to modify.
     * @param modifier - The modifier string.
     * @returns The modified value as a string.
     */
    private static applyModifier(value: unknown, modifier: string | undefined): string {
        if (!modifier) {
            return value !== null && value !== undefined ? String(value) : '';
        }

        const parts = modifier.split(':').slice(1);
        const action = parts[0];
        const param = parts[1];

        switch (action) {
            case 'truthy':
                return value ? ''.padEnd(parseInt(param, 10)) + String(value) : '';

            case 'falsy':
                return !value ? ''.padEnd(parseInt(param, 10)) + String(value) : '';

            case 'default':
                return value ? String(value) : param || '';

            case 'if':
                const condition = param.split(',')[0];
                const output = parts[2] || '';
                return String(value) === condition ? output : '';

            case 'uppercase':
                return value ? String(value).toUpperCase() : '';

            case 'lowercase':
                return value ? String(value).toLowerCase() : '';

            case 'date':
                return value instanceof Date ? value.toISOString().split('T')[0] : '';

            case 'number':
                return typeof value === 'number'
                    ? parseFloat(value.toString()).toFixed(parseInt(param, 10))
                    : '';

            default:
                throw new Error(`Unknown modifier: ${action}`);
        }
    }

}
