// #region Imports

import { Route } from '@angular/router';
import { RetailerAdminConfigResolver } from '../Resolvers/RetailerAdminConfigResolver';
import { RetailerAdminInfoResolver } from '../Resolvers/RetailerAdminInfoResolver';
import { RetailerAdminSearchUsersResolver } from '../Resolvers/RetailerAdminSearchUsersResolver';
import { EditRetailerUserView } from '../Views/EditRetailerUserView';
import { EditRetailerView } from '../Views/EditRetailerView';
import { RetailerConfigurationOverviewView } from '../Views/RetailerConfigurationOverviewView';
import { RetailersOverviewView } from '../Views/RetailersOverviewView';
import {
    RETAILER_CONFIGURATION_ROUTE_PATH,
    RETAILER_EDIT_ROUTE_PATH,
    RETAILER_OVERVIEW_ROUTE_PATH,
    RETAILER_USER_EDIT_ROUTE_PATH,
} from './RoutePaths';

// #endregion

/**
 * @internal
 */
export const RETAILER_OVERVIEW_ROUTE: Route = {
    path: RETAILER_OVERVIEW_ROUTE_PATH,
    component: RetailersOverviewView,
};

/**
 * @internal
 */
export const RETAILER_EDIT_ROUTE: Route = {
    path: `${RETAILER_EDIT_ROUTE_PATH}`,
    component: EditRetailerView,
};

/**
 * @internal
 */
export const RETAILER_USER_EDIT_ROUTE: Route = {
    path: `${RETAILER_USER_EDIT_ROUTE_PATH}`,
    component: EditRetailerUserView,
};

/**
 * @internal
 */
export const RETAILER_CONFIGURATION_ROUTE: Route = {
    path: `${RETAILER_CONFIGURATION_ROUTE_PATH}`,
    component: RetailerConfigurationOverviewView,
    resolve: {
        retailerAdminConfig: RetailerAdminConfigResolver,
        retailerAdminInfo: RetailerAdminInfoResolver,
        retailerAdminSearchUsers: RetailerAdminSearchUsersResolver,
    },
};
