import { BeneficialOwnerService, IBeneficialOwnerDto, IContactDto } from '@abcfinlab/api/contact';
import { IInsuranceAndHandlingDto } from '@abcfinlab/api/contract';
import {
    ICreditCheckDto,
    IInhouseQuoteDto, IInsuranceTypeDto,
    ILegalPersonalityDto,
    IObjectGroupDto,
    ISaleAndLeaseBackCodeDto,
} from '@abcfinlab/api/global';
import { BeneficialOwnerManager } from '@abcfinlab/contacts';
import { FeatureManager, subscribeOnce, TranslationFacade } from '@abcfinlab/core';
import { CameraService, formValuesAreEqual, InsuranceFormComponent } from '@abcfinlab/presentation';
import { BusyBoxService, ToastService } from '@abcfinlab/ui';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Store } from '@ngxs/store';
import { SigningLinkDTO } from 'libs/legacy/gwg/src/lib/models/SigningLinkDTO.interface';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { BeneficialOwnersView } from '../../../../../../../../libs/contacts/src/Views/beneficial-owners-view/beneficial-owners-view.component';
import { ConfirmationService } from '../../../../../../../../libs/legacy/gwg/src/lib/services/confirmation.service';
import { GwgDependencyBarComponent } from '../../../../../../../../libs/legacy/gwg/src/lib/shared/gwg-dependency-bar/gwg-dependency-bar.component';
import { UpdateCalculationUIState } from '../../../../actions/Calculation.actions';
import {
    SetCreditRating,
    SetLesseeForQuote,
    SetQuote,
    UpdateLessee,
    UpdateQuoteBankAccount,
    UpdateVendor,
} from '../../../../actions/Quote.actions';
import {
    calculateDefaultInsuranceValue,
} from '../../../../helper/calculate.helper';
import { BankAccountDTO } from '../../../../models/BankAccountDTO.interface';
import { CreditCheckDTO } from '../../../../models/CreditCheckDTO.interface';
import { ContractTypeId } from '../../../../models/enums/ContractTypeId.enum';
import { LesseeState as LesseeCamState } from '../../../../models/enums/LesseeState.enum';
import { LeasingQuoteDTO } from '../../../../models/LeasingQuoteDTO.interface';
import { LeasingQuoteOverviewDTO } from '../../../../models/LeasingQuoteOverviewDTO.interface';
import { NavInfoDTO } from '../../../../models/NavContactNoDTO.interface';
import {
    GenericDialogComponent,
    GenericDialogData,
} from '../../../../shared/modals/generic-dialog/generic-dialog.component';
import { CalculationUIState } from '../../../../state/Calculation.state';
import { QuoteState } from '../../../../state/Quote.state';
import { LesseeRepositoryService } from '../../../repository/lessee-repository.service';
import { QuoteRepositoryService } from '../../../repository/quote-repository.service';
import { DialogService } from '../../../services/dialog/dialog.service';
import { QuoteDetailService } from '../../../services/quoteDetail/quote-detail.service';

@UntilDestroy()
@Component({
    selector: 'l7-quote-detail-view',
    templateUrl: './quote-detail-view.component.html',
    styleUrls: ['./quote-detail-view.component.scss'],
    standalone: false,
})
export class QuoteDetailViewComponent implements OnInit, AfterViewInit {

    @ViewChild('feeFormComponent', { static: false }) feeFormComponent: InsuranceFormComponent;
    @ViewChild('gwgDependencyBar', { static: false }) gwgDependencyBar: GwgDependencyBarComponent;
    @ViewChild('beneficialOwnersView') public beneficialOwnersView: BeneficialOwnersView;

    public leasingQuoteOverview$: Observable<LeasingQuoteOverviewDTO>;

    public selectedQuote$: Observable<LeasingQuoteDTO>;

    public insuranceAndHandlingFeeValues: IInsuranceAndHandlingDto;

    private _originalQuote: LeasingQuoteDTO; // quote backup for compare etc - keep it untouched
    private readonly _isIdentificationRequired: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    visualQuote: LeasingQuoteDTO;
    leasingQuoteForm: UntypedFormGroup;
    lessee: IContactDto;
    formCopy: any;
    wasChanged: boolean;
    beneficialOwnersWasChanged: boolean = false;
    beneficialOwnersCnt: number = 0;
    isFeeFormValid: boolean = true;
    lesseeCamState = LesseeCamState;
    creditRating: CreditCheckDTO | ICreditCheckDto = {
        credit_rating: LesseeCamState.OPEN,
        description: null,
        code: null,
    };

    contractTypeId = ContractTypeId;
    isLoading: boolean;
    hasCameraEnabled: boolean;
    newQuoteCalculation: LeasingQuoteDTO;

    hasValidSigningLink: boolean;
    bankAccount: BankAccountDTO;
    objectGroup: IObjectGroupDto;
    navQuoteInfo: NavInfoDTO;
    isIbanValid: boolean = false;
    isIbanLoaded: boolean = true;
    totalLeasingValue?: number;
    objectGroupCode?: string;
    legalPersonality: BehaviorSubject<boolean>;
    beneficialOwners: BehaviorSubject<Array<IBeneficialOwnerDto>>;

    pageData: {
        objectGroup: IObjectGroupDto;
        signingLink: SigningLinkDTO;
        camResult: CreditCheckDTO;
        navQuoteInfo: NavInfoDTO;
        quote: LeasingQuoteDTO;
        newGetQuote: IInhouseQuoteDto;
    };

    public get creditCheckManuallyTriggered(): Observable<boolean> {
        return this.leasingQuoteOverview$.pipe(
            map((x) => {
                if (x?.quotes) {
                    return x.quotes
                        .filter(y => y.quote_number === this._originalQuote?.quote_number)
                        .some(y => y.versions.some(z => z.states.credit_check_manually_triggered));
                }

                return false;
            }),
        );
    }

    /**
     * Check if quote is of contract type *MIETKAUF* or not
     */
    public get is_hire_purchase(): boolean {
        return this.quote?.quote_calculation?.contract_type === ContractTypeId.MIETKAUF;
    }

    public get quote():
    LeasingQuoteDTO {
        return this.visualQuote;
    }

    /**
     * @todo Extract.
     */
    public get can_start_signing_process(): boolean {
        if (this.beneficialOwnersView && (!this.beneficialOwnersView.isBeneficialOwnersListConfirmed || !this.beneficialOwnersView.beneficialOwnersForm.valid) && this._isIdentificationRequired.getValue()) {
            return false;
        }
        if (this.legalPersonality.getValue() && !this.beneficialOwnersView?.beneficialOwnersList.length && this._isIdentificationRequired.getValue()) {
            return false;
        }
        if (!this.isIbanValid || !this.formCopy?.iban) {
            return false;
        }
        if (!this.lessee?.money_laundering.valid) {
            return false;
        }

        return (this.leasingQuoteForm.valid || this.leasingQuoteForm.disabled)
            && !!this._originalQuote?.objects[0].vendor
            && (this.creditRating.credit_rating === LesseeCamState.POSITIVE || this.creditRating.credit_rating === LesseeCamState.APPROVED_WITH_CONDITIONS || this.creditRating.credit_rating === LesseeCamState.APPROVED_WITH_CONDITIONS_FULFILLED)
            && !this.hasValidSigningLink;
    }

    /**
     * @todo Extract.
     */
    public get tooltip_signing_process_disabled(): string {
        let toolTip: string = 'Kein Vertragsprozess möglich!';

        if (this.creditRating.credit_rating !== LesseeCamState.APPROVED_WITH_CONDITIONS && this.creditRating.credit_rating !== LesseeCamState.APPROVED_WITH_CONDITIONS_FULFILLED && this.creditRating.credit_rating !== LesseeCamState.POSITIVE) {
            toolTip += ' Positive Bonitätsprüfung (ggf. mit Auflagen) erforderlich.';
        }
        // quote is discarded, user can only recalculate
        if (this.creditRating.credit_rating === LesseeCamState.DISCARDED) {
            toolTip += ' Die Bonitätsprüfung wurde abgelehnt.';
            return toolTip;
        }
        if (typeof this._originalQuote?.objects[0].vendor === 'undefined') {
            toolTip += ' Es sind Lieferanteninformationen erforderlich.';
        }
        if (!this.isIbanValid) {
            toolTip += ' Fügen Sie eine IBAN hinzu. ';
        }
        if (this.hasValidSigningLink) {
            toolTip += ' Deaktivieren Sie den Link zur Vertragsunterschrift, um den Prozess neu zu starten.';
        }
        if (!this.lessee?.money_laundering.valid) {
            toolTip += ' Leider ein Vertragsabschluss derzeit nicht möglich (LMP), bitte setzen Sie sich mit Ihrem Ansprechpartner in Verbindung.';
        }
        if (this.legalPersonality.getValue() && !this.beneficialOwnersView?.beneficialOwnersList.length && this._isIdentificationRequired.getValue()) {
            toolTip += ' Geben Sie den/die wirtschaftlich Berechtigte(n) an.';
        }
        if (this.beneficialOwnersView && !this.beneficialOwnersView.isBeneficialOwnersListConfirmed) {
            toolTip += ' Erfragen Sie den/die wirtschaftlich Berechtigten im Gespräch.';
        }
        return toolTip;
    }

    public get credit_rating_discarded():
    boolean {
        return this.creditRating.credit_rating === LesseeCamState.DISCARDED;
    }

    public get headline_title():
    string {
        let title: string = `Kalkulation: ${this._originalQuote.quote_name}`;
        if (this.navQuoteInfo?.quote_no) {
            title += ` | ${this.navQuoteInfo.quote_no}`;
        }
        return title;
    }

    public get isVendorChangeAllowed(): boolean {
        return this.quote?.sale_and_lease_back?.code !== ISaleAndLeaseBackCodeDto.Unechtln
            && this.quote?.sale_and_lease_back?.code !== ISaleAndLeaseBackCodeDto.UnechtlnV2
            && this.quote?.sale_and_lease_back?.code !== ISaleAndLeaseBackCodeDto.Unechtlnmk
            && this.creditRating?.credit_rating === LesseeCamState.OPEN;
    }

    public get isIdentificationRequired(): Observable<boolean> {
        return this._isIdentificationRequired.asObservable();
    }

    public constructor(
        private readonly _beneficialOwnerManager: BeneficialOwnerManager,
        private readonly _beneficialOwnerService: BeneficialOwnerService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _quoteRepository: QuoteRepositoryService,
        private readonly _router: Router,
        private readonly _store: Store,
        private readonly _toastService: ToastService,
        private readonly _cameraService: CameraService,
        private readonly _dialog: DialogService,
        private readonly _confirmationService: ConfirmationService,
        private readonly _quoteDetailService: QuoteDetailService,
        private readonly _translationFacade: TranslationFacade,
        private readonly _datePipe: DatePipe,
        private readonly _busyBox: BusyBoxService,
        private readonly _lesseeRepositoryService: LesseeRepositoryService,
        private readonly _cdr: ChangeDetectorRef,
        private readonly _featureManager: FeatureManager,
        private readonly _activatedRoute: ActivatedRoute) {
        this.leasingQuoteOverview$ = this._store.select(QuoteState.getQuotesListForLessee);
        this.selectedQuote$ = this._store.select(QuoteState.getQuote);
        this.leasingQuoteForm = this._fb.group({
            object: this._fb.group({
                name: [{
                    value: null,
                    disabled: true,
                }, [Validators.minLength(1), Validators.required, Validators.maxLength(50)]],
            }),
        });

        combineLatest([this._router.events, this.selectedQuote$]).pipe(untilDestroyed(this))
            .subscribe(([event, _quote]) => {
                if (event instanceof NavigationEnd && !formValuesAreEqual(this.bankAccount, _quote?.bank_account)) {
                    const bankData = _quote?.bank_account
                        ? _quote?.bank_account
                        : {
                                bank_account_name: this.lessee?.name,
                                bank_account_type: 'DIRECT_DEBIT',
                                bank_name: null,
                                bic: null,
                                iban: null,
                            };
                    this._store.dispatch(new UpdateFormValue({
                        value: bankData,
                        path: 'quote.bankAccountForm',
                    }));
                }
            });
        this.legalPersonality = new BehaviorSubject(false);
        this.beneficialOwners = new BehaviorSubject([]);
    }

    public ngOnInit(): void {
        // cameraCanBeUsed method try
        this.hasCameraEnabled = this._cameraService.camera_is_available;
        this._busyBox.show(undefined, undefined, this._quoteDetailService.setupPage().pipe(untilDestroyed(this)))
            .subscribe((pageData) => {
                this.pageData = pageData;
                this._originalQuote = pageData.quote;
                this.visualQuote = {
                    ...pageData.quote,
                    quote_calculation: {
                        ...pageData.quote.quote_calculation,
                        ...{
                            total_insurance_instalments: pageData.newGetQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.totalInsuranceInstalments,
                            total_insurance_instalments_vat: pageData.newGetQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.totalInsuranceInstalmentsVat,
                        },
                    },
                };
                this._patchFormOnDataLoaded(pageData);
                this.creditRating = pageData.camResult;
                if (this.creditRating.credit_rating === LesseeCamState.OPEN) {
                    this.leasingQuoteForm.get('object').enable();
                }
                this.hasValidSigningLink = pageData.signingLink && pageData.signingLink.invalid_at === null && pageData.signingLink.link !== null && (new Date() <= new Date(pageData.signingLink.expires_at));
                this.bankAccount = pageData.newGetQuote.bankAccount;
                this.lessee = pageData.newGetQuote.lessee;
                this.insuranceAndHandlingFeeValues = {
                    default_insurance_value: calculateDefaultInsuranceValue(pageData.newGetQuote.inhouseQuoteCalculation.quoteCalculation.totalLeasingValue, pageData.objectGroup.insurance_value),
                    default_insurance_value100_pro: pageData.newGetQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceValueMin,
                    handling_fee: pageData.newGetQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.handlingFee,
                    handling_fee_value: pageData.newGetQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.handlingFeeValue,
                    insurance: pageData.newGetQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceValue ? true : false,
                    insurance_changed_by_object_value_change: false,
                    insurance_type: pageData.newGetQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceType,
                    insurance_value: pageData.newGetQuote.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceValue,
                };
                this._cdr.detectChanges();

                // @TODO this action can be dispatched after getting credit rating
                this._store.dispatch(new SetCreditRating(pageData.camResult));
                this.objectGroup = pageData.objectGroup;

                this._store.dispatch(new SetLesseeForQuote(pageData.newGetQuote.lessee));
                this.navQuoteInfo = pageData.navQuoteInfo;
                this.objectGroupCode = pageData.objectGroup.code;
                this.totalLeasingValue = pageData.newGetQuote.inhouseQuoteCalculation.quoteCalculation.totalLeasingValue;
                this._isIdentificationRequired.next(pageData.lesseeNeedsIdentification.identificationRequired);

                of(this.lessee.legal_personality === ILegalPersonalityDto.J).subscribe((x) => {
                    this.legalPersonality.next(x);
                    if (x) {
                        this._beneficialOwnerService.search({ crefoId: this.lessee.crefo_id }).subscribe((y) => {
                            this.beneficialOwners.next(y as any as Array<IBeneficialOwnerDto>);
                            this.beneficialOwnersCnt = y.length;
                        });
                    }
                });
                this.feeFormComponent.areValuesChanged.pipe(untilDestroyed(this))
                    .subscribe((_) => {
                        this._compareForm();
                    });
            });
    }

    public ngAfterViewInit(): void {
        this.leasingQuoteForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(_ => this._compareForm());
    }

    /**
     * The user wants to start the signing process. Show extra dialog on *corona* mode. Corona mode needs no available
     * camera, but the user is informed that he can only use the upload function for personal id documents.
     */
    public onStartSigning(): void {
        let checkLesseeState: IContactDto;
        let checkVendorState: IContactDto;
        const confirmBeneficialOwners$ = this._isIdentificationRequired.getValue() ? this._beneficialOwnerManager.confirm(this.lessee) : of(true);
        combineLatest([
            this._lesseeRepositoryService.checkIfNewContactNeededForContractStart(this.lessee.id),
            this._lesseeRepositoryService.checkIfNewContactNeededForContractStart(this.visualQuote.objects[0].vendor.id),
            confirmBeneficialOwners$,
        ]).pipe(untilDestroyed(this))
            .subscribe(([lessee, vendor, beneficialOwnerResult]) => {
                checkLesseeState = lessee;
                checkVendorState = vendor;

                // if the beneficial owner dialog was canceled
                if (!beneficialOwnerResult) {
                    return;
                }

                let body: string;
                const nameLessee = checkLesseeState.name;
                const nameVendor = checkVendorState.name;
                const addressLessee = `${checkLesseeState.street} ${checkLesseeState.house_number}, ${checkLesseeState.postcode} ${checkLesseeState.city}`;
                const addressVendor = `${checkVendorState.street} ${checkVendorState.house_number}, ${checkVendorState.postcode} ${checkVendorState.city}`;

                if (checkLesseeState.new_contact_needed && checkVendorState.new_contact_needed) {
                    body = this._translationFacade.instant('dialogs.new_contact_address_both', {
                        param1: nameLessee,
                        param2: addressLessee,
                        param3: nameVendor,
                        param4: addressVendor,
                    });
                } else if (checkLesseeState.new_contact_needed && !checkVendorState.new_contact_needed) {
                    body = this._translationFacade.instant('dialogs.new_contact_address_lessee', {
                        param1: nameLessee,
                        param2: addressLessee,
                    });
                } else if (!checkLesseeState.new_contact_needed && checkVendorState.new_contact_needed) {
                    body = this._translationFacade.instant('dialogs.new_contact_address_vendor', {
                        param1: nameVendor,
                        param2: addressVendor,
                    });
                }

                const data: GenericDialogData = {
                    id: 'dialog_contact_alert_changed_address',
                    image: 'assets/images/icon-create-contract.svg',
                    title: 'Abweichende Adresse',
                    body,
                    negativeText: 'global.cancel',
                    positiveText: 'global.further',
                };

                if (checkLesseeState.new_contact_needed || checkVendorState.new_contact_needed) {
                    this._dialog.openDialog(GenericDialogComponent, {}, data).afterClosed().subscribe((x) => {
                        if (x) {
                            if (checkLesseeState.new_contact_needed && checkVendorState.new_contact_needed) {
                                combineLatest([this._store.dispatch(new UpdateLessee(checkLesseeState, this.visualQuote.quote_id)),
                                    this._store.dispatch(new UpdateVendor(checkVendorState, this.visualQuote.quote_id))])
                                    .pipe(untilDestroyed(this)).subscribe(([res1, res2]) => {
                                        this.startContractProcess();
                                    });
                            } else if (checkLesseeState.new_contact_needed && !checkVendorState.new_contact_needed) {
                                this._store.dispatch(new UpdateLessee(checkLesseeState, this.visualQuote.quote_id))
                                    .pipe(untilDestroyed(this)).subscribe(() => {
                                        this.startContractProcess();
                                    });
                            } else if (!checkLesseeState.new_contact_needed && checkVendorState.new_contact_needed) {
                                this._store.dispatch(new UpdateVendor(checkVendorState, this.visualQuote.quote_id))
                                    .pipe(untilDestroyed(this)).subscribe(() => {
                                        this.startContractProcess();
                                    });
                            }
                        }
                    });
                } else {
                    this.startContractProcess();
                }
            });
    }

    public startContractProcess(): void {
        this._confirmationService.deleteConfirmations(this._originalQuote.quote_id);
        if (this.creditRating.credit_rating === 'APPROVED_WITH_CONDITIONS' || this.creditRating.credit_rating === 'APPROVED_WITH_CONDITIONS_FULFILLED') {
            const confirmationData: GenericDialogData = {
                id: 'confirm_with_bonicheck_condition',
                title: 'global.action_required',
                body: 'dialogs.start_gwg_with_bonicheck_with_condition',
                negativeText: 'global.cancel',
                positiveText: 'global.confirm',
            };
            this._dialog.openDialog(GenericDialogComponent, {
                minHeight: '0px',
            }, confirmationData).afterClosed()
                .pipe(untilDestroyed(this))
                .subscribe((x) => {
                    if (x) {
                        this._confirmationService.postBonicheckApprovedWithConditionConfirmation(this._originalQuote.quote_id)
                            .subscribe(() => {
                                this.checkIfQuoteIsReadyForSignature();
                            });
                    }
                },
                (_error) => {
                    if (_error instanceof HttpErrorResponse && _error.status === 409 && _error.error.error === 'beneficial_owner_missing') {
                        // TODO: aw: find error message!!!
                        const data: GenericDialogData = {
                            id: 'dialog_beneficial_owner_missing',
                            image: 'assets/images/image-failure.svg',
                            body: `dialogs.${_error.error.error}`,
                            positiveText: 'global.close',
                        };
                        this._dialog.openDialog(GenericDialogComponent, { minHeight: undefined }, data);
                    }
                });
        } else {
            this.checkIfQuoteIsReadyForSignature();
        }
    }

    /**
     * Compare the whole form if values are changed by the user.
     * Is responsible for the state of the footer button components / for the save button to be visible
     * @private
     */

    ibanIsValid(evt: boolean): void {
        this.isIbanValid = evt;
        this._compareForm();
    }

    feeFormHasLoaded(evt: boolean): void {
        if (evt) {
            this._cdr.detectChanges();
            this._backupFormValues();
            this._compareForm();
        }
    }

    addBicAndBankNameToBankAccount(bankAccount: BankAccountDTO): void {
        this.bankAccount = bankAccount;
        this._compareForm();
    }

    beneficialOwnersListUpdated(listLength: number) {
        if (this.beneficialOwnersView) {
            this.beneficialOwnersWasChanged = this.beneficialOwnersView.beneficialOwnersForm.controls.beneficialOwners.valid && listLength !== this.beneficialOwnersCnt;
            if (listLength < this.beneficialOwnersCnt) {
                this.beneficialOwnersCnt = listLength;
            }
        }
    }

    /**
     * User wants to change the vendor
     */
    public onChangeVendor(): void {
        void this._router.navigate(['../search-vendor'], {
            relativeTo: this._activatedRoute,
        });
    }

    public saveBeneficialOwners(): void {
        subscribeOnce(this._busyBox.show(
            '',
            '',
            this._beneficialOwnerService.bulkSave({ body: this.beneficialOwnersView.beneficialOwnersList }),
        ), (x) => {
            this._toastService.showSuccess('Änderungen gespeichert!');
            this.beneficialOwnersView.beneficialOwnersListSaved();
            this.isLoading = false;
            this.beneficialOwnersWasChanged = false;
            this.beneficialOwnersCnt = this.beneficialOwnersView.beneficialOwnersList.length;
            this._cdr.detectChanges();
        });
    }

    /**
     * User wants to calculate a new version of the quote
     *
     */
    onCalculateNewVersion() {
        const isNewCalculationActive = this._featureManager.active('newCalculation');
        if (!isNewCalculationActive) {
            this._store.dispatch(new UpdateCalculationUIState(CalculationUIState.UPDATE))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    let calculation;
                    if (this.newQuoteCalculation) {
                        calculation = this.newQuoteCalculation;
                    } else {
                        calculation = this._originalQuote;
                    }
                    this._store.dispatch(new SetQuote(calculation))
                        .pipe(untilDestroyed(this))
                        .subscribe(() => {
                            void this._router.navigate(['../change'], {
                                relativeTo: this._activatedRoute,
                            });
                        });
                });
            return;
        }
        void this._router.navigate([`/presentation/quote/create/calculation/${this.visualQuote.quote_id}/new`]);
    }

    /**
     * Take user to the quotes list
     */
    onBackToOverview() {
        this._router.navigate(['../list'], {
            relativeTo: this._activatedRoute,
            queryParams: { id: this.lessee.id },
        }).then();
    }

    /**
     * Update the quote on change, only visible if the wasChanged value is true.
     * Values to be patched caused by form structure:
     *  - object
     *  - bank_account (TBI)
     *  - fee`s
     */
    onUpdateQuote(): void {
        this.isLoading = true;
        const payload = this._patchOriginalQuoteForUpdate();
        const updateRequest$ = this._quoteRepository.updateQuote(payload);

        const updateBeneficialOwners$ = this.legalPersonality.value && this._isIdentificationRequired.getValue()
            ? this._beneficialOwnerService.bulkSave({ body: this.beneficialOwnersView.beneficialOwnersList })
            : of(void 0);

        forkJoin([updateRequest$, updateBeneficialOwners$]).pipe(
            finalize(() => this.isLoading = false),
            untilDestroyed(this),
        ).subscribe(([updatedQuote, beneficialOwnersResponse]) => {
            this.newQuoteCalculation = updatedQuote as LeasingQuoteDTO;
            this._toastService.showSuccess('Änderungen gespeichert!');
            this.beneficialOwnersWasChanged = false;
            this.beneficialOwnersCnt = (this.beneficialOwnersView?.beneficialOwnersList ?? []).length;
            this.wasChanged = false;

            this.beneficialOwnersView?.beneficialOwnersListSaved();

            // Set new *original values* on success
            this._backupFormValues();
            this._store.dispatch(new UpdateQuoteBankAccount(updatedQuote as LeasingQuoteDTO));
            this._cdr.detectChanges();
        }, (error) => {
            this._toastService.showError(`${error.error.error_description}`);
        });
    }

    private _compareForm(): void {
        if (this.feeFormComponent === undefined || this.formCopy === undefined) {
            return;
        }
        const bankData = this.bankAccount
            ? this.bankAccount
            : {
                    bank_account_name: this.lessee?.name,
                    bank_account_type: 'DIRECT_DEBIT',
                    bank_name: null,
                    bic: null,
                    iban: null,
                };
        const actualValues = {
            ...{
                bank_account_name: null,
                bank_account_type: 'DIRECT_DEBIT',
            },
            ...this.leasingQuoteForm.getRawValue(),
            ...this.feeFormComponent.form.getRawValue(),
            // don't compare this
            ...{
                total_insurance_instalments: null,
                total_insurance_instalments_vat: null,
            },
            ...bankData,
        };
        this.wasChanged = !formValuesAreEqual(actualValues, this.formCopy)
        && this.isFeeFormValid && this.isIbanLoaded && (this.isIbanValid || actualValues.iban === null);
        this._cdr.detectChanges();
    }

    /**
     * Create a copy of the actual values to compare on changes.
     * @private
     */
    private _backupFormValues(): void {
        const bankData = this.bankAccount
            ? this.bankAccount
            : {
                    bank_account_name: this.lessee?.name,
                    bank_account_type: 'DIRECT_DEBIT',
                    bank_name: null,
                    bic: null,
                    iban: null,
                };
        this.formCopy = {
            ...{
                bank_account_name: null,
                bank_account_type: 'DIRECT_DEBIT',
            },
            ...this.leasingQuoteForm.getRawValue(),
            ...bankData,
            ...this.feeFormComponent.form.getRawValue(),
            // don't compare this
            ...{
                total_insurance_instalments: null,
                total_insurance_instalments_vat: null,
            },
        };
    }

    /**
     * Patch the original quote before submitting the PUT request
     */
    private _patchOriginalQuoteForUpdate(): LeasingQuoteDTO {
        // patch back condition! ...
        const patchedFees = {
            ...this.feeFormComponent.form.getRawValue(),
            ...{
                insurance_fee: this.feeFormComponent.form.controls.insurance_value.getRawValue(),
                insurance: this.feeFormComponent.form.controls.insurance_type.getRawValue() === IInsuranceTypeDto.No ? false : true,
            },
        };
        const patchedObject = {
            ...this._originalQuote.objects[0],
            name: this.leasingQuoteForm.get('object').value.name,
        };
        const payload = {
            ...this._originalQuote,
            sale_and_lease_back: null,
            objects: [{ ...patchedObject }],
            quote_calculation: { ...this._originalQuote.quote_calculation, ...patchedFees },
            bank_account: this.bankAccount
                ? this.bankAccount
                : {
                        bank_account_name: null,
                        bank_account_type: 'DIRECT_DEBIT',
                        bank_name: null,
                        bic: null,
                        iban: null,
                    },
        };
        return payload;
    }

    private openStartModal() {
        this.setGWGProcessData(this._originalQuote);
        if (!this._cameraService.camera_is_available) {
            const dialog = this._dialog.openDialog(GenericDialogComponent, null, {
                id: 'start_gwg_without_cam',
                title: 'global.action_required',
                body: 'dialogs.start_gwg_without_cam',
                negativeText: 'global.cancel',
                positiveText: 'global.further',
            } as GenericDialogData);
            dialog.afterClosed()
                .pipe(untilDestroyed(this))
                .subscribe((result) => {
                    if (result) {
                        void this._router.navigate(['../../gwg/signer-data'], { relativeTo: this._activatedRoute });
                    }
                });
        } else {
            void this._router.navigate(['../../gwg/signer-data'], { relativeTo: this._activatedRoute });
        }
    }

    private checkIfQuoteIsReadyForSignature(): void {
        this._quoteRepository.quoteReadyForSignature(this._originalQuote.quote_id)
            .pipe(untilDestroyed(this))
            .subscribe((response) => {
                if (response.ready_for_signature) {
                    this.openStartModal();
                } else {
                    const errorsToString = response.errors.toString();
                    const now = this._datePipe.transform(new Date(), 'dd.MM.yyyy HH:mm', 'UTC+1');
                    const dialogMessage = this._translationFacade.instant('error.signature_document_saving', { param1: errorsToString, date: now });
                    const dialogData: GenericDialogData = {
                        id: 'errors_ready_for_signature',
                        image: 'assets/images/image-failure.svg',
                        body: dialogMessage,
                        positiveText: 'global.cancel',
                    };
                    this._dialog.openDialog(GenericDialogComponent, {
                        minHeight: '0px',
                    }, dialogData);
                }
            });
    }

    /**
     * Patch the form values after receiving the backend call with the object name.
     * @private
     */
    private _patchFormOnDataLoaded(pageData): void {
        this.leasingQuoteForm.get('object').patchValue({ name: pageData.quote.objects[0].name });
    }

    /**
     * Store the needed properties to the session storage so that the GWG lib/process can be finished.
     * Those fields will be read in the GWG lib *gwg-initialization-resolver* on GWG start.
     * @param quote - The LeasingQuoteDTO containing all necessary fields
     */
    private setGWGProcessData(quote: LeasingQuoteDTO): void {
        const { quote_id } = quote;
        const { contract_type } = quote.quote_calculation;
        if (!quote_id || !contract_type) {
            throw new Error('GWG: Missing parameter to start GWG Process. QuoteID or ContractType is missing!');
        } else {
            sessionStorage.removeItem('GWG_PROCESS_DATA');
            sessionStorage.setItem('GWG_PROCESS_DATA', JSON.stringify({ quote_id, contract_type }));
        }
    }

}
