import {
    hundredProMustBeDisabled,
    toDecimal,
} from '@abcfinlab/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { calculateInsuranceValueRange, getInsuranceFactorFromObjectGroup } from '../../helper/calculate.helper';
import { validate100pro } from '../../private/validators/100proInsurance.validator';
import { InsuranceType, InsuranceViewTypes } from '../enums/InsuranceType.enum';
import { IFeeFormStrategy } from '../interfaces/IFeeFormStrategy.interface';
import { HirePurchaseCalculationDTO, LeasingQuoteCalculationDTO } from '../LeasingQuoteCalculationDTO.interface';
import { ObjectGroupDTO } from '../ObjectGroupDTO.interface';

const MINIMAL_100PRO_INSURANCE_VALUE = 0;
const STANDARD_100PRO_INSURANCE_VALUE = 0;
const MAXIMAL_100PRO_INSURANCE_VALUE = 35;

export class VATAStrategy implements IFeeFormStrategy {

    form: UntypedFormGroup;
    name: string = 'Versicherung';
    calculation: LeasingQuoteCalculationDTO | HirePurchaseCalculationDTO;
    objectGroup: ObjectGroupDTO;
    view: InsuranceViewTypes;

    constructor(form: UntypedFormGroup, calculation: LeasingQuoteCalculationDTO | HirePurchaseCalculationDTO, objectGroup: ObjectGroupDTO, view?: InsuranceViewTypes) {
        this.form = form;
        this.calculation = calculation;
        this.objectGroup = objectGroup;
        this.view = view;
    }

    init(): void {
        this.updateValidators();
        const insuranceFactor = getInsuranceFactorFromObjectGroup(this.objectGroup);
        if (!insuranceFactor) {
            this.setInsuranceType(InsuranceType.NO);
            this.disableInsurance();
            return;
        }
        if (insuranceFactor) {
            this.setInsuranceType(InsuranceType.STANDARD);
            this.form.get('insurance').enable();
            if (this.view === InsuranceViewTypes.UPDATE && !this.calculation.insurance_fee) {
                this.setInsuranceType(InsuranceType.NO);
            }
            if (this.view !== InsuranceViewTypes.QUOTE_DETAIL && this.view !== InsuranceViewTypes.UPDATE) {
                this.form.get('insurance').patchValue(true);
            }
        }
    }

    setInsuranceType(type: InsuranceType) {
        this.form.get('insurance_type').patchValue(type);
    }

    handleInsuranceChange(activated: boolean): void {
        if (!activated) {
            this.form.get('insurance_type').patchValue(InsuranceType.NO);
            this.form.get('insurance_fee').patchValue(null);
            this.form.get('insurance_fee').disable();
        }
        if (activated) {
            this.form.get('insurance_type').patchValue(InsuranceType.STANDARD);
            const insuranceRange = calculateInsuranceValueRange(this.calculation.total_leasing_value, this.objectGroup?.insurance_value);
            if (this.view !== InsuranceViewTypes.QUOTE_DETAIL && this.view !== InsuranceViewTypes.UPDATE) {
                this.form.get('insurance_fee').patchValue(insuranceRange.maxValue);
            } else {
                const isInsuranceValid = this.calculation.insurance_fee
                    && this.calculation.insurance_fee <= toDecimal(insuranceRange.maxValue * 2, 2, true)
                    && this.calculation.insurance_fee >= insuranceRange.minValue;
                const insuranceValue = isInsuranceValid ? this.calculation.insurance_fee : insuranceRange.maxValue;
                this.form.get('insurance_fee').patchValue(insuranceValue);
            }
            this.form.get('insurance_fee').enable();
        }
    }

    handleInsuranceFeeValue(fee: number) {
    }

    getErrorMessage(): string {
        if (this.calculation.total_leasing_value && this.objectGroup?.insurance_value) {
            const insuranceRange = calculateInsuranceValueRange(this.calculation.total_leasing_value, this.objectGroup?.insurance_value);
            return `Wert zwischen ${insuranceRange.minValue} € und ${toDecimal(insuranceRange.maxValue * 2, 2, true)} € eingeben.`;
        }
    }

    toggleValidation(active: boolean): void {
        const insuranceRange = calculateInsuranceValueRange(this.calculation.total_leasing_value, this.objectGroup?.insurance_value);
        if (active) {
            this.form.get('insurance_fee').setValidators([Validators.min(insuranceRange.minValue), Validators.max(toDecimal(insuranceRange.maxValue * 2, 2, true))]);
        } else {
            this.form.get('insurance_fee').clearValidators();
        }

        this.form.get('insurance_fee').updateValueAndValidity();
    }

    updateValidators() {
        if (this.calculation?.total_leasing_value && this.objectGroup) {
            this.form.get('insurance_fee').clearValidators();
            const insuranceRange = calculateInsuranceValueRange(this.calculation.total_leasing_value, this.objectGroup?.insurance_value);

            if (this.objectGroup?.insurance_value > 0 || this.calculation.insurance) {
                this.form.get('insurance_fee').setValidators([Validators.min(insuranceRange.minValue), Validators.max(toDecimal(insuranceRange.maxValue * 2, 2, true))]);
            } else {
                this.form.get('insurance_fee').clearValidators();
            }

            this.form.get('insurance_fee').updateValueAndValidity();
        }
    }

    enableInsurance(value: number): void {
        this.form.get('insurance').enable();
        this.form.get('insurance_fee').enable();
        this.form.patchValue({
            insurance: true,
            insurance_fee: value,
        });
    }

    disableInsurance(): void {
        this.form.get('insurance_fee').disable();
        this.form.get('insurance').disable();
        this.form.patchValue({
            insurance: false,
            insurance_fee: null,
        });
    }

    handleObjectGroupChange(val: ObjectGroupDTO): void {
        this.objectGroup = val;
        const insuranceFactor = getInsuranceFactorFromObjectGroup(this.objectGroup);
        if (!this.calculation?.total_leasing_value) {
            return;
        }
        const insuranceRange = calculateInsuranceValueRange(this.calculation.total_leasing_value, this.objectGroup?.insurance_value);
        this.updateValidators();
        if (insuranceFactor) {
            if (this.view !== InsuranceViewTypes.QUOTE_DETAIL && this.view !== InsuranceViewTypes.UPDATE) {
                this.enableInsurance(insuranceRange.maxValue);
            } else {
                const insuranceValue = this.calculation.insurance_fee ? this.calculation.insurance_fee : insuranceRange.maxValue;
                this.enableInsurance(insuranceValue);
            }
        } else {
            this.disableInsurance();
        }
    }

    handleValueChange(val: LeasingQuoteCalculationDTO): void {
        this.calculation = val;
        if (val.insurance) {
            this.handleInsuranceChange(true);
        }
        this.updateValidators();
    }

    handleInsuranceTypeChange(type: InsuranceType): void {
    }

    handleInsuranceFeeEnabled(): void {
        if (this.calculation?.insurance_fee) {
            this.form.get('insurance_fee').enable();
            this.updateValidators();
        }
    }

}

export class KFZStrategy implements IFeeFormStrategy {

    name: string = '100pro';
    form: UntypedFormGroup;
    calculation: LeasingQuoteCalculationDTO | HirePurchaseCalculationDTO;
    suggestDefault: boolean = true;
    view: InsuranceViewTypes;
    objectGroup: ObjectGroupDTO;

    constructor(form: UntypedFormGroup, calculation: LeasingQuoteCalculationDTO | HirePurchaseCalculationDTO, objectGroup: ObjectGroupDTO, view?: InsuranceViewTypes) {
        this.form = form;
        this.calculation = calculation;
        this.suggestDefault = true;
        this.view = view;
        this.objectGroup = objectGroup;
    }

    init(): void {
        this.form.get('insurance').enable();
        if (this.view !== InsuranceViewTypes.QUOTE_DETAIL && this.view !== InsuranceViewTypes.UPDATE) {
            this.setInsuranceType(InsuranceType.HUNDRED_PRO);
            this.form.get('insurance').patchValue(true);
            this.form.get('insurance_fee').patchValue(STANDARD_100PRO_INSURANCE_VALUE);
            this.form.get('insurance_fee').disable();
        } else if (this.view === InsuranceViewTypes.QUOTE_DETAIL) {
            const res = hundredProMustBeDisabled(this.calculation?.total_leasing_value, parseInt(this.objectGroup?.code));
            if (res) {
                this.form.get('insurance').disable();
            } else {
                this.form.get('insurance').enable();
            }
        }

        this.updateValidators();
    }

    getErrorMessage(): string {
        return `Wert zwischen ${MINIMAL_100PRO_INSURANCE_VALUE.toFixed(2)} € und ${MAXIMAL_100PRO_INSURANCE_VALUE.toFixed(2)} € eingeben.`;
    }

    handleInsuranceChange(activated: boolean) {
        if (!activated) {
            this.form.get('insurance_type').patchValue(InsuranceType.NO);
            this.form.get('insurance').patchValue(false, { emitEvent: false });
            this.form.get('insurance_fee').disable();
            this.form.get('insurance_fee').patchValue(null);
        }
        if (activated) {
            this.form.get('insurance_type').patchValue(InsuranceType.HUNDRED_PRO);
            this.form.get('insurance').patchValue(true, { emitEvent: false });
            if (this.view !== InsuranceViewTypes.QUOTE_DETAIL && this.view !== InsuranceViewTypes.UPDATE) {
                this.form.get('insurance_fee').patchValue(STANDARD_100PRO_INSURANCE_VALUE);
            } else {
                const insuranceValue = this.calculation.insurance_fee ? this.calculation.insurance_fee : STANDARD_100PRO_INSURANCE_VALUE;
                this.form.get('insurance_fee').patchValue(insuranceValue);
            }
            this.form.get('insurance_fee').enable();
        }
    }

    handleInsuranceFeeValue(fee: number): void { }

    toggleValidation(active: boolean): void {
        if (active) {
            this.form.get('insurance_fee').setValidators(validate100pro);
        } else {
            this.form.get('insurance_fee').clearValidators();
        }
        this.form.get('insurance_fee').updateValueAndValidity();
    }

    setInsuranceType(type?: InsuranceType): void {
        this.form.get('insurance_type').patchValue(InsuranceType.HUNDRED_PRO);
    }

    handleObjectGroupChange(): void { }

    handleValueChange(calculation: LeasingQuoteCalculationDTO): void {
        this.calculation = calculation;
        this.updateValidators();
    }

    disableInsurance(): void { }

    updateValidators(): void {
        if (this.calculation.insurance) {
            this.form.get('insurance_fee').clearValidators();
            this.form.get('insurance_fee').setValidators(validate100pro);
        } else {
            this.form.get('insurance_fee').clearValidators();
        }

        this.form.get('insurance_fee').updateValueAndValidity();
    }

    enableInsurance(value: number): void {
        this.form.get('insurance').enable();
        this.form.get('insurance_fee').enable();
        this.form.patchValue({
            insurance: true,
            insurance_fee: value,
        });
    }

    handleInsuranceTypeChange(type: InsuranceType): void {
    }

    handleInsuranceFeeEnabled(): void {
        if (this.calculation.insurance_fee) {
            this.form.get('insurance_fee').enable();
            this.updateValidators();
        }
    }

}

export class MKStrategy implements IFeeFormStrategy {

    name = 'Mietkauf';
    form: UntypedFormGroup;
    calculation: LeasingQuoteCalculationDTO | HirePurchaseCalculationDTO;
    objectGroup: ObjectGroupDTO;
    view: InsuranceViewTypes;

    constructor(form: UntypedFormGroup, calculation: LeasingQuoteCalculationDTO | HirePurchaseCalculationDTO, objectGroup: ObjectGroupDTO, view: InsuranceViewTypes) {
        this.form = form;
        this.calculation = calculation;
        this.objectGroup = objectGroup;
        this.view = view;
    }

    init(): void {
        if (!this.calculation?.insurance_fee) {
            this.setInsuranceType(InsuranceType.NO);
            this.disableInsurance();
        } else {
            this.handleInsuranceFeeEnabled();
        }

        this.updateValidators();
    }

    handleInsuranceTypeChange(type: InsuranceType) {
        this.setInsuranceType(type);
        switch (this.form.get('insurance_type').value) {
            case InsuranceType.NO || null: {
                this.disableInsurance();
                this.form.get('insurance_pro_mille').patchValue(null, { emitEvent: false });
                break;
            }
            case InsuranceType.HUNDRED_PRO: {
                this.form.get('insurance').patchValue(true, { emitEvent: false });
                this.form.get('insurance_fee').enable();
                this.form.get('insurance_fee').patchValue(STANDARD_100PRO_INSURANCE_VALUE, { emitEvent: false });
                this.form.get('insurance_pro_mille').patchValue(null, { emitEvent: false });
                break;
            }
            case InsuranceType.STANDARD: {
                const insuranceFactor = getInsuranceFactorFromObjectGroup(this.objectGroup);
                if (!insuranceFactor) {
                    this.form.get('insurance').patchValue(false, { emitEvent: false });
                    this.form.get('insurance_fee').patchValue(null, { emitEvent: false });
                    this.form.get('insurance_fee').disable();
                }
                if (insuranceFactor) {
                    const insuranceRange = calculateInsuranceValueRange(this.calculation.total_leasing_value, this.objectGroup?.insurance_value);
                    this.form.get('insurance').patchValue(true, { emitEvent: false });
                    this.form.get('insurance_fee').patchValue(insuranceRange.maxValue, { emitEvent: false });
                    this.form.get('insurance_fee').enable();
                }
                break;
            }
        }
        this.updateValidators();
    }

    handleInsuranceFeeValue(fee: number) { }

    getErrorMessage(): string {
        switch (this.form.get('insurance_type').value) {
            case InsuranceType.HUNDRED_PRO: {
                return `Wert zwischen ${MINIMAL_100PRO_INSURANCE_VALUE} € und ${MAXIMAL_100PRO_INSURANCE_VALUE} € eingeben.`;
            }
            case InsuranceType.STANDARD: {
                const insuranceRange = calculateInsuranceValueRange(this.calculation.total_leasing_value, this.objectGroup?.insurance_value);
                return `Wert zwischen ${insuranceRange.minValue} € und ${toDecimal(insuranceRange.maxValue * 2, 2, true)} € angeben.`;
            }
        }
    }

    setInsuranceType(type: InsuranceType): void {
        this.form.get('insurance_type').patchValue(type, { emitEvent: false });
    }

    toggleValidation(active: boolean): void { }

    handleObjectGroupChange(group: ObjectGroupDTO): void {
        this.objectGroup = group;
        this.init();
        this.updateValidators();
    }

    handleValueChange(calculation: LeasingQuoteCalculationDTO): void {
        if (calculation.total_leasing_value) {
            this.form.get('insurance_type').enable();
        }
        this.calculation = calculation;

        if (this.form.getRawValue().insurance_type === InsuranceType.STANDARD) {
            this.handleInsuranceChange(true);
        }

        this.updateValidators();
    }

    disableInsurance(): void {
        if (!this.calculation?.total_leasing_value) {
            this.form.get('insurance_type').disable();
        }

        this.form.get('insurance_type').patchValue(InsuranceType.NO);
        this.form.get('insurance_fee').disable();
        this.form.get('insurance_fee').patchValue(null, { emitEvent: false });
        this.form.get('insurance').patchValue(false);
    }

    updateValidators(): void {
        this.form.get('insurance_fee').clearValidators();
        switch (this.form.get('insurance_type').value) {
            case InsuranceType.HUNDRED_PRO: {
                this.form.get('insurance_fee').setValidators(validate100pro);
                break;
            }
            case InsuranceType.STANDARD: {
                if (!!getInsuranceFactorFromObjectGroup(this.objectGroup) && this.calculation.total_leasing_value) {
                    const insuranceRange = calculateInsuranceValueRange(this.calculation.total_leasing_value, this.objectGroup?.insurance_value);
                    this.form.get('insurance_fee').setValidators([Validators.min(insuranceRange.minValue), Validators.max(toDecimal(insuranceRange.maxValue * 2, 2, true))]);
                }
                break;
            }
        }
        this.form.get('insurance_fee').updateValueAndValidity();
    }

    handleInsuranceChange(active: boolean): void {
        const insuranceRange = calculateInsuranceValueRange(this.calculation.total_leasing_value, this.objectGroup?.insurance_value);

        if (active) {
            if (this.view !== InsuranceViewTypes.QUOTE_DETAIL && this.view !== InsuranceViewTypes.UPDATE) {
                this.form.get('insurance_fee').patchValue(insuranceRange.maxValue, { emitEvent: false });
            } else {
                const isInsuranceValid = this.calculation.insurance_fee
                    && this.calculation.insurance_fee <= toDecimal(insuranceRange.maxValue * 2, 2, true)
                    && this.calculation.insurance_fee >= insuranceRange.minValue;
                const insuranceValue = isInsuranceValid ? this.calculation.insurance_fee : insuranceRange.maxValue;
                this.form.get('insurance_fee').patchValue(insuranceValue, { emitEvent: false });
            }
        }
    }

    handleInsuranceFeeEnabled(): void {
        if (this.calculation.insurance_fee) {
            this.form.get('insurance_fee').enable();
            this.updateValidators();
        }
    }

}
