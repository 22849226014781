<l7-page [isEmpty]="(presenter.lessees | async ).length ? false : true">
    <l7-page-header #header
                    [title]="'quote.retailers.lessees.overview-title' | translate"></l7-page-header>
    <l7-page-pre-content fxLayout="row"
                         fxLayoutAlign="start start">
        <l7-page-section fxFlex.lt-md="100"
                         fxFlex="40">
            <mat-form-field fxFlex
                            appearance="outline">
                <mat-label>{{ 'Suchbegriff eingeben' | translate }}</mat-label>
                <input #term="matInput"
                       matInput
                       [attr.test-id]="'TermInput'"
                       [value]="presenter.searchString"
                       (keyup.enter)="presenter.onTermChanged(term.value)">
                <button mat-icon-button
                        matSuffix
                        (click)="presenter.onTermChanged(term.value)">
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Search'"></mat-icon>
                </button>
            </mat-form-field>
        </l7-page-section>
    </l7-page-pre-content>
    <l7-page-content fxFlex>
        <mat-nav-list fxFlex
                      fxFill>
            <mat-list-item *ngFor="let lessee of presenter.lessees | async; last as last"
                           (click)="presenter.onLesseeSelected(lessee)">
                <div fxLayout="row"
                     fxLayoutAlign="start center"
                     fxLayoutGap="8px"
                     matListItemTitle
                     [style]="'margin-bottom: 0px;'">
                    <l7-text-block [truncate]="true"
                                   [text]="lessee.lesseeCompanyName"></l7-text-block>
                    <mat-icon *ngIf="lessee.draft"
                              [fontSet]="'l7'"
                              matTooltip="{{ 'quote.draftTooltip' | translate }}"
                              [color]="'warning'"
                              [fontIcon]="'New_Release'"></mat-icon>
                </div>
                <l7-text-block matListItemLine
                               [truncate]="true"
                               [readonly]="true"
                               [text]="this.presenter.formatAddress(lessee)"></l7-text-block>
                <l7-text-block matListItemLine
                               [readonly]="true"
                               [text]="(lessee.creationDate | date: 'short') + ' Uhr' | translate "></l7-text-block>
                <mat-divider *ngIf="!last"
                             class="without-margin"
                             [inset]="true"></mat-divider>
                <div matListItemMeta>
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Chevron_Right'"></mat-icon>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </l7-page-content>
</l7-page>