import { IContractTypeDto } from '@abcfinlab/api/global';
import {
    ObjectCondition,
    RetailerCalculationRequest,
    RetailerCalculationResult,
    RetailerLeasingControllerService,
} from '@abcfinlab/api-global';
import { Globals, TranslationFacade, Validators as CoreValidators,
    toDecimal, getPercent, getPercentageValue,
} from '@abcfinlab/core';
import { ToastService } from '@abcfinlab/ui';
import { effect, inject, Injectable, linkedSignal, signal, untracked } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { EMPTY, Subject, takeUntil } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Validators as QuoteValidators } from '../../../../Validators/Validators';

@Injectable()
export class RetailerCalculateLeasingQuoteFormService {

    private readonly _retailerCalculationService = inject(RetailerLeasingControllerService);
    private readonly _toastService = inject(ToastService);
    private readonly _translationFacade = inject(TranslationFacade);
    private readonly _retailerCalculationRequest = signal<RetailerCalculationRequest | undefined>(undefined);
    private readonly _calculateLeasingQuoteResource = rxResource({
        request: () => this._retailerCalculationRequest(),
        loader: ({ request }) => request ? this._retailerCalculationService.calculateLeasingQuote({ retailerCalculationRequest: request }) : EMPTY,
    });

    calculationSelectionStatus = this._calculateLeasingQuoteResource.status;
    isCalculationSelectionLoading = this._calculateLeasingQuoteResource.isLoading;
    readonly calculationSelection = this._calculateLeasingQuoteResource.value.asReadonly();
    readonly calculationDetails = this._retailerCalculationRequest.asReadonly();
    readonly hasDownPayment = signal<boolean>(false);
    readonly hasDealerCommission = signal<boolean>(true);
    readonly monthlyInsuranceValue = linkedSignal<number>(() => this.calculationSelection()?.monthlyInsuranceValue ?? 0);
    readonly handlingFeeValue = linkedSignal<number>(() => this.calculationSelection()?.handlingFeeValue ?? 0);
    private readonly _fb = inject(NonNullableFormBuilder);
    private readonly _totalLeasingValueControl = this._fb.control<number | undefined>(undefined, [Validators.required]);
    private readonly _downPaymentControl = this._fb.control<number | undefined>(undefined, [Validators.required, QuoteValidators.allowedValuesRelatedToLeasingValue(0.3, 0.01, 'downPayment')]);
    private readonly _downPaymentInPercentControl = this._fb.control<number | undefined>(undefined, [Validators.required, CoreValidators.isNumberInRange(0.01, 0.3, 100)]);
    readonly monthlyInsuranceControl = this._fb.control<boolean>(true, Validators.required);

    calculationSelectionControl = this._fb.control<RetailerCalculationResult | undefined>(undefined, [Validators.required]);
    serviceFeeFormGroup = this._fb.group({
        serviceFeeRequested: this._fb.control<boolean>(true),
        amount: this._fb.control<number | undefined>(undefined),
        comment: this._fb.control<string>(''),
        singleCharges: this._fb.record<number>({}),
    });

    calculationDetailsFormGroup = this._fb.group({
        totalLeasingValue: this._totalLeasingValueControl,
        contractType: this._fb.control<RetailerCalculationRequest.ContractTypeEnum | undefined>(undefined),
        objectGroupCode: this._fb.control<number | undefined>(undefined, [Validators.required]),
        objectCondition: this._fb.control<ObjectCondition>('NEW'),
        downPayment: this._downPaymentControl,
        downPaymentInPercent: this._downPaymentInPercentControl,
        monthlyInsurance: this.monthlyInsuranceControl,
        residualValue: this._fb.control<number | undefined>(undefined),
        residualValueInPercent: this._fb.control<number | undefined>(undefined),
        handlingFee: this._fb.control<boolean>(true, Validators.required),
        dealerCommissionInPercent: this._fb.control<number | undefined>(undefined, [Validators.required, CoreValidators.isNumberInRange(0, 0.05, 100)]),
        serviceFeeRequest: this.serviceFeeFormGroup,
    });

    private readonly _destroyRef = new Subject<void>();

    constructor() {
        this.setSubscriptions();

        effect(() => {
            const error = this._calculateLeasingQuoteResource.error() as { error: { error: string } };
            untracked(() => {
                if (error) {
                    let errorKey = 'quote.retailers.calculation.calculate.toast.error';
                    switch (error?.error.error) {
                        case 'invalid_contract_type':
                            errorKey = 'quote.retailers.calculation.calculate.toast.invalid_contract_type';
                            break;
                    }
                    this._toastService.show(this._translationFacade.translate(errorKey), 'danger', 'long');
                }
            });
        });
    }

    onDownPaymentCheckboxChange(checked: boolean): void {
        this.hasDownPayment.set(checked);
        if (checked) {
            this._downPaymentControl.enable({ emitEvent: false });
            this._downPaymentInPercentControl.enable({ emitEvent: false });
        } else {
            this._downPaymentControl.disable({ emitEvent: false });
            this._downPaymentInPercentControl.disable({ emitEvent: false });
        }

        this.calculationDetailsFormGroup.updateValueAndValidity();
    }

    onMonthlyInsuranceCheckboxChange(checked: boolean): void {
        this.monthlyInsuranceControl.patchValue(checked, { emitEvent: false });
    }

    onDealerCommissionCheckboxChange(checked: boolean): void {
        this.hasDealerCommission.set(checked);
        if (checked) {
            this.calculationDetailsFormGroup.controls.dealerCommissionInPercent.enable();
        } else {
            this.calculationDetailsFormGroup.controls.dealerCommissionInPercent.disable();
        }
    }

    calculatePercentageValue(whatToCalculate: 'downPayment' | 'residualValue'): number | undefined {
        const ofValue = this.calculationDetailsFormGroup.controls[whatToCalculate].value;
        const fromValue = this.calculationDetailsFormGroup.controls.totalLeasingValue.value;
        if (ofValue && fromValue) {
            return toDecimal(((ofValue * 100) / fromValue), 10, true);
        }
    }

    private calculateLeasingQuote(): void {
        const getCalculationDetails = this.calculationDetailsFormGroup.value;
        let residualValueInPercent;
        let downPaymentInPercent;

        if (this.hasDownPayment()) {
            downPaymentInPercent = this.calculatePercentageValue('downPayment');
        }

        if (this.calculationDetailsFormGroup.controls.contractType.value === IContractTypeDto.Ta) {
            residualValueInPercent = this.calculatePercentageValue('residualValue');
        }

        // ToDo: rework the spread operators
        const calculationDetails: RetailerCalculationRequest = {
            ...getCalculationDetails,
            // ToDo: serviceFeeRequest will be implemented. till then it's null, in order for the request to work
            serviceFeeRequest: undefined,
            handlingFee: this.calculationDetailsFormGroup.controls.handlingFee.getRawValue(),
            residualValueInPercent,
            monthlyInsurance: true,
            downPaymentInPercent,
            dealerCommissionInPercent: this.hasDealerCommission() ? this.calculationDetailsFormGroup.controls.dealerCommissionInPercent.value : undefined,
        };

        this._retailerCalculationRequest.set(calculationDetails);
    }

    private onDownpaymentFormValueChanges(): void {
        this._downPaymentControl.valueChanges.pipe(takeUntil(this._destroyRef)).subscribe((value) => {
            const percentage = getPercent({ from: this._totalLeasingValueControl.value, of: value });
            if (percentage) {
                this._downPaymentInPercentControl?.setValue(percentage, { emitEvent: false });
                this._downPaymentInPercentControl?.updateValueAndValidity({ emitEvent: false });
            }
        });
        this._downPaymentInPercentControl.valueChanges.pipe(takeUntil(this._destroyRef)).subscribe((value) => {
            const ofPercentage = this._downPaymentInPercentControl?.status === 'VALID' ? getPercentageValue({ basis: this._totalLeasingValueControl.value, percentage: value }) : undefined;
            if (ofPercentage) {
                this._downPaymentControl?.setValue(ofPercentage, { emitEvent: false });
                this._downPaymentControl?.updateValueAndValidity({ emitEvent: false });
            }
        });
    }

    private setSubscriptions(): void {
        this.onDownPaymentCheckboxChange(false);
        this.calculationDetailsFormGroup.valueChanges.pipe(
            takeUntil(this._destroyRef),
            debounceTime(Globals.Input.DELAY),
            distinctUntilChanged(),
        ).subscribe(() => {
            if (this.calculationDetailsFormGroup.valid) {
                this.calculateLeasingQuote();
            }
        });
        this.calculationDetailsFormGroup.controls.objectGroupCode.valueChanges.pipe(takeUntil(this._destroyRef)).subscribe(() => {
            this.calculationDetailsFormGroup.controls.monthlyInsurance.patchValue(true, { emitEvent: false });
        });

        this._totalLeasingValueControl.valueChanges.pipe(takeUntil(this._destroyRef)).subscribe((value) => {
            if (this._downPaymentInPercentControl.value) {
                this._downPaymentControl.setValue(getPercentageValue({ basis: value, percentage: this._downPaymentInPercentControl.value }), { emitEvent: false });
            }
        });

        this.onDownpaymentFormValueChanges();
    }

    destroy(): void {
        this._destroyRef.next();
        this._destroyRef.complete();
    }

}
