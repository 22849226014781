<h3 *ngIf="!isReadOnlyUser">{{'settlement.state.contract_state' | translate}}</h3>
<div *ngIf="!isReadOnlyUser"
     fxLayout="row"
     fxLayoutGap="16px"
     style="padding-bottom: 16px">
        <button mat-stroked-button
                color="primary"
                [attr.test-id]="'NavisionButton'"
                [disabled]="(sendToNavState | async) === false"
                (click)="onSendToNavision()">{{ 'settlement.state.actions.send_to_navision' | translate }}</button>
        <button *ngIf="salesChanel !== 'RETAILER'"
                mat-stroked-button
                color="primary"
                [attr.test-id]="'ClarificationButton'"
                [disabled]="(clarificationState | async) === false"
                (click)="setToClarificationState()">{{ 'settlement.state.actions.clarification' | translate }}</button>
        <div [matTooltip]="sendToNavAndManualProcessingTooltip" [matTooltipClass]="'allow-cr'">
            <button mat-stroked-button
                    color="primary"
                    [attr.test-id]="'ManualButton'"
                    [disabled]="(manualProcessingState | async) === false"
                    (click)="showManualProcessingModal()">{{ 'settlement.state.actions.edit_manual' | translate }}
            </button>
        </div>
        <div [matTooltip]="sendToNavAndManualProcessingTooltip" [matTooltipClass]="'allow-cr'">
            <button mat-stroked-button
                    color="primary"
                    [attr.test-id]="'CancelButton'"
                    [disabled]="(discardState | async) === false"
                    (click)="showManualDiscardedModal()">
                {{ 'settlement.state.actions.cancel' | translate }}
            </button>
        </div>
    <div [matTooltip]="sendToNavAndManualProcessingTooltip" [matTooltipClass]="'allow-cr'">
        <button mat-button
                color="primary"
                [attr.test-id]="'CancelButton'"
                [disabled]="(hasReportsState | async) === false"
                (click)="showReportsModal()">
                    <mat-icon
                       [fontSet]="'l7'"
                       [fontIcon]="'Alert_Triangle'">
                    </mat-icon>
                    {{ 'settlement.state.actions.seeReports' | translate }}
        </button>
    </div>
</div>
<l7-spacer *ngIf="isReadOnlyUser" [thickness]="'bottom'" [density]="'cosy'"></l7-spacer>
