import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[l7AutocompleteScrollHandler]',
    standalone: false,
})
export class AutocompleteScrollHandlerDirective implements OnDestroy, AfterViewInit {

    private _matAutocompleteTrigger: MatAutocompleteTrigger;
    private readonly _root: HTMLElement | null = null;
    private readonly _rootMargin: string = '0px';
    private readonly _threshold: Array<number> = [0, 0.25, 0.5, 0.75, 1];
    private readonly _debounceTime: number = 0;
    private readonly _isContinuous: boolean = true;
    private _intersectionObserverSubscription: Subscription;
    private readonly _autocompleteInputRef: ElementRef;
    @Input() set autocompleteTriggerRef(value: MatAutocompleteTrigger) {
        this._matAutocompleteTrigger = value;
        window.addEventListener('scroll', this.scrollEvent, true);
    }

    public constructor(private readonly element: ElementRef) {
        this._autocompleteInputRef = this.element;
    }

    public ngAfterViewInit(): void {
        this._intersectionObserverSubscription = this.createAndObserve();
    }

    public ngOnDestroy(): void {
        this._intersectionObserverSubscription.unsubscribe();
        window.removeEventListener('scroll', this.scrollEvent, true);
    }

    private scrollEvent(): void {
        this._matAutocompleteTrigger.updatePosition();
    }

    private createAndObserve(): Subscription {
        const options: IntersectionObserverInit = {
            root: this._root,
            rootMargin: this._rootMargin,
            threshold: this._threshold,
        };

        return new Observable<boolean>((subscriber) => {
            const intersectionObserver = new IntersectionObserver((entries) => {
                const { isIntersecting }: IntersectionObserverEntry = entries[0];
                subscriber.next(isIntersecting);
                if (isIntersecting
                    && !this._isContinuous) {
                    intersectionObserver.disconnect();
                }
            }, options);

            intersectionObserver.observe(this.element.nativeElement);

            return {
                unsubscribe(): void {
                    intersectionObserver.disconnect();
                },
            };
        }).pipe(debounceTime(this._debounceTime)).subscribe((visible) => {
            if (!visible) {
                this._matAutocompleteTrigger.closePanel();
            }
        });
    }

}
