// #region Imports

import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { PseudoStringFormatter } from './PseudoStringFormatter';

// #endregion

/**
 * A pipe to format strings using the PseudoStringFormatter.
 */
@Pipe({
    name: 'l7PseudoFormat',
    standalone: false,
})
export class PseudoFormatPipe implements PipeTransform {

    /**
     * Transforms an object into a formatted string using the provided pseudo-language template.
     *
     * @param context - The object containing the fields to interpolate.
     * @param template - The pseudo-language template string.
     * @returns The formatted string.
     */
    public transform(context: Record<string, unknown>, template: string): string {
        if (!template || !context) {
            return '';
        }
        return PseudoStringFormatter.format(template, context);
    }

}

@NgModule({
    declarations: [PseudoFormatPipe],
    exports: [PseudoFormatPipe],
})
export class PseudoFormatPipeModule {
}
