import { ContactService, IContactDto, IContactSearchResultEntryDto, IIdTypeDto } from '@abcfinlab/api/contact';
import { IContractTypeDto, IHandlingFeeOptionDto, ILesseeUpdateForQuoteDraftDto, IObjectConditionDto, IRetailerCalculationRequestDto, IRetailerCalculationResultDto, IRetailerCreateDraftDto, IRetailerCreateQuoteRequestDto, IRetailerQuoteDto, RetailerQuoteService } from '@abcfinlab/api/global';
import {
    RetailerCalculationResult, RetailerQuoteDto, RetailerQuoteResult,
    RetailerQuoteSettingsResponse,
} from '@abcfinlab/api-global';
import { ISaveOfferWithoutCrefoViewDialogData, ISaveOfferWithoutCrefoViewDialogResult, SaveOfferWithoutCrefoView } from '@abcfinlab/contacts';
import {
    Validators as CoreValidators,
    FormValidator,
    once,
    TranslationFacade,
    getPercentDeprecated, getPercentageValueDeprecated,
} from '@abcfinlab/core';
import { objectToSnake } from '@abcfinlab/presentation';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService, WizardSelectionChangeEvent } from '@abcfinlab/ui';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
    computed,
    effect,
    inject,
    Injectable,
    signal,
    untracked,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH } from '../../../Routing/RoutePaths';
import { Validators as QuoteValidators } from '../../../Validators/Validators';
import {
    IUpdateCalculationInfo,
} from '../../../Views/retailer-update-lessee-overview/retailer-update-lessee-overview-presenter';
import {
    RetailerCalculateLeasingQuoteFormService,
} from '../../Services/forms/retailer-calculate-leasing-quote-form/retailer-calculate-leasing-quote-form.service';
import { RetailerQuoteDataService } from '../../Services/resources/retailer-quote-data/retailer-quote-data.service';
import {
    RetailerSettingsDataService,
} from '../../Services/resources/retailer-settings-data/retailer-settings-data.service';

@Injectable()
export class FeatureRetailerCalculationService {

    private readonly _calculateLeasingQuoteService = inject(RetailerCalculateLeasingQuoteFormService, { self: true });
    private readonly _retailerQuoteService = inject(RetailerQuoteService);
    private readonly _contactService = inject(ContactService);
    private readonly _router = inject(Router);
    private readonly _location = inject(Location);
    private readonly _dialogService = inject(MatDialog);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _busyBoxService = inject(BusyBoxService);
    private readonly _toastService = inject(ToastService);
    private readonly _messageBoxService = inject(MessageBoxService);
    private readonly _translationFacade = inject(TranslationFacade);
    private readonly _formValidator = inject(FormValidator);
    private readonly _showRatesSubject = new BehaviorSubject<boolean>(false);
    private readonly _showResidualValuesSubject = new BehaviorSubject<boolean>(false);
    private readonly _minAndMaxResidualValuesSubject = new BehaviorSubject<{ min: number; max: number } | null>(null);
    private readonly _minAndMaxDealerCommissionValuesSubject = new BehaviorSubject<{ min: number; max: number } | null>(null);
    readonly calculationDetailsForm = this._calculateLeasingQuoteService.calculationDetailsFormGroup;
    readonly rate = this._calculateLeasingQuoteService.calculationSelectionControl;
    private readonly _showExclusionOfWarrantySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _lessees = new BehaviorSubject<Array<IContactSearchResultEntryDto> | null>(null);
    private readonly _selectedStepSubject = new BehaviorSubject(0);
    readonly hasDownPayment = this._calculateLeasingQuoteService.hasDownPayment;
    private readonly _calculationDetails = this._calculateLeasingQuoteService.calculationDetails;
    readonly hasDealerCommission = signal<boolean>(true);
    readonly handlingFeeValue = this._calculateLeasingQuoteService.handlingFeeValue;
    readonly monthlyInsuranceValue = this._calculateLeasingQuoteService.monthlyInsuranceValue;
    private readonly _residualDissolvedFactorSubject = new BehaviorSubject<null | 'currency' | 'percent'>(null);
    readonly form = new FormGroup({
        calculation: new FormGroup({
            objectName: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
            objectQuantity: new FormControl<number>(1, Validators.required),
            calculationDetails: this.calculationDetailsForm,
            exclusionOfWarranty: new FormControl<boolean>(true),
            rate: this.rate,
        }),
        search: new FormGroup({
            companyName: new FormControl<string | null>(null, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
            city: new FormControl<string | null>(null, [Validators.required, Validators.minLength(2), Validators.maxLength(40)]),
            country: new FormControl<string>({ value: 'Deutschland', disabled: true }, [Validators.required, Validators.minLength(2), Validators.maxLength(8)]),
        }),
        lessee: new FormControl<Array<IContactSearchResultEntryDto> | null>(null, [Validators.required]),
    });

    private readonly _destroyRef = new Subject<void>();
    private _isRecalculation = false;
    private _updateExistingCalculationInfo?: IUpdateCalculationInfo;
    private readonly _retailerSettingsDataService = inject(RetailerSettingsDataService);
    private readonly _retailerQuoteDataService = inject(RetailerQuoteDataService);
    private readonly _isNewVersion = this._retailerQuoteDataService.isNewVersion;
    private readonly _quoteId = this._retailerQuoteDataService.quoteId;
    retailerQuote = this._retailerQuoteDataService.retailerQuote;
    retailerConfig = this._retailerSettingsDataService.retailerConfig;
    retailerSettings = this._retailerSettingsDataService.retailerSettings;
    quoteCalculationSettings = this._retailerSettingsDataService.calculationSettings;
    isInitialLoading = computed(() => this._retailerSettingsDataService.isInitialLoading() || this._retailerQuoteDataService.isLoading());
    private readonly _lesseeId = computed(() => this.retailerQuote()?.quote?.lesseeId ?? '');

    constructor() {
        effect(() => {
            const quoteDetails = this.retailerQuote();
            const retailerSettings = this.retailerSettings();
            const calculationSettings = this.quoteCalculationSettings();

            untracked(() => {
                if (retailerSettings) {
                    this.setRetailerSettings(retailerSettings);

                    if (quoteDetails && this._isNewVersion()) {
                        this.patchValuesFromQuote(quoteDetails);
                    }
                }
                if (calculationSettings) {
                    this.initializeForm();
                }
            });
        });
    }

    public onDownPaymentCheckboxChange(checked: boolean): void {
        this._calculateLeasingQuoteService.onDownPaymentCheckboxChange(checked);
    }

    public onDealerCommissionCheckboxChange(checked: boolean): void {
        this._calculateLeasingQuoteService.onDealerCommissionCheckboxChange(checked);
    }

    public onSelectRate(rate: RetailerCalculationResult): void {
        this.form.controls.calculation.controls.rate.setValue(rate);
    }

    public get selectedStep(): Observable<number> {
        return this._selectedStepSubject.asObservable();
    }

    public get isRecalculation(): boolean {
        return this._isRecalculation;
    }

    /**
    * Returns the `residualDissolvedFactor` property.
    *
    * @public
    * @readonly
    */
    public get residualDissolvedFactor(): Observable<null | 'currency' | 'percent'> {
        return this._residualDissolvedFactorSubject.asObservable();
    }

    /**
    * Returns the `showRates` property.
    *
    * @public
    * @readonly
    */
    public get showRates(): Observable<boolean> {
        return this._showRatesSubject.asObservable();
    }

    /**
    * Returns the `showResidualValues` property.
    *
    * @public
    * @readonly
    */
    public get showResidualValues(): Observable<boolean> {
        return this._showResidualValuesSubject.asObservable();
    }

    /**
    * Returns the `showExclusionOfWarranty` property.
    *
    * @public
    * @readonly
    */
    public get showExclusionOfWarranty(): Observable<boolean> {
        return this._showExclusionOfWarrantySubject.asObservable();
    }

    /**
    * Returns the `minAndMaxResidualValues` property.
    *
    * @public
    * @readonly
    */
    public get minAndMaxResidualValues(): Observable<{ min: number; max: number }> {
        return this._minAndMaxResidualValuesSubject.asObservable();
    }

    /**
    * Returns the `minAndMaxDealerCommissionValues` property.
    *
    * @public
    * @readonly
    */
    public get minAndMaxDealerCommissionValues(): Observable<{ min: number; max: number }> {
        return this._minAndMaxDealerCommissionValuesSubject.asObservable();
    }

    /**
    * Returns the `lessees` property.
    *
    * @public
    * @readonly
    */
    public get lessees(): Observable<Array<IContactSearchResultEntryDto>> {
        return this._lessees.asObservable();
    }

    // #endregion

    // #region Methods

    /**
    * Called before the view first displays the data-bound properties and sets the view's input properties.
    *
    * @internal
    */
    public initialize(): void {
        // @ts-ignore: we need like this to get the new calculation info and as location state does not have typing, we ignore it
        this._updateExistingCalculationInfo = this._location.getState();
        this._isRecalculation = this._updateExistingCalculationInfo?.isRecalculation ?? false;
    }

    private setRetailerSettings(retailerSettings: RetailerQuoteSettingsResponse): void {
        this.handlingFeeValue.set(retailerSettings.handlingFeeValue);
        this.calculationDetailsForm.controls.dealerCommissionInPercent.patchValue(retailerSettings.provisionInPercent, { emitEvent: false });

        if (!retailerSettings.provision) {
            this.calculationDetailsForm.controls.dealerCommissionInPercent.disable();
        }

        if (retailerSettings.objectGroups?.length === 1) {
            const groupCode = retailerSettings.objectGroups[0].code;
            this.calculationDetailsForm.controls.objectGroupCode.patchValue(groupCode);
        }

        if (retailerSettings.contractType?.length === 1) {
            this.calculationDetailsForm.controls.contractType.patchValue(retailerSettings.contractType[0]);
        }

        if (retailerSettings.contractType?.length === 1 && retailerSettings?.contractType[0] === IContractTypeDto.Ta) {
            this.addValidatorRequiredToFormControl(this.calculationDetailsForm.controls.residualValue);
            this.addValidatorRequiredToFormControl(this.calculationDetailsForm.controls.dealerCommissionInPercent);
            this._showResidualValuesSubject.next(true);
        }

        if (retailerSettings.handlingFeeOption === IHandlingFeeOptionDto.AlwaysCharge) {
            this.calculationDetailsForm.controls.handlingFee.disable();
        } else if (retailerSettings.handlingFeeOption === IHandlingFeeOptionDto.NeverCharge) {
            this.calculationDetailsForm.controls.handlingFee.patchValue(false);
            this.calculationDetailsForm.controls.handlingFee.disable();
            this.removeValidatorRequiredToFormControl(this.calculationDetailsForm.controls.handlingFee);
        } else if (retailerSettings.handlingFeeOption === IHandlingFeeOptionDto.Customizable) {
            this.removeValidatorRequiredToFormControl(this.calculationDetailsForm.controls.handlingFee);
        }
    }

    /**
    * Called before the view will be destroyed.
    * Unsubscribe Observables and detach event handlers to avoid memory leaks.
    *
    * @internal
    */
    public dispose(): void {
        this._destroyRef.next();
        this._destroyRef.complete();
        this._calculateLeasingQuoteService.destroy();
    }

    public createQuote(): Observable<string> {
        const subject = new Subject<string>();
        const subjectDoneFn = (query: unknown, _data?: unknown): void => {
            subject.next(btoa(JSON.stringify(query)));
            subject.complete();
        };

        let downPaymentInPercent = null;
        let residualValueInPercent = null;
        if (this.hasDownPayment()) {
            downPaymentInPercent = this._calculateLeasingQuoteService.calculatePercentageValue('downPayment');
        }
        if (this.calculationDetailsForm.controls.contractType.value === IContractTypeDto.Ta) {
            residualValueInPercent = this._calculateLeasingQuoteService.calculatePercentageValue('residualValue');
        }

        const formData: IRetailerCreateQuoteRequestDto = {
            ...{
                calculationDetails: {
                    ...this._calculationDetails(),
                    ...{
                        monthlyInsurance: this.calculationDetailsForm.controls.monthlyInsurance.getRawValue(),
                        handlingFee: this.calculationDetailsForm.controls.handlingFee.getRawValue(),
                        dealerCommissionInPercent: this.hasDealerCommission() ? this.calculationDetailsForm.controls.dealerCommissionInPercent.getRawValue() : null,
                        downPaymentInPercent,
                        residualValueInPercent,
                    },
                },
                calculationSelection: this.form.controls.calculation.controls.rate.getRawValue(),
                lesseeContact: this.form.controls.lessee.getRawValue()?.at(0) as unknown as IRetailerCreateQuoteRequestDto['lesseeContact'],
                quoteId: this._quoteId(),
                exclusionOfWarranty: this.form.controls.calculation.controls.exclusionOfWarranty.getRawValue(),
                objectQuantity: this.form.controls.calculation.controls.objectQuantity.getRawValue(),
                objectName: this.form.controls.calculation.controls.objectName.getRawValue(),
            },
            ...{ exclusionOfWarranty: this.calculationDetailsForm.controls.objectCondition.getRawValue() === IObjectConditionDto.New ? false : this.form.controls.calculation.controls.exclusionOfWarranty.getRawValue() },
        } as unknown as IRetailerCreateQuoteRequestDto;

        this._busyBoxService.show(null, this._translationFacade.translate('global.busy'), this._retailerQuoteService.createRetailerLeasingQuote({ body: formData }))
            .subscribe((result) => {
                subjectDoneFn(formData, result);
                const quoteDetailsRoute = this._isNewVersion() ? `../../../../../${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}` : `../../../${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}`;
                void this._router.navigate([quoteDetailsRoute, result.quoteId], { relativeTo: this._activatedRoute });
            }, () => {
                this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
            });

        return subject.asObservable();
    }

    public onRequestMail(): void {
        this._dialogService.open<SaveOfferWithoutCrefoView, ISaveOfferWithoutCrefoViewDialogData, ISaveOfferWithoutCrefoViewDialogResult>(SaveOfferWithoutCrefoView, {
            width: '600px',
            minHeight: '200px',
            data: this.form.controls.search.getRawValue(),
        }).afterClosed().subscribe((confirmed) => {
            const retailerCalculationResult = this.form.controls.calculation.controls.rate.value as IRetailerCalculationResultDto;
            if (confirmed && retailerCalculationResult !== null) {
                const data: IRetailerCreateDraftDto = {
                    warrantyRequired: this.form.controls.calculation.controls.exclusionOfWarranty.value,
                    objectName: this.form.controls.calculation.controls.objectName.value,
                    objectQuantity: this.form.controls.calculation.controls.objectQuantity.value,
                    quoteId: crypto.randomUUID(),
                    retailerCalculationRequest: this._calculationDetails() as IRetailerCalculationRequestDto,
                    retailerCalculationResult,
                    lesseeContact: {
                        city: confirmed.city,
                        country: confirmed.country,
                        firstName: confirmed.companyName,
                        houseNumber: confirmed.houseNumber,
                        name: confirmed.companyName,
                        name2: confirmed.companyName,
                        postcode: confirmed.postalCode,
                        street: confirmed.street,
                    },
                };
                this._busyBoxService.show(null, this._translationFacade.translate('global.busy'), this._retailerQuoteService.createQuoteDraft({ body: data })).subscribe((result) => {
                    const quoteDetailsRoute = this._isNewVersion() ? `../../../../../${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}` : `../../../${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}`;
                    void this._router.navigate([quoteDetailsRoute, result.quoteId], { relativeTo: this._activatedRoute });
                    this._toastService.show(this._translationFacade.translate('quote.draftCreated'), 'success');
                }, () => {
                    this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
                });
            }
        });
    }

    public updateCalculation(): void {
        const newCalculation: RetailerQuoteDto | undefined = this._updateExistingCalculationInfo?.newCalculation as RetailerQuoteDto | undefined;
        if (newCalculation) {
            newCalculation.type = this.calculationDetailsForm.controls.contractType.value;
            newCalculation.objects[0].objectQuantity = this.form.controls.calculation.controls.objectQuantity.value;
            newCalculation.objects[0].objectDescription = this.form.controls.calculation.controls.objectName.value;
            newCalculation.objects[0].exclusionOfWarranty = this.form.controls.calculation.controls.exclusionOfWarranty.value;
            newCalculation.objects[0].condition = this.calculationDetailsForm.controls.objectCondition.value;
            newCalculation.monthlyInsuranceValue = this.monthlyInsuranceValue();
            newCalculation.handlingFeeValue = this.handlingFeeValue();
            newCalculation.dealerCommissionInPercent = this.calculationDetailsForm.controls.dealerCommissionInPercent.value;
            newCalculation.calculation.downPaymentValue = this.calculationDetailsForm.controls.downPayment.value;
            newCalculation.calculation.terms = this.form.controls.calculation.controls.rate.value?.terms;
            newCalculation.calculation.instalment = this.form.controls.calculation.controls.rate.value?.instalment;
            newCalculation.calculation.residualValue = this.calculationDetailsForm.controls.residualValue.value;
            newCalculation.totalLeasingValue = this.calculationDetailsForm.controls.totalLeasingValue.value;
            newCalculation.objects[0].objectGroup = this.calculationDetailsForm.controls.objectGroupCode.value;
            newCalculation.quoteId = this._updateExistingCalculationInfo?.quoteId;
            const updateLesseeInfo: ILesseeUpdateForQuoteDraftDto = {
                lesseeContact: this._updateExistingCalculationInfo?.lesseeContact,
                newLeasingQuote: newCalculation as IRetailerQuoteDto,
            };
            once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
                this._retailerQuoteService.updateDraftWithLessee({ leasingQuoteId: this._updateExistingCalculationInfo?.quoteId ?? '', body: updateLesseeInfo }).pipe(),
            ), () => {
                void this._router.navigateByUrl(`presentation/${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}/${String(this._updateExistingCalculationInfo?.quoteId)}`);
            }, () => {
                this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
            });
        }
    }

    public onStepChanged(event: WizardSelectionChangeEvent): void {
        const { selectedIndex } = event;

        // if the user whants to go next, validate the form
        // #1 is the general calculation
        if (selectedIndex === 1) {
            this._formValidator
                .validate(this.form.controls.calculation);

            if (this._formValidator.errors(this.form.controls.calculation).length > 0) {
                console.error(this._formValidator.errors(this.form.controls.calculation).map(x => x.name).join(':'));
                this._toastService.show(this._translationFacade.translate('global.pleaseCheckData'), 'danger');
                event.cancel = true;
                return;
            }
            if (this._isNewVersion()) {
                this._contactService.getById({ id: this._lesseeId(), idType: IIdTypeDto.Id })
                    .subscribe((lessee) => {
                        const updatedLessee = {
                            ...lessee,
                            crefoId: lessee.crefo_id,
                        };
                        delete updatedLessee.crefo_id;
                        this.form.controls.lessee.patchValue([updatedLessee as any], { emitEvent: true });
                    });

                event.cancel = true;
                // this._selectedStepSubject.next(selectedIndex);
            } else {
                this._selectedStepSubject.next(selectedIndex);
            }
        }

        // #2 is the search for the lessee
        if (selectedIndex === 2) {
            this._formValidator
                .validate(this.form.controls.search);

            if (this._formValidator.errors(this.form.controls.search).length > 0) {
                console.error(this._formValidator.errors(this.form.controls.search).map(x => x.name).join(':'));
                this._toastService.show(this._translationFacade.translate('global.pleaseCheckData'), 'danger');
                event.cancel = true;
                return;
            }

            this._busyBoxService.show(null, this._translationFacade.translate('global.busy'), this._contactService.searchCamLegacy(this.form.controls.search.getRawValue())).subscribe((result) => {
                this._lessees.next(result.content);
                this._selectedStepSubject.next(selectedIndex);
            }, () => {
                this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
            });
        }

        // #3 is the lessee selection
        if (selectedIndex === 3) {
            this._formValidator
                .validate(this.form.controls.lessee);

            if (this._formValidator.errors(this.form.controls.lessee).length > 0) {
                this._toastService.show(this._formValidator.errors(this.form.controls.lessee).map(x => x.name).join(':'), 'danger');
                event.cancel = true;
                return;
            }

            this._selectedStepSubject.next(selectedIndex);
        }
    }

    public formatAddress(contact: IContactSearchResultEntryDto): string {
        let address = '';

        // it could be possible string with empty spaces
        if (contact.street?.trim()) {
            address += contact.street;
        }

        // it could be possible string with empty spaces
        if (contact.houseNumber?.trim()) {
            address += ` ${contact.houseNumber}`;
        }

        if (contact.postalCode) {
            address += `, ${contact.postalCode}`;
        }

        if (contact.city) {
            address += ` ${contact.city}`;
        }

        if (contact.contactNumber) {
            const sep = address.trim().length > 0 ? ' | ' : '';
            address += `${sep}${this._translationFacade.instant('global.nav_contact')}: ${contact.contactNumber}`;
        }

        return address;
    }

    private initializeForm(): void {
        const quoteCalculationProperties = this.quoteCalculationSettings()?.leasingQuoteCalculationProperties;
        const totalLeasingValueFormControl = this.calculationDetailsForm.controls.totalLeasingValue;
        totalLeasingValueFormControl.addValidators(QuoteValidators.allowedRange(quoteCalculationProperties?.retailer?.maxObjectValue, quoteCalculationProperties?.retailer?.minObjectValue));

        // we need to subscribe to the form changes to calculate the rate values.
        // only when the whole 'data' object is valid, the we can calculate the rates.

        // when the total leasing value changed we need to reset the residual value and residual value in percent
        totalLeasingValueFormControl.valueChanges.subscribe(() => {
            if (this.calculationDetailsForm.controls.contractType.value === 'TA') {
                if (this._residualDissolvedFactorSubject.value === 'currency') {
                    const model = (totalLeasingValueFormControl.value ?? 0);
                    const percent = getPercentDeprecated(model, this.calculationDetailsForm.controls.residualValue.value, 2, true);

                    this.calculationDetailsForm.controls.residualValueInPercent.patchValue(Number.isFinite(percent) ? percent : null, { emitEvent: false });
                    this.calculationDetailsForm.controls.residualValueInPercent.updateValueAndValidity({ emitEvent: false });

                    const minValue = getPercentageValueDeprecated(10, this.calculationDetailsForm.controls.totalLeasingValue.value);
                    const maxValue = getPercentageValueDeprecated(99, this.calculationDetailsForm.controls.totalLeasingValue.value);

                    this._minAndMaxResidualValuesSubject.next({ min: minValue, max: maxValue });
                }

                if (this._residualDissolvedFactorSubject.value === 'percent') {
                    const value = getPercentageValueDeprecated(this.calculationDetailsForm.controls.residualValueInPercent.value, this.calculationDetailsForm.controls.totalLeasingValue.value);

                    this.calculationDetailsForm.controls.residualValue.patchValue(value, { emitEvent: false });
                    this.calculationDetailsForm.controls.residualValue.updateValueAndValidity({ emitEvent: false });

                    const minValue = getPercentageValueDeprecated(10, this.calculationDetailsForm.controls.totalLeasingValue.value);
                    const maxValue = getPercentageValueDeprecated(99, this.calculationDetailsForm.controls.totalLeasingValue.value);

                    this._minAndMaxResidualValuesSubject.next({ min: minValue, max: maxValue });
                }
            }
        });

        // when the object condition changes we need to check if the exclusion of warranty is required
        this.calculationDetailsForm.controls.objectCondition.valueChanges.subscribe(() => {
            if (this.calculationDetailsForm.controls.objectCondition.value === IObjectConditionDto.Used) {
                this.addValidatorRequiredToFormControl(this.form.controls.calculation.controls.exclusionOfWarranty);
                this._showExclusionOfWarrantySubject.next(true);
            } else if (this.calculationDetailsForm.controls.objectCondition.value === IObjectConditionDto.New) {
                this.removeValidatorRequiredToFormControl(this.form.controls.calculation.controls.exclusionOfWarranty);
                this._showExclusionOfWarrantySubject.next(false);
            }
        });

        // when the contract type changes we need to check if the residual value is required
        this.calculationDetailsForm.controls.contractType.valueChanges.subscribe(() => {
            if (this.calculationDetailsForm.controls.contractType.value === IContractTypeDto.Ta) {
                this.calculationDetailsForm.controls.residualValue.addValidators(Validators.required);
                this.calculationDetailsForm.controls.residualValue.addValidators(QuoteValidators.allowedValuesRelatedToLeasingValue(0.99, 0.1, 'residualValue'));
                this.calculationDetailsForm.controls.residualValueInPercent.addValidators(Validators.required);
                this.calculationDetailsForm.controls.residualValueInPercent.addValidators(CoreValidators.isNumberInRange(0.1, 0.99, 100));
                this._showResidualValuesSubject.next(true);
            } else {
                this.calculationDetailsForm.controls.residualValue.clearValidators();
                this.calculationDetailsForm.controls.residualValue.updateValueAndValidity({ emitEvent: false });
                this.calculationDetailsForm.controls.residualValueInPercent.clearValidators();
                this.calculationDetailsForm.controls.residualValueInPercent.updateValueAndValidity({ emitEvent: false });
                this._showResidualValuesSubject.next(false);
            }
        });

        // when the residual value in euro changes we need calculate the residual value in percent
        this.calculationDetailsForm.controls.residualValue.valueChanges.subscribe(() => {
            // this is needed as signal witch field was the last one to change
            this._residualDissolvedFactorSubject.next('currency');

            const model = (this.calculationDetailsForm.controls.totalLeasingValue.value ?? 0);
            const percent = getPercentDeprecated(model, this.calculationDetailsForm.controls.residualValue.value, 2, true);

            this.calculationDetailsForm.controls.residualValueInPercent.patchValue(Number.isFinite(percent) ? percent : null, { emitEvent: false });
            this.calculationDetailsForm.controls.residualValueInPercent.updateValueAndValidity({ emitEvent: false });

            const minValue = getPercentageValueDeprecated(10, this.calculationDetailsForm.controls.totalLeasingValue.value);
            const maxValue = getPercentageValueDeprecated(99, this.calculationDetailsForm.controls.totalLeasingValue.value);

            this._minAndMaxResidualValuesSubject.next({ min: minValue, max: maxValue });
        });

        // when the residual value in percent changes we need calculate the residual value in euro
        this.calculationDetailsForm.controls.residualValueInPercent.valueChanges.subscribe(() => {
            // this is needed as signal witch field was the last one to change
            this._residualDissolvedFactorSubject.next('percent');

            const value = getPercentageValueDeprecated(this.calculationDetailsForm.controls.residualValueInPercent.value, this.calculationDetailsForm.controls.totalLeasingValue.value);

            this.calculationDetailsForm.controls.residualValue.patchValue(value, { emitEvent: false });
            this.calculationDetailsForm.controls.residualValue.updateValueAndValidity({ emitEvent: false });

            const minValue = getPercentageValueDeprecated(10, this.calculationDetailsForm.controls.totalLeasingValue.value);
            const maxValue = getPercentageValueDeprecated(99, this.calculationDetailsForm.controls.totalLeasingValue.value);

            this._minAndMaxResidualValuesSubject.next({ min: minValue, max: maxValue });
        });

        this.form.controls.lessee.valueChanges.subscribe((lessee) => {
            this._busyBoxService
                .show(null, null, this._contactService.checkContactNavStateByType({
                    id: lessee.at(0).crefoId,
                    contactType: 'lessee',
                    idType: IIdTypeDto.Crefo,
                }))
                .subscribe((x) => {
                    if (x.contactNo.length) {
                        this.checkIfNewContactIsNeeded(lessee.at(0));
                    } else {
                        this.form.controls.lessee.patchValue([objectToSnake(lessee) as any], { emitEvent: false });
                        this.createQuote().subscribe();
                    }
                }, (error) => {
                    // customer is protected
                    if (error instanceof HttpErrorResponse && error.status === 409 && error.error.error === 'contact_customer_protected') {
                        const message = this._translationFacade.instant('dialogs.contact_customer_protected');
                        this._messageBoxService.show('Bestehender Kundenschutz', message, MessageBoxButton.OK, {
                            labels: { yes: this._translationFacade.translate('global.close') },
                        }).subscribe((result) => {
                            if (result === MessageBoxResult.Yes) {
                                this.form.controls.lessee.patchValue([objectToSnake(lessee) as any], { emitEvent: false });
                                this.createQuote().subscribe();
                            }
                        });
                    }

                    if (error instanceof HttpErrorResponse && error.status === 409 && error.error.error === 'contact_customer_reserved') {
                        const message = this._translationFacade.instant('dialogs.contact_customer_reserved');
                        this._messageBoxService.show('Bestehende Reservierung für Kundenschutz', message, MessageBoxButton.YesNo, {
                            labels: {
                                yes: this._translationFacade.translate('global.further'),
                                no: this._translationFacade.translate('global.cancel'),
                            },
                        }).subscribe((result) => {
                            if (result === MessageBoxResult.Yes) {
                                this.checkIfNewContactIsNeeded(lessee.at(0));
                            } else {
                                this.form.controls.lessee.patchValue(null, { emitEvent: false });
                            }
                        });
                    }
                });
        });
    }

    private checkIfNewContactIsNeeded(lessee: IContactSearchResultEntryDto): void {
        this._busyBoxService.show(null, null, this._contactService.checkIfNewContactNeeded({ id: lessee.crefoId, idType: IIdTypeDto.Crefo }), { id: 'checkNewContact' })
            .subscribe((res: IContactDto) => {
                if (res.new_contact_needed) {
                    const contactDto = res;
                    const name = contactDto.name;
                    const address = `${contactDto.street} ${contactDto.house_number}, ${contactDto.postcode} ${contactDto.city}`;
                    const message = this._translationFacade.instant('dialogs.new_contact_address_lessee', {
                        param1: name,
                        param2: address,
                    });
                    this._messageBoxService.show('Abweichende Adresse', message, MessageBoxButton.YesNo, {
                        labels: {
                            yes: this._translationFacade.translate('global.further'),
                            no: this._translationFacade.translate('global.cancel'),
                        },
                    }).subscribe((result) => {
                        if (result === MessageBoxResult.Yes) {
                            this.form.controls.lessee.patchValue([res as any], { emitEvent: false });
                            this.createQuote().subscribe();
                        } else {
                            this.form.controls.lessee.patchValue(null, { emitEvent: false });
                        }
                    });
                } else {
                    this.form.controls.lessee.patchValue([objectToSnake(lessee) as any], { emitEvent: false });
                    this.createQuote().subscribe();
                }
            });
    }

    private resetForm(): void {
        this.form.reset();
        this.calculationDetailsForm.reset();
        this._selectedStepSubject.next(0);
    }

    private patchValuesFromQuote(quoteDetails: RetailerQuoteResult): void {
        const objectGroupCode = this.retailerSettings()?.objectGroups?.find(oG => oG.code === quoteDetails.quote?.objects?.[0].objectGroup)?.code;
        const data = {
            totalLeasingValue: quoteDetails.detail?.objectValue,
            contractType: quoteDetails.detail?.contractType,
            objectGroupCode,
            condition: quoteDetails.quote?.objects?.[0]?.condition as IObjectConditionDto,
            residualValue: quoteDetails.quote?.calculation?.residualValue,
            downPayment: quoteDetails.quote?.calculation?.downPaymentValue,
            monthlyInsurance: !!quoteDetails.quote?.monthlyInsuranceValue,
            handlingFee: !!quoteDetails.quote?.handlingFeeValue,
            dealerCommissionInPercent: quoteDetails.quote?.dealerCommissionInPercent,
        };

        if (quoteDetails.quote?.handlingFeeValue) {
            this.handlingFeeValue.set(quoteDetails.quote?.handlingFeeValue);
        }
        this.hasDealerCommission.set(!!quoteDetails.quote?.dealerCommissionInPercent);
        if (quoteDetails.quote?.monthlyInsuranceValue) {
            this.monthlyInsuranceValue.set(quoteDetails.quote?.monthlyInsuranceValue);
        }

        if (quoteDetails.detail?.contractType === 'TA') {
            this._showResidualValuesSubject.next(true);
        }

        this.onDownPaymentCheckboxChange(!!quoteDetails.quote?.calculation?.downPaymentValue);
        this.calculationDetailsForm.patchValue(data);
        this.form.controls.calculation.controls.rate.setValue(null);
        this.form.controls.calculation.controls.objectQuantity.patchValue(quoteDetails.quote.objects[0].objectQuantity);
        this.form.controls.calculation.controls.objectName.patchValue(quoteDetails.quote.objects[0].objectDescription);
        this.form.controls.calculation.controls.exclusionOfWarranty.patchValue(quoteDetails.quote.objects[0].exclusionOfWarranty);
        this._showExclusionOfWarrantySubject.next(quoteDetails.quote.objects[0].condition === IObjectConditionDto.Used);
    }

    private addValidatorRequiredToFormControl(ctrl: AbstractControl): void {
        ctrl.addValidators(Validators.required);
        ctrl.updateValueAndValidity({ emitEvent: false });
    }

    private removeValidatorRequiredToFormControl(ctrl: AbstractControl): void {
        ctrl.removeValidators(Validators.required);
        ctrl.updateValueAndValidity({ emitEvent: false });
    }

    // #endregion

}
