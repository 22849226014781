// #region Imports

import type { ICommand } from './Interfaces/ICommand';

// #endregion

/**
 * @public
 *
 * Represents a command that can be executed.
 */
export class Command<T = void> implements ICommand<T> {

    // #region Fields

    private readonly _executeMethod: (parameter: T) => void;
    private readonly _canExecuteMethod: (parameter: T) => boolean;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `Command` class.
     *
     * @public
     */
    public constructor(executeMethod: (parameter: T) => void, canExecuteMethod?: (parameter: T) => boolean) {
        this._executeMethod = executeMethod;
        this._canExecuteMethod = canExecuteMethod ?? (() => true);
    }

    // #endregion

    // #region Methods

    /**
     * Executes the command.
     *
     * @public
     * @param parameter - The parameter.
     */
    public execute(parameter: T): void {
        if (this._canExecuteMethod(parameter)) {
            this._executeMethod(parameter);
        }
    }

    /**
     * Determines whether the command can be executed.
     *
     * @public
     * @param parameter - The parameter.
     * @returns `true` if the command can be executed; otherwise, `false`.
     */
    public canExecute(parameter: T): boolean {
        return this._canExecuteMethod(parameter);
    }

    // #endregion

}
