<l7-dialog-header [supportiveTitle]="'dialogs.quoteDocumentTableAction.' + dialogData.actionType + '.subtitle' | translate: {lesseeName: dialogData.lesseeName}" [title]="'dialogs.quoteDocumentTableAction.' + dialogData.actionType + '.title' | translate"></l7-dialog-header>
<mat-dialog-content fxLayout="column">
    <l7-text-block  *ngIf="dialogData.actionType === QuoteDocumentTableAction.PRINT || dialogData.actionType === QuoteDocumentTableAction.PREVIEW" class="bodyText" [wrap]="true" [maxLines]="3" [text]="'dialogs.quoteDocumentTableAction.' + dialogData.actionType + '.body' | translate">
    </l7-text-block>
    <l7-text-block  *ngIf="dialogData.actionType === QuoteDocumentTableAction.SEND && dialogData.isDraft" class="bodyText" [wrap]="true" [maxLines]="3" [text]="'dialogs.quoteDocumentTableAction.' + dialogData.actionType + '.body' | translate">
    </l7-text-block>
    <l7-spacer [thickness]="'top'">
    <form *ngIf="dialogData.actionType === QuoteDocumentTableAction.SEND" [formGroup]="sendDraftForm">
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{ 'dialogs.quoteDocumentTableAction.send.email' | translate }}</mat-label>
            <input matInput
                   formControlName="email">
            <mat-error *ngIf="sendDraftForm.controls.email.hasError('required')">{{'auth.input.email.error.required' | translate}}</mat-error>
            <mat-error *ngIf="sendDraftForm.controls.email.hasError('email')">{{'auth.input.email.error.email' | translate}}</mat-error>
        </mat-form-field>
    </form>
    </l7-spacer>
</mat-dialog-content>
<mat-dialog-actions >
    <button mat-stroked-button
            color="primary"
            [attr.test-id]="'btnClose'"
            (click)="onClose(false)">{{'global.cancel' | translate}}</button>
    <button mat-flat-button
            color="primary"
            [disabled]="!sendDraftForm.valid && dialogData.actionType === QuoteDocumentTableAction.SEND"
            [attr.test-id]="'btnSubmit'"
            (click)="onClose(true)">{{'dialogs.quoteDocumentTableAction.' + dialogData.actionType + '.confirm' | translate}}</button>
</mat-dialog-actions>
