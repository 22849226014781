<ng-template #itemTemplate
             let-version
             let-last="last">
    <mat-card appearance="outlined" class="mat-card-outlined item"
              fxFlex="0 1 calc(33.3% - 16px)"
              fxFlex.lt-lg="0 1 calc(50% - 16px)"
              ngClass.lt-lg="lt-lg"
              fxFlex.lt-md="100%"
              ngClass.lt-md="lt-md"
              [attr.test-id]="'versionCard' + version.id">
        <mat-card-header  fxLayout="column">
            <mat-card-title>
                <mat-chip-listbox fxFlex="30" fxLayoutAlign="start center">
                    <mat-chip class="text-truncate quote-state"
                              fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" [ngClass]="'quote-state-'+ version.workflowState">
                        <mat-icon *ngIf="version.workflowState === 'OPEN'"
                                  matChipAvatar
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Checkbox_Square'">
                        </mat-icon>
                        <mat-icon *ngIf="version.workflowState === 'COMPLETED'"
                                  matChipAvatar
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Checkbox_Square'">
                        </mat-icon>
                        <mat-icon *ngIf="version.workflowState === 'PENDING'"
                                  matChipAvatar
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Time'">
                        </mat-icon>
                        <mat-icon *ngIf="version.workflowState === 'ERROR' || version.workflowState === 'DECLINED'"
                                  matChipAvatar
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Cross_Circle'">
                        </mat-icon>
                        <l7-text-block [truncate]="true"
                                       [text]="version.tasksCompleted + '/'+ version.tasksTotal">
                        </l7-text-block>
                    </mat-chip>
                </mat-chip-listbox>
                <l7-spacer style="flex: 1;" [thickness]="'all'"></l7-spacer>
                <mat-icon class="menuTrigger"
                          [matMenuTriggerFor]="menu"
                          [fontSet]="'l7'"
                          [color]="'primary'"
                          [fontIcon]="'More_Vert_Filled'">
                </mat-icon>
                <mat-menu #menu="matMenu">
                    <button *ngFor="let menuAction of menuActions" mat-menu-item  (click)="onMenuItemClick(menuAction, version.id)">
                        <mat-icon [fontSet]="'l7'"
                                  [fontIcon]="menuAction.icon"></mat-icon>
                        {{ menuAction.label | translate}}
                    </button>
                </mat-menu>
            </mat-card-title>
            <l7-spacer style="flex: 1;" multiplier="12" [thickness]="'top'">
                <l7-text-block matCardTitle
                               [truncate]="true"
                               [text]="version.objectName"></l7-text-block>
                <l7-text-block matCardSubtitle
                               [truncate]="true"
                               [text]="(version.leasingQuoteName) + ' | Variante ' + (version.versionNumber)"></l7-text-block>
            </l7-spacer>
        </mat-card-header>

        <mat-card-content class="more-padding">
            <div *ngFor="let row of rows"
                 fxLayout="row">
                <l7-spacer fxFlex
                           [thickness]="'top'">
                    <l7-text-block fxFlex="50"
                                   fxLayout="column"
                                   [truncate]="true"
                                   [readonly]="true"
                                   [text]="'quote.retailers.quotesList.versions.' + row | translate"></l7-text-block>
                    <div fxFlex="50"
                         fxLayout="column"
                         fxLayoutAlign="center flex-end"
                         class="item-value">
                        <ng-container [ngSwitch]="row">
                            <ng-container *ngSwitchCase="'objectValue'">
                                <l7-text-block class="bold"
                                               [text]="version.objectValue | currency"></l7-text-block>
                            </ng-container>
                            <ng-container *ngSwitchCase="'term'">
                                <l7-text-block class="bold"
                                               [text]="version.term + ' Monate'"></l7-text-block>
                            </ng-container>
                            <ng-container *ngSwitchCase="'instalment'">
                                <l7-text-block class="bold"
                                               [text]="version.instalment | currency"></l7-text-block>
                            </ng-container>
                            <ng-container *ngSwitchCase="'contractType'">
                                <ng-container *ngIf="version.contractType === 'MIETKAUF'; else notMiekauf">
                                    <l7-text-block class="bold"
                                                   [text]="'MK'"></l7-text-block>
                                </ng-container>
                                <ng-template #notMiekauf>
                                    <div fxLayout="row">
                                        <l7-text-block class="bold"
                                                       [text]="version.contractType"></l7-text-block>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </ng-container>
                    </div>
                </l7-spacer>
            </div>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions fxLayoutAlign="center">
            <button fxFlex
                    mat-button
                    [color]="'accent'"
                    (click)="goToDetailView($event, version)">Zum Angebot</button>
        </mat-card-actions>
    </mat-card>
</ng-template>

<div fxLayout="row wrap"
     fxLayout.lt-md="column"
     fxLayoutAlign="flex-start"
     style="margin: -8px;">
    <ng-container *ngFor="let version of versions; let last = last">
        <ng-content *ngTemplateOutlet="itemTemplate; context: { $implicit: version, last: last }"></ng-content>
    </ng-container>
</div>
