<div fxLayout="row">

  <div fxLayout="row"
       fxFlex>
    <a *ngIf="quoteId"
       fxFlex
       class="link"
       id="link_slb-details"
       fxLayout="row"
       fxLayoutAlign="start center"
       [routerLink]="['/presentation/quotes/slb-details', quoteId]">
      <div fxLayout="row"
           fxLayoutAlign="start center">
        <span>{{"Bearbeiten" | translate}}</span>
      </div>
    </a>

    <span *ngIf="completed; else notCompleted"
          fxFlex
          fxLayout="row"
          fxLayoutAlign="start center">
      <mat-icon class="completed"
                [color]="'success'"
                [fontIcon]="iconCheck"
                [fontSet]="'l7'"></mat-icon>
      <span> {{"slb.completed" | translate}}</span>
    </span>

    <ng-template #notCompleted>
      <span fxFlex
            fxLayout="row"
            fxLayoutAlign="start center">
        <mat-icon class="uncompleted"
                  [color]="'warning'"
                  [fontIcon]="iconExclamation"
                  [fontSet]="'l7'"></mat-icon>
        <span class="text"> {{"slb.uncompleted" | translate}}</span>
      </span>
    </ng-template>
  </div>

  <a target="_blank"
     rel="noopener noreferrer"
     [routerLink]="'/presentation/gwg/pdf-view/' + pdf_download_link">
    <div fxLayout="row"
         fxLayoutGap="8px"
         fxLayoutAlign="start center">
      <mat-icon [fontIcon]="iconDownload"
                [color]="'primary'"
                [fontSet]="'l7'"></mat-icon>
    </div>
  </a>
</div>
