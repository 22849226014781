<l7-page fxFlex>
    <l7-page-header motion
                    [title]="'dashboard.greeting.' + (presenter.greeting | async) | translate:{ value:(presenter.userName | async) }"
                    [subTitle]="'Hier findest du alles, um deine nächsten Schritte optimal zu planen.'"
                    [motionPreset]="'fadeSlideIn'">
        <div *ngIf="presenter.alert | async"
             fxFlex
             fxLayout="row"
             fxLayout.lt-sm="column"
             class="alert"
             motion
             [motionPreset]="'fadeSlideIn'">
            <div fxFlex
                 fxLayout="row"
                 fxLayoutAlign="start center">
                <mat-icon [fontSet]="'l7'"
                          [fontIcon]="'Bell'"></mat-icon>
                <span fxFlex
                      class="alert-text"
                      ngClass.lt-sm="sm">{{ (presenter.alert | async)?.title }}</span>
            </div>
            <div class="alert-action"
                 fxLayoutAlign.lt-sm="end"
                 ngClass.lt-sm="sm">
                <button mat-flat-button
                        (click)="presenter.onHandle('news')">Mehr erfahren</button>
            </div>
        </div>
        <l7-banner *ngIf="!(this.presenter.tiles | async).length"
                   [icon]="'Info'"
                   [iconFontSet]="'l7'"
                   [content]="'Das Dashboard befindet sich zur Zeit in der Entwicklung. Wir bitten um etwas Geduld.'"
                   [color]="'neutral'"
                   [appearance]="'filled'"></l7-banner>
    </l7-page-header>
    <l7-page-content fxFlex
                     fxLayout="column">
        <l7-page-section *ngIf="presenter.activatedRoute | async as activatedRoute"
                         fxFlex
                         fxLayout="row wrap">
            <mat-grid-list class="widget-container"
                           [cols]="(presenter.widgetLayout | async)?.columns"
                           [rowHeight]="'280px'">
                <mat-grid-tile *ngFor="let tile of presenter.tiles | async; let i = index;"
                               motion
                               [colspan]="(presenter.isWide | async) ? $any(tile).columnSpan.max : $any(tile).columnSpan.min"
                               [rowspan]="(presenter.isWide | async) ? $any(tile).rowSpan.max : $any(tile).rowSpan.min"
                               [motionOptions]="{ delay:200 + (i * 100) }"
                               [motionPreset]="'fadeSlideIn'">
                    <div class="grid-list-item">
                        <ng-container *ngIf="this.presenter.injector && tile?.views?.front">
                            <ng-container *ngComponentOutlet="tile.views.front; injector: this.presenter.injector"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="!tile?.views?.front">
                            <mat-card fxFlex
                                      ngClass.lt-sm="sm"
                                      matRipple
                                      class="mat-card-clickable"
                                      [id]="'button_' + tile.key"
                                      (click)="tile.action(activatedRoute)">
                                <div fxFlex
                                     fxLayout="column">
                                    <mat-card-header fxFlex
                                                     fxLayoutAlign="center center">
                                        <img matCardTitle
                                             matCardImageSmall
                                             [src]="tile.icon"
                                             [alt]="tile.name | translate">
                                    </mat-card-header>
                                    <mat-card-content fxLayout="column"
                                                      fxLayoutGap="16px">
                                        <l7-text [text]="tile.name | translate"
                                                 [alignment]="'center'"
                                                 [wrap]="true"></l7-text>
                                    </mat-card-content>
                                </div>
                            </mat-card>
                        </ng-container>


                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </l7-page-section>
    </l7-page-content>

    <l7-page-footer>
        <div *ngIf="presenter.isSales | async"
             fxFlex
             class="faq-container"
             fxLayout="row"
             fxLayoutGap="8px">

            <a mat-button
               (click)="presenter.onHandle('faq')">
                <div fxLayout="row"
                     fxLayoutGap="8px"
                     fxLayoutAlign="start center">
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Info'"></mat-icon>
                    <l7-text-block [wrap]="true"
                                   [text]="'Hinweise zur Nutzung von Lease Seven'"></l7-text-block>
                </div>
            </a>
        </div>
    </l7-page-footer>
</l7-page>
