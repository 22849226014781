<ng-container *cdkBreakpoint="let bp">
  <form #form="ngForm"
        [formGroup]="presenter.form">
    <l7-wizard #stepper
               class="no-header"
               [orientation]="bp('lt-md') ? 'vertical' : 'horizontal'"
               [selectedIndex]="presenter.selectedStep | async"
               (selectionChange)="presenter.onStepChanged($event)">
      <l7-wizard-step [stepControl]="presenter.form.controls.calculation">
        <div cdkScrollable
             fxLayout="column"
             fxLayoutGap="24px">
          <mat-card>
            <mat-card-header>
              <mat-card-title>
                {{ 'quote.retailers.stepper.object_title' | translate }}
              </mat-card-title>
              <mat-card-subtitle>{{'quote.retailers.stepper.object_sub_title' | translate}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayout="column"
                              [formGroupName]="'calculation'">
              <div fxLayout="row"
                   fxLayout.lt-sm="column"
                   fxLayoutGap.lt-sm="0px"
                   fxLayoutGap="16px">
                <mat-form-field appearance="outline"
                                [fxFlex]="'0 1 calc(' + 100 / 4 + '%' + ' - 16px)'">
                  <mat-label>{{ 'quote.retailers.calculation.input.objectQuantity.label' | translate }}</mat-label>
                  <mat-select l7MarkAsTouched
                              [formControlName]="'objectQuantity'"
                              [attr.test-id]="'objectQuantity'">
                    <ng-container *ngFor="let item of [].constructor(10); let i = index">
                      <mat-option [value]="i+1">{{ i + 1 }}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error *ngIf="presenter.form.controls.calculation.controls.objectQuantity.hasError('required')">
                    <p>{{ 'quote.retailers.calculation.input.objectQuantity.error.required' | translate }}</p>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline"
                                [fxFlex]="'0 1 calc(' + 80 + '%' + ' - 16px)'">
                  <mat-label>{{ 'quote.retailers.calculation.input.objectName.label' | translate }}</mat-label>
                  <input matInput
                         type="text"
                         l7MarkAsTouched
                         [attr.test-id]="'objectName'"
                         [formControlName]="'objectName'">
                  <mat-error *ngIf="presenter.form.controls.calculation.controls.objectName.hasError('required')">
                    <p>{{ 'quote.retailers.calculation.input.objectName.error.required' | translate }}</p>
                  </mat-error>
                  <mat-error *ngIf="!presenter.form.controls.calculation.controls.objectName.hasError('required') && presenter.form.controls.calculation.controls.objectName.hasError('maxlength')">
                    <p>{{ 'quote.retailers.calculation.input.objectName.error.allowed_range' | translate }}</p>
                  </mat-error>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card>
            <mat-card-header>
              <mat-card-title fxFlex
                              fxLayout="row"
                              fxLayoutAlign="space-between center">
                <div>{{ 'quote.retailers.stepper.create_offers_title' | translate }}</div>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content fxLayout="column"
                              [formGroupName]="'calculation'">
              <div fxLayout="row"
                   fxLayout.lt-sm="column"
                   fxLayoutGap.lt-sm="0px"
                   fxLayoutGap="16px"
                   [formGroupName]="'data'">
                <mat-form-field class="mat-form-field-shrink"
                                appearance="outline"
                                [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'">
                  <mat-label>{{ 'quote.retailers.calculation.input.object_value.label' | translate }}</mat-label>
                  <input matInput
                         type="text"
                         currencyMask
                         l7MarkAsTouched
                         autocomplete="off"
                         name="totalLeasingValue"
                         [attr.test-id]="'totalLeasingValue'"
                         [formControlName]="'totalLeasingValue'">
                  <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.totalLeasingValue.hasError('required')">
                    <p>{{ 'quote.retailers.calculation.input.object_value.error.required' | translate }}</p>
                  </mat-error>
                  <mat-error *ngIf="!presenter.form.controls.calculation.controls.data.controls.totalLeasingValue.hasError('required') && presenter.form.controls.calculation.controls.data.controls.totalLeasingValue.hasError('allowedRange')">
                    <p>{{ 'quote.retailers.calculation.input.object_value.error.allowed_range' | translate: {min: (presenter.quoteCalculationSettings | async).leasingQuoteCalculationProperties.retailer.minObjectValue, max: (presenter.quoteCalculationSettings | async).leasingQuoteCalculationProperties.retailer.maxObjectValue} }}</p>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="mat-form-field-shrink"
                                appearance="outline"
                                [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'">
                  <mat-label>{{ 'quote.retailers.calculation.input.contract_type.label' | translate }}</mat-label>
                  <mat-select required
                              [formControlName]="'contractType'"
                              [attr.test-id]="'contractType'">
                    <mat-option *ngFor="let contractType of presenter.retailerContractTypes | async"
                                [value]="contractType">{{ 'contractType.' + contractType | translate }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.contractType.hasError('required')">
                    <p>{{ 'quote.retailers.calculation.input.contract_type.error.required' | translate }}</p>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="mat-form-field-shrink"
                                appearance="outline"
                                [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'">
                  <mat-label>{{ 'quote.retailers.calculation.input.object_groups.label' | translate }}</mat-label>
                  <mat-select required
                              [formControlName]="'objectGroups'"
                              [attr.test-id]="'objectGroups'">
                    <mat-option *ngFor="let objectGroup of presenter.retailerObjectGroups | async"
                                [value]="objectGroup">{{ objectGroup.code + ': ' + objectGroup.description }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.objectGroups.hasError('required')">
                    <p>{{ 'quote.retailers.calculation.input.object_groups.error.required' | translate }}</p>
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="row"
                   fxLayout.lt-sm="column"
                   fxLayoutGap="16px"
                   [formGroupName]="'data'">
                <mat-form-field appearance="outline"
                                [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'">
                  <mat-label>{{ 'quote.retailers.calculation.input.condition.label' | translate }}</mat-label>
                  <mat-select required
                              [formControlName]="'condition'"
                              [attr.test-id]="'condition'">
                    <mat-option [value]="'NEW'">{{ 'condition.NEW' | translate }}</mat-option>
                    <mat-option [value]="'USED'">{{ 'condition.USED' | translate }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.condition.hasError('required')">
                    <p>{{ 'quote.retailers.calculation.input.condition.error.required' | translate }}</p>
                  </mat-error>
                </mat-form-field>
                <ng-container *ngIf="presenter.showResidualValues | async">
                  <mat-form-field appearance="outline"
                                  [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'">
                    <mat-label>{{ 'quote.retailers.calculation.input.residualValue.label' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           currencyMask
                           l7MarkAsTouched
                           formControlName="residualValue"
                           l7SelectOnDblClick
                           [attr.test-id]="'residualValue'">
                    <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.residualValue.invalid && presenter.form.controls.calculation.controls.data.controls.residualValue.hasError('required')">
                      {{'quote.retailers.calculation.input.residualValue.error.required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.residualValue.invalid && !presenter.form.controls.calculation.controls.data.controls.residualValue.hasError('required') && presenter.form.controls.calculation.controls.data.controls.residualValue.hasError('allowedValuesRelatedToLeasingValue')">
                      {{'quote.retailers.calculation.input.residualValue.error.allowedResidualValue' | translate: {min: (presenter.minAndMaxResidualValues |async ).min | currency : 'EUR', max: (presenter.minAndMaxResidualValues |async ).max | currency : 'EUR'} }}

                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline"
                                  [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'">
                    <mat-label>{{ 'quote.retailers.calculation.input.residualValueInPercent.label' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           currencyMask
                           l7MarkAsTouched
                           formControlName="residualValueInPercent"
                           l7SelectOnDblClick
                           [attr.test-id]="'residualValueInPercent'"
                           [options]="{suffix: ' %'}">
                    <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.residualValueInPercent.invalid && presenter.form.controls.calculation.controls.data.controls.residualValueInPercent.hasError('required')">
                      {{'quote.retailers.calculation.input.residualValueInPercent.error.required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.residualValueInPercent.invalid && !presenter.form.controls.calculation.controls.data.controls.residualValueInPercent.hasError('required') && presenter.form.controls.calculation.controls.data.controls.residualValueInPercent.hasError('isNumberInRange')">
                      {{'quote.retailers.calculation.input.residualValueInPercent.error.allowedResidualValue' | translate: {min: presenter.form.controls.calculation.controls.data.controls.residualValueInPercent.errors.min | percent, max: presenter.form.controls.calculation.controls.data.controls.residualValueInPercent.errors.max | percent} }}
                    </mat-error>
                  </mat-form-field>
                </ng-container>
              </div>
              <div fxLayout="row"
                   fxLayout.lt-sm="column"
                   fxLayoutGap="16px"
                   [formGroupName]="'data'">
                <mat-checkbox formControlName="downPaymentCheckBox"
                              [attr.test-id]="'downPaymentCheckBox'">
                  <p>{{ 'Anzahlung hinzufügen' | translate }}</p>
                </mat-checkbox>
              </div>
              <div fxLayout="row"
                   fxLayout.lt-sm="column"
                   fxLayoutGap="16px"
                   [ngClass]="{'hide': (presenter.hasDownPayment | async) === false}"
                   [formGroupName]="'data'">
                <mat-form-field appearance="outline"
                                [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'">
                  <mat-label>{{ 'quote.retailers.calculation.input.down_payment.label' | translate }}</mat-label>
                  <input matInput
                         type="text"
                         currencyMask
                         l7MarkAsTouched
                         formControlName="downPayment"
                         l7SelectOnDblClick
                         [attr.test-id]="'downPayment'">
                  <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.downPayment.invalid && presenter.form.controls.calculation.controls.data.controls.downPayment.hasError('required')">
                    {{'quote.retailers.calculation.input.down_payment.error.required' | translate}}
                  </mat-error>
                  <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.downPayment.invalid && !presenter.form.controls.calculation.controls.data.controls.downPayment.hasError('required') && presenter.form.controls.calculation.controls.data.controls.downPayment.hasError('allowedValuesRelatedToLeasingValue')">
                    {{'quote.retailers.calculation.input.down_payment.error.allowed_down_payment' | translate: {min: (presenter.minAndMaxDownPaymentValues | async)?.min | number, max: (presenter.minAndMaxDownPaymentValues | async)?.max | number} }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline"
                                [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'">
                  <mat-label>{{ 'quote.retailers.calculation.input.down_payment_in_percent.label' | translate }}</mat-label>
                  <input matInput
                         type="text"
                         currencyMask
                         l7MarkAsTouched
                         formControlName="downPaymentInPercent"
                         l7SelectOnDblClick
                         [attr.test-id]="'downPaymentInPercent'"
                         [options]="{suffix: ' %'}">
                  <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.downPaymentInPercent.invalid && presenter.form.controls.calculation.controls.data.controls.downPaymentInPercent.hasError('required')">
                    {{'quote.retailers.calculation.input.down_payment_in_percent.error.required' | translate}}
                  </mat-error>
                  <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.downPaymentInPercent.invalid && !presenter.form.controls.calculation.controls.data.controls.downPaymentInPercent.hasError('required') && presenter.form.controls.calculation.controls.data.controls.downPaymentInPercent.hasError('isNumberInRange')">
                    {{'quote.retailers.calculation.input.down_payment_in_percent.error.allowed_down_payment' | translate: {min: presenter.form.controls.calculation.controls.data.controls.downPaymentInPercent.errors.min | percent, max: presenter.form.controls.calculation.controls.data.controls.downPaymentInPercent.errors.max | percent} }}
                  </mat-error>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="presenter.showExclusionOfWarranty | async">
            <mat-card-header>
              <mat-card-title>
                {{ 'quote.retailers.stepper.exclusionOfWarrantyTitle' | translate }}
              </mat-card-title>
              <mat-card-subtitle>{{'quote.retailers.stepper.exclusionOfWarrantySubtitle' | translate}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayout="column"
                              [formGroupName]="'calculation'">
              <mat-radio-group fxLayout="column"
                               [attr.test-id]="'exclusionOfWarranty'"
                               [formControlName]="'exclusionOfWarranty'">
                <mat-radio-button [value]="true">
                  {{ 'quote.retailers.calculation.input.exclusionOfWarranty.optionTrueLabel' | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                  {{ 'quote.retailers.calculation.input.exclusionOfWarranty.optionFalseLabel' | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </mat-card-content>
          </mat-card>

          <mat-accordion [formGroupName]="'calculation'">
            <mat-expansion-panel [formGroupName]="'data'"
                                 [attr.test-id]="'expertMode'">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'Experten Modus' | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="column"
                   fxFlex="65%"
                   [formGroupName]="'expert'">
                <div fxLayout="row"
                     fxLayoutAlign="flex-start center"
                     fxLayout.lt-sm="column"
                     fxLayoutGap="16px">
                  <mat-checkbox formControlName="dealerCommission"
                                class="alignLeft alignBottom"
                                [fxFlex]="'0 1 calc(' + 35 + '%' + ' - 16px)'"
                                [attr.test-id]="'provision'">
                    <p>{{ 'Provision' | translate }}</p>
                  </mat-checkbox>
                  <mat-form-field appearance="outline"
                                  [fxFlex]="'0 1 calc(' + 65 + '%' + ')'">
                    <mat-label>{{ 'quote.retailers.calculation.input.provisionInPercent.label' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           currencyMask
                           l7MarkAsTouched
                           formControlName="dealerCommissionInPercent"
                           l7SelectOnDblClick
                           [attr.test-id]="'dealerCommissionInPercent'"
                           [options]="{suffix: ' %', precision: 1}">
                    <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.invalid && presenter.form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.hasError('required')">
                      {{'quote.retailers.calculation.input.provisionInPercent.error.required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.invalid && !presenter.form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.hasError('required') && presenter.form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.hasError('isNumberInRange')">
                      {{'quote.retailers.calculation.input.provisionInPercent.error.allowed_range' | translate: {min: presenter.form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.errors.min | percent, max: presenter.form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.errors.max | percent} }}
                    </mat-error>
                    <!-- <mat-error *ngIf="presenter.form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.invalid && (presenter.form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.hasError('min') || presenter.form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.hasError('max'))">
                        {{'quote.retailers.calculation.input.provisionInPercent.error.allowed_range' | translate: {min: 0 | number, max: 5 | number} }}
                    </mat-error> -->
                  </mat-form-field>
                </div>
                <div fxLayout="row"
                     fxLayoutAlign="flex-start center"
                     fxLayout.lt-sm="column"
                     fxLayoutGap="16px">
                  <mat-checkbox formControlName="insurance"
                                class="alignLeft alignBottom"
                                [fxFlex]="'0 1 calc(' + 35 + '%' + ' - 16px)'"
                                [attr.test-id]="'insurance'">
                    <p>{{ 'Versicherung' | translate }}</p>
                  </mat-checkbox>
                  <mat-form-field appearance="outline"
                                  [fxFlex]="'0 1 calc(' + 65 + '%' + ')'">
                    <mat-label>{{ 'quote.retailers.calculation.input.insuranceValue.label' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           currencyMask
                           formControlName="insuranceValue"
                           [attr.test-id]="'insuranceValue'">
                  </mat-form-field>
                </div>
                <div fxLayout="row"
                     fxLayoutAlign="flex-start center"
                     fxLayout.lt-sm="column"
                     fxLayoutGap="16px">
                  <mat-checkbox formControlName="handlingFee"
                                class="alignLeft alignBottom"
                                [fxFlex]="'0 1 calc(' + 35 + '%' + ' - 16px)'"
                                [attr.test-id]="'handlingFee'">
                    <p>{{ 'Bearbeitungsgebühr' | translate }}</p>
                  </mat-checkbox>
                  <mat-form-field appearance="outline"
                                  [fxFlex]="'0 1 calc(' + 65 + '%' + ')'">
                    <mat-label>{{ 'quote.retailers.calculation.input.handlingFeeValue.label' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           currencyMask
                           formControlName="handlingFeeValue"
                           [attr.test-id]="'handlingFeeValue'">
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-card [formGroupName]="'calculation'">
            <mat-card-header>
              <mat-card-title fxFlex
                              fxLayout="row"
                              fxLayoutAlign="space-between center">
                <l7-text-block [text]="'quote.retailers.stepper.show_offers_title' | translate"></l7-text-block>
                <ng-container *ngIf="presenter.rates | async">
                  <l7-text-block [text]="' (' + (presenter.rates | async)?.length + ')'"
                                 [readonly]="true"></l7-text-block>
                </ng-container>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content fxLayout="column">
              <ng-container *ngIf="presenter.form.controls.calculation.controls.rate.valueChanges | async"></ng-container>
              <mat-radio-group [formControlName]="'rate'">
                <ng-container *ngIf="bp(['xl', 'lg', 'md', 'sm'])">
                  <l7-carousel [elemWidth]="115"
                               [maxShowedItems]="bp('lt-md') ? 3 : 5"
                               [showControls]="(presenter.rates | async)?.length > (bp('lt-md') ? 3 : 5)"
                               [placeholder]="'Die Raten werden nach dem Kalkulationsvorgang sichtbar' | translate">
                    <ng-container *ngFor="let item of presenter.rates | async; trackBy:presenter.trackByIndex; index as i">
                      <ng-container *l7CarouselItem>
                        <l7-tile class="rate-card carousel-item"
                                 matstp
                                 motion
                                 id="rate-{{item.terms}}"
                                 [attr.test-id]="'rate-' + item.terms"
                                 [selected]="presenter.form.controls.calculation.controls.rate.getRawValue()?.terms === item.terms"
                                 [motionPreset]="'fadeSlideIn'"
                                 [motionOptions]="{ delay:100 + (i * 50) }"
                                 (click)="radio._inputElement.nativeElement.click(); ">
                          <mat-radio-button #radio
                                            l7Absolute="-8px;-12px"
                                            style="display: none;"
                                            [value]="item"
                                            [attr.test-id]="'rate-' + item.terms"
                                            [disableRipple]="true"></mat-radio-button>
                          <l7-text-block [truncate]="true"
                                         [alignment]="'center'"
                                         [text]="item.terms + ' Monate' | translate"></l7-text-block>
                          <l7-text-block [truncate]="true"
                                         [alignment]="'center'"
                                         [color]="'info'"
                                         [text]="item.instalment | currency"></l7-text-block>
                          <p class="small-grey">{{'Monatliche Rate' | translate}}</p>
                        </l7-tile>
                      </ng-container>
                    </ng-container>
                  </l7-carousel>
                </ng-container>
                <ng-container *ngIf="bp('xs')">
                  <l7-wrap [columns]="2"
                           [gap]="16">
                    @for (item of presenter.rates | async; let i = $index; track i;) {
                        <l7-tile class="rate-card carousel-item"
                                 matstp
                                 motion
                                 id="rate-{{item.terms}}"
                                 [attr.test-id]="'rate-' + item.terms"
                                 [selected]="presenter.form.controls.calculation.controls.rate.getRawValue()?.terms === item.terms"
                                 [motionPreset]="'fadeSlideIn'"
                                 [motionOptions]="{ delay:100 + (i * 50) }"
                                 (click)="radio._inputElement.nativeElement.click(); ">
                          <mat-radio-button #radio
                                            l7Absolute="-8px;-12px"
                                            style="display: none;"
                                            [value]="item"
                                            [attr.test-id]="'rate-' + item.terms"
                                            [disableRipple]="true"></mat-radio-button>
                          <l7-text-block [truncate]="true"
                                         [alignment]="'center'"
                                         [text]="item.terms + ' Monate' | translate"></l7-text-block>
                          <l7-text-block [truncate]="true"
                                         [alignment]="'center'"
                                         [color]="'info'"
                                         [text]="item.instalment | currency"></l7-text-block>
                          <p class="small-grey">{{'Monatliche Rate' | translate}}</p>
                        </l7-tile>
                    }
                  </l7-wrap>
                </ng-container>
                <mat-error *ngIf="!presenter.form.controls.calculation.controls.rate.untouched && presenter.form.controls.calculation.controls.rate.hasError('required')"
                           class="mat-error-standalone">
                  <p>{{ 'quote.retailers.calculation.rate.error.required' | translate }}</p>
                </mat-error>
              </mat-radio-group>
            </mat-card-content>
          </mat-card>
          <div fxLayout="row"
               fxLayoutAlign="flex-end center"
               fxLayout.lt-sm="column"
               fxLayoutGap="16px">
            <button *ngIf="!presenter.isRecalculation"
                    mat-flat-button
                    matStepperNext
                    color="primary"
                    type="button"
                    [attr.test-id]="'nextStep1'">{{'global.further' | translate}}</button>
            <button *ngIf="presenter.isRecalculation"
                    mat-flat-button
                    color="primary"
                    type="button"
                    [attr.test-id]="'updateCalculation'"
                    (click)="presenter.updateCalculation()">{{'quote.retailers.lessees.updateComparisonDialog.newCalculation' | translate}}</button>
          </div>
        </div>
      </l7-wizard-step>

      <l7-wizard-step [stepControl]="presenter.form.controls.search">
        <div cdkScrollable
             fxLayout="column"
             fxLayoutGap="24px">
          <mat-card>
            <mat-card-header>
              <mat-card-title>
                {{ 'quote.retailers.stepper.searchLesseeTitle' | translate }}
              </mat-card-title>
              <mat-card-subtitle>{{'quote.retailers.stepper.searchLesseeSubtitle' | translate}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayout="column"
                              [formGroupName]="'search'">
              <div fxLayout="row">
                <mat-form-field fxFlex
                                appearance="outline">
                  <mat-label>{{ 'Firmenname' | translate }}</mat-label>
                  <input matInput
                         l7MarkAsTouched
                         type="text"
                         formControlName="companyName"
                         [attr.test-id]="'inputCompanyName'">
                  <mat-error *ngIf="presenter.form.get('search.companyName').hasError('required')">
                    {{ 'error.field_required' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="row"
                   fxLayout.lt-md="column"
                   fxLayoutGap="16px"
                   fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex
                                appearance="outline">
                  <mat-label>{{ 'Stadt/PLZ' | translate }}</mat-label>
                  <input matInput
                         l7MarkAsTouched
                         type="text"
                         formControlName="city"
                         [attr.test-id]="'inputCity'">
                  <mat-error *ngIf="presenter.form.get('search.city').hasError('required')">
                    {{ 'error.field_required' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="row"
                   fxLayout.lt-md="column"
                   fxLayoutGap="16px"
                   fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex
                                appearance="outline">
                  <mat-label>{{ 'Land' }}</mat-label>
                  <input matInput
                         type="text"
                         formControlName="country"
                         [attr.test-id]="'inputCountryName'">
                  <mat-error *ngIf="presenter.form.get('search.country').hasError('required')">
                    {{ 'error.field_required' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>
          <div fxLayout="row"
               fxLayoutAlign="flex-end center"
               fxLayout.lt-sm="column"
               fxLayoutGap="16px">
            <button mat-stroked-button
                    matStepperPrevious
                    color="primary"
                    type="button"
                    [attr.test-id]="'previousStep2'">{{'contract_management.back' | translate}}</button>
            <button mat-flat-button
                    matStepperNext
                    color="primary"
                    type="button"
                    [attr.test-id]="'nextStep3'">{{'global.further' | translate}}</button>
          </div>
        </div>
      </l7-wizard-step>

      <l7-wizard-step [stepControl]="presenter.form.controls.lessee">
        <div cdkScrollable
             fxLayout="column"
             fxLayoutGap="24px">
          <mat-card>
            <mat-card-header>
              <mat-card-title>
                {{ 'quote.retailers.stepper.selectLesseeTitle' | translate }}
              </mat-card-title>
              <mat-card-subtitle>{{'quote.retailers.stepper.selectLesseeSubtitle' | translate}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayout="column"
                              class="no-padding">
              <mat-selection-list fxFlex
                                  fxFill
                                  class="no-padding"
                                  [multiple]="false"
                                  [formControlName]="'lessee'">
                <mat-list-option *ngFor="let lessee of presenter.lessees | async; last as last"
                                 [attr.test-id]="lessee.crefoId"
                                 [disabled]="(lessee.allowedAsLessee===false)"
                                 [disableRipple]="(lessee.allowedAsLessee===false)"
                                 [value]="lessee">
                  <l7-text-block matListItemTitle
                                 [truncate]="true"
                                 [text]="lessee.name"></l7-text-block>
                  <l7-text-block matListItemLine
                                 [truncate]="true"
                                 [readonly]="true"
                                 [text]="this.presenter.formatAddress(lessee)"></l7-text-block>
                  <div *ngIf="!lessee.allowedAsLessee"
                       matListItemLine>
                    <l7-banner [color]="'info'"
                               [appearance]="'textual'"
                               [icon]="'Info'"
                               [iconFontSet]="'l7'"
                               [content]="'error.contact_lefo_not_whitelisted' | translate:{ param1: lessee.legalFormDescription? lessee.legalFormDescription : ''}"></l7-banner>
                  </div>
                  <!-- <div matListItemMeta
                       *ngIf="lessee.allowedAsLessee">
                      <mat-icon [fontSet]="'l7'"
                                [fontIcon]="'Chevron_Right'"></mat-icon>
                  </div> -->
                  <mat-divider class="without-margin"
                               [inset]="true"></mat-divider>
                </mat-list-option>
                <mat-list-item (click)="presenter.onRequestMail()">
                  <mat-icon matListItemIcon
                            [fontSet]="'l7'"
                            [fontIcon]="'Plus_Circle'"></mat-icon>
                  <l7-text-block matListItemTitle
                                 [truncate]="true"
                                 [text]="'quote.retailers.calculation.selectLessee.lesseeNotFound' | translate">
                  </l7-text-block>
                </mat-list-item>
              </mat-selection-list>
            </mat-card-content>
          </mat-card>
          <div fxLayout="row"
               fxLayoutAlign="flex-end center"
               fxLayout.lt-sm="column"
               fxLayoutGap="16px">
            <!-- <div fxFlex="50"
                 [style]="'text-align: center;'">
                <l7-text-block mat-line
                               [alignment]="'center'"
                               [color]="'bold'"
                               [text]="'quote.retailers.calculation.selectLessee.lesseeNotFound' | translate">
                </l7-text-block>
                <l7-spacer [thickness]="'bottom'"
                           [multiplier]="12"></l7-spacer>
                <a class="mail-to"
                   [href]="presenter.requestUrlSubject | async"
                   (click)="presenter.onRequestMail()">{{'quote.retailers.calculation.selectLessee.nowSendMail' | translate}}</a>
            </div> -->
            <button mat-stroked-button
                    matStepperPrevious
                    color="primary"
                    type="button"
                    [attr.test-id]="'previousStep3'">{{'contract_management.back' | translate}}</button>

          </div>
        </div>
      </l7-wizard-step>
    </l7-wizard>
  </form>
  <!-- <div fxLayout="row"
       fxLayoutGap="16px"
       fxLayoutAlign="end">
      <button mat-stroked-button
              color="primary"
              *ngIf="stepper.selectedIndex !== 0"
              (click)="stepper.previous()">P</button>

      <button mat-flat-button
              color="primary"
              *ngIf="stepper.selectedIndex === 2"
              (click)="form.onSubmit($event)">CREATE</button>
  </div> -->
</ng-container>