<div class="page-container">
  <l7-headline *ngIf="!pictureCaptured"
               [prefix]="'Identifizierung nach dem Geldwäschegesetz'"
               [headline]="'Personalausweis des Unterzeichners - Vorderseite'">
  </l7-headline>
  <l7-headline *ngIf="pictureCaptured"
               [prefix]="'Identifizierung nach dem Geldwäschegesetz'"
               [headline]="'Kontrolle des Personalausweises des Unterzeichners - Vorderseite'">
  </l7-headline>
  <main>
    <div class="text">
      <p *ngIf="!pictureCaptured">
        Bitte fotografieren Sie die Vorderseite des Personalausweises des Unterzeichners ab.
        Der Ausweis sollte dafür die vorgegebene Form ausfüllen.
      </p>
      <p *ngIf="pictureCaptured">
        Bitte kontrollieren Sie die Qualität der Vorderseite des Personalausweises und nehmen Sie das Foto
        bei Bedarf neu auf.
      </p>
    </div>
    <l7-camera-stream #camera
                      (evStreamReady)="streamReady = $event"></l7-camera-stream>
    <ng-container *ngIf="!pictureCaptured">
      <div class="button-row">
        <button mat-flat-button
                id="button_capture-personal-id"
                [color]="'accent'"
                [disabled]="!streamReady"
                (click)="capture()">Aufnahme</button>
      </div>
    </ng-container>
    <ng-container *ngIf="pictureCaptured">
      <p>Die Vorderseite des Dokumentes ist vollständig und lesbar fotografiert?</p>
      <div class="button-row">
        <button mat-flat-button
                id="button_save-personal-id"
                [color]="'accent'"
                (click)="saveIdentityCard()">Bestätigen</button>
        <button mat-button
                id="button_reset-personal-id"
                [color]="'accent'"
                (click)="resetIdentityCard()">Erneut aufnehmen</button>
      </div>
    </ng-container>
  </main>
</div>