<div *ngIf="quoteSupportsObligations" class="quote-summary-card-obligations">
    <div class="obligations-headline">
        <mat-icon class="obligations-icon"
            [fontSet]="'l7'"
            [color]="'warning'"
            [fontIcon]="'Alert_Triangle'">
        </mat-icon>
        <h3>Auflage(n)</h3>
    </div>
    <p *ngIf="!quoteHasObligations" class="obligations-warning">
        Es sind keine Auflagen vorhanden!
    </p>
    <div *ngIf="quoteHasObligations"
         class="obligations-list">
        <div *ngFor="let obligation of obligations; index as i"
             class="obligations-item">
            <fieldset>
                <legend>{{ (i+1) + '. Auflage ' | translate }}</legend>
                <l7-text-block [wrap]="true"
                    [truncate]="true"
                    [maxLines]="1"
                    [text]="obligations[i].prePaymentPercent > 0 ? obligations[i].description + (' {0}%' | format:obligations[i].prePaymentPercent) : obligations[i].description ">
                </l7-text-block>
            </fieldset>
        </div>
        </div>
</div>
