/* libs/quote/src/lib/components/retailer-calculation-rates-selection/retailer-calculation-rates-selection.component.scss */
.rate-card {
  flex-shrink: 1;
  height: 146px;
  max-width: 146px;
  min-width: 115px;
}
.rate-card l7-text-block:nth-of-type(1) ::ng-deep {
  margin-top: 10px;
}
.rate-card l7-text-block:nth-of-type(1) ::ng-deep p {
  font-weight: bold;
}
.rate-card l7-text-block {
  margin-bottom: 19px;
}
/*# sourceMappingURL=retailer-calculation-rates-selection.component.css.map */
