// #region Imports

import { ICommand } from '@abcfinlab/core';
import { inject, Provider, Type } from '@angular/core';
import { CommandAggregator } from './Services/CommandAggregator';
import { CommandFactory } from './Services/CommandFactory';
import { CommandProxy } from './Services/CommandProxy';

// #endregion

export function provideCommands(config: { commands: Array<Type<ICommand<unknown>>> }): Array<Provider> {
    return [
        {
            provide: CommandAggregator,
            useClass: CommandAggregator,
        },
        {
            provide: CommandProxy,
            useFactory: (): CommandProxy => {
                const factory = inject(CommandFactory);
                const proxy = new CommandProxy();
                config.commands.forEach(x => proxy.register(factory.create(x)));
                return proxy;
            },
        },
        {
            provide: CommandFactory,
            useClass: CommandFactory,
        },
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: (proxy: CommandProxy): Function => {
        //         const fn = () => {
        //             // this is only a fake
        //             proxy.initialize();
        //         };

        //         return fn;
        //     }, deps: [CommandProxy], multi: true,
        // },
    ];
}
