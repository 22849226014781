// #region Imports

import { Command, Commandable, CompositeCommand } from '@abcfinlab/core';
import { MessageBoxButton, MessageBoxService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';

// #endregion

export namespace KnownCommands {

    export const openIdentityCardUpload = new CompositeCommand<void>();

    export const openDocumentUpload = new CompositeCommand<void>();

}

/**
 * The `OpenIdentityCardUploadCommand` class represents the command to open the identity card upload dialog.
 *
 * @public
 */
@Injectable()
@Commandable({
    key: 'openIdentityCardUpload',
    description: 'Opens the identity card upload dialog.',
})
export class OpenIdentityCardUploadCommand extends Command<unknown> {

    // #region Ctor

    /**
     * Constructs a new instance of the `NavigateToProfileCommand` class.
     *
     * @public
     */
    public constructor(messageBox: MessageBoxService) {
        super((parameter) => {
            if (parameter) {
                messageBox.show('Identity card uploaded successfully.', String(parameter), MessageBoxButton.OK);
            }
        });

        KnownCommands.openIdentityCardUpload.registerCommand(this);
    }

    // #endregion

}

/**
 * The `OpenDocumentUploadCommand` class represents the command to open the document upload dialog.
 *
 * @public
 */
@Injectable()
@Commandable({
    key: 'openDocumentUpload',
    description: 'Opens the document upload dialog.',
})
export class OpenDocumentUploadCommand extends Command<unknown> {

    // #region Ctor

    /**
     * Constructs a new instance of the `NavigateToProfileCommand` class.
     *
     * @public
     */
    public constructor(messageBox: MessageBoxService) {
        super((parameter) => {
            if (parameter) {
                messageBox.show('Document uploaded successfully.', String(parameter), MessageBoxButton.OK);
            }
        });

        KnownCommands.openDocumentUpload.registerCommand(this);
    }

    // #endregion

}
