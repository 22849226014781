// #region Imports

import { IUserInfoDto } from '@abcfinlab/api/login';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ReplaySubject, Subject } from 'rxjs';
import { AppConfig } from '../Configuration/AppConfig/AppConfig';
import { ProvokeErrorHttpInterceptor } from '../Http/ProvokeErrorHttpInterceptor';
import { TraceparentHttpInterceptor } from '../Http/TraceparentHttpInterceptor';
import { WithCredentialsHttpInterceptor } from '../Http/WithCredentialsHttpInterceptor';
import { BlobHandler } from '../Services/BlobHandler';
import { ProvokeErrorService } from '../Services/ProvokeErrorService';
import { ConfigState } from '../States/ConfigState';
import { SESSION_AVAILABLE_TOKEN } from '../Tokens/SessionAvailableToken';
import { USER_INFO_TOKEN } from '../Tokens/UserInfoToken';
import { WINDOW } from '../Tokens/WindowToken';

// #endregion

/**
 * @public
 */
export interface ICoreModuleConfig {
    baseUrl: string;
}

/**
 * @private
 */
export const CORE_MODULE_CONFIG = new InjectionToken<ICoreModuleConfig>('The Core Module Configuration');

/**
 * @internal
 * @hidden
 */
export function appConfigFactory(): AppConfig {
    return AppConfig.instance();
}

/**
 * @internal
 * @hidden
 */
export function sessionAvailableTokenFactory(): Subject<void> {
    return new ReplaySubject(1);
}

/**
 * @internal
 * @hidden
 */
export function userInfoTokenFactory(): ReplaySubject<IUserInfoDto> {
    return new ReplaySubject();
}

/**
 * The `NameModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        NgxsModule.forFeature([ConfigState]),
    ],
    providers: [
        { provide: SESSION_AVAILABLE_TOKEN, useFactory: sessionAvailableTokenFactory },
        { provide: USER_INFO_TOKEN, useFactory: userInfoTokenFactory },
        { provide: WINDOW, useValue: window },
        { provide: AppConfig, useFactory: appConfigFactory },
        { provide: BlobHandler, useClass: BlobHandler },
        { provide: ProvokeErrorService, useClass: ProvokeErrorService },
        { provide: HTTP_INTERCEPTORS, useClass: WithCredentialsHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ProvokeErrorHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TraceparentHttpInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi(), withFetch()),
    ],
})
export class CoreModule {

    // #region Methods

    public static forRoot(config: ICoreModuleConfig): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                { provide: CORE_MODULE_CONFIG, useValue: config },
            ],
        };
    }

    // #endregion

}
