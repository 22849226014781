<form *ngIf="contractType"
      fxFill
      fxFlex="100"
      fxLayout="column"
      fxLayoutGap="16px"
      [formGroup]="form"
      (submit)="saveInsurance()">
    <div fxFill
         fxFlex="100"
         fxLayout="row">
        <h3 *ngIf="contractType !== 'KFZ' else kfzTitle">{{'settlement.insurance.title' | translate}}</h3>
        <ng-template #kfzTitle>
            <h3>{{'settlement.insurance.title_kfz' | translate}}</h3>
        </ng-template>
    </div>
    <div fxLayout="row"
         fxLayoutGap="16px">
        <h3 *ngIf="contractType !== 'KFZ' else kfzLabel">{{'calculation.add_ordinary_insurance_label' | translate}}</h3>
        <ng-template #kfzLabel>
            <h3>{{'calculation.add_100pro_insurance_label' | translate}}</h3>
        </ng-template>
    </div>
    <div fxLayout="row"
         fxLayoutGap="16px">
        <mat-slide-toggle *ngIf="contractType === 'TA' || contractType === 'VA' || contractType === 'ITFLEX'"
                          labelPosition="before"
                          color="primary"
                          class="slide-toggle"
                          formControlName="insurance_toggle"
                          fxFlex="10"
                          [attr.test-id]="'toggle_insurance'"
                          (change)="handleInsuranceChange($event)">
        </mat-slide-toggle>

        <mat-slide-toggle *ngIf="contractType === 'KFZ'"
                          labelPosition="before"
                          color="primary"
                          class="slide-toggle"
                          formControlName="insurance_toggle"
                          fxFlex="10"
                          [attr.test-id]="'toggle_insurance'"
                          (change)="handle100ProChange($event)">
        </mat-slide-toggle>

        <mat-form-field *ngIf="contractType === 'MIETKAUF' || contractType === 'MKN'"
                        class="block select-insurance left"
                        [attr.test-id]="'select_insurance'"
                        [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                        [fxFlex.lt-md]="100"
                        [appearance]="view === 'QUOTE_DETAILS' ? 'outline' : 'fill'">
            <mat-label>{{'calculation.insurance_or_100_pro' | translate}}</mat-label>
            <mat-select id="select_insurance-type"
                        formControlName="insurance_toggle"
                        (selectionChange)="handleInsuranceSelection($event)">
                <mat-option *ngFor="let insurance of insuranceTypes; let i = index"
                            [value]="insurance.value"
                            [disabled]="insuranceTypeDisabled(insurance.value)">
                    {{ insurance.translation_key | translate | uppercase }}
                </mat-option>
            </mat-select>
        </mat-form-field>

         <mat-form-field [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                         [fxFlex.lt-md]="100"
                         [appearance]="view === 'QUOTE_DETAILS' ? 'outline' : 'fill'">
            <mat-label>{{'global.month_rate' | translate}}</mat-label>
            <input matInput
                   l7MarkAsTouched
                   type="text"
                   formControlName="insurance_value"
                   currencyMask
                   l7SelectOnDblClick
                   [markOnInit]="true"
                   [attr.test-id]="'InsuranceValue'">
            <mat-error *ngIf="form.controls.insurance_value.invalid && form.controls.insurance_value.hasError('required')">
                {{'error.field_required' | translate}}
            </mat-error>
            <mat-error *ngIf="form.controls.insurance_value.invalid && insuranceType === 'STANDARD' &&
                (form.controls.insurance_value.hasError('max') || form.controls.insurance_value.hasError('min'))">
                {{'error.insurance_min_max' | translate: {minValue: insuranceValueMin | currency, maxValue: insuranceValueMax | currency} }}
            </mat-error>
            <mat-error *ngIf="form.controls.insurance_value.invalid && insuranceType === 'PRO100' &&
                form.controls.insurance_value.hasError('min')">
                {{'error.value_bigger_than' | translate: {param1: insuranceValueMin | currency} }}
            </mat-error>
        </mat-form-field>
    </div>

    <ng-container *ngIf="view === 'QUOTE_DETAILS' && (contractType === contractTypes.Mietkauf || contractType === contractTypes.Mkn)">
        <div fxLayout="row" fxLayoutGap="16px">
            <mat-form-field [appearance]="view === 'QUOTE_DETAILS' ? 'outline' : 'fill'"
                            [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                            [fxFlex.lt-md]="100">
                <mat-label>{{'calculation.total_claim' | translate}}</mat-label>
                <input formControlName="total_insurance_instalments"
                       matInput
                       id="input_insurance-total-claim"
                       currencyMask
                       [placeholder]="'calculation.total_claim' | translate">
            </mat-form-field>
            <mat-form-field [appearance]="view === 'QUOTE_DETAILS' ? 'outline' : 'fill'"
                            [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'" [fxFlex.lt-md]="100">
                <mat-label>{{'calculation.total_claim_taxes' | translate}}</mat-label>
                <ng-container *ngIf="(contractType === contractTypes.Mkn && insuranceType !== 'PRO100') || contractType === contractTypes.Mietkauf; else noTaxes">
                    <input id="input_insurance-total-claim-taxes"
                           formControlName="total_insurance_instalments_vat"
                           matInput
                           currencyMask
                           [placeholder]="'calculation.total_claim_taxes' | translate">
               </ng-container>
                <ng-template #noTaxes>
                    <input id="input_insurance-total-claim-taxes-mkn"
                           matInput
                           [placeholder]="'calculation.total_claim_taxes' | translate"
                           [value]="'Nettobetrag: keine USt.'"
                           [disabled]="true">
                </ng-template>

            </mat-form-field>
        </div>
    </ng-container>

    <div fxLayout="row"
         fxLayoutGap="16px">
        <h3>{{'settlement.details.handling_fee' | translate}}</h3>
    </div>
    <div fxLayout="row"
         fxLayoutGap="16px">
        <mat-slide-toggle labelPosition="before"
                          color="primary"
                          class="slide-toggle"
                          formControlName="handling_fee"
                          fxFlex="10"
                          [attr.test-id]="'toggle_handling_fee'"
                          (change)="handleHandlingFeeChange($event)">
        </mat-slide-toggle>
        <mat-form-field [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                        [fxFlex.lt-md]="100"
                        [appearance]="view === 'QUOTE_DETAILS' ? 'outline' : 'fill'">
            <mat-label>{{'settlement.details.handling_fee' | translate}}</mat-label>
            <input matInput
                   l7MarkAsTouched
                   type="text"
                   formControlName="handling_fee_value"
                   currencyMask
                   l7SelectOnDblClick
                   [markOnInit]="true"
                   [attr.test-id]="'HandlingFeeValue'">
            <mat-error *ngIf="form.controls.handling_fee_value.invalid && form.controls.handling_fee_value.hasError('required')">
                {{'error.field_required' | translate}}
            </mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="view === 'BACKOFFICE'"
         fxLayout="row"
         fxLayoutAlign="end" fxLayoutGap="16px">
        <button *ngIf="!isReadonlyUser"
                type="submit"
                mat-flat-button
                [color]="'accent'"
                [attr.test-id]="'SaveInsuranceButton'"
                [disabled]="(areValuesChanged | async) === false || doesnt_have_status || form.invalid ">
            {{ 'global.save' | translate }}
        </button>
    </div>
</form>
