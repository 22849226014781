// #region Imports

import {
    AccountingService,
    IAccountingDto,
    ICocPresenceDto,
    IContractManagementOverviewDto, ISlbGetResponseDto,
    SlbService,
} from '@abcfinlab/api/contract';
import { ICalculationDto, QuoteService } from '@abcfinlab/api/global';
import { EventHub, subscribeOnce, TranslationFacade } from '@abcfinlab/core';
import { ToastService } from '@abcfinlab/ui';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, computed,
    EventEmitter, inject,
    Input,
    OnDestroy,
    OnInit,
    Output, signal,
    ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { KnownEvents } from '../Models/KnownEvents';

// #endregion

/**
 * @public
 */
@Component({
    selector: 'l7-contract-overview-billing-information-view',
    templateUrl: './ContractOverviewBillingInformationView.html',
    styleUrls: ['./ContractOverviewBillingInformationView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ContractOverviewBillingInformationView implements OnInit, OnDestroy {

    // #region Fields

    protected leasingStartType: string = '';

    @ViewChild(NgForm, { static: true })
    private readonly _form: NgForm;

    private readonly _accountingService: AccountingService;
    private readonly _quoteService: QuoteService;
    private readonly _toastService: ToastService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _cdr: ChangeDetectorRef;
    private readonly _eventHub: EventHub;
    private readonly _slbService = inject(SlbService);
    private _valueChangesSubScription: Subscription;
    private _accounting: IAccountingDto;
    private readonly _calculationSubject: BehaviorSubject<ICalculationDto | null>;
    private readonly _overviewSubject: BehaviorSubject<IContractManagementOverviewDto | null>;
    private readonly _hasChanges: BehaviorSubject<boolean>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `ContractOverviewBillingInformationView` class.
     *
     * @public
     */
    public constructor(accountingService: AccountingService, quoteService: QuoteService, toastService: ToastService, translationFacade: TranslationFacade, cdr: ChangeDetectorRef, eventHub: EventHub) {
        this._accountingService = accountingService;
        this._quoteService = quoteService;
        this._toastService = toastService;
        this._translationFacade = translationFacade;
        this._cdr = cdr;
        this._eventHub = eventHub;

        this._accounting = {
            leasing_quote_id: '',
            pre_rent: null,
            pre_rent_value: null,
            coc_presence: ICocPresenceDto.NotPresent,
        };
        this._calculationSubject = new BehaviorSubject(null);
        this._overviewSubject = new BehaviorSubject(null);
        this._hasChanges = new BehaviorSubject(false);
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `accounting` property.
     *
     * @public
     * @readonly
     */
    public get accounting(): IAccountingDto {
        return this._accounting;
    }

    /**
     * Returns the `calculation` property.
     *
     * @public
     * @readonly
     */
    public get calculation(): Observable<ICalculationDto> {
        return this._calculationSubject.asObservable();
    }

    /**
     * Returns the `overview` property.
     *
     * @public
     * @readonly
     */
    public get overview(): Observable<IContractManagementOverviewDto> {
        return this._overviewSubject.asObservable();
    }

    /**
     * Returns the `hasChanges` property.
     *
     * @public
     * @readonly
     */
    public get hasChanges(): Observable<boolean> {
        return this._hasChanges.asObservable();
    }

    @Output()
    hasAccounting: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._valueChangesSubScription = this._form.valueChanges.subscribe((_x) => {
            const isChanged = this._form.dirty;
            this._hasChanges.next(isChanged);
        });

        subscribeOnce(this._eventHub.getEvent<IContractManagementOverviewDto>(KnownEvents.CONTRACT_OVERVIEW_ROW_CHANGED), (payload) => {
            this._overviewSubject.next(payload);
            this._accounting.leasing_quote_id = payload.quote_id;
            const getSlb$ = payload.slb ? this._slbService.getSlb({ contractId: payload.contract_number }).pipe(take(1), catchError(() => of(null))) : of(null);

            subscribeOnce(combineLatest([
                this._quoteService.getQuoteCalculation({ leasingQuoteId: payload.quote_id }).pipe(take(1), catchError(() => of(null))),
                this._accountingService.read({ leasingQuoteId: payload.quote_id }).pipe(take(1), catchError(() => of(null))),
                getSlb$,
            ]), ([calculation, accounting, slbInfo]: [ICalculationDto | null, IAccountingDto | null, ISlbGetResponseDto | null]) => {
                this._accounting = {
                    ...this._accounting,
                    ...accounting,
                };
                this.leasingStartType = slbInfo ? slbInfo.activeSlbResponse.leasingStart : '';
                this._calculationSubject.next(calculation);
                this._cdr.detectChanges();
            });
        });
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._valueChangesSubScription?.unsubscribe();
    }

    /**
     * @internal
     */
    public onSave(form: NgForm): void {
        if (form.invalid) {
            this._toastService.show(this._translationFacade.translate('contract_management.billing_information.saved.invalid'), 'danger');
            return;
        }

        this._accountingService.save({ body: this._accounting }).subscribe(() => {
            this._hasChanges.next(false);
            this._toastService.show(this._translationFacade.translate('contract_management.billing_information.saved.success'), 'success');
            this.hasAccounting.emit([this._overviewSubject.value.contract_number]);
        }, () => {
            this._toastService.show(this._translationFacade.translate('contract_management.billing_information.saved.error'), 'danger');
            this.hasAccounting.emit([this._overviewSubject.value.contract_number]);
        });
    }

    // #endregion

}
