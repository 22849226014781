@let contractTypes = presenter.contractTypes();

<ng-container *cdkBreakpoint="let bp">
    <l7-dialog-header [title]="'administration.retailers.create.title' | translate"
                      [closeable]="true"
                      (closed)="presenter.onSubmit(true)"></l7-dialog-header>
    <mat-dialog-content fxLayout="column">
        <form #form="ngForm"
              [formGroup]="presenter.form"
              (ngSubmit)="presenter.onSubmit(false)">
            <mat-stepper #stepper
                         [orientation]="bp('lt-md') ? 'vertical' : 'horizontal'">
                <mat-step [stepControl]="presenter.form.get('retailer')"
                          [label]="'administration.retailers.create.step.contact.label' | translate"
                          [state]="'number'">
                    <div cdkScrollable
                         fxLayout="column"
                         fxLayoutGap="24px">
                        <mat-card appearance="outlined"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <mat-card-title>{{ 'administration.retailers.create.step.contact.section.address.title' | translate }}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content fxLayout="column"
                                              [formGroupName]="'retailer'">
                                <div fxLayout="row"
                                     fxLayout.lt-sm="column"
                                     fxLayoutGap="16px">
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'administration.retailers.create.input.name.label' | translate }}</mat-label>
                                        <input matInput
                                               required
                                               type="text"
                                               [attr.test-id]="'name'"
                                               [formControlName]="'name'">
                                        <mat-error *ngIf="presenter.form.get('retailer.name').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.name.error.required' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'administration.retailers.create.input.streetAndHousenumber.label' | translate }}</mat-label>
                                        <input matInput
                                               required
                                               type="text"
                                               [attr.test-id]="'streetAndHousenumber'"
                                               [formControlName]="'streetAndHousenumber'">
                                        <mat-error *ngIf="presenter.form.get('retailer.streetAndHousenumber').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.streetAndHousenumber.error.required' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row"
                                     fxLayout.lt-sm="column"
                                     fxLayoutGap="16px">
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'administration.retailers.create.input.postalCode.label' | translate }}</mat-label>
                                        <input matInput
                                               required
                                               type="text"
                                               autocomplete="postal-code"
                                               [attr.test-id]="'postalCode'"
                                               [formControlName]="'postalCode'">
                                        <mat-error *ngIf="presenter.form.get('retailer.postalCode').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.postalCode.error.required' | translate }}</p>
                                        </mat-error>
                                        <mat-error *ngIf="presenter.form.get('retailer.postalCode').hasError('postCode')">
                                            <p>{{ 'administration.retailers.create.input.postalCode.error.postCode' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'administration.retailers.create.input.city.label' | translate }}</mat-label>
                                        <input matInput
                                               required
                                               type="text"
                                               [attr.test-id]="'city'"
                                               [formControlName]="'city'">
                                        <mat-error *ngIf="presenter.form.get('retailer.city').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.city.error.required' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <mat-card appearance="outlined"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <mat-card-title>{{ 'administration.retailers.create.step.contact.section.partnerInfo.title' | translate }}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content fxLayout="column"
                                              [formGroupName]="'retailer'">
                                <div fxLayout="row"
                                     fxLayout.lt-sm="column"
                                     fxLayoutGap="16px">
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'administration.retailers.create.input.partnerNumber.label' | translate }}</mat-label>
                                        <input matInput
                                               required
                                               type="text"
                                               [attr.test-id]="'partnerNumber'"
                                               [formControlName]="'partnerNumber'">
                                        <div *ngIf="(presenter.form.get('retailer.partnerNumber').statusChanges | async) === 'PENDING'"
                                             matSuffix>
                                            <mat-progress-spinner [diameter]="16"
                                                                  [strokeWidth]="2"
                                                                  [color]="'primary'"
                                                                  [mode]="'indeterminate'"></mat-progress-spinner>
                                        </div>
                                        <mat-error *ngIf="presenter.form.get('retailer.partnerNumber').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.partnerNumber.error.required' | translate }}</p>
                                        </mat-error>
                                        <mat-error *ngIf="presenter.form.get('retailer.partnerNumber').hasError('maxlength')">
                                            <p>{{ 'administration.retailers.create.input.partnerNumber.error.maxlength' | translate }}</p>
                                        </mat-error>
                                        <mat-error *ngIf="presenter.form.get('retailer.partnerNumber').hasError('minlength')">
                                            <p>{{ 'administration.retailers.create.input.partnerNumber.error.minlength' | translate }}</p>
                                        </mat-error>
                                        <mat-error *ngIf="presenter.form.get('retailer.partnerNumber').hasError('partnerNumberExists')">
                                            <p>{{ 'administration.retailers.create.input.partnerNumber.error.partnerNumberExists' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'administration.retailers.create.input.contactNumber.label' | translate}}</mat-label>
                                        <input matInput
                                               required
                                               type="text"
                                               [attr.test-id]="'contactNumber'"
                                               [formControlName]="'contactNumber'">
                                        <div *ngIf="(presenter.form.get('retailer.contactNumber')?.statusChanges | async) === 'PENDING'"
                                             matSuffix>
                                            <mat-progress-spinner [diameter]="16"
                                                                  [strokeWidth]="2"
                                                                  [color]="'primary'"
                                                                  [mode]="'indeterminate'"></mat-progress-spinner>
                                        </div>
                                        <mat-error *ngIf="presenter.form.get('retailer.contactNumber').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.contactNumber.error.required' | translate }}</p>
                                        </mat-error>
                                        <mat-error *ngIf="presenter.form.get('retailer.contactNumber').hasError('contactNumberExists')">
                                            <p>{{ 'administration.retailers.create.input.contactNumber.error.contactNumberExists' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row"
                                     fxLayout.lt-sm="column"
                                     fxLayoutGap="16px">
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'administration.retailers.create.input.client.label' | translate }}</mat-label>
                                        <mat-select required
                                                    [attr.test-id]="'client'"
                                                    [formControlName]="'client'"
                                                    (selectionChange)="presenter.onClientChanged()">
                                            <mat-option *ngFor="let client of presenter.clients | async"
                                                        [value]="client">{{ 'client.' + client | translate }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="presenter.form.get('retailer.client').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.client.error.required' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'administration.retailers.create.input.factor.label' | translate}}</mat-label>
                                        <mat-select required
                                                    [attr.test-id]="'factor'"
                                                    [formControlName]="'factor'"
                                                    [disabled]="!presenter.factors">
                                            <mat-option *ngFor="let factor of presenter.factors | async"
                                                        [value]="factor">{{ 'factor.' + factor | translate }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="presenter.form.get('retailer.factor').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.factor.error.required' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </mat-step>
                <mat-step [stepControl]="presenter.form.get('contact')"
                          [label]="'Ansprechpartner' | translate"
                          [state]="'number'">
                    <div cdkScrollable
                         fxLayout="column"
                         fxLayoutGap="24px">
                        <mat-card appearance="outlined"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <mat-card-title>{{ 'Innendienst' | translate }}</mat-card-title>
                                <mat-card-subtitle>Ansprechpartner bei der abcfinance</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content [formGroupName]="'contact'">
                                <mat-card-content [formGroupName]="'officeService'">
                                    <div fxLayout="row"
                                         fxLayout.lt-sm="column"
                                         fxLayoutGap="16px">
                                        <mat-form-field fxFlex
                                                        appearance="outline">
                                            <mat-label>{{ 'Vorname' | translate }}</mat-label>
                                            <input matInput
                                                   required
                                                   type="text"
                                                   [attr.test-id]="'givenName'"
                                                   [formControlName]="'givenName'">
                                            <mat-error *ngIf="presenter.form.get('contact.officeService.givenName').hasError('required')">
                                                <p>{{ 'administration.retailers.create.input.givenName.error.required' | translate }}</p>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex
                                                        appearance="outline">
                                            <mat-label>{{ 'Nachname' | translate }}</mat-label>
                                            <input matInput
                                                   required
                                                   type="text"
                                                   [attr.test-id]="'familyName'"
                                                   [formControlName]="'familyName'">
                                            <mat-error *ngIf="presenter.form.get('contact.officeService.familyName').hasError('required')">
                                                <p>{{ 'administration.retailers.create.input.familyName.error.required' | translate }}</p>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row"
                                         fxLayout.lt-sm="column"
                                         fxLayoutGap="16px">
                                        <mat-form-field fxFlex
                                                        appearance="outline">
                                            <mat-label>{{ 'E-Mail' | translate }}</mat-label>
                                            <input matInput
                                                   required
                                                   type="email"
                                                   [attr.test-id]="'email'"
                                                   [formControlName]="'email'">
                                            <div *ngIf="(presenter.form.get('contact.officeService.email')?.statusChanges | async) === 'PENDING'"
                                                 matSuffix>
                                                <mat-progress-spinner [diameter]="16"
                                                                      [strokeWidth]="2"
                                                                      [color]="'primary'"
                                                                      [mode]="'indeterminate'"></mat-progress-spinner>
                                            </div>
                                            <mat-hint>Zulässig: {{ (presenter.allowedTopLevelDomains | async) ?? [].join(', ') }}</mat-hint>
                                            <mat-error *ngIf="presenter.form.get('contact.officeService.email').hasError('required')">
                                                <p>{{ 'administration.retailers.create.input.email.error.required' | translate }}</p>
                                            </mat-error>
                                            <mat-error *ngIf="presenter.form.get('contact.officeService.email').hasError('email')">
                                                <p>{{ 'administration.retailers.create.input.email.error.email' | translate }}</p>
                                            </mat-error>
                                            <mat-error *ngIf="presenter.form.get('contact.officeService.email').hasError('emailEndsWithDomain')">
                                                <p>{{ 'administration.retailers.create.input.email.error.emailEndsWithDomain' | translate }}</p>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex
                                                        appearance="outline">
                                            <mat-label>{{ 'Telefonnummer' | translate }}</mat-label>
                                            <input matInput
                                                   required
                                                   type="tel"
                                                   [attr.test-id]="'phoneNumber'"
                                                   [formControlName]="'phoneNumber'">
                                            <mat-error *ngIf="presenter.form.get('contact.officeService.phoneNumber').hasError('required')">
                                                <p>{{ 'administration.retailers.create.input.phoneNumber.error.required' | translate }}</p>
                                            </mat-error>
                                            <mat-error *ngIf="presenter.form.get('contact.officeService.phoneNumber').hasError('invalidPhoneNumber')">
                                                <p>{{ 'Die Telefonnummer ist ungültig' | translate }}</p>
                                            </mat-error>
                                            <mat-error *ngIf="presenter.form.get('contact.officeService.phoneNumber').hasError('maxLengthExceeded')">
                                                <p>{{ 'Die Telefonnummer ist zu lang' | translate }}</p>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </mat-card-content>
                            </mat-card-content>
                        </mat-card>
                        <mat-card appearance="outlined"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <mat-card-title>{{ 'Außendienst' | translate }}</mat-card-title>
                                <mat-card-subtitle>Ansprechpartner bei der abcfinance</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content [formGroupName]="'contact'">
                                <mat-card-content [formGroupName]="'fieldService'">
                                    <div fxLayout="row"
                                         fxLayout.lt-sm="column"
                                         fxLayoutGap="16px">
                                        <mat-form-field fxFlex
                                                        appearance="outline">
                                            <mat-label>{{ 'Vorname' | translate }}</mat-label>
                                            <input matInput
                                                   required
                                                   type="text"
                                                   [attr.test-id]="'givenNameContact'"
                                                   [formControlName]="'givenName'">
                                            <mat-error *ngIf="presenter.form.get('contact.fieldService.givenName').hasError('required')">
                                                <p>{{ 'administration.retailers.create.input.givenName.error.required' | translate }}</p>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex
                                                        appearance="outline">
                                            <mat-label>{{ 'Nachname' | translate }}</mat-label>
                                            <input matInput
                                                   required
                                                   type="text"
                                                   [attr.test-id]="'familyNameContact'"
                                                   [formControlName]="'familyName'">
                                            <mat-error *ngIf="presenter.form.get('contact.fieldService.familyName').hasError('required')">
                                                <p>{{ 'administration.retailers.create.input.familyName.error.required' | translate }}</p>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row"
                                         fxLayout.lt-sm="column"
                                         fxLayoutGap="16px">
                                        <mat-form-field fxFlex
                                                        appearance="outline">
                                            <mat-label>{{ 'E-Mail' | translate }}</mat-label>
                                            <input matInput
                                                   required
                                                   type="email"
                                                   [attr.test-id]="'emailContact'"
                                                   [formControlName]="'email'">
                                            <div *ngIf="(presenter.form.get('contact.fieldService.email').statusChanges | async) === 'PENDING'"
                                                 matSuffix>
                                                <mat-progress-spinner [diameter]="16"
                                                                      [strokeWidth]="2"
                                                                      [color]="'primary'"
                                                                      [mode]="'indeterminate'"></mat-progress-spinner>
                                            </div>
                                            <mat-hint>Zulässig: {{ (presenter.allowedTopLevelDomains | async) ?? [].join(', ') }}</mat-hint>
                                            <mat-error *ngIf="presenter.form.get('contact.fieldService.email').hasError('required')">
                                                <p>{{ 'administration.retailers.create.input.email.error.required' | translate }}</p>
                                            </mat-error>
                                            <mat-error *ngIf="presenter.form.get('contact.fieldService.email').hasError('email')">
                                                <p>{{ 'administration.retailers.create.input.email.error.email' | translate }}</p>
                                            </mat-error>
                                            <mat-error *ngIf="presenter.form.get('contact.fieldService.email').hasError('emailEndsWithDomain')">
                                                <p>{{ 'administration.retailers.create.input.email.error.emailEndsWithDomain' | translate }}</p>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex
                                                        appearance="outline">
                                            <mat-label>{{ 'Telefonnummer' | translate }}</mat-label>
                                            <input matInput
                                                   required
                                                   type="tel"
                                                   [attr.test-id]="'phoneNumberContact'"
                                                   [formControlName]="'phoneNumber'">
                                            <mat-error *ngIf="presenter.form.get('contact.fieldService.phoneNumber').hasError('required')">
                                                <p>{{ 'administration.retailers.create.input.phoneNumber.error.required' | translate }}</p>
                                            </mat-error>
                                            <mat-error *ngIf="presenter.form.get('contact.fieldService.phoneNumber').hasError('invalidPhoneNumber')">
                                                <p>{{ 'Die Telefonnummer ist ungültig' | translate }}</p>
                                            </mat-error>
                                            <mat-error *ngIf="presenter.form.get('contact.fieldService.phoneNumber').hasError('maxLengthExceeded')">
                                                <p>{{ 'Die Telefonnummer ist zu lang' | translate }}</p>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </mat-card-content>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </mat-step>
                <mat-step [stepControl]="presenter.form.get('retailerConfig')"
                          [label]="'administration.retailers.create.step.settings.label' | translate"
                          [state]="'number'">
                    <div cdkScrollable
                         fxLayout="column"
                         fxLayoutGap="24px">
                        <mat-card appearance="outlined"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <mat-card-title>{{'Vertragsarten' | translate }}</mat-card-title>
                                <mat-card-subtitle>{{ 'Bitte wählen Sie hier, welche Vertragsarten dem Fachhändler zur Verfügung stehen' | translate }}</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content [formGroupName]="'retailerConfig'">
                                @if(contractTypes) {
                                    <l7-checkbox-group [formControlName]="'contractTypes'"
                                                       [attr.test-id]="'contractTypes'">
                                        <div fxLayout="column"
                                             fxLayoutGap="16px">
                                            @for (type of contractTypes | keyvalue; track type.key;) {
                                                <mat-checkbox [attr.test-id]="'contractType-' + type.key"
                                                              [value]="type.key">
                                                    <p>{{ 'contractType.' + type.key | translate }} <span class="secondary-text">{{type.value}}</span></p>
                                                </mat-checkbox>
                                            }
                                        </div>
                                    </l7-checkbox-group>
                                }
                                <mat-error *ngIf="presenter.form.get('retailerConfig.contractTypes')?.hasError('required')"
                                           class="mat-error-standalone">
                                    <p>{{ 'administration.retailers.create.input.contractTypes.error.required' | translate }}</p>
                                </mat-error>
                            </mat-card-content>
                        </mat-card>
                        <mat-card appearance="outlined"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <mat-card-title>{{ 'Objektarten' | translate }}</mat-card-title>
                                <mat-card-subtitle>{{ 'Bitte wählen Sie hier, welche Objektarten dem Fachhändler zur Verfügung stehen' | translate }}</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content fxLayout="column"
                                              [formGroupName]="'retailerConfig'">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'Objektarten' | translate }}</mat-label>
                                    <mat-chip-grid #chipList
                                                   [formControlName]="'objectGroupCodes'">
                                        <mat-chip-row *ngFor="let code of presenter.form.get('retailerConfig.objectGroupCodes').value"
                                                      [color]="'primary'"
                                                      [value]="code"
                                                      (removed)="presenter.onObjectGroupRemove(presenter.getObjectGroupFromCode(code))">
                                            <span>{{ presenter.getObjectGroupFromCode(code)?.name }}</span>
                                            <button matChipRemove>
                                                <mat-icon [fontSet]="'l7'"
                                                          [fontIcon]="'Cross_Circle_Filled'"></mat-icon>
                                            </button>
                                        </mat-chip-row>
                                        <input #input
                                               #matAutocompleteTrigger="matAutocompleteTrigger"
                                               required
                                               l7AutocompleteScrollHandler
                                               [placeholder]="'Weitere Kategorie...' | translate"
                                               [matAutocomplete]="autocomplete"
                                               [matChipInputFor]="chipList"
                                               [attr.test-id]="'objectGroupCodes'"
                                               [autocompleteTriggerRef]="matAutocompleteTrigger"
                                               (input)="presenter.onFilterObjectGroups($event.target.value)">
                                    </mat-chip-grid>
                                    <mat-autocomplete #autocomplete
                                                      (optionSelected)="presenter.onObjectGroupSelected($event.option.value, matAutocompleteTrigger, input)">
                                        <mat-option *ngFor="let group of presenter.objectGroups | async"
                                                    [attr.test-id]="'objectGroup-' + group.code"
                                                    [value]="group">{{ group.code + ': ' + group.name }}</mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>
                        <mat-card appearance="outlined"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <mat-card-title>Bearbeitungsgebühr <span *ngIf="presenter.clientHandlingFee | async as clientHandlingFee">({{clientHandlingFee | currency}})</span></mat-card-title>
                                <mat-card-subtitle>{{ 'Bitte wählen Sie hier, ob der Fachhändler eine Bearbeitungsgebühr erheben darf' | translate }}</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content [formGroupName]="'retailerConfig'">
                                <mat-radio-group fxLayout="column"
                                                 fxLayoutGap="16px"
                                                 [attr.test-id]="'handlingFeeOption'"
                                                 [formControlName]="'handlingFeeOption'">
                                    <mat-radio-button [value]="'ALWAYS_CHARGE'">
                                        <l7-text-block [wrap]="true"
                                                       [text]="'Ja, der Fachhändler ist verpflichtet eine Bearbeitungsgebühr zu erheben' | translate"></l7-text-block>
                                    </mat-radio-button>
                                    <mat-radio-button [value]="'NEVER_CHARGE'">
                                        <l7-text-block [wrap]="true"
                                                       [text]="'Nein, der Fachhändler darf keine Bearbeitungsgebühr erheben' | translate"></l7-text-block>
                                    </mat-radio-button>
                                    <mat-radio-button [value]="'CUSTOMIZABLE'">
                                        <l7-text-block [wrap]="true"
                                                       [text]="'Der Fachhändler darf frei wählen, ob er eine Bearbeitungsgebühr erhebt' | translate"></l7-text-block>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-card-content>
                        </mat-card>
                        <mat-card appearance="outlined"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <mat-card-title>{{ 'Provision' | translate }}</mat-card-title>
                                <mat-card-subtitle>{{ 'Bitte wählen Sie hier, ob der Fachhändler die Höhe der voreingestellten Provision (0 - 5 %) selbst festlegen darf' | translate }}</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content [formGroupName]="'retailerConfig'">
                                <div fxLayout="column"
                                     fxLayoutGap="16px">
                                    <mat-radio-group fxLayout="column"
                                                     fxLayoutGap="16px"
                                                     [attr.test-id]="'adjustableProvision'"
                                                     [formControlName]="'adjustableProvision'">
                                        <mat-radio-button [value]="true">
                                            <p>{{ 'global.yes' | translate }}</p>
                                        </mat-radio-button>
                                        <mat-radio-button [value]="false">
                                            <p>{{ 'global.no' | translate }}</p>
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <p>{{ 'Voreingestellter Wert für Fachhändler' | translate }}</p>
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{ 'Provision' | translate }}</mat-label>
                                        <input matInput
                                               required
                                               currencyMask
                                               [options]="{suffix: ' %', precision: 1}"
                                               [attr.test-id]="'provision'"
                                               [formControlName]="'provision'">
                                        <mat-error *ngIf="presenter.form.get('retailerConfig.provision').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.provision.error.required' | translate }}</p>
                                        </mat-error>
                                        <mat-error *ngIf="presenter.form.get('retailerConfig.provision').hasError('min')">
                                            <p>{{ 'administration.retailers.create.input.provision.error.min' | translate }}</p>
                                        </mat-error>
                                        <mat-error *ngIf="presenter.form.get('retailerConfig.provision').hasError('max')">
                                            <p>{{ 'administration.retailers.create.input.provision.error.max' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <mat-card appearance="outlined"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <mat-card-title>{{ 'Vormiete' | translate }}</mat-card-title>
                                <mat-card-subtitle>{{ 'Bitte wählen Sie, ob der Fachhändler eine Vormiete erheben darf' | translate }}</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content [formGroupName]="'retailerConfig'">
                                <mat-radio-group fxLayout="column"
                                                 fxLayoutGap="16px"
                                                 [attr.test-id]="'preRent'"
                                                 [formControlName]="'preRent'">
                                    <mat-radio-button [value]="true">
                                        <p>{{'global.yes' | translate}}</p>
                                    </mat-radio-button>
                                    <mat-radio-button [value]="false">
                                        <p>{{'global.no' | translate}}</p>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </mat-step>
                <mat-step [stepControl]="presenter.form.get('user')"
                          [label]="'administration.retailers.create.step.userFacility.label' | translate"
                          [state]="'number'">
                    <div cdkScrollable
                         fxLayout="column"
                         fxLayoutGap="24px">
                        <mat-card appearance="outlined"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <mat-card-title>{{ 'Nutzerdaten' | translate }}</mat-card-title>
                                <mat-card-subtitle>{{ 'Bitte geben Sie die Nutzerdaten ein' | translate }}</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content [formGroupName]="'user'">
                                <div fxLayout="row"
                                     fxLayout.lt-sm="column"
                                     fxLayoutGap="16px">
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'Vorname' | translate }}</mat-label>
                                        <input matInput
                                               required
                                               type="text"
                                               [attr.test-id]="'givenNameUser'"
                                               [formControlName]="'givenName'">
                                        <mat-error *ngIf="presenter.form.get('user.givenName').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.givenName.error.required' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'Nachname' | translate }}</mat-label>
                                        <input matInput
                                               required
                                               type="text"
                                               [attr.test-id]="'familyNameUser'"
                                               [formControlName]="'familyName'">
                                        <mat-error *ngIf="presenter.form.get('user.familyName').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.familyName.error.required' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row"
                                     fxLayout.lt-sm="column"
                                     fxLayoutGap="16px">
                                    <mat-form-field fxFlex
                                                    class="mat-error-from-color"
                                                    appearance="outline"
                                                    [color]="presenter.form.get('user.email').hasError('emailInvaid') ? 'warning': ''">
                                        <mat-label>{{ 'E-Mail' | translate }}</mat-label>
                                        <input matInput
                                               required
                                               type="email"
                                               [attr.test-id]="'emailUser'"
                                               [formControlName]="'email'">
                                        <div *ngIf="(presenter.form.get('user.email').statusChanges | async) === 'PENDING'"
                                             matSuffix>
                                            <mat-progress-spinner [diameter]="16"
                                                                  [strokeWidth]="2"
                                                                  [color]="'primary'"
                                                                  [mode]="'indeterminate'"></mat-progress-spinner>
                                        </div>
                                        <mat-error *ngIf="presenter.form.get('user.email').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.email.error.required' | translate }}</p>
                                        </mat-error>
                                        <mat-error *ngIf="presenter.form.get('user.email').hasError('email')">
                                            <p>{{ 'administration.retailers.create.input.email.error.email' | translate }}</p>
                                        </mat-error>
                                        <mat-error *ngIf="presenter.form.get('user.email').hasError('emailInvaid')">
                                            <p>{{ 'administration.retailers.create.input.email.error.emailInvaid' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'Telefonnummer' | translate }}</mat-label>
                                        <input matInput
                                               type="tel"
                                               [attr.test-id]="'phoneNumberUser'"
                                               [formControlName]="'phoneNumber'">
                                        <mat-error *ngIf="presenter.form.get('user.phoneNumber').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.phoneNumber.error.required' | translate }}</p>
                                        </mat-error>
                                        <mat-error *ngIf="presenter.form.get('user.phoneNumber').hasError('invalidPhoneNumber')">
                                            <p>{{ 'Die Telefonnummer ist ungültig' | translate }}</p>
                                        </mat-error>
                                        <mat-error *ngIf="presenter.form.get('user.phoneNumber').hasError('maxLengthExceeded')">
                                            <p>{{ 'Die Telefonnummer ist zu lang' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row"
                                     fxLayoutGap="16px">
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'Mobilnummer' | translate }}</mat-label>
                                        <input matInput
                                               type="tel"
                                               [attr.test-id]="'mobileNumberUser'"
                                               [formControlName]="'mobileNumber'">
                                        <mat-error *ngIf="presenter.form.get('user.mobileNumber').hasError('required')">
                                            <p>{{ 'administration.retailers.create.input.mobileNumber.error.required' | translate }}</p>
                                        </mat-error>
                                        <mat-error *ngIf="presenter.form.get('user.mobileNumber').hasError('invalidPhoneNumber')">
                                            <p>{{ 'Die Mobilnummer ist ungültig' | translate }}</p>
                                        </mat-error>
                                        <mat-error *ngIf="presenter.form.get('user.mobileNumber').hasError('maxLengthExceeded')">
                                            <p>{{ 'Die Mobilnummer ist zu lang' | translate }}</p>
                                        </mat-error>
                                    </mat-form-field>
                                    <span fxFlex
                                          fxHide.lt-sm></span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <mat-card appearance="outlined"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <mat-card-title>{{ 'Nutzerrechte' | translate }}</mat-card-title>
                                <mat-card-subtitle>{{ 'Bitte weisen sie dem Nutzer die entsprechenden Rechte zu' | translate }}</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content [formGroupName]="'user'">
                                <l7-checkbox-group [formControlName]="'groups'">
                                    <div fxLayout="column"
                                         fxLayoutGap="16px">
                                        <mat-checkbox [value]="presenter.userGroup.RetailerAdmin"
                                                      [disabled]="true">
                                            <p>{{ 'Nutzerverwaltung und Einstellungen (Admin)' | translate }}</p>
                                        </mat-checkbox>
                                        <mat-checkbox [value]="presenter.userGroup.RetailerSales">
                                            <p>{{ 'Vertragsabschluss & Verwaltung eigener Verträge (Vertrieb)' | translate }}</p>
                                        </mat-checkbox>
                                        <mat-checkbox [value]="presenter.userGroup.RetailerContractManagement">
                                            <p>{{ 'Verwaltung aller Verträge (Verwaltung)' | translate }}</p>
                                        </mat-checkbox>
                                    </div>
                                </l7-checkbox-group>
                                <mat-error *ngIf="presenter.form.get('user.groups').hasError('required')"
                                           class="mat-error-standalone">
                                    <p>{{ 'administration.retailers.create.input.groups.error.required' | translate }}</p>
                                </mat-error>
                            </mat-card-content>
                        </mat-card>
                        <mat-card appearance="outlined"
                                  class="mat-card-outlined">
                            <mat-card-header>
                                <mat-card-title>{{ 'Status des Nutzers' | translate }}</mat-card-title>
                                <mat-card-subtitle>{{ 'Bitte wählen Sie den Status des Nutzers. Wenn Sie den Nutzer deaktivieren, hat dieser keinen Zugriff mehr auf Lease Seven.' | translate }}</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content [formGroupName]="'user'">
                                <mat-slide-toggle [formControlName]="'active'">{{ 'aktiv' | translate }}</mat-slide-toggle>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </mat-step>
            </mat-stepper>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button *ngIf="stepper.selectedIndex !== 0"
                mat-stroked-button
                color="primary"
                [attr.test-id]="'btnPrevious'"
                (click)="stepper.previous()">{{'administration.retailers.create.action.back.label' | translate}}</button>
        <button *ngIf="stepper.selectedIndex !== 3"
                mat-flat-button
                color="primary"
                [attr.test-id]="'btnNext'"
                (click)="stepper.next()">{{'administration.retailers.create.action.next.label' | translate}}</button>
        <button *ngIf="stepper.selectedIndex === 3"
                mat-flat-button
                color="primary"
                (click)="form.onSubmit($event)">{{'administration.retailers.create.action.save.label' | translate}}</button>
    </mat-dialog-actions>
</ng-container>