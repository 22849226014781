// #region Imports

import { ICommand } from '@abcfinlab/core';

// #endregion

/**
 * The `CompositeCommand` class.
 *
 * @public
 */
export class CompositeCommand<T = void> implements ICommand<T> {

    // #region Fields

    private readonly _registeredCommands: Array<ICommand> = [];

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `CompositeCommand` class.
     *
     * @public
     */
    public constructor() {
        // Empty
    }

    // #endregion

    // #region Properties

    public get registeredCommands(): ReadonlyArray<ICommand> {
        return this._registeredCommands;
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public execute(parameter: T): void {
        this._registeredCommands
            .reverse()
            .filter(x => this.shouldExecute(x))
            .forEach(x => x.execute(parameter as Parameters<ICommand['execute']>[0]));
    }

    /**
     * @public
     */
    public canExecute(parameter: T): boolean {
        let hasEnabledCommandsThatShouldBeExecuted = false;

        this._registeredCommands
            .filter(x => this.shouldExecute(x))
            .forEach((x) => {
                if (x.canExecute(parameter as Parameters<ICommand['canExecute']>[0])) {
                    hasEnabledCommandsThatShouldBeExecuted = true;
                }
            });

        return hasEnabledCommandsThatShouldBeExecuted;
    }

    /**
     * @public
     */
    public registerCommand(command: ICommand): void {
        if (command === null) {
            throw new Error('Parameter command cannot be null.');
        }

        if (command === this) {
            throw new Error('Cannot register a CompositeCommand in itself.');
        }

        if (this._registeredCommands.includes(command)) {
            throw new Error('Cannot register the same command twice in the same CompositeCommand.');
        }

        this._registeredCommands.push(command);
    }

    /**
     * @public
     */
    public unregisterCommand(command: ICommand): void {
        if (command === null) {
            throw new Error('Parameter command cannot be null.');
        }

        const index: number = this._registeredCommands.indexOf(command);
        if (index > -1) {
            this._registeredCommands.splice(index, 1);
        }
    }

    /**
     * @protected
     * @virtual
     */
    protected shouldExecute(_command: ICommand): boolean {
        return true;
    }

    // #endregion

}
