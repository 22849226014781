<div fxFlex
     fxLayout="column">
    <div mat-dialog-title
         fxLayout="column"
         fxLayoutAlign="start start"
         class="dialog-title">
        <h2>{{ 'Schnittstellenergebnis' | translate }}</h2>
    </div>
    <mat-dialog-content fxFlex
                        fxLayout="column"
                        fxLayoutGap="8px">
        <h3>{{'Vertragsstatus' | translate}}</h3>
        <h3>{{ (this.presenter.data | async).submitDate | date:'short'}}</h3>
        <div fxFlex
             fxLayout="row">
            <div fxFlex
                 fxLayout="row"
                 fxLayoutAlign="start start"
                 fxLayoutGap="8px">
                <mat-icon [color]="(this.presenter.isContractActivated | async) ? 'success' : 'danger'"
                          [inline]="true"
                          [fontSet]="'l7'"
                          [fontIcon]="(this.presenter.isContractActivated | async) ? 'Check_Circle_Filled' : 'Cross_Circle_Filled'"></mat-icon>
                <div fxLayout="column"
                     fxFlex>
                    <l7-text-block [text]="(this.presenter.isContractActivated | async) ? ('Vertrag aktiviert - Status S050 gesetzt'| translate) : ('Vertrag NICHT aktiviert - Status S050 nicht gesetzt'| translate)"></l7-text-block>
                </div>
            </div>
        </div>
        <h3>{{'Zusammenfassung' | translate}}</h3>
        <ng-container *ngFor="let error of (this.presenter.data | async).errors">
            <div *ngIf="error.value.name !=='timestamp'"
                 fxFlex fxLayout="row">
                <div fxFlex
                     fxLayout="row"
                     fxLayoutAlign="start start"
                     fxLayoutGap="8px">
                    <ng-container [ngSwitch]="error.value.status">
                        <mat-icon *ngSwitchCase="'OK'"
                                  [color]="'success'"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Check_Circle_Filled'"></mat-icon>
                        <mat-icon *ngSwitchCase="'WARNING'"
                                  [color]="'warning'"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Alert_Circle_Filled'"></mat-icon>
                        <mat-icon *ngSwitchCase="'ERROR'"
                                  [color]="'danger'"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Cross_Circle_Filled'"></mat-icon>
                    </ng-container>

                    <div fxLayout="column"
                         fxFlex>
                        <l7-text-block [text]="error.key | translate"></l7-text-block>
                        <l7-text-block *ngIf="error.value.message"
                                       [wrap]="true"
                                       [breakLine]="true"
                                       [maxLines]="2"
                                       [disabled]="true"
                                       [text]="error.value.message"></l7-text-block>
                    </div>
                </div>
            </div>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-flat-button
                color="accent"
                mat-dialog-close
                [attr.test-id]="'CancelButton'">{{ 'OK' | translate }}</button>
    </mat-dialog-actions>
</div>
