/* libs/administration/src/Views/Dialogs/CreateRetailerView.scss */
:host {
  display: block;
  height: 100%;
  width: 100%;
}
.secondary-text {
  color: var(--mat-card-subtitle-text-color, var(--mat-sys-on-surface));
}
/*# sourceMappingURL=CreateRetailerView.css.map */
