<l7-page *cdkBreakpoint="let bp"
         motion
         [motionPreset]="'fadeSlideIn'">
  <l7-page-header [title]="'Angebotskonfiguration'| translate"
                  [cancelNavigation]="true">
  </l7-page-header>
  <l7-page-content fxLayout="column">
    @if(presenter.isInitialLoading()) {
      <l7-skeleton-form></l7-skeleton-form>
    }
    <form [attr.test-id]="'retailer-quote-form'"
          [formGroup]="presenter.form"
          [hidden]="presenter.isInitialLoading()"
    >
      <l7-wizard #stepper
                 class="no-header"
                 [selectedIndex]="presenter.selectedStep | async"
                 (selectionChange)="presenter.onStepChanged($event)">
        <l7-wizard-step [stepControl]="presenter.form.controls.calculation">
          <div cdkScrollable class="grid grid-cols-1 gap-3" [formGroupName]="'calculation'">
            <mat-card>
              <mat-card-content>
                <div class="grid grid-cols-1 md:grid-cols-12 gap-1 md:gap-2">
                  <mat-form-field class="md:col-span-2" appearance="outline">
                    <mat-label>{{ 'quote.retailers.calculation.input.objectQuantity.label' | translate }}</mat-label>
                    <mat-select l7MarkAsTouched
                                [formControlName]="'objectQuantity'"
                                [attr.test-id]="'objectQuantity'">
                      <ng-container *ngFor="let item of [].constructor(10); let i = index">
                        <mat-option [value]="i+1">{{ i + 1 }}</mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error *ngIf="presenter.form.controls.calculation.controls.objectQuantity.hasError('required')">
                      <p>{{ 'quote.retailers.calculation.input.objectQuantity.error.required' | translate }}</p>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="md:col-span-8" appearance="outline">
                    <mat-label>{{ 'quote.retailers.calculation.input.objectName.label' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           l7MarkAsTouched
                           [attr.test-id]="'objectName'"
                           [formControlName]="'objectName'">
                    <mat-error *ngIf="presenter.form.controls.calculation.controls.objectName.hasError('required')">
                      <p>{{ 'quote.retailers.calculation.input.objectName.error.required' | translate }}</p>
                    </mat-error>
                    <mat-error *ngIf="!presenter.form.controls.calculation.controls.objectName.hasError('required') && presenter.form.controls.calculation.controls.objectName.hasError('maxlength')">
                      <p>{{ 'quote.retailers.calculation.input.objectName.error.allowed_range' | translate }}</p>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="md:col-span-2" appearance="outline"
                                  formGroupName="calculationDetails">
                    <mat-label>{{ 'quote.retailers.calculation.input.condition.label' | translate }}</mat-label>
                    <mat-select required
                                [formControlName]="'objectCondition'"
                                [attr.test-id]="'condition'">
                      <mat-option [value]="'NEW'">{{ 'condition.NEW' | translate }}</mat-option>
                      <mat-option [value]="'USED'">{{ 'condition.USED' | translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.objectCondition.hasError('required')">
                      <p>{{ 'quote.retailers.calculation.input.condition.error.required' | translate }}</p>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-12 gap-1 md:gap-2"
                     [formGroupName]="'calculationDetails'">
                  <mat-form-field class="mat-form-field-shrink md:col-span-4" appearance="outline">
                    <mat-label>{{ 'quote.retailers.calculation.input.object_value.label' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           currencyMask
                           l7MarkAsTouched
                           autocomplete="off"
                           name="totalLeasingValue"
                           [attr.test-id]="'totalLeasingValue'"
                           [formControlName]="'totalLeasingValue'">
                    <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.totalLeasingValue.hasError('required')">
                      <p>{{ 'quote.retailers.calculation.input.object_value.error.required' | translate }}</p>
                    </mat-error>
                    <mat-error *ngIf="!presenter.form.controls.calculation.controls.calculationDetails.controls.totalLeasingValue.hasError('required') && presenter.form.controls.calculation.controls.calculationDetails.controls.totalLeasingValue.hasError('allowedRange')">
                      <p>{{ 'quote.retailers.calculation.input.object_value.error.allowed_range' | translate: {min: presenter.quoteCalculationSettings()?.leasingQuoteCalculationProperties.retailer.minObjectValue, max: presenter.quoteCalculationSettings()?.leasingQuoteCalculationProperties.retailer.maxObjectValue} }}</p>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="mat-form-field-shrink md:col-span-4" appearance="outline">
                    <mat-label>{{ 'quote.retailers.calculation.input.contract_type.label' | translate }}</mat-label>
                    <mat-select required
                                [formControlName]="'contractType'"
                                [attr.test-id]="'contractType'">
                      @for(contractType of presenter.retailerConfig()?.contractTypes;  track contractType;) {
                        <mat-option [value]="contractType">{{ 'contractType.' + contractType | translate }}</mat-option>
                      }
                    </mat-select>
                    @if(presenter.form.controls.calculation.get('calculationDetails.contractType')?.hasError('required')) {
                      <mat-error>
                        <p>{{ 'quote.retailers.calculation.input.contract_type.error.required' | translate }}</p>
                      </mat-error>
                    }
                  </mat-form-field>
                  <mat-form-field class="mat-form-field-shrink md:col-span-4" appearance="outline">
                    <mat-label>{{ 'quote.retailers.calculation.input.object_groups.label' | translate }}</mat-label>
                    <mat-select required
                                [formControlName]="'objectGroupCode'"
                                [attr.test-id]="'objectGroups'">
                      @for (objectGroup of presenter.retailerSettings()?.objectGroups; track objectGroup?.code) {
                        <mat-option [value]="objectGroup.code">{{ objectGroup.code + ': ' + objectGroup.description }}</mat-option>
                      }
                    </mat-select>
                    <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.objectGroupCode.hasError('required')">
                      <p>{{ 'quote.retailers.calculation.input.object_groups.error.required' | translate }}</p>
                    </mat-error>
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>

            <div class="grid grid-cols-1 md:grid-cols-12 gap-1 md:gap-2" [formGroupName]="'calculationDetails'">
              <mat-card class="mat-form-field-shrink md:col-span-6 lg:col-span-4">
                <mat-card-content>
                  <mat-checkbox [attr.test-id]="'downPaymentCheckBox'"
                                [checked]="presenter.hasDownPayment()"
                                (change)="presenter.onDownPaymentCheckboxChange($event.checked)">
                    <p>{{ 'Anzahlung hinzufügen' | translate }}</p>
                  </mat-checkbox>
                  <div class="linked-fields justify-content-space-between">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'quote.retailers.calculation.input.down_payment.label' | translate }}</mat-label>
                      <input matInput
                             type="text"
                             currencyMask
                             l7MarkAsTouched
                             formControlName="downPayment"
                             l7SelectOnDblClick
                             [attr.test-id]="'downPayment'">
                      <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.downPayment.invalid && presenter.form.controls.calculation.controls.calculationDetails.controls.downPayment.hasError('required')">
                        {{'quote.retailers.calculation.input.down_payment.error.required' | translate}}
                      </mat-error>
                      <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.downPayment.invalid && !presenter.form.controls.calculation.controls.calculationDetails.controls.downPayment.hasError('required') && presenter.form.controls.calculation.controls.calculationDetails.controls.downPayment.hasError('allowedValuesRelatedToLeasingValue')">
                        @let downPaymentError = presenter.form.controls.calculation.controls.calculationDetails.controls.downPayment.errors.allowedValuesRelatedToLeasingValue;
                        {{'quote.retailers.calculation.input.down_payment.error.allowed_down_payment' | translate: {min: downPaymentError.minAllowedValue | number, max: downPaymentError.maxAllowedValue | number} }}
                      </mat-error>
                    </mat-form-field>
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Link'" />
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'quote.retailers.calculation.input.down_payment_in_percent.label' | translate }}</mat-label>
                      <input matInput
                             type="text"
                             currencyMask
                             l7MarkAsTouched
                             formControlName="downPaymentInPercent"
                             l7SelectOnDblClick
                             [attr.test-id]="'downPaymentInPercent'"
                             [options]="{suffix: ' %'}">
                      <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.downPaymentInPercent.invalid && presenter.form.controls.calculation.controls.calculationDetails.controls.downPaymentInPercent.hasError('required')">
                        {{'quote.retailers.calculation.input.down_payment_in_percent.error.required' | translate}}
                      </mat-error>
                      <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.downPaymentInPercent.invalid && !presenter.form.controls.calculation.controls.calculationDetails.controls.downPaymentInPercent.hasError('required') && presenter.form.controls.calculation.controls.calculationDetails.controls.downPaymentInPercent.hasError('isNumberInRange')">
                        {{'quote.retailers.calculation.input.down_payment_in_percent.error.allowed_down_payment' | translate: {min: presenter.form.controls.calculation.controls.calculationDetails.controls.downPaymentInPercent.errors.min | percent, max: presenter.form.controls.calculation.controls.calculationDetails.controls.downPaymentInPercent.errors.max | percent} }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </mat-card>
              @if(presenter.showResidualValues | async) {
                <mat-card class="mat-form-field-shrink md:col-span-6 lg:col-span-4">
                  <mat-card-content>
                    <mat-label class="linked-label">{{ 'Restwert eingeben' | translate }}</mat-label>
                    <div class="linked-fields align-content-start justify-content-space-between">
                      <mat-form-field appearance="outline">
                        <mat-label>{{ 'quote.retailers.calculation.input.residualValue.label' | translate }}</mat-label>
                        <input matInput
                               type="text"
                               currencyMask
                               l7MarkAsTouched
                               formControlName="residualValue"
                               l7SelectOnDblClick
                               [attr.test-id]="'residualValue'">
                        <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.residualValue.invalid && presenter.form.controls.calculation.controls.calculationDetails.controls.residualValue.hasError('required')">
                          {{'quote.retailers.calculation.input.residualValue.error.required' | translate}}
                        </mat-error>
                        <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.residualValue.invalid && !presenter.form.controls.calculation.controls.calculationDetails.controls.residualValue.hasError('required') && presenter.form.controls.calculation.controls.calculationDetails.controls.residualValue.hasError('allowedValuesRelatedToLeasingValue')">
                          {{'quote.retailers.calculation.input.residualValue.error.allowedResidualValue' | translate: {min: (presenter.minAndMaxResidualValues |async )?.min | currency : 'EUR', max: (presenter.minAndMaxResidualValues |async )?.max | currency : 'EUR'} }}

                        </mat-error>
                      </mat-form-field>
                      <mat-icon [fontSet]="'l7'"
                                [fontIcon]="'Link'" />
                      <mat-form-field appearance="outline">
                        <mat-label>{{ 'quote.retailers.calculation.input.residualValueInPercent.label' | translate }}</mat-label>
                        <input matInput
                               type="text"
                               currencyMask
                               l7MarkAsTouched
                               formControlName="residualValueInPercent"
                               l7SelectOnDblClick
                               [attr.test-id]="'residualValueInPercent'"
                               [options]="{suffix: ' %'}">
                        <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.residualValueInPercent.invalid && presenter.form.controls.calculation.controls.calculationDetails.controls.residualValueInPercent.hasError('required')">
                          {{'quote.retailers.calculation.input.residualValueInPercent.error.required' | translate}}
                        </mat-error>
                        <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.residualValueInPercent.invalid && !presenter.form.controls.calculation.controls.calculationDetails.controls.residualValueInPercent.hasError('required') && presenter.form.controls.calculation.controls.calculationDetails.controls.residualValueInPercent.hasError('isNumberInRange')">
                          {{'quote.retailers.calculation.input.residualValueInPercent.error.allowedResidualValue' | translate: {min: presenter.form.controls.calculation.controls.calculationDetails.controls.residualValueInPercent.errors.min | percent, max: presenter.form.controls.calculation.controls.calculationDetails.controls.residualValueInPercent.errors.max | percent} }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </mat-card-content>
                </mat-card>
              }
            </div>
            <mat-card *ngIf="presenter.showExclusionOfWarranty | async">
              <mat-card-header>
                <mat-card-title>
                  {{ 'quote.retailers.stepper.exclusionOfWarrantyTitle' | translate }}
                </mat-card-title>
                <mat-card-subtitle>{{'quote.retailers.stepper.exclusionOfWarrantySubtitle' | translate}}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content fxLayout="column">
                <mat-radio-group fxLayout="column"
                                 [attr.test-id]="'exclusionOfWarranty'"
                                 [formControlName]="'exclusionOfWarranty'">
                  <mat-radio-button [value]="true">
                    {{ 'quote.retailers.calculation.input.exclusionOfWarranty.optionTrueLabel' | translate }}
                  </mat-radio-button>
                  <mat-radio-button [value]="false">
                    {{ 'quote.retailers.calculation.input.exclusionOfWarranty.optionFalseLabel' | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </mat-card-content>
            </mat-card>

            <mat-accordion>
              <mat-expansion-panel [formGroupName]="'calculationDetails'"
                                   [attr.test-id]="'expertMode'">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'Experten Modus' | translate}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div fxLayout="column"
                     fxFlex="65%">
                  <div fxLayout="row"
                       fxLayoutAlign="flex-start center"
                       fxLayout.lt-sm="column"
                       fxLayoutGap="16px">
                    <mat-checkbox class="alignLeft alignBottom"
                                  [fxFlex]="'0 1 calc(' + 35 + '%' + ' - 16px)'"
                                  [attr.test-id]="'provision'"
                                  [checked]="presenter.hasDealerCommission()"
                                  (change)="presenter.onDealerCommissionCheckboxChange($event.checked)">
                      <p>{{ 'Provision' | translate }}</p>
                    </mat-checkbox>
                    <mat-form-field appearance="outline"
                                    [fxFlex]="'0 1 calc(' + 65 + '%' + ')'">
                      <mat-label>{{ 'quote.retailers.calculation.input.provisionInPercent.label' | translate }}</mat-label>
                      <input matInput
                             type="text"
                             currencyMask
                             l7MarkAsTouched
                             formControlName="dealerCommissionInPercent"
                             l7SelectOnDblClick
                             [attr.test-id]="'dealerCommissionInPercent'"
                             [options]="{suffix: ' %', precision: 1}">
                      <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.dealerCommissionInPercent.invalid && presenter.form.controls.calculation.controls.calculationDetails.controls.dealerCommissionInPercent.hasError('required')">
                        {{'quote.retailers.calculation.input.provisionInPercent.error.required' | translate}}
                      </mat-error>
                      <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.dealerCommissionInPercent.invalid && !presenter.form.controls.calculation.controls.calculationDetails.controls.dealerCommissionInPercent.hasError('required') && presenter.form.controls.calculation.controls.calculationDetails.controls.dealerCommissionInPercent.hasError('isNumberInRange')">
                        {{'quote.retailers.calculation.input.provisionInPercent.error.allowed_range' | translate: {min: presenter.form.controls.calculation.controls.calculationDetails.controls.dealerCommissionInPercent.errors.min | percent, max: presenter.form.controls.calculation.controls.calculationDetails.controls.dealerCommissionInPercent.errors.max | percent} }}
                      </mat-error>
                      <!-- <mat-error *ngIf="presenter.form.controls.calculation.controls.calculationDetails.controls.expert.controls.dealerCommissionInPercent.invalid && (presenter.form.controls.calculation.controls.calculationDetails.controls.expert.controls.dealerCommissionInPercent.hasError('min') || presenter.form.controls.calculation.controls.calculationDetails.controls.expert.controls.dealerCommissionInPercent.hasError('max'))">
                          {{'quote.retailers.calculation.input.provisionInPercent.error.allowed_range' | translate: {min: 0 | number, max: 5 | number} }}
                      </mat-error> -->
                    </mat-form-field>
                  </div>
                  <div fxLayout="row"
                       fxLayoutAlign="flex-start center"
                       fxLayout.lt-sm="column"
                       fxLayoutGap="16px">
                    <mat-checkbox formControlName="handlingFee"
                                  class="alignLeft alignBottom"
                                  [fxFlex]="'0 1 calc(' + 35 + '%' + ' - 16px)'"
                                  [attr.test-id]="'handlingFee'">
                      <p>{{ 'Bearbeitungsgebühr' | translate }}</p>
                    </mat-checkbox>
                    <mat-form-field appearance="outline"
                                    [fxFlex]="'0 1 calc(' + 65 + '%' + ')'">
                      <mat-label>{{ 'quote.retailers.calculation.input.handlingFeeValue.label' | translate }}</mat-label>
                      <input matInput
                             type="text"
                             currencyMask
                             readonly
                             [disabled]="!presenter.handlingFeeValue()"
                             [value]="presenter.handlingFeeValue()"
                             [attr.test-id]="'handlingFeeValue'">
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <l7-retailer-calculation-rates-selection />
            <l7-retailer-calculation-monthly-insurance-toggle />
            <div fxLayout="row"
                 fxLayoutAlign="flex-end center"
                 fxLayout.lt-sm="column"
                 fxLayoutGap="16px">
              @if(!presenter.isRecalculation) {
                <button mat-flat-button
                        matStepperNext
                        color="primary"
                        type="button"
                        [attr.test-id]="'nextStep1'">{{'global.further' | translate}}</button>
              } @else {
                <button mat-flat-button
                        color="primary"
                        type="button"
                        [attr.test-id]="'updateCalculation'"
                        (click)="presenter.updateCalculation()">{{'quote.retailers.lessees.updateComparisonDialog.newCalculation' | translate}}</button>
              }
            </div>
          </div>
        </l7-wizard-step>

        <l7-wizard-step [stepControl]="presenter.form.controls.search">
          <div cdkScrollable
               fxLayout="column"
               fxLayoutGap="24px">
            <mat-card>
              <mat-card-header>
                <mat-card-title>
                  {{ 'quote.retailers.stepper.searchLesseeTitle' | translate }}
                </mat-card-title>
                <mat-card-subtitle>{{'quote.retailers.stepper.searchLesseeSubtitle' | translate}}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content fxLayout="column"
                                [formGroupName]="'search'">
                <div fxLayout="row">
                  <mat-form-field fxFlex
                                  appearance="outline">
                    <mat-label>{{ 'Firmenname' | translate }}</mat-label>
                    <input matInput
                           l7MarkAsTouched
                           type="text"
                           formControlName="companyName"
                           [attr.test-id]="'inputCompanyName'">
                    <mat-error *ngIf="presenter.form.get('search.companyName')?.hasError('required')">
                      {{ 'error.field_required' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutGap="16px"
                     fxLayoutGap.lt-md="0px">
                  <mat-form-field fxFlex
                                  appearance="outline">
                    <mat-label>{{ 'Stadt/PLZ' | translate }}</mat-label>
                    <input matInput
                           l7MarkAsTouched
                           type="text"
                           formControlName="city"
                           [attr.test-id]="'inputCity'">
                    <mat-error *ngIf="presenter.form.get('search.city')?.hasError('required')">
                      {{ 'error.field_required' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutGap="16px"
                     fxLayoutGap.lt-md="0px">
                  <mat-form-field fxFlex
                                  appearance="outline">
                    <mat-label>{{ 'Land' }}</mat-label>
                    <input matInput
                           type="text"
                           formControlName="country"
                           [attr.test-id]="'inputCountryName'">
                    <mat-error *ngIf="presenter.form.get('search.country')?.hasError('required')">
                      {{ 'error.field_required' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>
            <div fxLayout="row"
                 fxLayoutAlign="flex-end center"
                 fxLayout.lt-sm="column"
                 fxLayoutGap="16px">
              <button mat-stroked-button
                      matStepperPrevious
                      color="primary"
                      type="button"
                      [attr.test-id]="'previousStep2'">{{'contract_management.back' | translate}}</button>
              <button mat-flat-button
                      matStepperNext
                      color="primary"
                      type="button"
                      [attr.test-id]="'nextStep3'">{{'global.further' | translate}}</button>
            </div>
          </div>
        </l7-wizard-step>

        <l7-wizard-step [stepControl]="presenter.form.controls.lessee">
          <div cdkScrollable
               fxLayout="column"
               fxLayoutGap="24px">
            <mat-card>
              <mat-card-header>
                <mat-card-title>
                  {{ 'quote.retailers.stepper.selectLesseeTitle' | translate }}
                </mat-card-title>
                <mat-card-subtitle>{{'quote.retailers.stepper.selectLesseeSubtitle' | translate}}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content fxLayout="column"
                                class="no-padding">
                <mat-selection-list fxFlex
                                    fxFill
                                    class="no-padding"
                                    [multiple]="false"
                                    [formControlName]="'lessee'">
                  <mat-list-option *ngFor="let lessee of presenter.lessees | async; last as last"
                                   [attr.test-id]="lessee.crefoId"
                                   [disabled]="(lessee.allowedAsLessee===false)"
                                   [disableRipple]="(lessee.allowedAsLessee===false)"
                                   [value]="lessee">
                    <l7-text-block matListItemTitle
                                   [truncate]="true"
                                   [text]="lessee.name"></l7-text-block>
                    <l7-text-block matListItemLine
                                   [truncate]="true"
                                   [readonly]="true"
                                   [text]="this.presenter.formatAddress(lessee)"></l7-text-block>
                    <div *ngIf="!lessee.allowedAsLessee"
                         matListItemLine>
                      <l7-banner [color]="'info'"
                                 [appearance]="'textual'"
                                 [icon]="'Info'"
                                 [iconFontSet]="'l7'"
                                 [content]="'error.contact_lefo_not_whitelisted' | translate:{ param1: lessee.legalFormDescription? lessee.legalFormDescription : ''}"></l7-banner>
                    </div>
                    <!-- <div matListItemMeta
                         *ngIf="lessee.allowedAsLessee">
                        <mat-icon [fontSet]="'l7'"
                                  [fontIcon]="'Chevron_Right'"></mat-icon>
                    </div> -->
                    <mat-divider class="without-margin"
                                 [inset]="true"></mat-divider>
                  </mat-list-option>
                  <mat-list-item (click)="presenter.onRequestMail()">
                    <mat-icon matListItemIcon
                              [fontSet]="'l7'"
                              [fontIcon]="'Plus_Circle'"></mat-icon>
                    <l7-text-block matListItemTitle
                                   [truncate]="true"
                                   [text]="'quote.retailers.calculation.selectLessee.lesseeNotFound' | translate">
                    </l7-text-block>
                  </mat-list-item>
                </mat-selection-list>
              </mat-card-content>
            </mat-card>
            <div fxLayout="row"
                 fxLayoutAlign="flex-end center"
                 fxLayout.lt-sm="column"
                 fxLayoutGap="16px">
              <!-- <div fxFlex="50"
                   [style]="'text-align: center;'">
                  <l7-text-block mat-line
                                 [alignment]="'center'"
                                 [color]="'bold'"
                                 [text]="'quote.retailers.calculation.selectLessee.lesseeNotFound' | translate">
                  </l7-text-block>
                  <l7-spacer [thickness]="'bottom'"
                             [multiplier]="12"></l7-spacer>
                  <a class="mail-to"
                     [href]="presenter.requestUrlSubject | async"
                     (click)="presenter.onRequestMail()">{{'quote.retailers.calculation.selectLessee.nowSendMail' | translate}}</a>
              </div> -->
              <button mat-stroked-button
                      matStepperPrevious
                      color="primary"
                      type="button"
                      [attr.test-id]="'previousStep3'">{{'contract_management.back' | translate}}</button>

            </div>
          </div>
        </l7-wizard-step>
      </l7-wizard>
    </form>
  </l7-page-content>
</l7-page>