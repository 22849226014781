<div #containerElement class="camera-stream">
  <div class="camera-stream__video">
    <!-- <div class="camera-stream__placeholder" #placeholderElement>PLACEHOLDER</div> -->
    <video #videoElement [autoplay]="autoplay"></video>
    <canvas #canvasElement></canvas>
    <!--
    <div class="camera-stream__menu">
      <button (click)="captureImage()">Capture</button>
    </div>
    -->
  </div>
</div>
