// #region Imports

import { IUserInfoDto } from '@abcfinlab/api/login';
import { IUserGroupRetailerDto } from '@abcfinlab/auth';
import { SESSION_AVAILABLE_TOKEN, TranslationModule, USER_INFO_TOKEN } from '@abcfinlab/core';
import { PageModule } from '@abcfinlab/layout';
import { ArrayFilterPipeModule, CheckSignaturesDialogModule, InsuranceTypeIconPipeModule, PresentationModule, TileRegistry } from '@abcfinlab/presentation';
import { QuoteModule, QuoteSummaryCardModule } from '@abcfinlab/quote';
import {
    BannerModule,
    BreakpointObserverModule,
    BusyBoxModule,
    CompareToModule,
    DatePickerModule,
    DetailRowModule,
    DialogHeaderModule,
    FilterModule,
    FormatPipeModule,
    KeysPipeModule,
    MandatoryCheckboxWithErrorDirectiveModule,
    MaxValidatorModule,
    MemberValuePipeModule,
    MessageBoxModule,
    MinValidatorModule,
    MotionModule,
    Motions,
    NotAvailablePipeModule,
    OfPipeModule,
    PercentageMaxValidatorModule,
    RemoveNullPipe,
    ScrollToErrorModule,
    SelectOnDblClickDirectiveModule,
    SpacerModule,
    SummaryModule,
    TextBlockModule,
    TileModule,
} from '@abcfinlab/ui';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ContractDocumentUploadViewComponent } from 'libs/contractManagement/src/Views/contract-document-upload-view/contract-document-upload-view.component';
import { ContractOverviewAcknowledgementView } from 'libs/contractManagement/src/Views/ContractOverviewAcknowledgementView';
import { RetailerContractDetailsView } from 'libs/contractManagement/src/Views/retailer-contract-managment/retailer-contract-details-view/RetailerContractDetailsView';
import { NgxCurrencyDirective } from 'ngx-currency';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { SuccessModule } from '../../../legacy/gwg/src/lib/success/success.module';
import { AppPaginatorIntlAdapter } from '../../../presentation/src/Adapters/paginator.adapter';
import {
    KuevComparisonComponent,
} from '../Components/kuev-comparison/kuev-comparison.component';
import {
    ContractOverviewKuevView,
} from '../Views/contract-overview-kuev-view/ContractOverviewKuevView';
import { ContractPurchaseEntryViewComponent } from '../Views/contract-purchase-entry-view/contract-purchase-entry-view.component';
import { ContractOverviewBillingInformationView } from '../Views/ContractOverviewBillingInformationView';
import { ContractOverviewDetailsView } from '../Views/ContractOverviewDetailsView';
import { ContractOverviewDocumentsView } from '../Views/ContractOverviewDocumentsView';
import { ContractOverviewRecalculationView } from '../Views/ContractOverviewRecalculationView';
import { ContractOverviewView } from '../Views/ContractOverviewView';
import { AccountingDialogComponent } from '../Views/Dialogs/accounting-dialog/accounting-dialog.component';
import { ContractOverviewTakeoverConfirmationUploadDialogView } from '../Views/Dialogs/ContractOverviewTakeoverConfirmationUploadDialogView';
import { DigitalSignDialogView } from '../Views/Dialogs/DigitalSignDialog/DigitalSignDialogView';
import { PurchaseEntryObjectValueComponent } from '../Views/Dialogs/purchase-entry-object-value/purchase-entry-object-value.component';
import { TakeoverDocumentationDialogView } from '../Views/Dialogs/takeover-documentation/takeoverDocumentationDialogView';
import { UploadDeliveryDocumentDialogView } from '../Views/Dialogs/UploadDeliveryDocumentDialog/UploadDeliveryDocumentDialogView';
import { UploadTakeoverDocumentDialogView } from '../Views/Dialogs/UploadTakeoverDocumentDialog/UploadTakeoverDocumentDialogView';
import { RetailerAcknowledgmentDigitalSignModule } from '../Views/retailer-acknowledgment-signature/retailer-acknowledgment-digitalSign.module';
import { RetailerContractsOverviewViewComponent } from '../Views/retailer-contract-managment/retailer-contracts-overview-view/retailer-contracts-overview-view.component';
import { TodoWidgetView } from '../Views/Widgets/TodoWidgetView';

// #endregion

/**
 * The `ContractManagementModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        ArrayFilterPipeModule,
        MemberValuePipeModule,
        PresentationModule,
        CommonModule,
        BrowserAnimationsModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionModule,
        MatTableModule,
        MatSortModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        DetailRowModule,
        BannerModule,
        ClipboardModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatRippleModule,
        MatChipsModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSelectModule,
        MatMenuModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatSelectModule,
        MatDividerModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        FlexLayoutModule,
        TranslationModule,
        TextBlockModule,
        MessageBoxModule,
        PercentageMaxValidatorModule,
        MaxValidatorModule,
        MinValidatorModule,
        QuoteModule,
        SuccessModule,
        KeysPipeModule,
        OfPipeModule,
        MatListModule,
        SelectOnDblClickDirectiveModule,
        NotAvailablePipeModule,
        InsuranceTypeIconPipeModule,
        SummaryModule,
        QuoteSummaryCardModule,
        BusyBoxModule,
        SpacerModule,
        DialogHeaderModule,
        DatePickerModule,
        RetailerAcknowledgmentDigitalSignModule,
        NgxCurrencyDirective,
        PageModule,
        CdkStepperModule,
        MatStepperModule,
        BreakpointObserverModule,
        MatSlideToggleModule,
        FormatPipeModule,
        FilterModule,
        TileModule,
        MandatoryCheckboxWithErrorDirectiveModule,
        ScrollToErrorModule,
        CompareToModule,
        MotionModule.forRoot({
            motions: [
                Motions.fadeSlideIn,
            ],
        }),
        CheckSignaturesDialogModule,
        RemoveNullPipe,
    ],
    declarations: [
        ContractOverviewView,
        ContractOverviewDetailsView,
        ContractOverviewDocumentsView,
        ContractPurchaseEntryViewComponent,
        ContractOverviewBillingInformationView,
        ContractDocumentUploadViewComponent,
        ContractOverviewAcknowledgementView,
        ContractOverviewTakeoverConfirmationUploadDialogView,
        AccountingDialogComponent,
        PurchaseEntryObjectValueComponent,
        ContractOverviewRecalculationView,
        RetailerContractsOverviewViewComponent,
        ContractOverviewRecalculationView,
        RetailerContractDetailsView,
        TakeoverDocumentationDialogView,
        DigitalSignDialogView,
        UploadDeliveryDocumentDialogView,
        UploadTakeoverDocumentDialogView,
        ContractOverviewKuevView,
        KuevComparisonComponent,
        TodoWidgetView,
    ],
    exports: [],
    providers: [{ provide: MatPaginatorIntl, useClass: AppPaginatorIntlAdapter }, CurrencyPipe, { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'fill' } }],
})
export class ContractManagementModule {

    // #region Ctor

    /**
     * Constructs a new instance of the `ContractManagementModule` class.
     *
     * @public
     */
    public constructor(@Inject(SESSION_AVAILABLE_TOKEN) sessionAvailable: Subject<void>, @Inject(USER_INFO_TOKEN) userInfo: ReplaySubject<IUserInfoDto>, tileRegistry: TileRegistry) {
        combineLatest([sessionAvailable, userInfo])
            .pipe(first())
            .subscribe(([_, user]) => {
                if (user.groups.includes(IUserGroupRetailerDto.RetailerContractManagement) || user.groups.includes(IUserGroupRetailerDto.RetailerSales)) {
                    tileRegistry.register({
                        key: 'TASKS',
                        order: 10,
                        columnSpan: { min: 2, max: 2 },
                        rowSpan: { min: 1, max: 1 },
                        views: {
                            front: TodoWidgetView,
                        },
                    });
                }
            });
    }

    // #endregion

}
