// #region Imports
// #endregion

export interface ICommandMeta {

    // #region Properties

    readonly key: string;

    readonly description?: string;

    // #endregion

}

/**
 * @public
 */
export function Commandable(meta: ICommandMeta): ClassDecorator {
    return (target: (Parameters<ClassDecorator>[0])): void => {
        const propertyKey = '$$__commandMeta';

        if (Reflect.has(target.prototype, propertyKey)) {
            console.warn(`The property '${propertyKey}' is already exist, it will be overrided by Commandable decorator.`);
        }

        Reflect.set(target.prototype, propertyKey, meta);
    };
}
