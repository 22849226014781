<div class="list-item-container"
     [ngClass]="classes">
  <div class="list-item__description">
    <input type="checkbox"
           [id]="lessee_crefo_no"
           [checked]="is_checked">
    <label [htmlFor]="lessee_crefo_no">
      <span></span>
      {{lessee_name}} <br>
      {{lessee_address}}
    </label>
  </div>
  <div *ngIf="is_checked"
       class="list-item__submenu">
    <button mat-flat-button
            id="button_calculate-quote"
            [color]="'accent'"
            [disabled]="list_performing_action | async"
            (click)="onCalculateQuote()">Angebot kalkulieren</button>
  </div>
</div>