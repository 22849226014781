<div fxLayout="column"
     fxLayoutGap="16px">
    <mat-expansion-panel class="biggerTitle"
                         [expanded]="panelOpenState"
                         (toggle)="togglePanelState($event)">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div>{{ 'quote.calculation.refinancingInterest.title' | translate }}</div>
            </mat-panel-title>

        </mat-expansion-panel-header>
        <div fxLayout="column" fxLayoutGap="16px">
            <div fxLayout="row"
                 fxLayout.lt-sm="column"
                 fxLayoutGap.lt-sm="0px"
                 fxLayoutGap="16px" [formGroup]="form">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>{{'quote.calculation.refinancingInterest.label' | translate}}</mat-label>
                    <input matInput
                           l7MarkAsTouched
                           l7Calculate
                           type="text"
                           formControlName="refinancingInterestValue"
                           currencyMask
                           l7SelectOnDblClick
                           [markOnInit]="true"
                           [attr.test-id]="'refinancingInterestValue'"
                           [l7Min]="0"
                           [l7Max]="100"
                           [options]="{suffix: ' %'}">
                    <mat-error *ngIf="form.controls.refinancingInterestValue.errors">
                        {{'error.value_bigger_than' | translate : {param1: '0'} }}
                    </mat-error>
                    <mat-icon *ngIf="form.controls.refinancingInterestValue.value !== refinancingInterestResponse?.refinancingInterestDefaultValue"
                              matSuffix
                              color="warning"
                              [fontSet]="'l7'"
                              [fontIcon]="'Alert_Circle'">
                    </mat-icon>
                    <mat-hint *ngIf="form.controls.refinancingInterestValue.value !== refinancingInterestResponse?.refinancingInterestDefaultValue">
                        {{ 'calculation.refinancing_rate_warning' | translate: {nav_value: refinancingInterestResponse?.refinancingInterestDefaultValue} }}
                    </mat-hint>
                </mat-form-field>
            </div>

            <l7-summary [maxColumns]="2"
                        [minColumns]="1">
                <l7-summary-item [title]="'quote.calculation.refinancingInterest.riskDiscount' | translate"
                                 [content]="refinancingInterestResponse?.riskDiscount | currency: '%'">
                </l7-summary-item>
                <l7-summary-item [title]="'quote.calculation.refinancingInterest.exploitationCode' | translate"
                                 [content]="exploitationCode.toString()">
                </l7-summary-item>
                <l7-summary-item [title]="'quote.calculation.refinancingInterest.presentValueProfit' | translate"
                                 [content]="refinancingInterestResponse?.presentValueProfit | currency: 'EUR'">
                </l7-summary-item>
                <l7-summary-item [title]="'quote.calculation.refinancingInterest.presentValueProfitPercentage' | translate"
                                 [content]="refinancingInterestResponse?.presentValueProfitPercentage * 100 | currency: '%'">
                </l7-summary-item>
            </l7-summary>
        </div>

    </mat-expansion-panel>
</div>
