export function toDecimal(value: any, digits: number = 2, up: boolean = false): number {
    try {
        const factor = Math.pow(10, digits); // This is used to shift the decimal places
        let result = Math.floor(value * factor) / factor; // Default rounding down

        // If the 'up' flag is true, we use Math.round to round up
        if (up) {
            result = Math.round((value + Number.EPSILON) * factor) / factor;
        }

        return Number(result.toFixed(digits));
    } catch (e) {
    // In case of error, return a rounded 0
        return Number(Math.round(0).toFixed(digits));
    }
}

/*
   * @deprecated use getPercent()
 */
export function getPercentDeprecated(from: number, of: number, digits: number = 2, up: boolean = false): number {
    try {
        const percent = (of * 100) / from;
        return toDecimal(percent, digits, up);
    } catch (e) {
        console.error(e);
    }
}

export function getPercent({ from, of, digits = 2, up = false }: { from?: number; of?: number; digits?: number; up?: boolean }): number | undefined {
    if (from && of) {
        const percent = (of * 100) / from;
        return Number.isFinite(percent) ? toDecimal(percent, digits, up) : undefined;
    }
}

/*
   * @deprecated use getPercentageValue()
 */
export function getPercentageValueDeprecated(percentage: number, basis: number): number {
    try {
        const result = (basis * percentage) / 100;
        return toDecimal(result, 2, true);
    } catch (e) {
        console.error(e);
    }
}

export function getPercentageValue({ percentage, basis }: { percentage?: number; basis?: number }): number | undefined {
    if (!!basis && !!percentage) {
        const result = (basis * percentage) / 100;
        return toDecimal(result, 2, true);
    }
}

/**
 * Decide if a insurance is possible in general. This decision is made base on the 'insurance_value' of an object-group.
 * @param objectGroupInsuranceFactor      The insurance value of a given object group
 */
export function insuranceIsPossible(objectGroupInsuranceFactor: number): boolean {
    if (typeof objectGroupInsuranceFactor === 'undefined' || objectGroupInsuranceFactor === null) {
        return false;
    }
    if (objectGroupInsuranceFactor <= 0) {
        return false;
    }
    if (objectGroupInsuranceFactor > 0) {
        return true;
    }
}
/**
 * Get the calculated insurance pro mille value according to a object value and insurance value.
 */
export function calculateInsuranceProMilleValue(objectValue: number, insuranceValue: number): number {
    if (insuranceValue === null || typeof insuranceValue === 'undefined' || objectValue === null || typeof objectValue === 'undefined') {
        throw new Error('Cannot calculate insurance pro mille value! Missing parameters.');
    }

    return toDecimal((insuranceValue / objectValue) * 1000, 4, true);
}

export function hundredProMustBeDisabled(totalLeasingValue?: number, objectGroupCode?: number) {
    if (totalLeasingValue && objectGroupCode) {
        return (objectGroupCode === 2190) || (objectGroupCode === 2290)
            || ((objectGroupCode < 2120) && totalLeasingValue >= 100000)
            || ((objectGroupCode >= 2120) && totalLeasingValue >= 130000);
    }
    return false;
}
/**
 * Prevent values to be displayed as "0" or "0,00" etc.
 * @param value   The value to be
 */
export const preventNullValue = (value: number) => value === 0 ? null : value;
