<h2>{{'contract_management.documents.view_title' | translate : {param1: contract_number} }}</h2>
<p>{{'contract_management.documents.view_text' | translate}}</p>
<ng-template #selectCategory
             let-customData>
    <mat-form-field appearance="fill"
                    fxFlex="40"
                    class="fixed-height"
                    fxFlex.lt-md="100"
                    fxLayout="column">
        <mat-label>KATEGORIE<sup>*</sup></mat-label>
        <mat-select panelClass="cdk-select-categories"
                    (selectionChange)="customData.option.selectCategory($event, customData.index)">
            <mat-option *ngFor="let category of categories"
                        [value]="category.value">
                {{category.text}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>
<div *ngIf="loading" class="spinner-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
    <l7-spacer [thickness]="'all'" [density]="'comfortable'">
        <mat-spinner></mat-spinner>
    </l7-spacer>
</div>
<l7-banner *ngIf="(countInvoices$ | async) > 1"
           [appearance]="'textual'"
           [color]="'warn'"
           [icon]="'Alert_Triangle'"
           [content]="'contract_management.invoice_upload.warning_message' | translate">
</l7-banner>
<l7-file-uploader [useUploadButton]="true"
                  [canUpload]="false"
                  [allowedFileTypes]="['pdf']"
                  [maxFiles]="20"
                  [optionTemplate]="selectCategory"
                  [clearFiles]="clearUploadedFiles"
                  (filesForUpload)="uploadFiles($event)"
                  (filesChanged)="filesToUpload($event)">
</l7-file-uploader>
