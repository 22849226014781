@let hasError = !rateFormControl.untouched && rateFormControl.hasError('required');
<ng-container *cdkBreakpoint="let bp">
@if(!isIdle()) {
  <mat-card>
    <mat-card-header>
      <mat-card-title fxFlex
                      fxLayout="row"
                      fxLayoutAlign="space-between center">
        <l7-text-block [text]="'quote.retailers.stepper.show_offers_title' | translate"></l7-text-block>
        @if(rateOptions()) {
          <l7-text-block [text]="' (' + rateOptions()?.length + ')'"
                         [readonly]="true"></l7-text-block>
        }
      </mat-card-title>
    </mat-card-header>
    @if(isLoading()){
      <l7-skeleton-carousel />
    } @else {
      <mat-card-content fxLayout="column">
        <ng-container *ngIf="bp(['xl', 'lg', 'md', 'sm'])">
          <l7-carousel [elemWidth]="115"
                       [maxShowedItems]="bp('lt-md') ? 3 : 5"
                       [showControls]="rateOptions()?.length > (bp('lt-md') ? 3 : 5)"
                       [placeholder]="'Die Raten werden nach dem Kalkulationsvorgang sichtbar' | translate">
            @for(item of rateOptions(); let i = $index; track i;) {
              <ng-container *l7CarouselItem>
                <l7-tile class="rate-card carousel-item"
                         matstp
                         motion
                         id="rate-{{item.terms}}"
                         [attr.test-id]="'rate-' + item.terms"
                         [selected]="rateFormControl.getRawValue()?.terms === item.terms"
                         [motionPreset]="'fadeSlideIn'"
                         [motionOptions]="{ delay:100 + (i * 50) }"
                         (click)="onClick(item)">
                  <l7-text-block [truncate]="true"
                                 [alignment]="'center'"
                                 [text]="item.terms + ' Monate' | translate"></l7-text-block>
                  <l7-text-block [truncate]="true"
                                 [alignment]="'center'"
                                 [color]="'info'"
                                 [text]="item.instalment | currency"></l7-text-block>
                  <p class="small-grey">{{'Monatliche Rate' | translate}}</p>
                </l7-tile>
              </ng-container>
            }
          </l7-carousel>
        </ng-container>
        <ng-container *ngIf="bp('xs')">
          <l7-wrap [columns]="2"
                   [gap]="16">
            @for(item of rateOptions(); let i = $index; track i;) {
              <l7-tile class="rate-card carousel-item"
                       matstp
                       motion
                       id="rate-{{item.terms}}"
                       [attr.test-id]="'rate-' + item.terms"
                       [selected]="rateFormControl.getRawValue()?.terms === item.terms"
                       [motionPreset]="'fadeSlideIn'"
                       [motionOptions]="{ delay:100 + (i * 50) }"
                       (click)="onClick(item)">
                <l7-text-block [truncate]="true"
                               [alignment]="'center'"
                               [text]="item.terms + ' Monate' | translate"></l7-text-block>
                <l7-text-block [truncate]="true"
                               [alignment]="'center'"
                               [color]="'info'"
                               [text]="item.instalment | currency"></l7-text-block>
                <p class="small-grey">{{'Monatliche Rate' | translate}}</p>
              </l7-tile>
            }
          </l7-wrap>
        </ng-container>
        @if(hasError) {
          <mat-error class="mat-error-standalone">
            <p>{{ 'quote.retailers.calculation.rate.error.required' | translate }}</p>
          </mat-error>
        }
      </mat-card-content>
    }
  </mat-card>
}
</ng-container>