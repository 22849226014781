<mat-card fxFlex
          fxLayout="column"
          [appearance]="'outlined'">
    <mat-card-content fxFill
                      fxLayout="column"
                      fxLayoutAlign="center center">
        <div>
            <span class="day-name">{{ presenter.date | async | date:'EEE' }}, {{' '}}</span>
            <span class="day">{{ presenter.date | async | date:'d' }}.</span>
        </div>
        <span class="month-name">{{ presenter.date | async | date:'MMMM' }}</span>
        <span class="designator"></span>
        <span class="year">{{ presenter.date | async | date:'y' }}</span>
    </mat-card-content>
</mat-card>