<form *ngIf="form"
      [formGroup]="form">
    <div fxLayout="column"
         fxLayoutGap="16px">
        <mat-card>
            <mat-card-header class="full-width">
                <mat-card-title fxFlex="100"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                    <div>{{ 'quote.calculation.view.calculationTitle' | translate }}</div>
                    <div class="function-resolve" fxLayout="row" fxLayoutAlign="center center">
                        <span>{{ 'Kalkulieren nach' | translate }}</span>
                        <mat-chip-listbox formControlName="valueToCalculate" l7Calculate>
                            <mat-chip-option test-id="INSTALMENT"
                                             [value]="'INSTALMENT'"
                                             [ngClass]="{'active': selectedValueToCalculate === 'INSTALMENT'}"
                                             (click)="changeValueToCalculate($event)">
                                {{ 'Rate' | translate }}
                            </mat-chip-option>
                            <mat-chip-option test-id="YEARLY_INTEREST"
                                             [value]="'YEARLY_INTEREST'"
                                             [ngClass]="{'active': selectedValueToCalculate === 'YEARLY_INTEREST'}"
                                             (click)="changeValueToCalculate($event)">
                                {{ 'Zins' | translate }}
                            </mat-chip-option>
                            <mat-chip-option *ngIf="selectedValueToCalculate === 'FIRST_INSTALMENT'"
                                             test-id="FIRST_INSTALMENT"
                                             [value]="'FIRST_INSTALMENT'"
                                             [selected]="selectedValueToCalculate === 'FIRST_INSTALMENT'"
                                             [ngClass]="{'active': selectedValueToCalculate === 'FIRST_INSTALMENT'}">
                                {{ 'Abw. erste Rate' | translate }}
                            </mat-chip-option>
                            <mat-chip-option *ngIf="selectedValueToCalculate === 'LAST_INSTALMENT'"
                                             test-id="LAST_INSTALMENT"
                                             [value]="'LAST_INSTALMENT'"
                                             [selected]="selectedValueToCalculate === 'LAST_INSTALMENT'"
                                             [ngClass]="{'active': selectedValueToCalculate === 'LAST_INSTALMENT'}">
                                {{ 'Abw. letzte Rate' | translate }}
                            </mat-chip-option>
                        </mat-chip-listbox>
                        <ng-container *ngIf="form.controls.contractType.getRawValue() === 'MIETKAUF' || form.controls.contractType.getRawValue() === 'MKN'">
                            <button mat-icon-button
                                    type="button"
                                    [matMenuTriggerFor]="menuExtraValuesToCalculate">
                                <mat-icon [fontSet]="'l7'"
                                          [color]="'neutral'"
                                          [fontIcon]="'More_Vert_Filled'">
                                </mat-icon>
                            </button>
                            <mat-menu #menuExtraValuesToCalculate="matMenu" test-id="menuExtraValuesToCalculate">
                                <button mat-menu-item
                                        data-value="FIRST_INSTALMENT"
                                        test-id="calculateToFirstInstalment"
                                        [hidden]="selectedValueToCalculate === 'FIRST_INSTALMENT'"
                                        [disabled]="!form.controls.quoteCalculationRequest.controls.firstInstalmentValue.getRawValue()"
                                        (click)="changeValueToCalculate($event)">
                                    {{ 'calculation.first_instalment' | translate }}
                                </button>
                                <button mat-menu-item
                                        data-value="LAST_INSTALMENT"
                                        test-id="calculateToLastInstalment"
                                        [hidden]="selectedValueToCalculate === 'LAST_INSTALMENT'"
                                        [disabled]="!form.controls.quoteCalculationRequest.controls.lastInstalmentValue.getRawValue()"
                                        (click)="changeValueToCalculate($event)">
                                    {{ 'calculation.last_instalment' |translate }}
                                </button>
                            </mat-menu>
                        </ng-container>

                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="more-padding" fxLayout="column" fxLayoutGap="16px">
                <ng-container [formGroup]="objectForm">
                    <div *ngIf="lessee"
                         fxLayout="row"
                         class="no-margin"
                         fxLayout.lt-sm="column">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{ 'Objektbezeichnung' | translate }}</mat-label>
                            <input formControlName="name"
                                   test-id="inputObjectName"
                                   matInput
                                   l7MarkAsTouched
                                   placeholder="Objektbezeichnung">
                            <mat-error *ngIf="objectForm.get('name').errors?.maxlength">
                                {{'error.name_bigger_than' | translate}}
                            </mat-error>
                            <mat-error *ngIf="objectForm.get('name').errors?.required">
                                {{'error.field_required' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>
                <div fxLayout="row"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="0px"
                     fxLayoutGap="16px">
                    <mat-radio-group formControlName="objectCondition"
                                     l7Calculate
                                     (change)="selectObjectCondition($event)">
                        <mat-radio-button color="primary"
                                          test-id="radioConditionNew"
                                          [value]="'NEW'">
                            {{ 'calculation.condition_new_label' | translate }}
                        </mat-radio-button>
                        <mat-radio-button color="primary"
                                          test-id="radioConditionUsed"
                                          [value]="'USED'">
                            {{ 'calculation.condition_used_label' | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div fxLayout="row"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="0px"
                     fxLayoutGap="16px">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>{{ 'Objektgruppe' | translate }}</mat-label>
                        <mat-select formControlName="objectGroupCode"
                                    test-id="selectObjectGroup"
                                    (selectionChange)="selectObjectGroup($event)">
                            <mat-option>
                                <ngx-mat-select-search noEntriesFoundLabel="Keine Objektgruppe gefunden ..."
                                                       [formControl]="objectGroupFilterCtrl">
                                </ngx-mat-select-search>
                            </mat-option>
                            <ng-container *ngIf="objectGroups?.length">
                                <mat-option *ngFor="let objectGroup of filteredObjectGroups$ | async"
                                            [value]="objectGroup.code">
                                    {{objectGroup.code}}: {{objectGroup.name}}
                                </mat-option>
                            </ng-container>

                        </mat-select>
                        <mat-error *ngIf="form.get('objectGroupCode').errors?.required">
                            {{'error.field_required' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row wrap"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="0px"
                     fxLayoutGap="16px">
                    <mat-form-field appearance="outline"
                                    [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'"
                                    [fxFlex.lt-md]="'calc(' + 100 + '%' + ' - 16px)'">
                        <mat-label>{{ 'Vertragsart' | translate }}</mat-label>
                        <mat-select formControlName="contractType"
                                    test-id="selectContractType">
                            <ng-container *ngFor="let contractType of contractTypes;">
                                <mat-option [value]="contractType">
                                    {{'contractTypes.' + contractType | translate }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="form.get('contractType').errors?.required">
                            {{'error.field_required' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <ng-container [formGroup]="form.controls.quoteCalculationRequest">
                        <mat-form-field appearance="outline"
                                        [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ')'"
                                        [fxFlex.lt-md]="'calc(' + 100 + '%' + ')'">
                            <mat-label>{{ 'Objektwert' | translate }}</mat-label>
                            <input formControlName="totalLeasingValue"
                                   test-id="inputObjectValue"
                                   matInput
                                   l7Calculate
                                   l7MarkAsTouched
                                   currencyMask
                                   placeholder="Objektwert *">
                            <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.totalLeasingValue.errors?.allowedRange && form.controls.quoteCalculationRequest.controls.totalLeasingValue.dirty">
                                Wert zwischen {{calculationSettings.leasingQuoteCalculationProperties.inhouse.minObjectValue}} € und {{calculationSettings.leasingQuoteCalculationProperties.inhouse.maxObjectValue}} € eingeben.
                            </mat-error>
                            <mat-error *ngIf="form.controls.quoteCalculationRequest.get('totalLeasingValue').errors?.required">
                                {{'error.field_required' | translate}}
                            </mat-error>
                            <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.totalLeasingValue.errors?.invalid || form.controls.quoteCalculationRequest.controls.totalLeasingValue.errors?.valueNotPossible">
                                {{'error.value_not_allowed' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>

                </div>
                <div fxLayout="row wrap"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="0px"
                     fxLayoutGap="16px" [formGroup]="form.controls.quoteCalculationRequest">
                    <mat-form-field appearance="outline"
                                    [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'"
                                    [fxFlex.lt-md]="'calc(' + 100 + '%' + ' - 16px)'">
                        <mat-label>{{ 'Laufzeit' | translate }}</mat-label>
                        <mat-select formControlName="totalTerms"
                                    l7Calculate
                                    test-id="selectTotalTerms">
                            <ng-container *ngIf="amortizationRange |async">
                                <mat-option *ngFor="let range of (amortizationRange | async); let i = index"
                                            [value]="(amortizationRange | async)[i]">
                                    {{range}} Monate
                                </mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="form.controls.quoteCalculationRequest.get('totalTerms').errors?.required">
                            {{'error.field_required' | translate}}
                        </mat-error>
                        <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.totalTerms.errors?.invalid || form.controls.quoteCalculationRequest.controls.totalTerms.errors?.valueNotPossible">
                            {{'error.value_not_allowed' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline"
                                    [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ')'"
                                    [fxFlex.lt-md]="'calc(' + 100 + '%' + ')'"
                                    [ngClass]="{'blocked': form.controls.valueToCalculate.getRawValue() === 'INSTALMENT'}">
                        <mat-label>{{ 'Rate' | translate }}</mat-label>
                        <input type="text"
                               formControlName="instalment"
                               matInput
                               l7MarkAsTouched
                               l7Calculate
                               placeholder="Rate"
                               test-id="inputInstalment"
                               currencyMask>
                        <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.instalment.errors?.invalid || form.controls.quoteCalculationRequest.controls.instalment.errors?.valueNotPossible">
                            {{'error.value_not_allowed' | translate }}
                        </mat-error>
                        <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.instalment.errors?.min">
                            {{'error.value_bigger_than' | translate: {param1: 0 | currency} }}
                        </mat-error>
                        <mat-error *ngIf="form.controls.quoteCalculationRequest.get('instalment').errors?.required">
                            {{'error.field_required' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header class="full-width">
                <mat-card-title fxFlex="100"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                    <div *ngIf="form.controls.contractType.getRawValue() !== 'MIETKAUF' && form.controls.contractType.getRawValue() !== 'MKN'; else titleMK">{{ 'quote.calculation.view.leasingTitle' | translate }}</div>
                    <ng-template #titleMK>
                        <div>{{ 'Nominalzins' | translate }}</div>
                    </ng-template>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="more-padding" fxLayout="column" fxLayoutGap="16px" [formGroup]="form.controls.quoteCalculationRequest">
                <div fxLayout="row wrap"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="0px"
                     fxLayoutGap="16px">
                    <mat-form-field appearance="outline"
                                    [fxFlex]="'0 1 calc(80% - 16px)'"
                                    [fxFlex.lt-md]="'calc(' + 100 + '%' + ' - 16px)'"
                                    [ngClass]="{'blocked': form.controls.valueToCalculate.getRawValue() === 'YEARLY_INTEREST' }">
                        <mat-label>{{ 'Nom.Zins P.A.' | translate }}</mat-label>
                        <input formControlName="yearlyInterestPercentage"
                               matInput
                               l7MarkAsTouched
                               l7Calculate
                               placeholder="Nom. Zins P.A."
                               test-id="inputYearlyInterestPercentage"
                               currencyMask
                               [options]="{suffix: ' %', allowNegative: true}">
                        <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.yearlyInterestPercentage.errors?.min || form.controls.quoteCalculationRequest.controls.yearlyInterestPercentage.errors?.invalid || form.controls.quoteCalculationRequest.controls.yearlyInterestPercentage.errors?.valueNotPossible">
                            {{'error.value_not_allowed' | translate }}
                        </mat-error>
                        <mat-error *ngIf="form.controls.quoteCalculationRequest.get('yearlyInterestPercentage').errors?.required">
                            {{'error.field_required' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <l7-summary *ngIf="form.controls.contractType.getRawValue() !== 'MIETKAUF' && form.controls.contractType.getRawValue() !== 'MKN'"
                                [maxColumns]="1"
                                [minColumns]="1">
                        <l7-summary-item [title]="'Leasingfaktor' | translate"
                                         [content]="leasingFactorValue | number">
                        </l7-summary-item>
                    </l7-summary>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</form>
