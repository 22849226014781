// #region Imports

import { Pipe, PipeTransform } from '@angular/core';

// #endregion

/**
 * Transforms a value to an observable sequence.
 *
 * @public
 */
@Pipe({
    name: 'removeNull',
    standalone: true,
})
export class RemoveNullPipe implements PipeTransform {

    transform(value: string | null | undefined): string {
        if (!value) {
            return '';
        }
        if (Array.isArray(value)) {
            return value
                .filter(Boolean)
                .map(item => item.replace(/\b(null|undefined)\b/g, ''))
                .join(' ');
        }
        return value.replace(/\b(null|undefined)\b/g, '');
    }

}
