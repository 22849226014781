import { BASE_PATH as CONTACT_BASE_PATH } from '@abcfinlab/api-contact';
import { BASE_PATH as CONTRACT_BASE_PATH } from '@abcfinlab/api-contract';
import { BASE_PATH } from '@abcfinlab/api-global';
import { BASE_PATH as LOGIN_BASE_PATH } from '@abcfinlab/api-login';
import { BASE_PATH as RETAILER_BASE_PATH } from '@abcfinlab/api-retailer';
import { AppConfig } from '@abcfinlab/core';
import { Provider } from '@angular/core';

const openApiFactory = (appConfig: AppConfig, url = ''): string => {
    return appConfig.get('host') + (url ? `/${url}` : url);
};

export const withOpenAPIProviders = (): Array<Provider> => [
    {
        provide: BASE_PATH,
        useFactory: appConfig => openApiFactory(appConfig),
        deps: [AppConfig],
    },
    {
        provide: LOGIN_BASE_PATH,
        useFactory: appConfig => openApiFactory(appConfig, 'login-service'),
        deps: [AppConfig],
    },
    {
        provide: RETAILER_BASE_PATH,
        useFactory: appConfig => openApiFactory(appConfig, 'retailer-service'),
        deps: [AppConfig],
    },
    {
        provide: CONTACT_BASE_PATH,
        useFactory: appConfig => openApiFactory(appConfig, 'contact-service'),
        deps: [AppConfig],
    },
    {
        provide: CONTRACT_BASE_PATH,
        useFactory: appConfig => openApiFactory(appConfig, 'contract-service'),
        deps: [AppConfig],
    },
];
