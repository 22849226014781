import { Component, inject, OnInit, OnDestroy } from '@angular/core';
import {
    RetailerCalculateLeasingQuoteFormService,
} from '../../Services/forms/retailer-calculate-leasing-quote-form/retailer-calculate-leasing-quote-form.service';
import { RetailerQuoteDataService } from '../../Services/resources/retailer-quote-data/retailer-quote-data.service';
import { FeatureRetailerCalculationService } from './feature-retailer-calculation.service';

@Component({
    selector: 'l7-feature-retailer-calculation',
    templateUrl: './feature-retailer-calculation.component.html',
    styleUrl: './feature-retailer-calculation.component.scss',
    standalone: false,
    providers: [FeatureRetailerCalculationService, RetailerQuoteDataService, RetailerCalculateLeasingQuoteFormService],
})
export class FeatureRetailerCalculationComponent implements OnInit, OnDestroy {

    readonly presenter = inject(FeatureRetailerCalculationService);

    public ngOnInit(): void {
        this.presenter.initialize();
    }

    public ngOnDestroy(): void {
        this.presenter.dispose();
    }

}
