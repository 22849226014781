// #region Imports

import { ContactService } from '@abcfinlab/api/contact';
import { IRetailerQuoteResultDto } from '@abcfinlab/api/global';
import { EventHub, once } from '@abcfinlab/core';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { RetailerQuoteDataService } from '../../lib/Services/resources/retailer-quote-data/retailer-quote-data.service';
import { RetailerSettingsDataService } from '../../lib/Services/resources/retailer-settings-data/retailer-settings-data.service';
import { KnownEvents } from '../../Models/KnowEvents';

// #endregion

/**
 * The presenter of the {@link RetailerQuoteView} view.
 *
 * @internal
 */
@Injectable({ providedIn: 'root' })
export class RetailerQuoteViewPresenter {

    // #region Fields

    private readonly _eventHub: EventHub;
    private readonly _activatedRoute: ActivatedRoute;
    private readonly _contactService: ContactService;
    private readonly _currentStep: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private readonly _showBackNavigation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private readonly _quoteDetails: BehaviorSubject<IRetailerQuoteResultDto> = new BehaviorSubject<IRetailerQuoteResultDto>(null);
    private readonly _showSuccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _showNumber: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _isDraft: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _isSignedWithoutIdentification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _contractNo: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _title: BehaviorSubject<string> = new BehaviorSubject<string>('quote.retailers.calculation.title');
    private readonly _subTitle: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _activatedRouteSubscription: Subscription;
    private _eventNextStepSubscription: Subscription;
    private _eventQuoteDetailsSubscription: Subscription;
    private readonly _retailerQuoteDataService = inject(RetailerQuoteDataService);
    private readonly _isNewVersion = this._retailerQuoteDataService.isNewVersion;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `RetailerQuoteViewPresenter` class.
     *
     * @public
     */
    public constructor(activatedRoute: ActivatedRoute, eventHub: EventHub, contactService: ContactService) {
        this._activatedRoute = activatedRoute;
        this._eventHub = eventHub;
        this._contactService = contactService;
    }

    // #endregion

    // #region Properties
    /**
     * Returns the `currentStep` property.
     *
     * @public
     * @readonly
     */
    public get currentStep(): Observable<number> {
        return this._currentStep.asObservable();
    }

    /**
     * Returns the `isDraft` property.
     *
     * @public
     * @readonly
     */
    public get isDraft(): Observable<boolean> {
        return this._isDraft.asObservable();
    }

    /**
     * Returns the `showBackNavigation` property.
     *
     * @public
     * @readonly
     */
    public get showBackNavigation(): Observable<boolean> {
        return this._showBackNavigation.asObservable();
    }

    /**
     * Returns the `quoteDetails` property.
     *
     * @public
     * @readonly
     */
    public get quoteDetails(): Observable<IRetailerQuoteResultDto> {
        return this._quoteDetails.asObservable();
    }

    /**
     * Returns the `showSuccess` property.
     *
     * @public
     * @readonly
     */
    public get showSuccess(): Observable<boolean> {
        return this._showSuccess.asObservable();
    }

    /**
     * Returns the `showNumber` property.
     *
     * @public
     * @readonly
     */
    public get showNumber(): Observable<boolean> {
        return this._showNumber.asObservable();
    }

    /**
     * Returns the `contractNo` property.
     *
     * @public
     * @readonly
     */
    public get contractNo(): Observable<string> {
        return this._contractNo.asObservable();
    }

    /**
     * Returns the `title` property.
     *
     * @public
     * @readonly
     */
    public get title(): Observable<string> {
        return this._title.asObservable();
    }

    /**
     * Returns the `subTitle` property.
     *
     * @public
     * @readonly
     */
    public get subTitle(): Observable<string> {
        return this._subTitle.asObservable();
    }

    public get isSignedWithoutIdentification(): Observable<boolean> {
        return this._isSignedWithoutIdentification.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this._activatedRouteSubscription = combineLatest([this._activatedRoute.data])
            .subscribe(([data]) => {
                if (data.quoteDetails && !this._isNewVersion()) {
                    const creditCheckTaskStartable = data.quoteDetails.workflow.workflowSteps.find(task => task.stepType === 'CREDIT_CHECK').startable;
                    const identificationTaskStartable = data.quoteDetails.workflow.workflowSteps.find(task => task.stepType === 'IDENTIFICATION').startable;
                    const contractSignTaskStartable = data.quoteDetails.workflow.workflowSteps.find(task => task.stepType === 'CREATE_CONTRACT').startable;

                    const creditCheckTaskStatus = data.quoteDetails.workflow.workflowSteps.find(task => task.stepType === 'CREDIT_CHECK').status;
                    const identificationTaskStatus = data.quoteDetails.workflow.workflowSteps.find(task => task.stepType === 'IDENTIFICATION').status;
                    this._isSignedWithoutIdentification.next(identificationTaskStatus !== 'COMPLETED');
                    const contractSignTaskStatus = data.quoteDetails.workflow.workflowSteps.find(task => task.stepType === 'CREATE_CONTRACT').status;
                    const isCreateContract = data.quoteDetails.workflow.workflowSteps.find(task => task.stepType === 'CREATE_CONTRACT') ? true : false;
                    if (creditCheckTaskStartable || creditCheckTaskStatus === 'PENDING') {
                        this._currentStep.next(1);
                    } else if ((creditCheckTaskStatus === 'COMPLETED' || creditCheckTaskStatus === 'ERROR') && !identificationTaskStartable && !contractSignTaskStartable && contractSignTaskStatus !== 'PENDING') {
                        this._currentStep.next(2);
                        this._showNumber.next(true);
                    } else if (identificationTaskStartable || identificationTaskStatus === 'ERROR' || identificationTaskStatus === 'PENDING') {
                        this._currentStep.next(2);
                    } else if (contractSignTaskStartable || contractSignTaskStatus === 'ERROR' || contractSignTaskStatus === 'PENDING') {
                        this._currentStep.next(3);
                    } else if (contractSignTaskStatus === 'COMPLETED' && identificationTaskStatus === 'COMPLETED') {
                        this._currentStep.next(3);
                        this._showSuccess.next(true);
                    }

                    if (isCreateContract) {
                        this._currentStep.next(1);
                    }

                    this._showBackNavigation.next(false);
                }
                this._isDraft.next(data.quoteDetails?.workflow.workflowInfo.firstOpenType === 'CREFO_CONFIRMATION');

                if (data.quoteDetails) {
                    this._subTitle.next(`Angebotsnummer: ${data.quoteDetails.quote.name}`);
                    once(this._contactService.getById({ id: data.quoteDetails.quote.lesseeId }), lessee => this._title.next(lessee.name));
                }
            });
        this._eventNextStepSubscription = this._eventHub.getEvent<number>(KnownEvents.RETAILER_QUOTE_NEXT_STEP)
            .subscribe((nextStep) => {
                if (nextStep === 2 && this._currentStep.getValue() === 1) {
                    this._currentStep.next(2);
                }
                if (nextStep === 3 && this._currentStep.getValue() === 2) {
                    this._currentStep.next(3);
                }

                if (nextStep === 4) {
                    this._currentStep.next(3);
                    this._showSuccess.next(true);
                }
            });

        this._eventQuoteDetailsSubscription = this._eventHub.getEvent<IRetailerQuoteResultDto>(KnownEvents.RETAILER_QUOTE_DETAILS)
            .subscribe((quoteDetails) => {
                this._contractNo.next(quoteDetails.quote.contractId);
            });
    }

    /**
     * Called before the view will be destroyed..
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        this._activatedRouteSubscription.unsubscribe();
        this._eventNextStepSubscription.unsubscribe();
        this._eventQuoteDetailsSubscription.unsubscribe();
    }

    // #endregion

}
