import { IContactDto } from '@abcfinlab/api/contact';
import {
    IContractTypeDto,
    ISlbCalculationDto,
    ISlbGetResponseDto,
    IVersionedSlbGetResponseDto,
    SlbService,
} from '@abcfinlab/api/contract';
import {
    IAcknowledgementCalculationConfigurationDto,
    IBankAccountDto,
    IContractTypeDto as IContractTypeDtoGlobal,
    ICreditCheckDetailDto, ICreditCheckDto, IInsuranceTypeDto,
    IObjectConditionDto,
    ISaleAndLeaseBackCalculationDto,
    ISaleAndLeaseBackCodeDto,
    ISaleAndLeaseBackConfirmationTypeDto,
    ISaleAndLeaseBackDetailDto,
    SaleAndLeaseBackService,
    SettingsService,
} from '@abcfinlab/api/global';
import { Validators as CoreValidators, FormValidator, once, TranslationFacade } from '@abcfinlab/core';
import {
    BusyBoxService,
    MessageBoxButton,
    MessageBoxService,
    percentageValidator,
    ScrollService,
    ToastService,
} from '@abcfinlab/ui';
import { DatePipe, Location } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    TrackByFunction,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { BankAccountDTO } from '../../../../../apps/l7/src/app/models/BankAccountDTO.interface';
import {
    BankAccountCheckerComponent,
} from '../../../../legacy/gwg/src/lib/shared/bank-account-checker/bank-account-checker.component';

export interface ISlbDetailsInitialData {
    lessee: IContactDto;
    creditRating: ICreditCheckDetailDto | ICreditCheckDto;
    saleAndLeaseBackQuoteDetail: ISaleAndLeaseBackDetailDto;
    saleAndLeaseBackDetail: IVersionedSlbGetResponseDto;
}

@UntilDestroy()
@Component({
    selector: 'l7-slb-details-form',
    templateUrl: './slb-details-form.component.html',
    styleUrls: ['./slb-details-form.component.scss'],
    standalone: false,
})
export class SlbDetailsFormComponent implements AfterViewInit {

    private readonly _activatedRoute: ActivatedRoute;
    private readonly _location: Location;
    private readonly _busyBox: BusyBoxService;
    private readonly _toastService: ToastService;
    private readonly _saleAndLeaseBackService: SaleAndLeaseBackService;
    private readonly _slbService: SlbService;
    private readonly _settingsService: SettingsService;
    private readonly _datePipe: DatePipe;
    private readonly _formValidator: FormValidator;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _scrollService: ScrollService;
    private readonly _yearTrackByIndex: TrackByFunction<number>;
    private readonly _years: BehaviorSubject<Array<number>>;
    private readonly _labelSerialNumber: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _slbCode: BehaviorSubject<ISaleAndLeaseBackCodeDto> = new BehaviorSubject<ISaleAndLeaseBackCodeDto>(null);
    private readonly _objectCondition: BehaviorSubject<IObjectConditionDto> = new BehaviorSubject<IObjectConditionDto>(null);
    private readonly _contractType: BehaviorSubject<IContractTypeDtoGlobal | IContractTypeDto> = new BehaviorSubject<IContractTypeDtoGlobal | IContractTypeDto>(null);

    private readonly _isSlbObjectKeptByLessee: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private readonly _isSlbOriginalInvoiceAttached: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _isObjectValueChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _minBillingDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);

    private readonly _bankAccount: BehaviorSubject<IBankAccountDto> = new BehaviorSubject<IBankAccountDto>(null);

    private readonly _calculationConfiguration: BehaviorSubject<IAcknowledgementCalculationConfigurationDto> = new BehaviorSubject<IAcknowledgementCalculationConfigurationDto>(null);
    private _quoteId: string;

    private _kuevComponentData: ISlbGetResponseDto;
    private _kuevComponentDataContractType: IContractTypeDtoGlobal | IContractTypeDto;

    private readonly _allowedLeasingStartDates: BehaviorSubject<Array<Date>> = new BehaviorSubject<Array<Date>>(null);

    private _bankAccountValueToSave: IBankAccountDto = null;
    private readonly start: Date = new Date();
    // TODO move to constant definition file
    public readonly maxDate: Date = new Date(2037, 11, 31);

    public maxDateInvoice: Date = new Date(this.start.setMonth(this.start.getMonth() + 6));
    public purchasePriceSubject: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
    public calenderIcon: string = 'Calendar_Dates';
    @Input() set kuevComponentData(value: ISlbGetResponseDto) {
        if (value) {
            this._kuevComponentData = value;
            this._objectCondition.next(value.objectCondition);
            const bankAccount = value.inactiveSlbResponse ? value.inactiveSlbResponse?.bankAccountDto : value.activeSlbResponse?.bankAccountDto;
            this._bankAccount.next(bankAccount as IBankAccountDto);
            this._slbCode.next(value.code);
        }
    }

    public get kuevComponentData(): ISlbGetResponseDto {
        return this._kuevComponentData;
    }

    @Input() kuevComponentDataQuoteId: string;

    @Input() kuevComponentDataContractId: string;

    @Input() set kuevComponentDataContractType(value: IContractTypeDtoGlobal | IContractTypeDto) {
        this._kuevComponentDataContractType = value;
        this._contractType.next(value);
    }

    public get kuevComponentDataContractType(): IContractTypeDtoGlobal | IContractTypeDto {
        return this._kuevComponentDataContractType;
    }

    @Input() creditCheck: ICreditCheckDto;

    @Input() originalPurchasePrice: number;

    @Input() insuranceType: IInsuranceTypeDto;

    @Output() slbUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    public lessee: IContactDto;
    public creditRating: ICreditCheckDetailDto | ICreditCheckDto;
    public isIbanValid: boolean;

    public slbDetailsForm: UntypedFormGroup;

    public objectDetailsForm: UntypedFormGroup;

    public objectValueChangeForm: UntypedFormGroup;

    public slbCalculation: ISaleAndLeaseBackCalculationDto | ISlbCalculationDto;

    @ViewChild('acknowledgment_date') dateAcknowledgment: ElementRef;
    @ViewChild('leasing_start_date') dateLeasingStart: ElementRef;
    @ViewChild('billing_date') dateBilling: ElementRef;

    @ViewChild('bankAccountCheckerComponent') bankAccount: BankAccountCheckerComponent;

    constructor(private readonly _fb: UntypedFormBuilder, busyBox: BusyBoxService, toastService: ToastService, messageBox: MessageBoxService,
        private readonly _translationFacade: TranslationFacade, activatedRoute: ActivatedRoute, location: Location, datePipe: DatePipe,
        saleAndLeaseBackService: SaleAndLeaseBackService, formValidator: FormValidator, slbService: SlbService, settingsService: SettingsService,
        scrollService: ScrollService) {
        this._activatedRoute = activatedRoute;
        this._formValidator = formValidator;
        this._slbService = slbService;
        this._settingsService = settingsService;
        this._location = location;
        this._saleAndLeaseBackService = saleAndLeaseBackService;
        this._datePipe = datePipe;
        this._busyBox = busyBox;
        this._toastService = toastService;
        this._messageBoxService = messageBox;
        this._scrollService = scrollService;

        this.slbDetailsForm = this._fb.group({
            purchasePrice: [null],
            acknowledgementDate: [null],
            leasingStart: [{ value: null, disabled: true }],
            billingNumber: [null],
            billingDate: [null],
            taxNumberLessee: [null],
            confirmationTypes: [[]],
            yearOfConstruction: [null],
            chassisNumber: [false],
            objectSerialNumber: [null, []],
        });

        this.objectDetailsForm = this._fb.group({
            totalLeasingValueNet: [{ value: null, disabled: true }, [Validators.required]],
            vatPercentage: [{ value: null, disabled: true }],
            vatAmount: [{ value: null, disabled: true }],
            totalLeasingValueGross: [{ value: null, disabled: true }],
            objectCondition: [{ value: null, disabled: true }],
        });
        this.objectValueChangeForm = this._fb.group({
            firstInstalment: [{ value: null, disabled: true }],
            lastInstalment: [{ value: null, disabled: true }],
            instalment: [{ value: null, disabled: true }],
            downPayment: [{ value: null, disabled: true }],
            residualValue: [{ value: null, disabled: true }],
            insuranceValue: [{ value: null, disabled: true }],
        });
        this._activatedRoute.data.pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data.slbDetails) {
                    this._objectCondition.next(data.slbDetails[0].saleAndLeaseBackCalculationDto.objectCondition);
                    this._bankAccount.next(data.slbDetails[0].bankAccountDto as IBankAccountDto);
                    this._slbCode.next(data.slbDetails[0].saleAndLeaseBackDto.code);
                    this._contractType.next(data.slbDetails[3].contract_type);
                    this.initializeForms({ lessee: data.slbDetails[1], creditRating: data.slbDetails[2], saleAndLeaseBackQuoteDetail: data.slbDetails[0], saleAndLeaseBackDetail: null });
                }
            });

        this._activatedRoute.params.pipe(untilDestroyed(this))
            .subscribe(params => this._quoteId = params.quote_id);

        // year range
        this._yearTrackByIndex = (index: number): number => index;
        const result = new Array<number>();
        const currentYear = new Date(Date.now()).getFullYear();
        let offset = (currentYear - 1900);
        while (offset--) {
            result.push(currentYear - offset);
        }
        this._years = new BehaviorSubject(result.reverse());

        this.purchasePriceSubject.pipe(
            distinctUntilChanged(),
            debounceTime(500),
            untilDestroyed(this),
            mergeMap(search => of(search).pipe(
                delay(500),
            )),
        ).subscribe((x) => {
            const ignoreKeysList = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', ',', '.'];
            if (ignoreKeysList.indexOf(x.key) < 0) {
                this.onPurchasePriceChanged();
            }
        });
        this._settingsService.getCalculationSettingsDto().pipe(untilDestroyed(this)).subscribe((x) => {
            this._calculationConfiguration.next(x.leasingQuoteCalculationProperties.inhouse.acknowledgement);
            if (this.kuevComponentData) {
                const salesAndLeaseBackDetail = this.kuevComponentData.inactiveSlbResponse ? this.kuevComponentData.inactiveSlbResponse : this.kuevComponentData.activeSlbResponse;
                this.initializeForms({ lessee: null, creditRating: this.creditCheck, saleAndLeaseBackDetail: salesAndLeaseBackDetail, saleAndLeaseBackQuoteDetail: null });
            }
        });
    }

    /**
     * @public
     * @readonly
     */
    public get years(): Array<number> {
        return this._years.value;
    }

    /**
     * Returns the _yearTrackByIndex` property.
     *
     * @public
     * @readonly
     */
    public get yearTrackByIndex(): TrackByFunction<number> {
        return this._yearTrackByIndex;
    }

    /**
     * Returns the labelSerialNumber property.
     *
     * @public
     * @readonly
     */
    public get labelSerialNumber(): Observable<string> {
        return this._labelSerialNumber.asObservable();
    }

    /**
     * Returns the isSlbObjectKeptByLessee property.
     *
     * @public
     * @readonly
     */
    public get isSlbObjectKeptByLessee(): Observable<boolean> {
        return this._isSlbObjectKeptByLessee.asObservable();
    }

    /**
     * Returns the isSlbOriginalInvoiceAttached property.
     *
     * @public
     * @readonly
     */
    public get isSlbOriginalInvoiceAttached(): Observable<boolean> {
        return this._isSlbOriginalInvoiceAttached.asObservable();
    }

    /**
     * Returns the slbCode property.
     *
     * @public
     * @readonly
     */
    public get slbCode(): Observable<ISaleAndLeaseBackCodeDto> {
        return this._slbCode.asObservable();
    }

    /**
     * Returns the contractType property.
     *
     * @public
     * @readonly
     */
    public get contractType(): Observable<IContractTypeDtoGlobal | IContractTypeDto> {
        return this._contractType.asObservable();
    }

    /**
     * Returns the allowedLeasingStartDates property.
     *
     * @public
     * @readonly
     */
    public get allowedLeasingStartDates(): Observable<Array<Date>> {
        return this._allowedLeasingStartDates.asObservable();
    }

    public get isObjectValueChanged(): Observable<boolean> {
        return this._isObjectValueChanged.asObservable();
    }

    public get minBillingDate(): Observable<Date> {
        return this._minBillingDate.asObservable();
    }

    public get condition_to_text(): string {
        return this._objectCondition.getValue() === IObjectConditionDto.New ? 'condition_new_label' : 'condition_used_label';
    }

    ngAfterViewInit() {
        if ((this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.Unechtln || this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.UnechtlnV2 || this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.Unechtlnmk) && this._bankAccount.getValue()) {
            this.bankAccount.internalForm.controls.iban.patchValue(this._bankAccount.getValue().iban);
            this.bankAccount.internalForm.controls.bank_account_name.patchValue(this._bankAccount.getValue().bank_account_name);
        } else if ((this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.Unechtln || this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.UnechtlnV2 || this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.Unechtlnmk) && !this._bankAccount.getValue()) {
            this.bankAccount.internalForm.controls.iban.patchValue(null);
            this.bankAccount.internalForm.controls.bank_account_name.patchValue(null);
        }
    }

    handleEmptyString(evt) {
        this.slbDetailsForm.get(evt.formControlName).patchValue(null);
    }

    bankAccountChanged(evt: BankAccountDTO) {
        this._bankAccountValueToSave = evt as IBankAccountDto;
    }

    onGoBack() {
        once(this._messageBoxService.show('Vorgang abbrechen?', 'Möchten Sie den Vorgang wirklich abbrechen?', MessageBoxButton.OKCancel, {
            labels: {
                ok: this._translationFacade.translate('global.yes'),
                cancel: this._translationFacade.instant('global.no'),
            },
        }),
        (res) => {
            if (res === 1) {
                this._location.back();
            }
        });
    }

    public onAcknowledgementDateChanged(evt: Date): void {
        if (evt) {
            // calculate dates
            const firstDate = new Date(evt.getFullYear(), evt.getMonth(), 1);
            const secondDate = new Date(evt.getFullYear(), evt.getMonth() + 1, 1);
            this._allowedLeasingStartDates.next([firstDate, secondDate]);
            this.slbDetailsForm.controls.leasingStart.enable();
        } else {
            this._allowedLeasingStartDates.next(null);
            this.slbDetailsForm.controls.leasingStart.disable();
        }
    }

    public onSave(): void {
        if (this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.Unechtln || this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.UnechtlnV2 || this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.Unechtlnmk) {
            this.bankAccount.checkForErrors();
        }
        if (this.objectDetailsForm.invalid) {
            this._scrollService.scrollToElementById('purchasePrice');
            return;
        }
        this._formValidator.validate(this.slbDetailsForm);

        // we only check for Valid iBan form if type was Unechtln or UnechtlnV2
        const isCheckForIbanPassed = ((this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.Unechtln || this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.UnechtlnV2 || this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.Unechtlnmk) && this.bankAccount.internalForm.valid) || (this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.Unechtli || this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.UnechtliV2 || this._slbCode.getValue() === ISaleAndLeaseBackCodeDto.Unechtlimk);
        if (this.kuevComponentData && this.slbDetailsForm.valid && isCheckForIbanPassed) {
            const body = {
                ...this.slbDetailsForm.getRawValue(),
                ...{ purchasePrice: this.objectDetailsForm.controls.totalLeasingValueNet.value },
                ...{ bankAccountDto: this._bankAccountValueToSave },
            };

            once(this._busyBox.show(undefined, undefined,
                this._slbService.putSlb({
                    contractId: this.kuevComponentDataContractId,
                    body,
                }),
            ), (res) => {
                this._toastService.show('Angaben wurden erfolgreich gespeichert', 'success', 'long');
                this.slbUpdated.emit(true);
            }, error => this._toastService.show('Leider konnten die Angaben nicht gespeichert werden. Bitte versuchen Sie es erneut.', 'danger'));
        }
    }

    public onSaveInQuote(): void {
        this._formValidator.validate(this.slbDetailsForm);
        if (!this.slbDetailsForm.valid) {
            return;
        }
        const body = {
            ...{
                saleAndLeaseBackDto: {
                    ...this.slbDetailsForm.getRawValue(),
                    ...{ confirmations: this.slbDetailsForm.controls.confirmationTypes.getRawValue() },
                },
            },
            ...{ bankAccountDto: this._bankAccountValueToSave },
        };
        once(this._busyBox.show(undefined, undefined,
            this._saleAndLeaseBackService.editSaleAndLeaseBackDetails({
                leasingQuoteId: this._quoteId,
                body,
            }),
        ), (res) => {
            this._toastService.show('Angaben wurden erfolgreich gespeichert', 'success', 'long');
            this._location.back();
        }, (error) => {
            this._toastService.show('Leider konnten die Angaben nicht gespeichert werden. Bitte versuchen Sie es erneut.', 'danger');
        });
    }

    public changeSerialNumberLabel(evt: MatRadioChange): void {
        const label = evt.value ? 'contract_management.takeover_confirmation.object.chassis_number' : 'contract_management.takeover_confirmation.object.serial_number';
        this._labelSerialNumber.next(this._translationFacade.instant(label));
        if (evt.value) {
            this.slbDetailsForm.controls.objectSerialNumber.setValidators([Validators.required, CoreValidators.serialNumber(), Validators.minLength(17), Validators.maxLength(17)]);
        } else {
            this.slbDetailsForm.controls.objectSerialNumber.setValidators([Validators.required]);
        }
        this.slbDetailsForm.controls.objectSerialNumber.updateValueAndValidity();
    }

    public confirmationChanged(evt: MatCheckboxChange): void {
        const confirmations = this.slbDetailsForm.get('confirmationTypes').getRawValue().length ? this.slbDetailsForm.get('confirmationTypes').getRawValue() : new Array<ISaleAndLeaseBackConfirmationTypeDto>();
        let result;
        if (evt.checked) {
            confirmations.push(evt.source.name);
            result = confirmations;
        } else {
            result = confirmations.filter(c => c !== evt.source.name);
        }

        this.slbDetailsForm.get('confirmationTypes').patchValue(result);
    }

    private initializeForms(slbDetails: ISlbDetailsInitialData): void {
        if (this.kuevComponentData) {
            Object.keys(this.slbDetailsForm.controls)
                .forEach((key) => {
                    this.slbDetailsForm.controls[key].setValidators([Validators.required]);
                });
            this.slbDetailsForm.controls.purchasePrice.removeValidators([Validators.required]);
            this.objectDetailsForm.controls.totalLeasingValueNet.enable();
            this.objectDetailsForm.controls.totalLeasingValueNet.setValidators([
                Validators.required,
                Validators.min(this._calculationConfiguration.getValue().minObjectValueChange),
                Validators.max(this._calculationConfiguration.getValue().maxObjectValueChange),
                percentageValidator(this._calculationConfiguration.getValue().maxObjectValueChangePercent, this.originalPurchasePrice, 2),
            ]);
        }
        if (slbDetails.creditRating?.timestamp) {
            this._minBillingDate.next(this._setMinBillingDate(slbDetails.creditRating.timestamp));
        } else {
            this.slbDetailsForm.controls.billingDate.disable();
        }
        this.slbDetailsForm.controls.billingDate.addValidators(CoreValidators.minDate(this._minBillingDate.getValue()));

        const confirmations = this.kuevComponentData ? slbDetails.saleAndLeaseBackDetail.confirmationTypes : slbDetails.saleAndLeaseBackQuoteDetail.saleAndLeaseBackDto.confirmations;
        this.lessee = slbDetails.lessee;
        this.creditRating = slbDetails.creditRating;
        const details = this.kuevComponentData ? slbDetails.saleAndLeaseBackDetail : slbDetails.saleAndLeaseBackQuoteDetail.saleAndLeaseBackDto;
        if (details.acknowledgementDate) {
            this.onAcknowledgementDateChanged(new Date(details.acknowledgementDate));
        }
        this.slbCalculation = this.kuevComponentData ? slbDetails.saleAndLeaseBackDetail.calculation : slbDetails.saleAndLeaseBackQuoteDetail.saleAndLeaseBackCalculationDto;
        this.objectDetailsForm.patchValue(this.slbCalculation);
        this.objectDetailsForm.controls.objectCondition.patchValue(this._objectCondition.getValue());

        this.slbDetailsForm.patchValue(details);
        this.slbDetailsForm.get('confirmationTypes').patchValue(confirmations);

        const isChassisNumber: boolean = this.kuevComponentData ? slbDetails.saleAndLeaseBackDetail.chassisNumber : slbDetails.saleAndLeaseBackQuoteDetail.saleAndLeaseBackDto.chassisNumber;
        if (isChassisNumber) {
            this.slbDetailsForm.controls.objectSerialNumber.setValidators([CoreValidators.serialNumber(), Validators.minLength(17), Validators.maxLength(17)]);
            this.slbDetailsForm.controls.objectSerialNumber.updateValueAndValidity();
        } else {
            this.slbDetailsForm.controls.chassisNumber.patchValue(false);
        }

        this._isSlbObjectKeptByLessee.next(confirmations.filter(c => c === ISaleAndLeaseBackConfirmationTypeDto.SlbObjectKeptByLessee).length ? true : false);
        this._isSlbOriginalInvoiceAttached.next(confirmations.filter(c => c === ISaleAndLeaseBackConfirmationTypeDto.SlbOriginalInvoiceAttached).length ? true : false);
        this._labelSerialNumber.next(this._translationFacade.instant('contract_management.takeover_confirmation.object.serial_number'));
        if (this._contractType.getValue() === IContractTypeDtoGlobal.Mkn) {
            const noVatTranslation = this._translationFacade.instant('global.omitted');
            this.objectDetailsForm.controls.vatPercentage.patchValue(noVatTranslation);
            this.objectDetailsForm.controls.vatAmount.patchValue(noVatTranslation);
        }
    }

    private onPurchasePriceChanged(): void {
        const oldVal = this.kuevComponentData.inactiveSlbResponse ? this.kuevComponentData.inactiveSlbResponse.purchasePrice : this.kuevComponentData.activeSlbResponse.purchasePrice;
        const newVal = this.objectDetailsForm.controls.totalLeasingValueNet.value;
        if (oldVal !== newVal) {
            once(this._slbService.calculateSlbObjectValueChange({ contractId: this.kuevComponentDataContractId, objectValue: newVal }), (x) => {
                this.objectValueChangeForm.controls.instalment.patchValue(x.instalment);
                this.objectValueChangeForm.controls.firstInstalment.patchValue(x.firstInstalment);
                this.objectValueChangeForm.controls.lastInstalment.patchValue(x.lastInstalment);
                this.objectValueChangeForm.controls.downPayment.patchValue(x.downPayment);
                this.objectValueChangeForm.controls.residualValue.patchValue(x.residualValue);
                this.objectValueChangeForm.controls.insuranceValue.patchValue(x.insuranceValue);
                if (this._contractType.getValue() !== IContractTypeDto.Mkn) {
                    this.objectDetailsForm.controls.vatPercentage.patchValue(x.vatPercentage);
                    this.objectDetailsForm.controls.vatAmount.patchValue(x.vatAmount);
                }
                this.objectDetailsForm.controls.totalLeasingValueGross.patchValue(x.totalLeasingValueGross);
                this._isObjectValueChanged.next(true);
            });
        } else {
            this._isObjectValueChanged.next(false);
        }
    }

    private _setMinBillingDate(creditCheckTimestamp: string): Date {
        const approvalDate = new Date(creditCheckTimestamp);
        return new Date(approvalDate.setMonth(approvalDate.getMonth() - 6));
    }

    private convertDate(value: string): Date {
        const parts = value.split('.');
        return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
    }

    protected readonly IInsuranceTypeDto = IInsuranceTypeDto;

}
