// #region Imports

import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    viewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { EditRetailerViewPresenter } from './EditRetailerViewPresenter';

// #endregion

/**
 * The `EditRetailerView` view.
 *
 * @public
 */
@Component({
    selector: 'edit-retailer-view',
    templateUrl: './EditRetailerView.html',
    styleUrls: ['./EditRetailerView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        EditRetailerViewPresenter,
    ],
    standalone: false,
})
export class EditRetailerView implements OnInit, OnDestroy {

    matPaginator = viewChild<MatPaginator>('paginator');

    // #region Fields

    private readonly _presenter: EditRetailerViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `EditRetailerView` class.
     *
     * @public
     */
    public constructor(presenter: EditRetailerViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `EditRetailerView`
     *
     * @public
     * @readonly
     */
    public get presenter(): EditRetailerViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    public onTermChanged(value: string): void {
        this._presenter.onTermChanged(value);
        this.matPaginator()?.firstPage();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
