/* libs/quote/src/Views/retailer-calculation/RetailerCalculationView.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
::ng-deep .ng-animating div mat-accordion mat-expansion-panel mat-expansion-panel-header {
  height: 48px;
}
::ng-deep .ng-animating div mat-accordion mat-expansion-panel div.mat-expansion-panel-content {
  height: 0px;
  visibility: hidden;
}
:host mat-stepper.stepper-without-header ::ng-deep .mat-horizontal-stepper-header-container {
  display: none !important;
}
:host mat-stepper.stepper-without-header ::ng-deep .mat-vertical-stepper-header,
:host mat-stepper.stepper-without-header ::ng-deep .mat-stepper-vertical-line::before {
  display: none !important;
}
:host mat-stepper.stepper-without-header ::ng-deep .mat-vertical-content-container .mat-vertical-content {
  padding: 0;
}
:host .hide {
  display: none !important;
}
:host .alignBottom {
  margin-bottom: 19px;
}
:host .mat-stepper-horizontal {
  background: none;
}
:host .rate-card {
  flex-shrink: 1;
  height: 146px;
  max-width: 146px;
  min-width: 115px;
}
:host .rate-card l7-text-block:nth-of-type(1) ::ng-deep {
  margin-top: 10px;
}
:host .rate-card l7-text-block:nth-of-type(1) ::ng-deep p {
  font-weight: bold;
}
:host .rate-card l7-text-block {
  margin-bottom: 19px;
}
/*# sourceMappingURL=RetailerCalculationView.css.map */
