// #region Imports

import { IObjectGroupDto, ObjectgroupService } from '@abcfinlab/api/global';
import { FinanceAdminService, IClientDto, ICreateWithUserRequestDto, IHandlingFeeOptionDto } from '@abcfinlab/api/retailer';
import { ClientControllerService } from '@abcfinlab/api-retailer';
import { IUserGroupRetailerDto } from '@abcfinlab/auth';
import { ControlsOf, Validators as CoreValidators, CountryCode, DeepRequired, DefaultFrom, FormChangesObserver, FormValidator, once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { inject, Injectable, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { FinanceAdminValidators } from '../../Validators/Validators';

// #endregion

const DEFAULT_FORM_CONTROL_VALUE: DefaultFrom<ICreateWithUserRequestDto> = {
    contact: {
        fieldService: {
            email: null,
            familyName: null,
            givenName: null,
            phoneNumber: null,
        },
        officeService: {
            email: null,
            familyName: null,
            givenName: null,
            phoneNumber: null,
        },
    },
    retailer: {
        contactId: null,
        city: null,
        client: null,
        contactNumber: null,
        factor: null,
        id: null,
        name: null,
        partnerNumber: null,
        postalCode: null,
        streetAndHousenumber: null,
    },
    retailerConfig: {
        active: null,
        adjustableProvision: null,
        contractTypes: null,
        handlingFeeOption: null,
        objectGroupCodes: null,
        preRent: null,
        provision: null,
    },
    user: {
        active: null,
        email: null,
        familyName: null,
        givenName: null,
        groups: null,
        mobileNumber: null,
        phoneNumber: null,
    },
};

/**
 * The presenter of the {@link CreateRetailerView} view.
 *
 * @internal
 */
@Injectable()
export class CreateRetailerViewPresenter {

    // #region Fields

    private readonly _financeAdminService = inject(FinanceAdminService);
    private readonly _financeAdminValidatorService = inject(FinanceAdminValidators);
    private readonly _clientService = inject(ClientControllerService);
    private readonly _busyBoxService = inject(BusyBoxService);
    private readonly _translationFacade = inject(TranslationFacade);
    private readonly _objectgroupService = inject(ObjectgroupService);
    private readonly _formValidator = inject(FormValidator);
    private readonly _formChangesObserver = inject(FormChangesObserver);
    private readonly _toastService = inject(ToastService);
    private readonly _messageBoxService = inject(MessageBoxService);
    private readonly _dialogRef: MatDialogRef<any>;
    private readonly _objectGroupsSubject = new BehaviorSubject<Array<IObjectGroupDto>>([]);
    private readonly _factorsSubject = new BehaviorSubject<Array<string>>([]);
    private readonly _clientsSubject = new BehaviorSubject<Array<IClientDto>>([IClientDto.Pag, IClientDto.Fhl]);
    private readonly _availableContractTypes = signal<Record<string, string> | undefined>(undefined);
    readonly contractTypes = this._availableContractTypes.asReadonly();
    private readonly _clientHandlingFeeSubject = new BehaviorSubject<number | undefined>(undefined);
    private readonly _allowedTopLevelDomainsSubject = new BehaviorSubject(new Array<string>());
    private readonly _hasChangesSubject = new ReplaySubject<boolean>(1);
    private readonly _objectGroupsTerm = new BehaviorSubject<string>('');
    private readonly _form: FormGroup<ControlsOf<DeepRequired<ICreateWithUserRequestDto>>>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `CreateRetailerViewPresenter` class.
     *
     * @public
     */
    public constructor(dialogRef: MatDialogRef<any>) {
        this._dialogRef = dialogRef;

        this._form = new FormGroup<ControlsOf<DeepRequired<ICreateWithUserRequestDto>>>({
            retailer: new FormGroup({
                contactId: new FormControl(''),
                id: new FormControl(''),
                city: new FormControl('', Validators.required),
                client: new FormControl<IClientDto>(null, Validators.required),
                contactNumber: new FormControl('', Validators.required, this._financeAdminValidatorService.contactNumberExistsAsync().bind(this._financeAdminValidatorService)),
                factor: new FormControl<string>(null, Validators.required),
                name: new FormControl('', Validators.required),
                partnerNumber: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10)], this._financeAdminValidatorService.partnerNumberExistsAsync().bind(this._financeAdminValidatorService)),
                postalCode: new FormControl('', [Validators.required, CoreValidators.postalCode(CountryCode.DE)]),
                streetAndHousenumber: new FormControl('', Validators.required),
            }),
            retailerConfig: new FormGroup({
                active: new FormControl<boolean>(true),
                adjustableProvision: new FormControl<boolean>(false),
                contractTypes: new FormControl<any>([''], Validators.required),
                handlingFeeOption: new FormControl<IHandlingFeeOptionDto>(IHandlingFeeOptionDto.AlwaysCharge, Validators.required),
                objectGroupCodes: new FormControl(new Array<string>(), Validators.required),
                preRent: new FormControl<boolean>(true),
                provision: new FormControl<number>(null, [Validators.required, Validators.min(0), Validators.max(5)]),
            }),
            user: new FormGroup({
                email: new FormControl('', [Validators.required, CoreValidators.email()], this._financeAdminValidatorService.emailInvalidAsync().bind(this._financeAdminValidatorService)),
                familyName: new FormControl('', Validators.required),
                givenName: new FormControl('', Validators.required),
                groups: new FormControl<Array<any>>([IUserGroupRetailerDto.RetailerAdmin], Validators.required),
                mobileNumber: new FormControl('', [CoreValidators.phoneNumber()]),
                phoneNumber: new FormControl('', [CoreValidators.phoneNumber()]),
                active: new FormControl<boolean>(true),
            }),
            contact: new FormGroup({
                officeService: new FormGroup({
                    familyName: new FormControl('', Validators.required),
                    givenName: new FormControl('', Validators.required),
                    email: new FormControl('', [Validators.required, CoreValidators.email()], CoreValidators.emailEndsWithDomainAsync(this._financeAdminService.getAllowedDomains())),
                    phoneNumber: new FormControl('', [Validators.required, CoreValidators.phoneNumber()]),
                }),
                fieldService: new FormGroup({
                    familyName: new FormControl('', Validators.required),
                    givenName: new FormControl('', Validators.required),
                    email: new FormControl('', [Validators.required, CoreValidators.email()], CoreValidators.emailEndsWithDomainAsync(this._financeAdminService.getAllowedDomains())),
                    phoneNumber: new FormControl('', [Validators.required, CoreValidators.phoneNumber()]),
                }),
            }),
        });
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `userGroup` property.
     * A Static helper property to bind enums in the view
     *
     * @public
     * @readonly
     */
    public get userGroup(): typeof IUserGroupRetailerDto {
        return IUserGroupRetailerDto;
    }

    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): FormGroup<ControlsOf<DeepRequired<ICreateWithUserRequestDto>>> {
        return this._form;
    }

    /**
     * Returns the `hasChanges` property.
     *
     * @public
     * @readonly
     */
    public get hasChanges(): Observable<boolean> {
        return this._hasChangesSubject.asObservable();
    }

    /**
     * Returns the `allowedTopLevelDomains` property.
     *
     * @public
     * @readonly
     */
    public get allowedTopLevelDomains(): Observable<Array<string>> {
        return this._allowedTopLevelDomainsSubject.asObservable();
    }

    /**
     * Returns the `objectGroups` property.
     *
     * @public
     * @readonly
     */
    public get objectGroups(): Observable<Array<IObjectGroupDto>> {
        return this._objectGroupsSubject.asObservable().pipe(
            withLatestFrom(this._objectGroupsTerm.asObservable()),

            map(([values, term]) => {
                return values.filter((x) => {
                    let hasResult = false;
                    // filter out the selected ones...
                    hasResult = !this._form.controls.retailerConfig.controls.objectGroupCodes.value.includes(x.code.toString());

                    if (hasResult && term) {
                        // filter by term in input
                        const key = `${x.code}: ${x.name}`;
                        hasResult = key.toLowerCase().includes(term.toLowerCase());
                    }

                    return hasResult;
                });
            }),
        );
    }

    /**
     * Returns the `clients` property.
     *
     * @public
     * @readonly
     */
    public get clients(): Observable<Array<IClientDto>> {
        return this._clientsSubject.asObservable();
    }

    /**
     * Returns the `factors` property.
     *
     * @public
     * @readonly
     */
    public get factors(): Observable<Array<string>> {
        return this._factorsSubject.asObservable();
    }

    /**
     * Returns the `clientHandlingFee` property.
     *
     * @public
     * @readonly
     */
    public get clientHandlingFee(): Observable<number | undefined> {
        return this._clientHandlingFeeSubject.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        once(this._objectgroupService.getObjectgroups(), (x) => {
            this._objectGroupsSubject.next(x);
        });

        once(this._financeAdminService.getAllowedDomains(), (x) => {
            this._allowedTopLevelDomainsSubject.next(x);
        });

        // this._formChangesObserver.observe(this._form, DEFAULT_FORM_CONTROL_VALUE);
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        //
    }

    /**
     * @internal
     */
    public onFilterObjectGroups(value: string): void {
        this._objectGroupsTerm.next(value);
    }

    /**
     * @internal
     */
    public getObjectGroupFromCode(code: string): IObjectGroupDto | null {
        return this._objectGroupsSubject.value.find(y => y.code === parseInt(code)) ?? null;
    }

    /**
     * @internal
     */
    public onObjectGroupSelected(value: IObjectGroupDto, autocompleteTrigger: MatAutocompleteTrigger, input: HTMLInputElement): void {
        this._form.controls.retailerConfig.controls.objectGroupCodes.value.push(value.code.toString());
        this._form.controls.retailerConfig.controls.objectGroupCodes.updateValueAndValidity();

        // hack to reopen the auto complete popup
        setTimeout(() => {
            autocompleteTrigger.openPanel();
        }, 100);

        // reset the input
        input.value = '';
        this.onFilterObjectGroups('');
    }

    /**
     * @internal
     */
    public onObjectGroupRemove(value: IObjectGroupDto | null): void {
        if (value) {
            const index = this._form.controls.retailerConfig.controls.objectGroupCodes.value.indexOf(value.code.toString());
            this._form.controls.retailerConfig.controls.objectGroupCodes.value.splice(index, 1);
            this._form.controls.retailerConfig.controls.objectGroupCodes.updateValueAndValidity();
        }
    }

    /**
     * @internal
     */
    public onClientChanged(): void {
        const client = this._form.controls.retailer.controls.client?.value;

        if (client) {
            once(this._busyBoxService.show('', this._translationFacade.translate('global.busy'), this._clientService.getClientInformation({ clientId: client })), (x) => {
                this._clientHandlingFeeSubject.next(x.handlingFee);
                this._availableContractTypes.set(x.availableContractTypes);
                this._factorsSubject.next(x.factors ?? []);
                this._form.controls.retailer.controls.factor?.reset();
            });
        }
    }

    /**
     * @internal
     */
    public onSubmit(cancel: boolean): void {
        if (cancel) {
            this._messageBoxService.show('Vorgang abbrechen', 'Sind Sie sicher, dass Sie den Vorgang abbrechen wollen? Ihre Angaben werden nicht gespeichert.', MessageBoxButton.YesNo, {
                labels: {
                    yes: this._translationFacade.translate('global.yes'),
                    no: this._translationFacade.translate('global.no'),
                },
            }).subscribe((result) => {
                if (result === MessageBoxResult.Yes) {
                    this._dialogRef.close(false);
                }
            });

            return;
        }

        const errors = this._formValidator
            .validate(this._form)
            .errors(this._form);

        // ignore user.email emailInvaid error because it is only a warning.
        if ((errors.length - (errors.some(x => x.name === 'user.email' && x.errors.emailInvaid) ? 1 : 0)) > 0) {
            this._toastService.show(this._translationFacade.translate('administration.retailers.create.toast.form.error'), 'danger');
        } else {
            once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
                this._financeAdminService.createWithUser({
                    body: this._form.getRawValue(),
                }),
            ), () => {
                this._toastService.show(this._translationFacade.translate('administration.retailers.create.toast.success'), 'success');
                this._dialogRef.close(true);
            }, (error) => {
                this._toastService.show(this._translationFacade.translate('administration.retailers.create.toast.error'), 'danger');
                throwError(error);
            });
        }
    }

    // #endregion

}
