import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WizardComponent } from '../../../../ui/src/Index';
import {
    RetailerCalculateLeasingQuoteFormService,
} from '../../lib/Services/forms/retailer-calculate-leasing-quote-form/retailer-calculate-leasing-quote-form.service';
import { RetailerCalculationViewPresenter } from '../retailer-calculation/RetailerCalculationViewPresenter';

@UntilDestroy()
@Component({
    selector: 'l7-retailer-calculation-view',
    templateUrl: './RetailerCalculationView.html',
    styleUrls: ['./RetailerCalculationView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        RetailerCalculationViewPresenter,
        RetailerCalculateLeasingQuoteFormService,
    ],
    standalone: false,
})
export class RetailerCalculationView implements OnInit, OnDestroy {

    readonly presenter = inject(RetailerCalculationViewPresenter);

    @ViewChild('stepper')
    public stepperCreateQuote: WizardComponent;

    public ngOnInit(): void {
        this.presenter.initialize();
    }

    public ngOnDestroy(): void {
        this.presenter.dispose();
    }

}
