import { Component } from '@angular/core';
import { DefaultLayoutDirective, DefaultLayoutGapDirective } from '@ngbracket/ngx-layout';

@Component({
    selector: 'l7-skeleton-form',
    imports: [
        DefaultLayoutDirective,
        DefaultLayoutGapDirective,
    ],
    templateUrl: './skeleton-form.component.html',
    styleUrl: './skeleton-form.component.scss',
})
export class SkeletonFormComponent {

}
