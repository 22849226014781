import { RetailerQuoteControllerService } from '@abcfinlab/api-global';
import { computed, inject, Injectable, Signal } from '@angular/core';
import { rxResource, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';

@Injectable()
export class RetailerQuoteDataService {

    private readonly _retailerQuoteOpenAPIService = inject(RetailerQuoteControllerService);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _activatedRouteUrl = toSignal(this._activatedRoute.url, { initialValue: undefined });
    private readonly _params: Signal<{ id?: string } | undefined> = toSignal(this._activatedRoute.params, { initialValue: undefined });
    quoteId = computed(() => {
        return this._params()?.id;
    });

    private readonly _retailerDataResource = rxResource({
        request: () => ({ leasingQuoteId: this.quoteId() }),
        loader: ({ request: { leasingQuoteId } }) => leasingQuoteId ? this._retailerQuoteOpenAPIService.get({ leasingQuoteId }) : of(undefined),
    });

    isNewVersion = computed(() => !!this._activatedRouteUrl()?.find(seg => seg.path === 'new-version'));
    retailerQuote = this._retailerDataResource.value;
    isLoading = this._retailerDataResource.isLoading;

}
