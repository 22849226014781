<l7-dialog-header [title]="'dialogs.saveOfferWithoutCrefo_title'| translate"
                  [supportiveTitle]="'dialogs.saveOfferWithoutCrefo_sub_title' | translate">
</l7-dialog-header>
<mat-dialog-content fxLayout="column"
                    fxLayoutGap="16px">
    <l7-text-block [maxLines]="3"
                   [wrap]="true"
                   [text]="'dialogs.saveOfferWithoutCrefo_text' | translate">
    </l7-text-block>
    <form fxLayout="column"
          [formGroup]="presenter.form">
        <mat-form-field fxFlex
                        appearance="outline">
            <mat-label>{{ 'dialogs.saveOfferWithoutCrefo_company'| translate }}</mat-label>
            <input matInput
                   type="text"
                   formControlName="companyName"
                   [attr.test-id]="'companyName'">
            <mat-error *ngIf="presenter.form.controls.companyName.getError('required')">
                {{'error.field_required' | translate}}
            </mat-error>
        </mat-form-field>
        <div fxLayout="row"
             fxLayoutGap="16px">
            <mat-form-field fxFlex="calc(70% - 24px)"
                            appearance="outline">
                <mat-label>{{ 'dialogs.saveOfferWithoutCrefo_street'| translate }}</mat-label>
                <input matInput
                       type="text"
                       formControlName="street"
                       [attr.test-id]="'street'">
                <mat-error *ngIf="presenter.form.controls.street.getError('required')">
                    {{'error.field_required' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="calc(30% + 8px)"
                            appearance="outline">
                <mat-label>{{ 'Hausnummer'| translate }}</mat-label>
                <input matInput
                       type="text"
                       formControlName="houseNumber"
                       [attr.test-id]="'houseNumber'">
                <mat-error *ngIf="presenter.form.controls.houseNumber.getError('required')">
                    {{'error.field_required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px">
            <mat-form-field appearance="outline">
                <mat-label>{{ 'dialogs.saveOfferWithoutCrefo_post'| translate }}</mat-label>
                <input matInput
                       type="text"
                       formControlName="postalCode"
                       [attr.test-id]="'postalCode'">
                <mat-error *ngIf="presenter.form.controls.postalCode.getError('required')">
                    {{'error.field_required' | translate}}
                </mat-error>
                <mat-error *ngIf="presenter.form.controls.postalCode.getError('postCode')">
                    <p>{{ 'administration.retailers.create.input.postalCode.error.postCode' | translate }}</p>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'dialogs.saveOfferWithoutCrefo_city'| translate }}</mat-label>
                <input matInput
                       type="text"
                       formControlName="city"
                       [attr.test-id]="'city'">
                <mat-error *ngIf="presenter.form.controls.city.getError('required')">
                    {{'error.field_required' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'dialogs.saveOfferWithoutCrefo_country'| translate }}</mat-label>
                <input matInput
                       type="text"
                       formControlName="country"
                       [attr.test-id]="'country'">
                <mat-error *ngIf="presenter.form.controls.country.getError('required')">
                    {{'error.field_required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button
            id="button_close-delete-quote-modal"
            [color]="'accent'"
            [matDialogClose]
            (click)="presenter.onSubmit(true)">{{"global.cancel" | translate}}</button>
    <button mat-flat-button
            id="button_delete-quote"
            [color]="'accent'"
            (click)="presenter.onSubmit(false)">{{"dialogs.saveOfferWithoutCrefo_save" | translate}}</button>
</mat-dialog-actions>