<l7-dialog-header [title]="(documentTitle + ' prüfen') | translate"
                  [subTitle]="'Vertrag Nr. ' + data.navContractNumber"
                  [supportiveTitle]="data.lesseeName"
                  [closeable]="true"
                  (closed)="this.closeDialog(true)">
</l7-dialog-header>

<mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutGap="8px">
    <div *ngIf="isLoading" class="spinner-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
        <l7-spacer [thickness]="'all'" [density]="'comfortable'">
            <mat-spinner></mat-spinner>
        </l7-spacer>
    </div>
    <l7-banner *ngIf="data.isReadonlyUser && !this.ignoreReadOnlyBanner"
               class="sticky-banner"
               [appearance]="'outlined'"
               [closable]="true"
               [color]="'warn'"
               [icon]="'Alert_Triangle'"
               [iconFontSet]="'l7'"
               [content]="'settlement.readOnlyBanner' | translate"
               (closed)="this.ignoreReadOnlyBanner=true">
    </l7-banner>
    <div fxLayout="row">
        <a *ngIf="!isLoading"
           class="download"
           id="button-download-pdf"
           [href]="options.url">
            <mat-icon [inline]="true"
                      [fontSet]="'l7'"
                      [fontIcon]="'Import_2'"></mat-icon> {{documentTitle}} herunterladen
        </a>
    </div>
    <div fxLayout="row"
         fxLayout.lt-md="column">
        <div fxLayout="column"
             fxFlex="70"
             fxFlex.lt-md="100">
            <pdf-viewer style="display: block; height: 100vh; width: 100vw;"
                        [src]="options"
                        [original-size]="false"
                        [render-text]="true"
                        [autoresize]="true"
                        [fit-to-page]="true"
                        [zoom-scale]="'page-width'"
                        (after-load-complete)="isLoading = false">
            </pdf-viewer>
        </div>
        <div fxLayout="column"
             fxFlex="30"
             fxFlex.lt-md="100"
             [style.padding]="'0px 0 15px 15px'">
            <ng-container [ngSwitch]="true">
                <ng-container *ngSwitchCase="data.type==='ACKNOWLEDGEMENT_SIGNED' || data.type==='ACKNOWLEDGEMENT_SIGNED_PAPER' || data.type === 'KUEV' || data.type === 'DELIVERY_NOTE'">
                    <l7-settlement-acknowledgement-form [ackDto]="this.ackDto"
                                                        [ackData]="ackFormData"
                                                        [isReadonlyUser]="data.isReadonlyUser"
                                                        [documentType]="data.type"
                                                        [contractStatus]="data.status"
                                                        [navStatus]="navStatus"
                                                        [salesChanel]="salesChanel"
                                                        [documentId]="data.documentId"
                                                        [slbData]="data.kuevData?.kuev"
                                                        [purchaseEntry]="data.ackData?.purchaseEntry"
                                                        [quoteId]="data.ackData?.ack?.leasing_quote_id"
                                                        [taxNumberLessee]="taxNumberLessee"
                                                        (closeDialog)="closeDialog($event)">
                    </l7-settlement-acknowledgement-form>
                </ng-container>
                <ng-container *ngSwitchCase="data.type==='INVOICE' || data.type==='PRE_VENDOR_INVOICE'">
                    <l7-bo-invoice-form [purchaseEntryDate]="purchaseEntryDate"
                                        [approvalDate]="approvalDate"
                                        [contractNumber]="data.navContractNumber"
                                        [ackLeasingValue]="ackLeasingValue"
                                        [navStatus]="navStatus"
                                        [isReadonlyUser]="data.isReadonlyUser"
                                        [isSlb]="data.isSlb"
                                        [salesChanel]="salesChanel"
                                        [invoiceData]="invoiceData"
                                        [invoiceFormData]="invoiceFormData"
                                        [ocrData]="invoiceOcr"
                                        [contractStatus]="data.status"
                                        [documentId]="data.documentId"
                                        (closeDialog)="closeDialog($event)">
                    </l7-bo-invoice-form>
                </ng-container>
                <ng-container *ngSwitchCase="data.type === 'PROOF_OF_PAYMENT'">
                    <l7-document-confirmation-form [isConfirmed]="data.isConfirmed" [documentId]="data.documentId" [isReadonlyUser]="data.isReadonlyUser" (closeDialog)="closeDialog($event)"></l7-document-confirmation-form>
                </ng-container>
            </ng-container>
        </div>
    </div>
</mat-dialog-content>
