<l7-page motion
         [motionPreset]="'fadeSlideIn'">
    <l7-page-header *ngIf="(presenter.lessee | async)"
                    [title]="'contract_management.retailers.contractDetails.title' | translate: {contractNo: (presenter.contractDetails | async)?.contractNo}"
                    [subTitle]="(presenter.lessee | async)?.name"
                    [hasBackNavigation]="true"
                    [cancelNavigation]="false">
    </l7-page-header>
    <l7-page-content fxLayout="column">
        <div fxLayout="column"
             fxLayoutGap="24px">
            <mat-card *ngIf="(this.presenter.contractDetails | async)" class="more-padding">
                <mat-card-content class="more-padding"
                                  fxLayout="column">
                    <div fxFlex
                         fxLayout="row wrap"
                         fxLayoutAlign="space-between center"
                         fxLayoutGap="16px">
                        <fieldset fxFlex="0 1 calc(25% - 16px)"
                                  fxFlex.lt-md="calc(50% - 16px)"
                                  fxLayout="column">
                            <legend>{{ 'quote.retailers.calculation.input.objectName.label' | translate }}</legend>
                            <l7-text-block class="small-text"
                                           [truncate]="true"
                                           [text]="(this.presenter.contractDetails | async).objectDescription"></l7-text-block>
                        </fieldset>
                        <fieldset fxFlex="0 1 calc(25% - 16px)"
                                  fxFlex.lt-md="calc(50% - 16px)"
                                  fxLayout="column">
                            <legend>{{ 'quote.retailers.calculation.input.contract_type.label' | translate }}</legend>
                            <l7-text-block class="small-text"
                                           [truncate]="true"
                                           [text]="'contractType.' + (this.presenter.contractDetails | async).contractType | translate"></l7-text-block>
                        </fieldset>
                        <fieldset fxFlex="0 1 calc(25% - 16px)"
                                  fxFlex.lt-md="calc(50% - 16px)"
                                  fxLayout="column">
                            <legend>{{ 'contract_management.retailers.contractDetails.labels.netPrice' | translate }}</legend>
                            <l7-text-block class="small-text"
                                           [truncate]="true"
                                           [text]="(this.presenter.contractDetails | async).objectValue | currency"></l7-text-block>
                        </fieldset>
                        <fieldset fxFlex="0 1 calc(25% - 16px)"
                                  fxFlex.lt-md="calc(50% - 16px)"
                                  fxLayout="column">
                            <legend>{{ 'contract_management.retailers.contractDetails.labels.signedAt' | translate }}</legend>
                            <l7-text-block class="small-text"
                                           [truncate]="true"
                                           [text]="(this.presenter.contractDetails | async).signedDate | date"></l7-text-block>
                        </fieldset>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card *ngIf="presenter.contractTasks | async">
                <mat-card-header>
                    <mat-card-title fxFlex
                                    fxLayout="row"
                                    fxLayoutAlign="space-between center">
                        <div fxflex
                             fxLayout="row">{{ 'quote.retailers.quoteDetails.openTasksTitle' | translate }} ({{presenter.openTasks | async}})</div>
                        <mat-chip-listbox>
                            <mat-chip class="text-truncate contract-state"
                                      fxLayout="row"
                                      fxLayoutAlign="space-between center"
                                      fxLayoutGap="8px"
                                      [ngClass]="'contract-state-'+ (presenter.workFlowInfo | async).workflowState">
                                <mat-icon *ngIf="(presenter.workFlowInfo | async).workflowState === 'OPEN'"
                                          matChipAvatar
                                          [fontSet]="'l7'"
                                          [fontIcon]="'Checkbox_Square'">
                                </mat-icon>
                                <mat-icon *ngIf="(presenter.workFlowInfo | async).workflowState === 'COMPLETED'"
                                          matChipAvatar
                                          [fontSet]="'l7'"
                                          [fontIcon]="'Checkbox_Square'">
                                </mat-icon>
                                <mat-icon *ngIf="(presenter.workFlowInfo | async).workflowState === 'PENDING'"
                                          matChipAvatar
                                          [fontSet]="'l7'"
                                          [fontIcon]="'Time'">
                                </mat-icon>
                                <mat-icon *ngIf="(presenter.workFlowInfo | async).workflowState === 'ERROR'"
                                          matChipAvatar
                                          [fontSet]="'l7'"
                                          [fontIcon]="'Cross_Circle'">
                                </mat-icon>
                                <l7-text-block [truncate]="true"
                                               [text]="(presenter.workFlowInfo | async).tasksCompleted + '/'+ (presenter.workFlowInfo | async).tasksTotal ">
                                </l7-text-block>
                            </mat-chip>
                        </mat-chip-listbox>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column">
                    <mat-nav-list fxFlex
                                  fxFill>
                        <mat-list-item *ngFor="let task of (presenter.contractTasks | async); last as last"
                                       class="no-padding retailerTask alignSelfNormal"
                                       fxLayout="column"
                                       [attr.test-id]="task.stepType"
                                       [ngClass]="{'item-disabled': task.startable===false && task.stepType === 'REQUEST_PAYMENT' && task.status === 'OPEN'}"
                                       [style]="'margin-bottom: 20px; height: auto;'"
                                       [disableRipple]="true"
                                       (click)="onTaskSelected(task)">
                            <l7-tile fxFlex
                                     fxLayout="column"
                                     [disabled]="task.startable===false && task.status !== 'COMPLETED' && task.status !== 'PENDING' && task.status !== 'IN_PROGRESS'"
                                     [readonly]="task.status !== 'OPEN'">
                                <div fxFlex="100"
                                     fxLayout="row"
                                     fxLayoutAlign="space-between center">
                                    <input #fileInput
                                           hidden
                                           type="file"
                                           id="uploaded-file"
                                           [attr.test-id]="'inputUploadInvoice'"
                                           [accept]="'application/pdf'"
                                           (change)="presenter.uploadInvoice($event.target.files, task)">
                                    <div fxLayout="row"
                                         fxLayoutAlign="flex-start center">
                                        <ng-container *ngIf="task.status === 'COMPLETED'; else notCompleted">
                                            <mat-icon [fontSet]="'l7'"
                                                      [color]="'success'"
                                                      [fontIcon]="'Check_Circle_Filled'"></mat-icon>
                                        </ng-container>
                                        <ng-template #notCompleted>
                                            <ng-container [ngSwitch]="task.stepType">
                                                <ng-container *ngSwitchCase="'UPLOAD_INVOICE'">
                                                    <mat-icon [fontSet]="'l7'"
                                                              [color]="'primary'"
                                                              [fontIcon]="'Export_2'"></mat-icon>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'ACKNOWLEDGEMENT'">
                                                    <mat-icon *ngIf="task.status === 'OPEN'"
                                                              [fontSet]="'l7'"
                                                              [color]="'primary'"
                                                              [fontIcon]="'File_Agreement'"></mat-icon>
                                                    <mat-icon *ngIf="task.status === 'PENDING'"
                                                              class="task-state-waiting"
                                                              [fontSet]="'l7'"
                                                              [fontIcon]="'Time'"></mat-icon>
                                                    <mat-icon *ngIf="task.status === 'ERROR'"
                                                              [fontSet]="'l7'"
                                                              [color]="'warn'"
                                                              [fontIcon]="'Alert_Triangle'">
                                                    </mat-icon>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'REQUEST_PAYMENT'">
                                                    <mat-icon *ngIf="task.status !== 'PENDING'"
                                                              [fontSet]="'l7'"
                                                              [color]="'primary'"
                                                              [fontIcon]="'Send'"></mat-icon>
                                                    <mat-icon *ngIf="task.status === 'PENDING'"
                                                              [fontSet]="'l7'"
                                                              [color]="'highlight'"
                                                              [fontIcon]="'Time'"></mat-icon>
                                                </ng-container>
                                            </ng-container>
                                        </ng-template>
                                        <ng-container [ngSwitch]="task.stepType">
                                            <ng-container *ngSwitchDefault>
                                                <l7-text-block mat-line
                                                               class="block-with-margin"
                                                               [color]="'bold'"
                                                               [truncate]="true"
                                                               [text]="'contract_management.retailers.contractDetails.tasks.labels.' + task.stepType | translate">
                                                </l7-text-block>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'UPLOAD_INVOICE'">
                                                <l7-text-block *ngIf="task.status === 'COMPLETED'"
                                                               mat-line
                                                               class="block-with-margin"
                                                               [color]="'bold'"
                                                               [truncate]="true"
                                                               [text]="'contract_management.retailers.contractDetails.tasks.labels.UPLOAD_INVOICE_Done' | translate: {uploadDate: (presenter.invoiceUploadDate | async) | date: 'shortDate'}">
                                                </l7-text-block>
                                                <l7-text-block *ngIf="task.status !== 'COMPLETED'"
                                                               mat-line
                                                               class="block-with-margin"
                                                               [color]="'bold'"
                                                               [truncate]="true"
                                                               [text]="'contract_management.retailers.contractDetails.tasks.labels.UPLOAD_INVOICE' | translate">
                                                </l7-text-block>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'REQUEST_PAYMENT'">
                                                <l7-text-block *ngIf="task.status === 'OPEN' || task.status === 'ERROR'"
                                                               mat-line
                                                               class="block-with-margin"
                                                               [color]="'bold'"
                                                               [truncate]="true"
                                                               [text]="'contract_management.retailers.contractDetails.tasks.labels.REQUEST_PAYMENT_open' | translate: {uploadDate: (presenter.invoiceUploadDate | async) | date: 'shortDate'}">
                                                </l7-text-block>
                                                <l7-text-block *ngIf="task.status !== 'OPEN' && task.status !== 'ERROR' && task.status !== 'COMPLETED'"
                                                               mat-line
                                                               class="block-with-margin"
                                                               [color]="'bold'"
                                                               [truncate]="true"
                                                               [wrap]="true"
                                                               [text]="'contract_management.retailers.contractDetails.tasks.labels.REQUEST_PAYMENT_pending' | translate:{requestPaymentDate: task.metaInformation?.requestPaymentDate | date: 'shortDate'}">
                                                </l7-text-block>
                                                <l7-text-block *ngIf="task.status === 'COMPLETED'"
                                                               mat-line
                                                               class="block-with-margin"
                                                               [color]="'bold'"
                                                               [truncate]="true"
                                                               [text]="'contract_management.retailers.contractDetails.tasks.labels.REQUEST_PAYMENT_done' | translate">
                                                </l7-text-block>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'ACKNOWLEDGEMENT'">
                                                <l7-text-block *ngIf="task.status !== 'COMPLETED' && task.status !== 'PENDING'"
                                                               mat-line
                                                               class="block-with-margin"
                                                               [color]="'bold'"
                                                               [truncate]="true"
                                                               [text]="'contract_management.retailers.contractDetails.tasks.labels.ACKNOWLEDGEMENT' | translate: {uploadDate: (presenter.invoiceUploadDate | async) | date: 'shortDate'}">
                                                </l7-text-block>
                                                <l7-text-block *ngIf="task.status === 'PENDING'"
                                                               mat-line
                                                               class="block-with-margin"
                                                               [color]="'bold'"
                                                               [truncate]="true"
                                                               [text]="'contract_management.retailers.contractDetails.tasks.labels.ACKNOWLEDGEMENT_pending' | translate: {linkDate: task.metaInformation?.signingLinkSentDate | date: 'shortDate'}">
                                                </l7-text-block>
                                                <l7-text-block *ngIf="task.status === 'COMPLETED'"
                                                               mat-line
                                                               class="block-with-margin"
                                                               [color]="'bold'"
                                                               [truncate]="true"
                                                               [text]="'contract_management.retailers.contractDetails.tasks.labels.ACKNOWLEDGEMENT_Done' | translate: {completedDate: task.metaInformation?.completedDate | date: 'shortDate'}">
                                                </l7-text-block>
                                            </ng-container>
                                        </ng-container>

                                    </div>
                                    <div *ngIf="task.status === 'OPEN'" matListItemMeta>
                                        <mat-icon [fontSet]="'l7'"
                                                  [color]="'primary'"
                                                  [fontIcon]="'Chevron_Right'">
                                        </mat-icon>
                                    </div>
                                    <div *ngIf="(task.actions.length > 0) && ((presenter.contractTasks | async)[2].status === 'OPEN')" matListItemMeta>
                                        <mat-icon [matMenuTriggerFor]="menu"
                                                  [fontSet]="'l7'"
                                                  [color]="'primary'"
                                                  [fontIcon]="'More_Horiz_Filled'">
                                        </mat-icon>
                                    </div>
                                    <div *ngIf="task.status === 'ERROR' && task.actions.length === 0" matListItemMeta>
                                        <mat-icon [fontSet]="'l7'"
                                                  [color]="'warn'"
                                                  [fontIcon]="'Alert_Triangle'">
                                        </mat-icon>
                                    </div>
                                    <mat-menu #menu="matMenu">
                                        <ng-container [ngSwitch]="task.stepType">
                                            <ng-container *ngSwitchCase="'UPLOAD_INVOICE'">
                                                <button mat-menu-item
                                                        (click)="presenter.deleteInvoice(task)">{{'contract_management.retailers.contractDetails.tasks.menu.deleteInvoice' | translate}}</button>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'ACKNOWLEDGEMENT'">
                                                <button mat-menu-item
                                                        (click)="presenter.deleteAcknowledgment(task)">{{'contract_management.retailers.contractDetails.tasks.menu.deleteTakeoverConfirmation' | translate}}</button>
                                                <button *ngIf="task.status === 'PENDING'"
                                                        mat-menu-item
                                                        [cdkCopyToClipboard]="task.metaInformation?.acknowledgementSigningLink"
                                                        (cdkCopyToClipboardCopied)="presenter.onLinkCopy($event)">{{'contract_management.retailers.contractDetails.tasks.menu.copyLink' | translate}}</button>
                                            </ng-container>
                                        </ng-container>
                                    </mat-menu>
                                </div>
                            </l7-tile>
                        </mat-list-item>
                    </mat-nav-list>
                </mat-card-content>
            </mat-card>
            <mat-card *ngIf="presenter.contractDocuments | async">
                <mat-card-header>
                    <mat-card-title fxFlex
                                    fxLayout="row"
                                    fxLayoutAlign="space-between center">
                        <div>{{ 'Dokumente' | translate }}</div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="presenter.contractDocuments | async"
                                  fxLayout="column">
                    <mat-table fxFill
                               matSort
                               [dataSource]="presenter.contractDocuments | async"
                               [attr.test-id]="'QuoteDocumentsTable'">
                        <ng-container *ngFor="let column of columns"
                                      [matColumnDef]="column">
                            <mat-header-cell *matHeaderCellDef
                                             mat-sort-header>
                                <span *ngIf="column !== 'actions'"
                                      class="text-truncate">
                                    {{ 'contract_management.documents.' + column | translate }}
                                </span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row ; let i = index;"
                                      (click)="this.fileDownload(row)">
                                <ng-container [ngSwitch]="column">
                                    <ng-container *ngSwitchCase="'creationDate'">
                                        <span class="text-truncate">{{ row[column] | date }}</span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'type'">
                                        <mat-chip-listbox>
                                            <mat-chip-option class="text-truncate"
                                                      [color]="'primary'">{{ 'contract_management.documents.' + row[column] | translate }}</mat-chip-option>
                                        </mat-chip-listbox>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'actions'">
                                        <div fxFlex
                                             fxLayout="row"
                                             fxLayoutAlign="end center">
                                            <mat-icon [fontSet]="'l7'"
                                                      [fontIcon]="'External_Link'"></mat-icon>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <span class="text-truncate">{{ row[column] }}</span>
                                    </ng-container>
                                </ng-container>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: columns; let i = index"
                                 matRipple
                                 [attr.test-id]="'OverviewDocumentsTableRow' + i">
                        </mat-row>
                    </mat-table>
                </mat-card-content>
            </mat-card>
        </div>
    </l7-page-content>
</l7-page>