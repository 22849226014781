<ng-container *ngIf="!lessee_state_discarded; else lesseeDiscardedOrPending">
    <ng-container *ngIf="has_signing_link">
        <div class="icon icon-link"
             [ngClass]="signing_link_classes">
            <mat-icon *ngIf="signing_link_positive else invalidLink"
                      [fontSet]="'l7'"
                      [fontIcon]="'Link_2'"
                      [matTooltip]="tooltip_signing_link"
                      [matMenuTriggerFor]="menuLink"
                      (click)="$event.stopPropagation()"></mat-icon>
            <mat-menu #menuLink="matMenu"
                      [attr.test-id]="'menu-link'">
                <a mat-menu-item
                   [cdkCopyToClipboard]="version.link.link"
                   (cdkCopyToClipboardCopied)="onCopy($event)">
                    <mat-icon class="menu-item-icon"
                              [fontSet]="'l7'"
                              [fontIcon]="'Copy_Filled'">
                    </mat-icon>Link kopieren
                </a>
                <a mat-menu-item
                   (click)="deactivateLink()">
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Link_Slash'"></mat-icon>Link deaktivieren
                </a>
            </mat-menu>
            <ng-template #invalidLink>
                <mat-icon class="disabled_option"
                          [fontSet]="'l7'"
                          [fontIcon]="'Link_Slash'"
                          [matTooltip]="tooltip_signing_link"></mat-icon>
            </ng-template>
        </div>
    </ng-container>
    <div class="icon icon-download">
        <mat-icon [fontSet]="'l7'"
                  [fontIcon]="'File_Download'"
                  [matMenuTriggerFor]="menuDownload"
                  (click)="$event.stopPropagation()"></mat-icon>
        <mat-menu #menuDownload="matMenu"
                  class="download-select"
                  panelClass="download-select-item"
                  [attr.test-id]="'contract-draft-download'">
            <a target="_blank"
               mat-menu-item
               rel="noopener noreferrer"
               [routerLink]="'/presentation/gwg/pdf-view/' + contract_draft_download_link">Vertragsvorschau-PDF</a>
            <a target="_blank"
               mat-menu-item
               rel="noopener noreferrer"
               [routerLink]="'/presentation/gwg/pdf-view/' + offer_download_link">l7-Angebots-PDF</a>
        </mat-menu>
    </div>
    <mat-icon class="icon icon-vendor"
              [ngClass]="vendor_state"
              [fontSet]="'l7'"
              [fontIcon]="'Truck'"
              (click)="onAddVendor($event)">
    </mat-icon>
    <mat-icon *ngIf="lessee_state === 'NEGATIVE'"
              class="icon btn-BC icon-lessee"
              [ngClass]="lessee_state"
              [fontSet]="'l7'"
              [fontIcon]="'Shield_Cross'"
              [matTooltip]="'states.credit_check.' + lessee_state | translate"
              (click)="openBoniCheckDialog($event)">
    </mat-icon>
    <mat-icon *ngIf="lessee_state !== 'APPROVED_WITH_CONDITIONS' && lessee_state !== 'APPROVED_WITH_CONDITIONS_FULFILLED' && lessee_state!== 'NEGATIVE'"
              class="icon btn-BC icon-lessee"
              [ngClass]="lessee_state"
              [fontSet]="'l7'"
              [fontIcon]="lessee_state === 'OPEN' ? 'Shield' : 'Shield_Check'"
              [matTooltip]="'states.credit_check.' + lessee_state | translate"
              (click)="openBoniCheckDialog($event)">
    </mat-icon>
    <span *ngIf="lessee_state === 'APPROVED_WITH_CONDITIONS' || lessee_state === 'APPROVED_WITH_CONDITIONS_FULFILLED'"
          matBadge="A"
          matBadgeOverlap="true"
          class="btn-BC"
          matBadgeSize="small"
          [matTooltip]="'states.credit_check.' + lessee_state | translate"
          (click)="openBoniCheckDialog($event)">
        <mat-icon class="icon icon-lessee"
                  [ngClass]="lessee_state"
                  [fontSet]="'l7'"
                  [fontIcon]="'Shield_Check'"></mat-icon>
    </span>
    <mat-icon class="icon icon-delete"
              [ngClass]="{'DISABLED': lessee_has_positive_credit_rating || lessee_state_pending || lessee_state_approved_with_condition}"
              [fontSet]="'l7'"
              [fontIcon]="'Trash'"
              [matTooltip]="tooltip_delete"
              (click)="onDeleteVersion($event)">
    </mat-icon>
</ng-container>


<ng-template #lesseeDiscardedOrPending>
    <mat-icon class="icon icon-download DISABLED"
              [fontSet]="'l7'"
              [fontIcon]="'File_Download'"
              [matTooltip]="'Nicht möglich!'"></mat-icon>
    <mat-icon class="icon icon-vendor DISABLED"
              [fontSet]="'l7'"
              [fontIcon]="'Truck'"
              [matTooltip]="'Nicht möglich!'"></mat-icon>
    <mat-icon *ngIf="lessee_state_discarded else lesseePending"
              class="icon icon-lessee DISABLED"
              [fontSet]="'l7'"
              [fontIcon]="'Shield_Check'"
              [matTooltip]="'states.credit_check.' + lessee_state | translate"></mat-icon>
    <ng-template #lesseePending>
        <mat-icon class="icon icon-lessee"
                  [ngClass]="lessee_state"
                  [fontSet]="'l7'"
                  [fontIcon]="'Shield_Check'"
                  [matTooltip]="'states.credit_check.' + lessee_state | translate"
                  (click)="openDialogAlreadyPending($event)"></mat-icon>
    </ng-template>
    <mat-icon class="icon icon-delete"
              [fontSet]="'l7'"
              [fontIcon]="'Trash'"
              [matTooltip]="tooltip_delete"
              (click)="onDeleteVersion($event)">
    </mat-icon>
</ng-template>
