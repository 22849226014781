<l7-page [isEmpty]="($searchResult | async)?.length === 0">
    <l7-page-header #header
                    [title]="'Leasingnehmersuche' | translate"
                    [supportiveTitle]="'Bitte wählen Sie die entsprechende Firma aus' | translate"></l7-page-header>
    <l7-page-content>
        <mat-nav-list fxFlex
                      fxFill>
            <mat-list-item *ngFor="let item of $searchResult | async; last as last"
                           [attr.test-id]="item.crefo_id"
                           [disabled]="(item.allowed_as_lessee===false)"
                           [disableRipple]="(item.allowed_as_lessee===false)"
                           (click)="onSelectionChange(item)">
                <l7-text-block matListItemTitle
                               [truncate]="true"
                               [text]="item.name"></l7-text-block>
                <l7-text-block matListItemLine
                               [readonly]="true"
                               [truncate]="true"
                               [text]="formatAddress(item)"></l7-text-block>
                <div *ngIf="!item.allowed_as_lessee"
                     matListItemLine>
                    <l7-banner [color]="'info'"
                               [appearance]="'textual'"
                               [icon]="'Info'"
                               [iconFontSet]="'l7'"
                               [content]="'error.contact_lefo_not_whitelisted' | translate:{ param1: item.legal_form_description? item.legal_form_description : ''}"></l7-banner>
                </div>
                <div matListItemMeta>
                    <mat-icon *ngIf="(item.allowed_as_lessee!==false)"
                              [fontSet]="'l7'"
                              [fontIcon]="'Chevron_Right'"></mat-icon>
                </div>

                <mat-divider *ngIf="!last"
                             class="without-margin"
                             [inset]="true"></mat-divider>
            </mat-list-item>
        </mat-nav-list>
    </l7-page-content>
    <div fxLayoutAlign="center center">
        <l7-spacer [thickness]="'all'"
                   [multiplier]="24">
            <button *ngIf="!header.hasBackNavigation"
                    mat-button
                    id="button_back-to-contact-searcht"
                    [color]="'accent'"
                    (click)="header.onNavigate()">{{'Zurück zur Suche' | translate}}</button>
        </l7-spacer>
    </div>
</l7-page>