<l7-page *cdkBreakpoint="let bp"
         motion
         [motionPreset]="'fadeSlideIn'">
    <l7-page-header [title]="(presenter.title | async) | translate"
                    [subTitle]="(presenter.subTitle | async)"
                    [hasBackNavigation]="(presenter.showBackNavigation | async) && isCalculationOnFirstStep() === false"
                    [cancelNavigation]="true"
                    (navigated)="this.goBack()">
        <header-icon fxLayoutAlign="start center">
            <mat-icon *ngIf="(presenter.isDraft | async)"
                                       [fontSet]="'l7'"
                                       matTooltip="{{ 'quote.draftTooltip' | translate }}"
                                       [color]="'warning'"
                                       [fontIcon]="'New_Release'">
            </mat-icon>
        </header-icon>
    </l7-page-header>
    <l7-page-content fxLayout="column">
        <mat-stepper #stepper
                     class="no-pointer-events no-header"
                     [selectedIndex]="presenter.currentStep | async"
                     [orientation]="bp('lt-md') ? 'vertical' : 'horizontal'"
                     [@.disabled]="(presenter.currentStep | async) > 0">
            <ng-template matStepperIcon="edit">
                <mat-icon>check</mat-icon>
            </ng-template>

            <ng-template let-index="index"
                         matStepperIcon="number">
                <ng-container *ngIf="index === (presenter.currentStep | async) && (presenter.showSuccess | async) === false && (presenter.showNumber | async) === false">
                    <mat-icon>edit</mat-icon>
                </ng-container>
                <ng-container *ngIf="index !== (presenter.currentStep | async)">
                    {{index + 1}}
                </ng-container>
                <ng-container *ngIf="(presenter.showNumber | async) && index === (presenter.currentStep | async)">
                    <mat-icon class="disabled-number"
                              [fontSet]="'Nunito Sans'">
                        <div>
                            {{index + 1}}
                        </div>
                    </mat-icon>
                </ng-container>
                <ng-container *ngIf="(presenter.showSuccess | async)">
                    <mat-icon>check</mat-icon>
                </ng-container>
            </ng-template>
            <mat-step [label]="'quote.retailers.stepper.create_offers_label' | translate"
                      [completed]="(presenter.currentStep | async) > 0">
                <l7-retailer-calculation-view #calculationStepper></l7-retailer-calculation-view>
            </mat-step>
            <mat-step [label]="'quote.retailers.stepper.credit_check_label' | translate"
                      [completed]="(presenter.currentStep | async) > 1">
                <l7-retailer-quote-details-view *ngIf="(presenter.currentStep | async) <= 1">
                </l7-retailer-quote-details-view>
            </mat-step>
            <mat-step [label]="'quote.retailers.stepper.identification_label' | translate"
                      [completed]="(presenter.currentStep | async) > 2">
                <l7-retailer-quote-details-view *ngIf="(presenter.currentStep | async) <= 2">
                </l7-retailer-quote-details-view>
            </mat-step>
            <mat-step [label]="'quote.retailers.stepper.sign_contract_label' | translate"
                      [completed]="(presenter.showSuccess | async)">
                    <l7-retailer-quote-details-view>
                    </l7-retailer-quote-details-view>

            </mat-step>
        </mat-stepper>
    </l7-page-content>
</l7-page>
