/**
 * retailer-service
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type HandlingFeeOption = 'ALWAYS_CHARGE' | 'NEVER_CHARGE' | 'CUSTOMIZABLE';

export const HandlingFeeOption = {
    AlwaysCharge: 'ALWAYS_CHARGE' as HandlingFeeOption,
    NeverCharge: 'NEVER_CHARGE' as HandlingFeeOption,
    Customizable: 'CUSTOMIZABLE' as HandlingFeeOption
};

