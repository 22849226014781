import { FeatureManagementControllerService } from '@abcfinlab/api-global';
import { computed, inject, Injectable, Signal } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class LaunchDarklyFeatureManager {

    private readonly _featureManagementService = inject(FeatureManagementControllerService);

    featureResource = rxResource({
        loader: () => this._featureManagementService.getFeatures(),
    });

    features = computed(() => this.featureResource.value()?.features);

    getActiveFeature({ featureName, isFeatureOptional = true }: { featureName: string; isFeatureOptional?: boolean }): Signal<boolean> {
        return computed(() => {
            const feature = this.features()?.find(x => x.key === featureName);
            if (feature) {
                return feature.isActive || false;
            }

            if (!isFeatureOptional) {
                throw new Error(`Invalid feature name '${featureName}'.`);
            }

            return false;
        });
    }

}
