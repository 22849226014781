<div fxLayout="row"
     fxLayoutAlign="start center">
    <h2 fxFlex>{{'contract_management.documents.table_title' | translate}}</h2>
    <a mat-button
       [color]="'accent'"
       [attr.test-id]="'ButtonCheckSignatures'"
       (click)="openSignaturesDialog()">
        <div fxLayout="row"
             fxLayoutGap="8px"
             fxLayoutAlign="center center ">
            <mat-icon [fontSet]="'l7'"
                      [fontIcon]="'Validate'"></mat-icon>
            <span>{{'contract_management.documents.checkSignatures' | translate}}</span>
        </div>
    </a>
    <a mat-button
       target="_blank"
       rel="noopener noreferrer"
       [color]="'accent'"
       [href]="download_all_files_link"
       [attr.test-id]="'ButtonDownloadAllFile'">
        <div fxLayout="row"
             fxLayoutGap="8px"
             fxLayoutAlign="center center ">
            <mat-icon [fontSet]="'l7'"
                      [fontIcon]="'Import_2'"></mat-icon>
            <span>{{'contract_management.documents.all_documents_link' | translate}}</span>
        </div>
    </a>
</div>

<div *ngIf="!documentsReady"
     fxLayout="column"
     fxLayoutAlign="start center">
    <l7-spacer [thickness]="'all'"
               [density]="'comfortable'">
        <mat-spinner></mat-spinner>
    </l7-spacer>
</div>

<mat-table fxFill
           matSort
           [dataSource]="dataSource"
           [attr.test-id]="'OverviewDocumentsTable'">
    <ng-container *ngFor="let column of columns"
                  [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef
                         mat-sort-header>
            <span *ngIf="column !== 'actions'"
                  class="text-truncate">
                {{ 'contract_management.documents.' + column | translate }}
            </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row ; let i = index;">
            <ng-container [ngSwitch]="column">
                <ng-container *ngSwitchCase="'creationDate'">
                    <span class="text-truncate">{{ row[column] | date }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'type'">
                    <mat-chip-listbox>
                        <mat-chip class="text-truncate"
                                  [color]="'primary'">
                            @if (salesChanel === ISalesChannelDto.Retailer && row[column] === IDocumentTypeDto.DeliveryNote) {
                                {{ 'contract_management.documents.ACKNOWLEDGEMENT' | translate }}
                            } @else {
                                {{ 'contract_management.documents.' + row[column] | translate }}
                            }
                        </mat-chip>
                    </mat-chip-listbox>
                </ng-container>
                <ng-container *ngSwitchCase="'actions'">
                    <div *ngIf="(presenter.contractBillableReady$ | async) !== null; else showLoader"
                         fxFlex
                         fxLayout="row"
                         fxLayoutAlign="end center">
                        <ng-container *ngIf="row['type']==='INVOICE' || row['type']==='ACKNOWLEDGEMENT_SIGNED' || row['type']==='KUEV'
                        || row['type']==='ACKNOWLEDGEMENT_SIGNED_PAPER' || (row['type'] === 'PRE_VENDOR_INVOICE' && (presenter.canEditPreVendorInvoice$ | async))
                        || (row['type'] === 'PROOF_OF_PAYMENT' && (presenter.canEditProofOfPayment | async)) || (row['type'] === 'DELIVERY_NOTE' && salesChanel === 'RETAILER') else newTab">

                            <ng-container *ngIf="row['type']==='INVOICE'">
                                <mat-icon [fontSet]="'l7'"
                                          [fontIcon]="row['invoiceComplete'] ? 'Check_Circle' : 'Alert_Circle'"
                                          [color]="row['invoiceComplete'] ? 'success' : 'warning'"
                                          [matTooltipClass]="'allow-cr'"
                                          [matTooltip]="row['invoiceIncompleteReasons']"></mat-icon>
                            </ng-container>

                            <ng-container *ngIf="row['type']==='PRE_VENDOR_INVOICE'">
                                <mat-icon [fontSet]="'l7'"
                                          [fontIcon]="row['invoiceComplete'] ? 'Check_Circle' : 'Alert_Circle'"
                                          [color]="row['invoiceComplete'] ? 'success' : 'warning'"
                                          [matTooltipClass]="'allow-cr'"
                                          [matTooltip]="row['invoiceIncompleteReasons']"></mat-icon>
                            </ng-container>

                            <ng-container *ngIf="row['type']==='PROOF_OF_PAYMENT'">
                                <mat-icon [fontSet]="'l7'"
                                          [fontIcon]="row['proofOfPaymentComplete'] ? 'Check_Circle' : 'Alert_Circle'"
                                          [color]="row['proofOfPaymentComplete'] ? 'success' : 'warning'"
                                          [matTooltipClass]="'allow-cr'"
                                          [matTooltip]="row['proofOfPaymentCompleteReasons']"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="row['type']==='ACKNOWLEDGEMENT_SIGNED' || row['type']==='ACKNOWLEDGEMENT_SIGNED_PAPER'|| (row['type'] === 'DELIVERY_NOTE' && salesChanel === 'RETAILER')">
                                <mat-icon [fontSet]="'l7'"
                                          [color]="row['ackComplete'] ? 'success' : 'warning'"
                                          [fontIcon]="row['ackComplete'] ? 'Check_Circle' : 'Alert_Circle'"></mat-icon>
                            </ng-container>

                            <ng-container *ngIf="row['type']==='KUEV'">
                                <mat-icon [fontSet]="'l7'"
                                          [color]="row['kuevComplete'] ? 'success' : 'warning'"
                                          [fontIcon]="row['kuevComplete'] ? 'Check_Circle' : 'Alert_Circle'"></mat-icon>
                            </ng-container>

                            <button mat-icon-button
                                    [attr.test-id]="'ButtonOpen' + row['type']"
                                    (click)="this.openFile(row)">
                                <mat-icon [fontSet]="'l7'"
                                          [fontIcon]="row['ackComplete'] || row['invoiceComplete'] || row['kuevComplete'] ? 'Edit_3' : 'Edit_3'">
                                </mat-icon>
                            </button>
                        </ng-container>

                        <ng-template #newTab>
                            <button mat-icon-button
                                    [attr.test-id]="'ButtonDownloadFile'"
                                    (click)="this.fileDownload(row)">
                                <mat-icon [fontSet]="'l7'"
                                          [fontIcon]="'External_Link'"></mat-icon>
                            </button>
                        </ng-template>
                    </div>
                    <ng-template #showLoader>
                        <mat-spinner [diameter]="24"></mat-spinner>
                    </ng-template>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <span class="text-truncate">{{ row[column] }}</span>
                </ng-container>
            </ng-container>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns; let i = index"
             matRipple
             [attr.test-id]="'OverviewDocumentsTableRow' + i">
    </mat-row>
</mat-table>

<ng-container *ngIf="can_upload_files && !isReadonlyUser">
    <div fxLayout="column">
        <l7-upload-documents [navContractNr]="this.presenter.navContractNumber$ | async"
                             [quoteId]="this.presenter.quoteId$ | async"
                             (documentUploaded)="updateDocumentsTable($event)">
        </l7-upload-documents>
    </div>

</ng-container>
