import { Component } from '@angular/core';

@Component({
    selector: 'l7-skeleton-carousel',
    templateUrl: './skeleton-carousel.compent.html',
    styleUrls: ['./skeleton-carousel.compent.scss'],
})
export class SkeletonCarouselComponent {

}
