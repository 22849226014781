<div fxLayout="column"
     fxLayoutGap="24px" cdkScrollable>
    <form l7ScrollToError [formGroup]="signForm.controls.general" [isScrollOnWindow]="true">
    <ng-container *ngIf="singingInfo()">
        <l7-text-block matCardSubtitle
                       [text]="'contract_management.retailers.dialogs.digitalSigning.step4.acknowledgementFor' | translate:{contractName: singingInfo().contractNumber, approvalDate: singingInfo().creationDate | date: 'shortDate'} "></l7-text-block>
        <l7-text-block matCardTitle
                       [text]="singingInfo().lesseeCompanyName | translate"></l7-text-block>
        <l7-text-block matCardSubtitle
                       [text]="singingInfo().lesseeStreetAndHouseNumber + ', ' + singingInfo().lesseePostalCodeAndCity | translate | removeNull"></l7-text-block>
        <l7-spacer [thickness]="'top'"
                   [multiplier]="16"></l7-spacer>
        <l7-text-block [wrap]="true"
                       [text]="'contract_management.retailers.dialogs.digitalSigning.step4.signRemoteConfirmationTitle' | translate:{fhCompany: singingInfo().vendorCompanyName} "></l7-text-block>
        <l7-spacer [thickness]="'top'"
                   [multiplier]="16"></l7-spacer>
        <mat-card appearance="outlined" class="mat-card-outlined">
            <mat-card-header>
                <l7-text-block matCardTitle
                               [text]="'contract_management.retailers.dialogs.digitalSigning.step1.stepName' | translate"></l7-text-block>
            </mat-card-header>
            <mat-card-content class="more-padding"
                              fxLayout="column">
                <div fxFlex
                     fxLayout="row wrap"
                     fxLayoutAlign="space-between center"
                     fxLayoutGap="16px">
                    <fieldset fxFlex="0 1 calc(33,33% - 16px)"
                              fxFlex.lt-md="calc(50% - 16px)"
                              fxLayout="column">
                        <legend>{{ 'Bezeichnung (Typ/Hersteller)' | translate }}</legend>
                        <l7-text-block class="small-text"
                                       [truncate]="true"
                                       [text]="singingInfo().objectDescription"></l7-text-block>
                    </fieldset>
                    <fieldset fxFlex="0 1 calc(33,33% - 16px)"
                              fxFlex.lt-md="calc(50% - 16px)"
                              fxLayout="column">
                        <legend>{{ 'Geräte-Nr./Serien-Nr.' | translate }}</legend>
                        <button *ngIf="singingInfo().isSerialNumberDocumentPresent"
                                class="textAndIcon"
                                mat-icon-button
                                [attr.test-id]="'ButtonDownloadFile'"
                                (click)="showSerialNumberDocument()">
                            <span>Siehe Anhang</span>
                            <mat-icon [fontSet]="'l7'"
                                      [inline]="true"
                                      [fontIcon]="'External_Link'"></mat-icon>
                        </button>
                        <l7-text-block *ngIf="!singingInfo().isSerialNumberDocumentPresent"
                                       class="small-text"
                                       [truncate]="true"
                                       [text]="singingInfo().serialNumber">
                        </l7-text-block>
                    </fieldset>
                    <fieldset fxFlex="0 1 calc(33,33% - 16px)"
                              fxFlex.lt-md="calc(50% - 16px)"
                              fxLayout="column">
                        <legend>{{ 'Monatliche Rate' | translate }}</legend>
                        <l7-text-block class="small-text"
                                       [truncate]="true"
                                       [text]="singingInfo().instalment | currency">
                        </l7-text-block>
                    </fieldset>
                    <fieldset fxFlex="0 1 calc(33,33% - 16px)"
                              fxFlex.lt-md="calc(50% - 16px)"
                              fxLayout="column">
                        <legend>{{ 'Zustand' | translate }}</legend>
                        <l7-text-block class="small-text"
                                       [truncate]="true"
                                       [text]="'contract_management.retailers.dialogs.digitalSigning.step4.condition.' + singingInfo().objectCondition | translate"></l7-text-block>
                    </fieldset>
                </div>
            </mat-card-content>
        </mat-card>
        <l7-spacer [thickness]="'top'"
                   [multiplier]="16"></l7-spacer>
        <mat-card appearance="outlined" class="mat-card-outlined">
            <l7-text-block matCardTitle
                           class="padding"
                           [wrap]="true"
                           [text]="'Einverständniserklärung' | translate"></l7-text-block>
            <mat-card-content>
                <div fxLayout="column"
                     fxLayoutGap="16px">
                    <div *ngIf="singingInfo().isPriceChanged" fxLayout="row">
                        <div fxLayout="column">
                            <mat-checkbox l7MandatoryCheckboxWithError [attr.test-id]="'signingCheckBox1'"
                                          [formControlName]="'signingCheckBox1'">
                                {{'contract_management.retailers.dialogs.digitalSigning.step4.' + checkboxTranslationKey() | translate: {instalment: singingInfo().instalment | currency , downPayment: singingInfo().downPayment | currency, residualValue: singingInfo().residualValue | currency }  }}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div fxLayout="column">
                            <mat-checkbox l7MandatoryCheckboxWithError [attr.test-id]="'signingCheckBox2'"
                                          [formControlName]="'signingCheckBox2'">
                                Dem Leasingnehmer (nachfolgend LN) ist bekannt, dass mit der vorbehaltlosen Unterzeichnung der Übernahmebestätigung die Rechnung des Lieferanten zur Zahlung fällig wird. Die abcfinance GmbH leistet Zahlung an den Lieferanten nur im Vertrauen auf die Richtigkeit der Übernahmebestätigung. Die Unterzeichnung der Übernahmebestätigung vor tatsächlicher Inbesitznahme der Geräte ist daher unzulässig. Sämtliche der abcfinance GmbH aus unzutreffenden Angaben in dieser Übernahmebestätigung sowie deren unberechtigter Erteilung oder Verweigerung entstehende Schäden gehen voll zu Lasten des LN. Dieses gilt auch für Schäden, welche die abcfinance GmbH erleidet, wenn die Übernahmebestätigung ohne Vorbehalt abgegeben wird, obwohl das Leasingobjekt offensichtliche Mängel aufweist, nicht funktionsfähig ist oder Teile des Objekts nicht geliefert wurden. Das vom LN bestätigte Übernahmedatum ist maßgebend für den Beginn der Leasinglaufzeit.
                            </mat-checkbox>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div fxLayout="column">
                            <mat-checkbox l7MandatoryCheckboxWithError [attr.test-id]="'signingCheckBox3'"
                                          [formControlName]="'signingCheckBox3'">
                                Diese Übernahmebestätigung ist ein wesentlicher Bestandteil des über das Leasingobjekt abgeschlossenen Leasingvertrages. Der LN erklärt hiermit ausdrücklich, dass weder mit der Lieferfirma noch mit der abcfinance GmbH irgendwelche Nebenabreden getroffen wurden.
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <l7-spacer [thickness]="'top'"
                   [multiplier]="16"></l7-spacer>
        <mat-card appearance="outlined" class="mat-card-outlined">
            <mat-card-header>
                <l7-text-block matCardTitle
                               [wrap]="true"
                               [text]="'contract_management.retailers.dialogs.digitalSigning.step4.singedByTitle' | translate: {companyName: singingInfo().lesseeCompanyName}"></l7-text-block>
            </mat-card-header>
            <mat-card-content fxLayout="column"
                              fxLayoutGap="8px">
                <l7-spacer [thickness]="'top'"
                           [multiplier]="16"></l7-spacer>
                <div fxLayout="row"
                     fxLayoutGap="8px">
                    <mat-form-field fxFlex="30"
                                    appearance="outline"
                                    class="formFieldItem">
                        <mat-label>{{'contract_management.retailers.dialogs.digitalSigning.step4.singedByFirstName' | translate}}</mat-label>
                        <input matInput
                               [formControlName]="'signedByFirstName'"
                               [attr.test-id]="'signedByFirstName'">
                    </mat-form-field>
                    <mat-form-field fxFlex="30"
                                    appearance="outline"
                                    class="formFieldItem">
                        <mat-label>{{'contract_management.retailers.dialogs.digitalSigning.step4.singedByLastName' | translate}}</mat-label>
                        <input matInput
                               [formControlName]="'signedByLastName'"
                               [attr.test-id]="'signedByLastName'">
                    </mat-form-field>

                    <l7-date-picker
                        fxFlex="30"
                        [label]="'contract_management.retailers.dialogs.digitalSigning.step4.singedByDate'"
                        [uniqueId]="'signedByDate'"
                        [controlName]="'signedByDate'"
                        [parentFormGroup]="signForm.get('general')"
                        [minDate]="minDate"
                        [maxDate]="currentDate">
                    </l7-date-picker>

                </div>
                <div fxLayout="column"
                     fxLayout.gt-sm="row"
                     fxFlexAlign="center">
                    <l7-signature [attr.test-id]="'signature'"
                                  [resetSignature]="resetSignature$ | async"
                                  (canvasDirty)="this.isSignatureDirty$.next($event)"
                                  (signatureBlob)="signature$.next($event)">
                    </l7-signature>
                </div>
            </mat-card-content>
        </mat-card>
        <l7-spacer [thickness]="'top'"
                   [multiplier]="16"></l7-spacer>
        <l7-spacer fxLayout="row"
                   fxLayoutAlign="center center"
                   [thickness]="'bottom'"
                   [multiplier]="16">
            <button mat-flat-button
                    color="primary"
                    [attr.test-id]="'doAcknowledgmentSigning'"
                    (click)="doAcknowledgmentSigning()">{{'contract_management.retailers.dialogs.digitalSigning.step4.btnSignLocal' | translate}}</button>
        </l7-spacer>
    </ng-container>

</form>
</div>
