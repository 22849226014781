<mat-form-field
    class="formFieldItem"
    appearance="{{formFieldAppearance}}"
    [matTooltipPosition]="formFieldTooltipPosition"
    [matTooltipClass]="formFieldTooltipClass"
    [matTooltip]="formFieldTooltip | translate">
    <mat-label>{{ label | translate }}</mat-label>
    <input
        #inputRef
        matInput
        [min]="minDate"
        [max]="maxDate"
        [matDatepicker]="picker"
        [formControl]="formControl"
        [attr.test-id]="uniqueId"
        (dateChange)="onDateChange($event)"
    />
    <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon [fontSet]="'l7'" [fontIcon]="'Calendar_Dates'"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker color="primary"></mat-datepicker>
    <mat-error *ngIf="inputRef.value ==='' && hasError('required')">{{ requiredErrorMessage | translate}}</mat-error>
    <mat-error *ngIf="hasError('minDate')">{{ minDateErrorMessage | translate}}</mat-error>
    <mat-error *ngIf="hasError('maxDate')">{{ maxDateErrorMessage | translate}}</mat-error>
    <mat-error *ngIf="inputRef.value !=='' && hasError('invalid') && !hasError('minDate') && !hasError('maxDate')">
        {{ invalidDateErrorMessage | translate}}
    </mat-error>
</mat-form-field>
