import { Component } from '@angular/core';

@Component({
    selector: 'l7-feature-retailer-calculation',
    imports: [],
    templateUrl: './feature-retailer-calculation.component.html',
    styleUrl: './feature-retailer-calculation.component.scss',
})
export class FeatureRetailerCalculationComponent {

}
