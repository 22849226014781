<h2 mat-dialog-title>{{"configuration.logo.modal_title" | translate}}</h2>
<mat-dialog-content class="logo-crop-container">
    <div fxLayout="column">
        <image-cropper
            class="uploadfilecontainer"
            fxFlex="96"
            format="png"
            [imageChangedEvent]="this.data.imagetoCrop"
            [maintainAspectRatio]="true"
            [aspectRatio]="this.data.logoConfig.aspectRatio"
            [transform]="transform"
            resizeToWidth="{{this.data.logoConfig.width}}"
            resizeToHeight="{{this.data.logoConfig.height}}"
            (imageCropped)="imageCropped($event)"
            (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
        <div fxLayoutAlign="center center">
            <l7-spacer fxLayoutGap="48px" [thickness]="'bottom'">
                <button type="button" mat-button color="accent" id="button_zoom_out" (click)="zoomOut()">{{ '- Zoom' | translate }}</button>
                <button type="button" mat-button color="accent" id="button_zoom_in" (click)="zoomIn()">{{ '+ Zoom' | translate }}</button>
            </l7-spacer>
        </div>
        <div fxFlex="4">
             {{"configuration.logo.upload_modal_description"  | translate}}
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions>
    <div fxLayout="row"
         fxLayoutGap="16px">
        <button
            mat-button
            id="button_left_cancel"
            [color]="'accent'"
            (click)="onCancel()">
            {{ 'global.cancel'| translate}}
        </button>
        <button
            mat-flat-button
            id="button_right_save"
            [color]="'accent'"
            (click)="onConfirmUpload()">
            {{ 'global.save' | translate}}
        </button>
    </div>
</mat-dialog-actions>
