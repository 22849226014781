import {
    RetailerLeasingControllerService,
    SettingsControllerService,
} from '@abcfinlab/api-global';
import { RetailerAdminControllerService } from '@abcfinlab/api-retailer';
import { computed, inject, Injectable } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { combineLatest } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RetailerSettingsDataService {

    private readonly _retailerAdminOpenAPIService = inject(RetailerAdminControllerService);
    private readonly _retailerLeasingOpenAPIService = inject(RetailerLeasingControllerService);
    private readonly _calculationSettingsOpenAPIService = inject(SettingsControllerService);
    private readonly _retailerDataResource = rxResource({
        loader: () => {
            return combineLatest({
                retailer: this._retailerAdminOpenAPIService.getRetailerById1(),
                retailerSettings: this._retailerLeasingOpenAPIService.getRetailerQuoteSettings(),
                calculationSettings: this._calculationSettingsOpenAPIService.getCalculationSettingsDto(),
            });
        },
    });

    isInitialLoading = this._retailerDataResource.isLoading;
    retailerConfig = computed(() => this._retailerDataResource.value()?.retailer.retailerConfig);
    retailerSettings = computed(() => this._retailerDataResource.value()?.retailerSettings);
    calculationSettings = computed(() => this._retailerDataResource.value()?.calculationSettings);

}
