<ng-container *ngIf="presenter.form | async as form">
    <l7-page>
        <l7-page-header #header
                        [title]="((presenter.searchType | async) === 'lessee' ? 'Leasingnehmersuche' : 'Lieferantensuche') | translate"
                        [supportiveTitle]="((presenter.searchType | async) === 'lessee' ? 'Sie können entweder nach Firmennamen oder Kontaktnummer suchen' : 'Bitte geben sie die Daten zur Identifizierung des Lieferanten für folgende Kalkulation ein') | translate"></l7-page-header>
        <l7-page-content>
            <form novalidate
                  [formGroup]="form">
                <mat-tab-group [selectedIndex]="presenter.selectedTab | async"
                               (selectedTabChange)="presenter.onTabChanged($event.index)">
                    <mat-tab [label]="'Anschrift' | translate">
                        <ng-container *ngIf="(presenter.selectedTab | async) === 0">
                            <div fxLayout="row" [style.margin-top]="'5px'">
                                <mat-form-field fxFlex
                                                appearance="outline">
                                    <mat-label>{{ 'Firmenname' | translate }}</mat-label>
                                    <input id="input_company-name"
                                           matInput
                                           required
                                           type="text"
                                           formControlName="companyName">
                                    <mat-error *ngIf="form.controls?.companyName.hasError('required')">{{ 'error.field_required' | translate }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row"
                                 fxLayout.lt-md="column"
                                 fxLayoutGap="16px"
                                 fxLayoutGap.lt-md="0">
                                <mat-form-field fxFlex
                                                appearance="outline">
                                    <mat-label>{{ 'Stadt' | translate }}</mat-label>
                                    <input id="input_city"
                                           matInput
                                           required
                                           type="text"
                                           formControlName="city">
                                    <mat-error *ngIf="form.controls?.city.hasError('required')">{{ 'error.field_required' | translate }}</mat-error>
                                </mat-form-field>
                                <mat-form-field fxFlex
                                                appearance="outline">
                                    <mat-label>{{ 'Land' }}</mat-label>
                                    <input id="input_country-name"
                                           matInput
                                           type="text"
                                           formControlName="country">
                                    <mat-error *ngIf="form.controls?.country.hasError('required')">{{ 'error.field_required' | translate }}</mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>
                    </mat-tab>
                    <mat-tab [label]="'Kontaktnummer' | translate">
                        <ng-container *ngIf="(presenter.selectedTab | async) === 1">
                            <div fxLayout="row" [style.margin-top]="'5px'">
                                <mat-form-field fxFlex
                                                appearance="outline">
                                    <mat-label>{{ 'Kontaktnummer' | translate }}</mat-label>
                                    <input matInput
                                           required
                                           type="text"
                                           formControlName="contactNumber"
                                           [attr.test-id]="'contactNumber'">
                                    <mat-error *ngIf="form.controls?.contactNumber.hasError('required')">{{ 'error.field_required' | translate }}</mat-error>
                                    <mat-error *ngIf="form.controls?.contactNumber.hasError('minlength')">{{ 'Bitte geben Sie mindestens 8 Zeichen an.' | translate }}</mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>
                    </mat-tab>
                </mat-tab-group>
                <l7-page-section fxLayoutAlign="end center"
                                 fxLayoutGap="16px">
                    <button type="button"
                            mat-button
                            color="accent"
                            id="button_cancel-search-contact"
                            (click)="header.onNavigate()">{{ 'Suche abbrechen' | translate }}</button>
                    <button mat-flat-button
                            color="accent"
                            id="button_search-contact"
                            type="submit"
                            (click)="presenter.onSearch()">{{ 'Suchen' | translate }}</button>
                </l7-page-section>
            </form>
        </l7-page-content>
    </l7-page>
</ng-container>
