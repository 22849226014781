<h2 mat-dialog-title>Anmeldung nicht möglich</h2>

<mat-dialog-content>
    <ng-container *ngIf="!serverData.error_params">
        <p>Es ist ein Problem mit ihren Anmeldedaten aufgetreten.</p>
    </ng-container>
    <ng-container *ngIf="serverData.error_params && (user | async)?.groups.includes('makler') && !(user | async)?.groups.includes('sales-assistance'); then isMiddleman; else withParams"></ng-container>
    <ng-template #isMiddleman>
        <p>{{'error.middleman_missing_attributes' | translate}}</p>
    </ng-template>
    <ng-template #withParams>
        <ng-container *ngIf="(user | async)?.groups.includes('sales-assistance') && this.filterParamsForMiddleman.length > 0; else noMiddlemanAssistance">
            <p [innerHTML]="'error.sales_assistance_middleman_missing_attributes' | translate: this.serverData.error_params"></p>
        </ng-container>
        <ng-template #noMiddlemanAssistance>
            <ng-container *ngIf="serverData.error_params">
                <p [innerHTML]="translationWithParams"></p>
            </ng-container>
        </ng-template>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row"
                    fxLayoutAlign="end">
    <button *ngIf="!serverData.error_params"
            id="button_close-auth-error-dialog"
            mat-flat-button
            [color]="'accent'"
            (click)="onCloseDialog(serverData.error_params)">
        Zur Anmeldung
    </button>
    <ng-container *ngIf="serverData.error_params && (user | async)?.groups.includes('makler') && !(user | async)?.groups.includes('sales-assistance'); then isMiddlemanBtn; else textBtnWithParams"></ng-container>
    <ng-template #isMiddlemanBtn>
        <button id="button_close-auth-error-dialog-with-params_middleman"
                mat-flat-button
                [color]="'accent'"
                (click)="onCloseDialog(serverData.error_params, true)">
            {{"global.change_data" | translate}}
        </button>
    </ng-template>
    <ng-template #textBtnWithParams>
        <button *ngIf="serverData.error_params"
                id="button_close-auth-error-dialog-with-params"
                mat-flat-button
                [color]="'accent'"
                (click)="onCloseDialog(serverData.error_params)">
            {{"OK" | translate}}
        </button>
    </ng-template>
</mat-dialog-actions>
