<div class="calculator-used-condition-form-container">
  <div class="form-labels">
    Objektzustand
  </div>
  <form id="form_condition"
        class="form form-used"
        [formGroup]="internalForm">
    <mat-radio-group formControlName="condition" (change)="objectConditionChanged($event)">
      <mat-radio-button color="primary"
                        id="radio_condition-is-new"
                        [value]="'NEW'">
        {{ 'calculation.condition_new_label' | translate }}
      </mat-radio-button>
      <mat-radio-button color="primary"
                        id="radio_condition-is-used"
                        [value]="'USED'">
        {{ 'calculation.condition_used_label' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </form>
</div>
