<div class="page-container">
  <h1 class="headline">Datenschutzerklärung</h1>
  <p>
    Mit den folgenden Informationen möchten wir Ihnen einen Überblick über die Verarbeitung  Ihrer  personenbezogenen  Daten  durch  uns  und  Ihre  Rechte  aus  dem  Datenschutzrecht  geben.
    Welche  Daten  im  Einzelnen  verarbeitet  und  in  welcher Weise genutzt werden, richtet sich maßgeblich nach den beantragten bzw. vereinbarten Dienstleistungen.
  </p>
  <br />
  <mat-accordion #expansionPanel="matAccordion" [multi]="true">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          1. Wer ist für die Datenverarbeitung verantwortlich und an wen kann ich mich wenden?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Verantwortliche Stelle ist:<br />
        abcfinance GmbH<br />
        Kamekestraße 2-8<br />
        50672 Köln<br />
        Telefon: <a href="tel:(0221) 57908">(0221) 57908 - 0</a><br />
        Fax: (0221) 57908 - 9126<br />
        E-Mail-Adresse: <a href="mailto:info@abcfinance.de">info&#64;abcfinance.de</a>
      </p>
      <br />
      <p>
        Sie erreichen unseren betrieblichen Datenschutzbeauftragten unter:<br />
        abcfinance GmbH<br />
        Datenschutzbeauftragter<br />
        Kamekestraße 2-8<br />
        50672 Köln<br />
        Telefon: <a href="tel:(0221) 57908">(0221) 57908 - 0</a><br />
        Fax: (0221) 57908 - 9126<br />
        E-Mail-Adresse: <a href="mailto:datenschutz@abcfinance.de">datenschutz&#64;abcfinance.de</a>
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          2. Welche Quellen und Daten nutzen wir?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Wir  verarbeiten  personenbezogene  Daten,  die  wir  im  Rahmen  unserer  Geschäftsbeziehung von unseren Kunden erhalten.
        Zudem verarbeiten wir - soweit für die Erbringung unserer Dienstleistung erforderlich -
        personenbezogene Daten, die wir aus öffentlich zugänglichen Quellen
        (z.B. Schuldnerverzeichnisse, Einwohnermeldeämter,  Grundbücher,  Handels-  und  Vereinsregister,  Presse,  Internet)
        zulässigerweise  gewinnen  oder  die  uns  von  anderen  Unternehmen  der  abc-Gruppe  oder  von  sonstigen  Dritten
        (z.  B.  von  einer  Kreditauskunftei  oder - im Rahmen des Factoring - von unserem Anschlusskunden) berechtigt übermittelt werden.
      </p>
      <br />
      <p>
        Relevante personenbezogene Daten sind Personalien (Name, Adresse und andere Kontaktdaten, Geburtstag und -ort und Staatsangehörigkeit),
        Legitimationsdaten (z.B. Ausweisdaten) und Authentifikationsdaten (z.B. Unterschriftprobe).
        Darüber  hinaus  können  dies  auch  Auftragsdaten  (z.B.  Zahlungsauftrag),
        Daten  aus  der  Erfüllung  unserer  vertraglichen  Verpflichtungen  (z.B.  Umsatzdaten  im  Zahlungsverkehr),
        Informationen  über  Ihre  finanzielle  Situation  (z.B.  Bonitätsdaten,  Scoring-/Ratingdaten,  Herkunft  von  Vermögenswerten),
        Werbe-  und  Vertriebsdaten  (inklusive  Werbe-Scores),  Dokumentationsdaten  (z.B.  Gesprächsdokumentation,  Besuchsbericht)
        sowie  andere  mit  den  genannten  Kategorien vergleichbare Daten sein.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          3. Wofür verarbeiten wir Ihre Daten (Zweck der Verarbeitung) und auf welcher Rechtsgrundlage?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Wir verarbeiten personenbezogene Daten im Einklang mit den Bestimmungen der Europäischen Datenschutz-Grundverordnung
        (DSGVO) und dem Bundesdatenschutzgesetz (BDSG):
      </p>
      <br />
      <p>
        a. Zur Erfüllung von vertraglichen Pflichten (Art. 6 Abs. 1 b DSGVO)
      </p>
      <br />
      <p>
        Die Verarbeitung von Daten erfolgt zur Erbringung von Finanzdienstleistungen im Rahmen der Durchführung  unserer
        Verträge  mit  unseren  Kunden oder  zur  Durchführung  vorvertraglicher  Maßnahmen,  die  auf  Anfrage  hin  erfolgen.
        Die  Zwecke der Datenverarbeitung richten sich in erster Linie nach dem konkreten Produkt (z.B. Leasing, Miete, Mietkauf, Factoring)
        und können unter anderem Bedarfsanalysen,  Beratung  und  Refinanzierung  umfassen.  Die  weiteren  Einzelheiten
        zu  den  Datenverarbeitungszwecken  können  Sie  den  maßgeblichen  Vertragsunterlagen und Geschäftsbedingungen entnehmen.
      </p>
      <br />
      <p>
        b. Im Rahmen der Interessenabwägung (Art. 6 Abs. 1 f DSGVO)
      </p>
      <br />
      <p>
        Soweit erforderlich verarbeiten wir Ihre Daten über die eigentliche Erfüllung des Vertrages  hinaus  zur  Wahrung
        berechtigter Interessen von uns oder Dritten. Beispiele:<br />
        - Konsultation von und Datenaustausch mit Auskunfteien (z.B. Creditreform) zur Ermittlung von Bonitäts- bzw. Ausfallrisiken,<br />
        - Prüfung und Optimierung von Verfahren zur Bedarfsanalyse zwecks direkter Kundenansprache,<br />
        - Werbung oder Markt- und Meinungsforschung, soweit Sie der Nutzung Ihrer Daten nicht widersprochen haben,<br />
        - Geltendmachung rechtlicher Ansprüche und Verteidigung bei rechtlichen Streitigkeiten,<br />
        - Datenaustausch mit Sicherheitengebern,<br />
        - Gewährleistung der IT-Sicherheit und des IT-Betriebs,<br />
        - Verhinderung und Aufklärung von Straftaten,<br />
        - Maßnahmen zur Geschäftssteuerung und Weiterentwicklung von Dienstleistungen und Produkten,<br />
        - Risikosteuerung innerhalb der abc-Gruppe
      </p>
      <br />
      <p>
        c. Aufgrund Ihrer Einwilligung (Art. 6 Abs. 1 a DSGVO)
      </p>
      <br />
      <p>
        Soweit  Sie  uns  eine  Einwilligung  zur  Verarbeitung  von  personenbezogenen  Daten für bestimmte Zwecke
        (z.B. Auswertung von Zahlungsverkehrsdaten für Marketingzwecke) erteilt haben, ist die Rechtmäßigkeit dieser
        Verarbeitung auf Basis Ihrer Einwilligung gegeben. Eine erteilte Einwilligung kann jederzeit widerrufen werden.
        Dies gilt auch für den Widerruf von Einwilligungserklärungen, die vor der Geltung der DSGVO, also vor dem 25. Mai 2018,
        uns gegenüber erteilt worden  sind.  Der  Widerruf  der  Einwilligung  berührt nicht die Rechtmäßigkeit der bis zum Widerruf verarbeiteten Daten.
      </p>
      <br />
      <p>
        d. Aufgrund gesetzlicher Vorgaben (Art. 6 Abs.1 c DSGVO) oder im öffentlichen Interesse (Art. 6 Abs. 1 e DSGVO)
      </p>
      <br />
      <p>
        Zudem  unterliegen  wir  als  Finanzdienstleistungsinstitut  diversen  rechtlichen  Verpflichtungen,  das  heißt
        gesetzlichen  Anforderungen  (z.B.  Kreditwesengesetz, Geldwäschegesetz, Steuergesetze) sowie aufsichtsrechtlichen
        Vorgaben (z.B.  der  Europäischen  Zentralbank,  der  Europäischen  Bankenaufsicht,  der  Deutschen  Bundesbank  und  der  Bundesanstalt  für  Finanzdienstleistungsaufsicht).
        Zu  den  Zwecken  der  Verarbeitung  gehören  unter  anderem  die  Kreditwürdigkeitsprüfung,  die  Identitäts-  und  Altersprüfung,
        Betrugs-  und  Geldwäscheprävention,  die  Erfüllung  steuerrechtlicher  Kontroll-  und  Meldepflichten  sowie die
        Bewertung und Steuerung von Risiken in der abcfinance GmbH und innerhalb der abc-Gruppe.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          4. Wer bekommt meine Daten?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Innerhalb  unseres  Unternehmens  erhalten  diejenigen  Stellen  Zugriff  auf  Ihre  Daten,
        die  diese  zur  Erfüllung  unserer  vertraglichen  und  gesetzlichen  Pflichten  brauchen.
        Auch  von  uns  eingesetzte  Dienstleister  und  Erfüllungsgehilfen  können zu diesen Zwecken Daten erhalten,
        wenn diese sich zur Vertraulichkeit verpflichten.  Dies  sind  Unternehmen  in  den  Kategorien
        kreditwirtschaftliche  Leistungen,  IT-Dienstleistungen,  Logistik,  Druckdienstleistungen,
        Telekommunikation, Inkasso, Beratung und Consulting sowie Vertrieb und Marketing.
      </p>
      <br />
      <p>
        Im  Hinblick  auf  die  Datenweitergabe  an  Empfänger  außerhalb  unseres  Unter-nehmens ist zunächst zu beachten,
        dass wir alle kundenbezogenen Tatsachen und  Wertungen,  von  denen  wir  Kenntnis  erlangen,  vertraulich  behandeln.
        Informationen  über  Sie  dürfen  wir  nur  weitergeben,  wenn  gesetzliche  Bestimmungen  dies  gebieten  oder
        Sie  eingewilligt  haben.  Unter  diesen  Voraussetzungen können Empfänger personenbezogener Daten z. B. sein:
      </p>
      <br />
      <p>
        a. Öffentliche Stellen und Institutionen (z. B. Deutsche Bundesbank, Bundesanstalt  für  Finanzdienstleistungsaufsicht,
        Europäische  Bankenaufsichtsbehörde,  Europäische  Zentralbank,  Finanzbehörden,  Strafverfolgungsbehörden)
        bei Vorliegen einer gesetzlichen oder behördlichen Verpflichtung.
      </p>
      <br />
      <p>
        b.  Andere  Kredit-  und  Finanzdienstleistungsinstitute  oder  vergleichbare  Einrichtungen,
        an die wir zur Durchführung der Geschäftsbeziehung mit Ihnen personenbezogene Daten übermitteln,
        je nach Vertrag z. B. Korrespondenzbanken, Refinanzierer, Kreditversicherer, Detekteien, Auskunfteien, insb.
      </p>
      <br />
      <p>
        Datenübermittlung an die Creditreform Boniversum GmbH:<br />
        Im Rahmen unseres Geschäftsmodells Factoring übermitteln wir
        der Auskunftei Creditreform  Boniversum  GmbH,  Hellersbergstraße  11,  41460  Neuss,  (nachfolgend Boniversum genannt)
        Daten über die Beantragung, die Aufnahme und vereinbarungsgemäße Abwicklung und Beendigung der Geschäftsbeziehung.<br />
        Unabhängig  davon  werden  wir  der  Boniversum  auch  Daten  aufgrund  nicht  vertragsgemäßen  Verhaltens  oder  betrügerischen  Verhaltens  melden.
        Diese  Meldungen dürfen nach der DSGVO/BDSG nur erfolgen, soweit dies nach der Abwägung aller betroffenen Interessen zulässig ist.<br />
        Die  Boniversum  speichert  und  übermittelt  die  Daten  an  ihre  Vertragspartner  im  europäischen  Binnenmarkt,
        um  diesen  Informationen  zur  Beurteilung  der  Kreditwürdigkeit von natürlichen Personen zu geben.
        Vertragspartner der Boniversum  sind  vor  allem  Kreditinstitute  sowie  Kreditkarten-  und  Leasinggesellschaften.
        Daneben  erteilt  Boniversum  auch  Auskünfte  an  Handels-,  Telekommunikations- und sonstige Unternehmen, die Leistungen
        und Lieferungen gegen Kredit gewähren. Die Boniversum stellt Daten nur zur Verfügung, wenn ein  berechtigtes  Interesse  hieran
        im  Einzelfall  glaubhaft  dargelegt  wurde.  Zur Schuldnerermittlung gibt die Boniversum Adressdaten bekannt.
        Bei der Erteilung von Auskünften kann die Boniversum ihren Vertragspartnern ergänzend einen aus ihrem Datenbestand
        errechneten Wahrscheinlichkeitswert zur Beurteilung des Kreditrisikos mitteilen (Score-Verfahren).<br />
        Nähere  Informationen  zur  Tätigkeit  der  Boniversum  kann  dem  Boniversum-Informationsblatt zur EU-DSGVO entnommen
        oder online unter <a href="http://www.boniver-sum.de/EU-DSGVO" target="_blank" rel="noopener noreferrer">www.boniver-sum.de/EU-DSGVO</a> eingesehen werden.
      </p>
      <br />
      <p>
        c. Andere  Unternehmen  in  der  abc-Gruppe  zur  Risikosteuerung  aufgrund  gesetzlicher oder behördlicher Verpflichtung.
      </p>
      <br />
      <p>
        d. Lieferanten oder andere Dritte, die uns eine Sicherheit für die Erfüllung Ihres Vertrages gewährt haben oder gewähren.
      </p>
      <br />
      <p>
        e. Zuträger (z. B. Makler) im Rahmen etwaiger Provisionsabreden.
      </p>
      <br />
      <p>
        f.  Makler, Lieferanten oder andere Dritte, die uns im Rahmen des Forderungsmanagements unterstützen.
      </p>
      <br />
      <p>
        g. Weitere Datenempfänger können diejenigen Stellen sein, für die Sie uns Ihre Einwilligung zur Datenübermittlung erteilt haben.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          5. Werden Daten in ein Drittland oder an eine internationale Organisation übermittelt?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Eine Datenübermittlung an Stellen in Staaten außerhalb der Europäischen Uni-on (sogenannte Drittstaaten) findet statt,
        soweit<br />
        - es zur Ausführung Ihrer Aufträge erforderlich ist (z. B. Zahlungsaufträge),<br />
        - es gesetzlich vorgeschrieben ist (z.B. steuerrechtliche Meldepflichten) oder<br />
        - Sie uns Ihre Einwilligung erteilt haben.<br />
        Darüber hinaus kann insbesondere im Rahmen beauftragter IT-Dienstleistungen eine Datenübermittlung in Drittstaaten
        (insbesondere Schweiz, USA, Dubai) erfolgen.  In  Ländern,  für  die  kein  Angemessenheitsbeschluss  der  Kommission
        besteht, sind sämtliche beauftragte Dienstleister entweder nach dem EU US-Privacy Shield zertifiziert und bieten
        damit geeignete Garantien zur Einhaltung des  Datenschutzes  oder  es  wurden  EU-Standardvertragsklauseln  vereinbart
        oder  der  Dienstleister  verfügt  über  verbindliche  interne  Datenschutzvorschriften, die von einer Aufsichtsbehörde
        genehmigt wurden. Informationen zum EU US-Privacy Shield erhalten Sie unter:<br />
        <a href="https://www.privacyshield.gov" target="_blank" rel="noopener noreferrer">www.privacyshield.gov</a><br />
        Die EU-Standardvertragsklauseln können sie einsehen unter:<br />
        <a href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32010D0087" target="_blank" rel="noopener noreferrer">eur-lex.europa.eu</a>
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          6. Wie lange werden meine Daten gespeichert?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Wir verarbeiten und speichern Ihre personenbezogenen Daten, solange es für die  Erfüllung  unserer  vertraglichen
        und  gesetzlichen  Pflichten  erforderlich  ist.  Dabei  ist  zu  beachten,  dass  unsere  Geschäftsbeziehung  ein
        Dauerschuldverhältnis ist, welches auf Jahre angelegt ist.<br />
        Sind die Daten für die Erfüllung vertraglicher oder
        gesetzlicher Pflichten nicht mehr erforderlich, werden diese regelmäßig gelöscht, es sei denn, deren – befristete – Weiterverarbeitung
        ist erforderlich zu folgende Zwecken:<br />
        - Erfüllung handels- und steuerrechtlicher Aufbewahrungspflichten: Zu nennen sind das
        Handelsgesetzbuch (HGB), die Abgabenordnung (AO), das Kreditwesengesetz  (KWG)  und  das  Geldwäschegesetz  (GwG).
        Die  dort  vorgegebenen Fristen zur Aufbewahrung bzw. Dokumentation betragen zwei bis zehn Jahre.<br />
        - Erhaltung  von Beweismitteln  im  Rahmen  der  gesetzlichen  Verjährungsvorschriften. Nach den §§ 195ff. des Bürgerlichen Gesetzbuches
        (BGB) können diese  Verjährungsfristen  bis  zu  30  Jahre  betragen,  wobei  die  regelmäßige  Verjährungsfrist 3 Jahre beträgt.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          7. Welche Datenschutzrechte habe ich?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Jede  betroffene  Person  hat  das  Recht  auf  Auskunft  nach  Artikel  15  DSGVO,  das Recht auf Berichtigung nach
        Artikel 16 DSGVO, das Recht auf Löschung nach Artikel 17 DSGVO, das Recht auf Einschränkung der Verarbeitung nach
        Artikel  18  DSGVO,  das  Recht  auf  Widerspruch  aus  Artikel  21  DSGVO  sowie  das  Recht  auf  Datenübertragbarkeit
        aus  Artikel  20  DSGVO.  Beim  Auskunftsrecht  und  beim  Löschungsrecht  gelten  die  Einschränkungen  nach  §§  34  und  35 BDSG.
        Darüber hinaus besteht ein Beschwerderecht bei einer zuständigen Datenschutzaufsichtsbehörde (Artikel 77 DSGVO i.V.m. § 19 BDSG).
      </p>
      <br />
      <p>
        Eine erteilte Einwilligung in die Verarbeitung personenbezogener Daten können Sie jederzeit uns gegenüber widerrufen.
        Dies gilt auch für den Widerruf von Einwilligungserklärungen,  die  vor  der  Geltung  der  Datenschutzgrundverordnung,
        also vor dem 25. Mai 2018, uns gegenüber erteilt worden sind. Bitte beachten Sie, dass der Widerruf erst für die Zukunft wirkt.
        Verarbeitungen, die vor dem Widerruf erfolgt sind, sind davon nicht betroffen.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          8. Gibt es für mich eine Pflicht zur Bereitstellung von Daten?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Im  Rahmen  unserer  Geschäftsbeziehung  müssen  Sie  diejenigen  personenbezogenen  Daten  bereitstellen,
        die  für  die  Aufnahme  und  Durchführung  einer  Geschäftsbeziehung  und  der  Erfüllung  der  damit  verbundenen
        vertraglichen  Pflichten  erforderlich  sind  oder  zu  deren  Erhebung  wir  gesetzlich  verpflichtet  sind.
        Ohne  diese  Daten  werden  wir  in  der  Regel  nicht  in  der  Lage  sein,  den  Vertrag mit Ihnen zu schließen oder diesen auszuführen.
      </p>
      <br />
      <p>
        nsbesondere sind wir nach den geldwäscherechtlichen Vorschriften verpflichtet,  Sie  vor  der  Begründung  der
        Geschäftsbeziehung  anhand  Ihres  Ausweisdokumentes  zu  identifizieren  und  dabei  Namen,  Geburtsort,
        Geburtsdatum,  Staatsangehörigkeit, Anschrift sowie Ausweisdaten zu erheben und festzuhalten. Damit wir dieser
        gesetzlichen Verpflichtung nachkommen können, haben Sie uns nach dem Geldwäschegesetz die notwendigen Informationen
        und Unterlagen  zur  Verfügung  zu  stellen  und  sich  im  Laufe  der  Geschäftsbeziehung  ergebende Änderungen
        unverzüglich anzuzeigen. Sollten Sie uns die notwendigen Informationen und Unterlagen nicht zur Verfügung stellen,
        dürfen wir die von Ihnen gewünschte Geschäftsbeziehung nicht aufnehmen oder fortsetzen.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          9. In wieweit gibt es eine automatisierte Entscheidungsfindung?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Damit wir unsere Finanzierungslösungen effizient und zielgerecht anbieten können,  nutzen  wir  auch  die
        Möglichkeit  der  vollautomatisierten  Entscheidungsfindung.
      </p>
      <br />
      <p>
        Dies betrifft die folgenden Entscheidungen:<br />
        - Kreditwürdigkeit (nur bei den Produkten „Cashfox“ und „eLeasing“): Im Rahmen der Beurteilung Ihrer Kreditwürdigkeit
        nutzen wir das Scoring. Dabei wird die  Wahrscheinlichkeit  berechnet,  mit  der  ein  Kunde  seinen
        Zahlungsverpflichtungen  vertragsgemäß  nachkommen  wird.  In  die  Berechnung  können  beispielsweise Einkommensverhältnisse,
        Ausgaben, bestehende Verbindlich-keiten, Beruf, Arbeitgeber, Beschäftigungsdauer, Erfahrungen aus der bisherigen Geschäftsbeziehung,
        vertragsgemäße Rückzahlung früherer Kredite sowie Informationen von Kreditauskunfteien einfließen. Das Scoring beruht
        auf einem  mathematisch-statistisch  anerkannten  und  bewährten  Verfahren.  Auf  Grundlage  der  errechneten  Werte
        erfolgt  eine  automatisierte  Entscheidung,  ob wir Ihnen die gewünschte Finanzierungslösung anbieten können.<br />
        - Forderungsankauf (nur bei dem Produkt „Cashfox“): Über unser Internet-Portal (<a href="https://www.cashfox.de" target="_blank" rel="noopener noreferrer">www.cashfox.de</a>) bieten wir Nutzern die Möglichkeit,
        uns Forderungen gegenüber Dritten zum Kauf anzudienen. Hierfür haben Sie die Möglichkeit, insbesondere Rechnungen im
        Internet-Portal hochzuladen. Die Rechnungsdaten werden  von  uns  automatisiert  ausgelesen.  Außerdem  erfolgt  auf
        Grundlage  relevanter Datenfelder der Rechnung (insbesondere Name und Anschrift des Debitors, Rechnungsbetrag netto, Mehrwertsteuer,
        Rechnungsbetrag brutto, Rechnungsnummer, Zahlungsziel, Rechnungsdatum, IBAN) in Verbindung mit vorhandenen  Bonitätsdaten
        des  Nutzers  eine  automatisierte  Entscheidung  darüber, ob wir die konkrete Forderung ankaufen.<br />
        - Mahnlauf (nur bei dem Produkt „Cashfox“): Bei fälligen bzw. überfälligen Forderungen nutzen wir einen automatisierten Mahnlauf,
        um Sie über ausstehende Zahlungen zu informieren. Dabei werden fällige Forderungen mit vorhandenen Zahlungseingängen automatisiert abgeglichen.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          10. Findet Profiling statt?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        Wir verarbeiten teilweise Ihre Daten automatisiert mit dem Ziel, bestimmte persönliche Aspekte zu bewerten (Profiling).
        Wir setzen Profiling beispielsweise in folgenden Fällen ein:<br />
        - Aufgrund  gesetzlicher  und  regulatorischer  Vorgaben  sind  wir  zur  Bekämpfung   von   Geldwäsche,
        Terrorismusfinanzierung   und   vermögensgefährdenden Straftaten verpflichtet. Dabei werden auch Datenauswertungen
        (u.a. im Zahlungsverkehr) vorgenommen. Diese Maßnahmen dienen zugleich auch Ihrem Schutz.<br />
        - Um  Sie  zielgerichtet  über  Produkte  informieren  und  beraten  zu  können,  set-zen wir Auswertungsinstrumente ein.
        Diese ermöglichen eine bedarfsgerechte Kommunikation und Werbung einschließlich Markt- und Meinungsforschung.<br />
        - Im  Rahmen  der  Beurteilung  Ihrer  Kreditwürdigkeit  nutzen  wir  das  Scoring.  Dabei wird die Wahrscheinlichkeit
        berechnet, mit der ein Kunde seinen Zahlungsverpflichtungen  vertragsgemäß  nachkommen  wird.  In  die  Berechnung
        können   beispielsweise   Einkommensverhältnisse,   Ausgaben,   bestehende   Verbindlichkeiten, Beruf, Arbeitgeber,
        Beschäftigungsdauer, Erfahrungen aus der  bisherigen  Geschäftsbeziehung,  vertragsgemäße  Rückzahlung  früherer
        Kredite sowie Informationen von Kreditauskunfteien einfließen. Das Scoring beruht auf einem mathematisch-statistisch
        anerkannten und bewährten Verfahren. Die errechneten Scorewerte unterstützen uns bei der Entscheidungsfindung  im
        Rahmen  von  Produktabschlüssen  und  gehen  in  das  laufende  Risikomanagement mit ein.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Information über Ihr Widerspruchsrecht nach Artikel 21 Datenschutz-Grundverordnung (DSGVO)
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        1. Einzelfallbezogenes  Widerspruchsrecht:  Sie  haben  das  Recht,  aus  Gründen,  die  sich  aus  Ihrer  besonderen
        Situation  ergeben,  jederzeit  gegen  die  Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von
        Artikel 6 Absatz 1 Buchstabe e DSGVO (Datenverarbeitung im öffentlichen Interesse)  und  Artikel  6  Absatz  1  Buchstabe  f  DSGVO
        (Datenverarbeitung  auf  der  Grundlage  einer  Interessenabwägung)  erfolgt,  Widerspruch  einzulegen;  dies  gilt  auch
        für  ein  auf  diese  Bestimmung  gestütztes  Profiling  im  Sinne von Artikel 4 Nr. 4 DSGVO. Legen Sie Widerspruch ein,
        werden wir Ihre  personenbezogenen  Daten  nicht  mehr  verarbeiten,  es  sei  denn,  wir  können zwingende schutzwürdige
        Gründe für die Verarbeitung nachweisen, die  Ihre  Interessen,  Rechte  und  Freiheiten  überwiegen,  oder  die  Verarbeitung
        dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
      </p>
      <br />
      <p>
        2. Widerspruchsrecht  gegen  eine  Verarbeitung  von  Daten  für  Zwecke  der  Direktwerbung: In Einzelfällen
        verarbeiten wir Ihre personenbezogenen Daten, um Direktwerbung zu betreiben. Sie haben das Recht, jederzeit Widerspruch
        gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch
        für das Profiling, soweit  es  mit  solcher  Direktwerbung  in  Verbindung  steht.  Widersprechen  Sie der Verarbeitung
        für Zwecke der Direktwerbung, so werden wir Ihre personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.
      </p>
      <br />
      <p>
        Der Widerspruch kann formfrei erfolgen und sollte möglichst gerichtet werden an:
      </p>
      <br />
      <p>
        <a href="mailto:datenschutz@abcfinance.de">datenschutz&#64;abcfinance.de</a>
      </p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
