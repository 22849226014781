// #region Imports

import { BrokerCustomizationService } from '@abcfinlab/api/contract';
import { UserService } from '@abcfinlab/auth';
import { once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { base64ToBlob } from '../../../../../apps/l7/src/app/helper/presentation.helper';
import { DialogService } from '../../../../../apps/l7/src/app/private/services/dialog/dialog.service';
import { Globals } from '../../../../core/src/Globals';
import { LogoUploadComponent } from '../logo-upload/logo-upload.component';

// #endregion

/**
 * The presenter of the {@link ConfigurationOverviewView} view.
 *
 * @internal
 */
@UntilDestroy()
@Injectable()
export class ConfigurationOverviewViewPresenter {

    // #region Fields

    private readonly _brokerCustomizationService: BrokerCustomizationService;
    private readonly _busyBox: BusyBoxService;
    private readonly _userService: UserService;
    private readonly _logoUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _useCustomLogo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `ConfigurationOverviewViewPresenter` class.
     *
     * @public
     */
    public constructor(brokerCustomizationService: BrokerCustomizationService, busyBox: BusyBoxService, userService: UserService,
        private readonly _dialog: DialogService, private readonly translate: TranslationFacade, private readonly notification: ToastService) {
        this._brokerCustomizationService = brokerCustomizationService;
        this._busyBox = busyBox;
        this._userService = userService;
    }

    // #endregion

    // #region Properties
    /**
     * Returns the `logoImage` property.
     *
     * @public
     * @readonly
     */
    public get logoImageURL(): Observable<string> {
        return this._logoUrl.asObservable();
    }

    public get isCustomLogoUsed(): Observable<boolean> {
        return this._useCustomLogo.asObservable();
    }
    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        once(this._userService.getUserInfo(), (userInfo) => {
            this._busyBox.show(undefined, '', this._brokerCustomizationService.getLogo({ groupId: userInfo.groupId }))
                .subscribe((x) => {
                    const blob = base64ToBlob(x.base64EncodedLogo);
                    const imageURL = URL.createObjectURL(blob);
                    this._logoUrl.next(imageURL);
                    this._useCustomLogo.next(x.customized);
                });
        });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        // optional
    }

    public showContractDraft(): void {
        this._brokerCustomizationService.getContractDraft().pipe().subscribe((draftPdf) => {
            const draftPdfUrl = URL.createObjectURL(draftPdf);
            window.open(draftPdfUrl, '_blank');
        }, () => {
            this.notification.showError(this.translate.instant('error.generic_error'));
        });
    }

    public openLogoUploadDialog(uploadedFile: any, defaultLogoConfig: typeof Globals.defaultLogoConfig): void {
        const dialogRef = this._dialog.openDialog(LogoUploadComponent, {}, { imagetoCrop: uploadedFile, logoConfig: defaultLogoConfig });
        dialogRef.afterClosed().pipe(
            untilDestroyed(this),
        ).subscribe((result) => {
            if (result) {
                once(this._userService.getUserInfo(), (userInfo) => {
                    this._brokerCustomizationService.postLogo({ groupId: userInfo.groupId, body: { logo: result.croppedImageFile } })
                        .pipe(untilDestroyed(this))
                        .subscribe(() => {
                            this.initialize();
                            this.notification.showSuccess(this.translate.instant('global.save_successful'));
                        }, (_error) => {
                            this._useCustomLogo.next(false);
                            this.notification.showError(this.translate.instant('error.generic_error'));
                        });
                });
            }
        });
    }

    public resetLogo(): void {
        once(this._userService.getUserInfo(), (userInfo) => {
            this._busyBox.show(undefined, '', this._brokerCustomizationService.deleteLogo({ groupId: userInfo.groupId }))
                .subscribe(() => {
                    this.initialize();
                });
        });
    }

    // #endregion

}
