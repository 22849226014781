import {
    IRetailerAcknowledgementSignRemoteRequestDto,
    IRetailerRemoteAcknowledgementResultDto,
} from '@abcfinlab/api/contract';
import { Validators } from '@abcfinlab/core';
import { ToastService } from '@abcfinlab/ui';
import { DatePipe } from '@angular/common';
import { Component, computed, EventEmitter, inject, input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators as NgValidators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

interface IDigitalSignDialogViewPresenterFormData {
    general: FormGroup<{
        signingCheckBox1: FormControl<boolean>;
        signingCheckBox2: FormControl<boolean>;
        signingCheckBox3: FormControl<boolean>;
        signedByDate: FormControl<string>;
        signedByFirstName: FormControl<string>;
        signedByLastName: FormControl<string>;
    }>;
}
export interface IRetailerAcknowledgmentSingDataDTO {
    signRequestData: IRetailerAcknowledgementSignRemoteRequestDto;
    signatureFile: Blob;
}

@Component({
    selector: 'l7-retailer-acknowledgment-signature',
    templateUrl: './retailer-acknowledgment-signature.component.html',
    styleUrls: ['./retailer-acknowledgment-signature.component.scss'],
    standalone: false,
})
export class RetailerAcknowledgmentSignatureComponent implements OnInit {

    private readonly _toastService = inject(ToastService);
    private readonly _datePipe: DatePipe;
    private readonly _showSuccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    singingInfo = input.required<IRetailerRemoteAcknowledgementResultDto>();
    checkboxTranslationKey = computed(() => {
        const { firstInstalment, lastInstalment } = this.singingInfo();
        if (firstInstalment === 0 && lastInstalment === 0) {
            return 'checkBoxConditional';
        } else if (firstInstalment !== 0 && lastInstalment !== 0) {
            return 'checkBoxConditionalWithResidualValueAndDownPayment';
        } else if (firstInstalment !== 0 && lastInstalment === 0) {
            return 'checkBoxConditionalWithDownPayment';
        } else if (firstInstalment === 0 && lastInstalment !== 0) {
            return 'checkBoxConditionalWithResidualValue';
        }
        return 'checkBoxConditional';
    });

    @Output() contractSigned: EventEmitter<IRetailerAcknowledgmentSingDataDTO> = new EventEmitter<IRetailerAcknowledgmentSingDataDTO>();
    @Output() showSerialNumbers: EventEmitter<boolean> = new EventEmitter<boolean>();
    public isSignatureDirty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public resetSignature$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public signature$: BehaviorSubject<Blob> = new BehaviorSubject<Blob>(null);
    private _form: FormGroup<IDigitalSignDialogViewPresenterFormData>;

    constructor(
        datePipe: DatePipe,
    ) {
        this._datePipe = datePipe;
    }

    public get signForm(): FormGroup<IDigitalSignDialogViewPresenterFormData> {
        return this._form;
    }

    /**
     * Get the current date to validate against
     */
    public get currentDate(): string {
        return this._datePipe.transform(new Date(), 'yyyy-MM-dd');
    }

    /**
     * Get the minimum date to validate against
     */
    public get minDate(): string {
        return this._datePipe.transform(new Date(this.singingInfo().creationDate), 'yyyy-MM-dd');
    }

    private _initForm(): void {
        this._form = new FormGroup<IDigitalSignDialogViewPresenterFormData>({
            general: new FormGroup({
                signedByFirstName: new FormControl<string>('', NgValidators.required),
                signedByLastName: new FormControl<string>('', NgValidators.required),
                signedByDate: new FormControl<string>(this.singingInfo().expectedAcknowledgementDate,
                    [
                        NgValidators.required,
                        Validators.minDate(this.singingInfo().creationDate),
                        Validators.futureDateValidator(),
                    ],
                ),
                signingCheckBox1: new FormControl<boolean>(false),
                signingCheckBox2: new FormControl<boolean>(false, NgValidators.requiredTrue),
                signingCheckBox3: new FormControl<boolean>(false, NgValidators.requiredTrue),
            }),
        });
    }

    public ngOnInit(): void {
        this._initForm();
    }

    public get showSuccess(): Observable<boolean> {
        return this._showSuccess.asObservable();
    }

    public showSerialNumberDocument(): void {
        this.showSerialNumbers.emit(true);
    }

    public doAcknowledgmentSigning(): void {
        if (this.signForm.valid && this.signature$.getValue() && !(this.singingInfo().isPriceChanged && !this.signForm.controls.general.controls.signingCheckBox1.value)) {
            const formData = this.signForm.getRawValue();
            const signReqData: IRetailerAcknowledgementSignRemoteRequestDto = { acknowledgementDate: formData.general.signedByDate, signedByFirstName: formData.general.signedByFirstName, signedByLastName: formData.general.signedByLastName };
            this.contractSigned.emit({ signatureFile: this.signature$.getValue(), signRequestData: signReqData });
        } else {
            this._toastService.show('Bitte überprüfen Sie ihre Angaben.', 'danger');
        }
    }

}
