<l7-page *cdkBreakpoint="let bp">
    <l7-page-pre-content *ngIf="!isMobile"
                         fxLayoutAlign="end">
        <l7-page-section>
            <button mat-icon-button
                    [color]="activeView === 'CARD' ? 'accent' : ''"
                    (click)="changeViewType('CARD')">
                <mat-icon [fontSet]="'l7'"
                          [fontIcon]="'Elements'"></mat-icon>
            </button>
            <button mat-icon-button
                    (click)="changeViewType('TABLE')">
                <mat-icon [color]="activeView === 'TABLE' ? 'accent' : ''"
                          [fontSet]="'l7'"
                          [fontIcon]="'Grid_15'"></mat-icon>
            </button>
        </l7-page-section>
    </l7-page-pre-content>
    <l7-page-header [title]="'Angebotsübersicht für '+ presenter.lesseName | translate"
                    [hasBackNavigation]="true"
                    [cancelNavigation]="false">
        <header-icon fxLayoutAlign="start center">
            <mat-icon *ngIf="presenter.isDraft"
                      [fontSet]="'l7'"
                      matTooltip="{{'quote.draftTooltip' | translate}}"
                      [color]="'warning'"
                      [fontIcon]="'New_Release'">
            </mat-icon>
        </header-icon>
    </l7-page-header>
    <l7-page-content>
        <l7-page-section fxLayout="column">
            <mat-accordion multi>
                <mat-expansion-panel *ngFor="let quoteGroup of (presenter.quoteGroups | async); let i = index"
                                     [expanded]="i === 0"
                                     [attr.test-id]="'expansionPanel'+ quoteGroup.groupId">
                    <mat-expansion-panel-header matRipple>
                        <mat-panel-title fxLayoutAlign="start center"
                                         fxLayoutGap="16px">
                            <p>
                                <span>{{ (quoteGroup.groupName) }}</span>
                                <ng-container *ngIf="quoteGroup.nav_info?.quote_no">{{' | ' + quoteGroup.nav_info?.quote_no}}</ng-container>
                            </p>
                            <!--                            <l7-delete-quote [versions]="quoteGroup.versions"-->
                            <!--                                             (delete)="deleteQuote($event)">-->
                            <!--                            </l7-delete-quote>-->
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container *ngIf="activeView === 'TABLE'">

                    </ng-container>
                    <l7-retailer-quote-versions-table *ngIf="activeView === 'TABLE'"
                                                      [versions]="quoteGroup.versions"
                                                      [menuActions]="quoteVersionActions"
                                                      (goToVersionDetailView)="presenter.showLesseeQuoteDetails($event)"
                                                      (menuItemClicked)="presenter.triggerMenuAction($event)">
                    </l7-retailer-quote-versions-table>
                    <l7-retailer-quote-versions-cards *ngIf="activeView === 'CARD'"
                                                      [versions]="quoteGroup.versions"
                                                      [menuActions]="quoteVersionActions"
                                                      (goToVersionDetailView)="presenter.showLesseeQuoteDetails($event)"
                                                      (menuItemClicked)="presenter.triggerMenuAction($event)">

                    </l7-retailer-quote-versions-cards>
                </mat-expansion-panel>
            </mat-accordion>
        </l7-page-section>
    </l7-page-content>
</l7-page>
