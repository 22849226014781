<div fxFlex="100" fxLayout="row" fxLayoutGap="8px">
    <mat-card *ngIf="activeVersion" class="mat-card-flat more-padding">
        <mat-card-header class="no-padding">
            <h3 matCardTitle class="padding-top">
                <mat-chip class="mat-neutral no-transform">
                    {{ 'KÜV unterschrieben' | translate }}
                </mat-chip>
            </h3>
        </mat-card-header>
        <mat-card-content class="more-padding">
            <div fxFlex
                 fxLayout="row wrap"
                 fxLayoutGap="16px">
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.totalLeasingValueNet' | translate }}</legend>
                    <p>{{activeVersion.calculation.totalLeasingValueNet | currency}}</p>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.vatPercentage' | translate }}</legend>
                    <p>{{isMKN ? ('quote.summary.totalInstalmentsVatMKN' | translate) : (activeVersion.calculation.vatPercentage + ' %') }}</p>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.vatAmount' | translate }}</legend>
                    <p>{{isMKN ? ('quote.summary.totalInstalmentsVatMKN' | translate) : (activeVersion.calculation.vatAmount | currency) }}</p>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.totalLeasingValueGross' | translate }}</legend>
                    <p>{{activeVersion.calculation.totalLeasingValueGross | currency}}</p>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.acknowledgementDate' | translate }}</legend>
                    <p>{{activeVersion.acknowledgementDate | date: 'shortDate'}}</p>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.leasingStartDate' | translate }}</legend>
                    <p>{{'slb.option_leasing_start_date_' + activeVersion.leasingStart | translate}}</p>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.billingDate' | translate }}</legend>
                    <p>{{activeVersion.billingDate | date: 'shortDate'}}</p>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.objectCondition' | translate }}</legend>
                    <p>{{'condition.' + objectCondition | translate}}</p>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.yearOfConstruction' | translate }}</legend>
                    <p>{{activeVersion.yearOfConstruction}}</p>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.objectSerialNumber' | translate }}</legend>
                    <p>{{activeVersion.objectSerialNumber}}</p>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ isMKN ? ('slb.contractManagement.taxNumberLesseeMkn' | translate) : ('slb.contractManagement.taxNumberLessee' | translate) }}</legend>
                    <p>{{activeVersion.taxNumberLessee}}</p>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ isMKN ? ('slb.contractManagement.billingNumberMkn' | translate) : ('slb.contractManagement.billingNumber' | translate) }}</legend>
                    <p>{{activeVersion.billingNumber}}</p>
                </fieldset>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card *ngIf="inactiveVersion" appearance="outlined" class="mat-card-flat more-padding">
        <mat-card-header class="no-padding">
            <h3 *ngIf="inactiveVersion && activeVersion; else onlyInactiveVersion" matCardTitle class="padding-top">
                <mat-chip class="mat-inactive">{{ 'Ausstehender KÜV (Angepasste Konditionen)' | translate }}</mat-chip>
            </h3>
            <ng-template #onlyInactiveVersion>
                <h3 matCardTitle class="padding-top">
                    <mat-chip class="mat-inactive">{{ 'Ausstehender KÜV' | translate }}</mat-chip>
                </h3>
            </ng-template>
        </mat-card-header>
        <mat-card-content class="more-padding inactive">
            <div fxFlex
                 fxLayout="row wrap"
                 fxLayoutGap="16px">
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.totalLeasingValueNet' | translate }}</legend>
                    <l7-compare-to [valueToShow]="inactiveVersion.calculation.totalLeasingValueNet | currency"
                                   [valueToCompare]="activeVersion?.calculation?.totalLeasingValueNet | currency">
                    </l7-compare-to>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.vatPercentage' | translate }}</legend>
                    <l7-compare-to *ngIf="!isMKN; else noVatPercentage"
                                   [valueToShow]="inactiveVersion.calculation.vatPercentage + ' %'" [valueToCompare]="activeVersion?.calculation?.vatPercentage + ' %'">
                    </l7-compare-to>
                    <ng-template #noVatPercentage>
                        <p>{{ 'quote.summary.totalInstalmentsVatMKN' | translate }}</p>
                    </ng-template>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.vatAmount' | translate }}</legend>
                    <l7-compare-to *ngIf="!isMKN; else noVatAmount"
                                   [valueToShow]="inactiveVersion.calculation.vatAmount | currency"  [valueToCompare]="activeVersion?.calculation?.vatAmount | currency">
                    </l7-compare-to>
                    <ng-template #noVatAmount>
                        <p>{{ 'quote.summary.totalInstalmentsVatMKN' | translate }}</p>
                    </ng-template>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.totalLeasingValueGross' | translate }}</legend>
                    <l7-compare-to [valueToShow]="inactiveVersion.calculation.totalLeasingValueGross | currency"
                                   [valueToCompare]="activeVersion?.calculation?.totalLeasingValueGross | currency">
                    </l7-compare-to>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.acknowledgementDate' | translate }}</legend>
                    <l7-compare-to [valueToShow]="inactiveVersion.acknowledgementDate | date: 'shortDate'"
                                   [valueToCompare]="activeVersion?.acknowledgementDate | date: 'shortDate'">
                    </l7-compare-to>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.leasingStartDate' | translate }}</legend>
                    <l7-compare-to [valueToShow]="'slb.option_leasing_start_date_' + inactiveVersion.leasingStart | translate"
                                   [valueToCompare]="'slb.option_leasing_start_date_' + activeVersion?.leasingStart | translate">
                    </l7-compare-to>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.billingDate' | translate }}</legend>
                    <l7-compare-to [valueToShow]="inactiveVersion.billingDate | date: 'shortDate'"
                                   [valueToCompare]="activeVersion?.billingDate | date: 'shortDate'">
                    </l7-compare-to>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.objectCondition' | translate }}</legend>
                    <p *ngIf="(activeVersion && !inactiveVersion) || (!activeVersion && inactiveVersion); else showText">
                        {{'condition.' + objectCondition | translate}}
                    </p>
                    <ng-template #showText>
                        <l7-compare-to [valueToShow]="'condition.' + objectCondition | translate"
                                       [valueToCompare]="'condition.' + objectCondition | translate">
                        </l7-compare-to>
                    </ng-template>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.yearOfConstruction' | translate }}</legend>
                    <l7-compare-to [valueToShow]="inactiveVersion.yearOfConstruction.toString()"
                                   [valueToCompare]="activeVersion?.yearOfConstruction.toString()">
                    </l7-compare-to>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + + '%' + ' - 16px)'">
                    <legend>{{ 'slb.contractManagement.objectSerialNumber' | translate }}</legend>
                    <l7-compare-to [valueToShow]="inactiveVersion.objectSerialNumber"
                                   [valueToCompare]="activeVersion?.objectSerialNumber">
                    </l7-compare-to>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ isMKN ? ('slb.contractManagement.taxNumberLesseeMkn' | translate) : ('slb.contractManagement.taxNumberLessee' | translate) }}</legend>
                    <l7-compare-to [valueToShow]="inactiveVersion.taxNumberLessee"
                                   [valueToCompare]="activeVersion?.taxNumberLessee">
                    </l7-compare-to>
                </fieldset>
                <fieldset
                    [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                    [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
                    <legend>{{ isMKN ? ('slb.contractManagement.billingNumberMkn' | translate) : ('slb.contractManagement.billingNumber' | translate) }}</legend>
                    <l7-compare-to [valueToShow]="inactiveVersion.billingNumber"
                                   [valueToCompare]="activeVersion?.billingNumber">
                    </l7-compare-to>
                </fieldset>

            </div>
        </mat-card-content>
    </mat-card>
</div>
