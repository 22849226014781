// #region Imports

import { ICommand } from '@abcfinlab/core';
import { inject, Injectable } from '@angular/core';
import { ICommandMeta } from '../Decorators/CommandDecorator';
import { CommandAggregator } from './CommandAggregator';

// #endregion

/**
 * @public
 */
@Injectable()
export class CommandProxy {

    // #region Fields

    private readonly _aggregator: CommandAggregator;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `CommandProxy` class.
     *
     * @public
     */
    public constructor() {
        this._aggregator = inject(CommandAggregator);
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `commands` property.
     *
     * @public
     * @readonly
     */
    public get commands(): ReadonlyArray<ICommand> {
        return Array.from(this._aggregator.all().values());
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public register(command: ICommand): void {
        const meta = this.reflect(command);
        this._aggregator.register(meta.key, command);
    }

    /**
     * @public
     */
    public resolve(key: string): ICommand {
        return this._aggregator.get(key);
    }

    /**
     * @private
     */
    private reflect(command: ICommand): ICommandMeta {
        const meta = Reflect.get(command, '$$__commandMeta');
        if (meta) {
            return meta as ICommandMeta;
        }

        throw new Error('The command is invalid. It seems that the \'@Commandable\' decorator is missing.');
    }

    // #endregion

}
