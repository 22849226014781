@if (lessee) {
    <l7-page-header [title]="lessee.name"
                    [subTitle]="subTitle"
                    [hasBackNavigation]="false"
                    [cancelNavigation]="true">
    </l7-page-header>
}

<div fxLayout="column"
     fxLayoutGap="24px" style="padding: 2px;">
    <mat-card>
        <mat-card-content fxLayout="column" fxFlexAlign="center">
            <l7-spacer [thickness]="'bottom'" [multiplier]="12">
            </l7-spacer>
            <div fxLayoutAlign="center">
                <img src="assets/images/Checkboard_done.svg" alt="">
            </div>
            <l7-spacer [thickness]="'bottom'" [multiplier]="48">
            </l7-spacer>
            <l7-text-block class="mat-headline-6"
                           [truncate]="true"
                           [color]="'bold'"
                           [text]="headerText | translate"
                           [alignment]="'center'"></l7-text-block>
            <l7-spacer [thickness]="'bottom'" [multiplier]="24">
            </l7-spacer>
            <l7-text-block [truncate]="true"
                           [wrap]="true"
                           [maxLines]="2"
                           [text]="successText | translate"
                           [alignment]="'center'"></l7-text-block>
            <l7-spacer [thickness]="'bottom'" [multiplier]="24">
            </l7-spacer>
            <l7-text-block [truncate]="true"
                           [maxLines]="2"
                           [text]="'Wir freuen uns auf die Zusammenarbeit!' | translate"
                           [alignment]="'center'"></l7-text-block>
            <l7-spacer [thickness]="'bottom'" [multiplier]="48">
            </l7-spacer>
            <div *ngIf="showButton" fxLayout="row" fxFlexAlign="center">
                <button mat-flat-button
                        color="primary"
                        [attr.test-id]="'btnGoToContractDetails'"
                        (click)="goToContract()">{{buttonText | translate}}</button>
            </div>

        </mat-card-content>
    </mat-card>
</div>
