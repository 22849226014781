<div fxLayout="column" fxLayoutGap="16px" class="skeleton-form">
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px">
    <div class="skeleton skeleton-select"></div>
    <div class="skeleton skeleton-select"></div>
  </div>
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px">
    <div class="skeleton skeleton-input"></div>
    <div class="skeleton skeleton-input"></div>
    <div class="skeleton skeleton-input"></div>
  </div>

  <div class="skeleton skeleton-button"></div>
</div>