<form *ngIf="form"
      [formGroup]="form">
    <div fxLayout="column"
         fxLayoutGap="16px">
        <mat-card>
            <mat-card-header class="full-width">
                <mat-card-title fxFlex="100"
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                    <div>{{ 'quote.calculation.view.extraPaymentsTitle' | translate }}</div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="more-padding" fxLayout="column" fxLayoutGap="16px" [formGroup]="form.controls.quoteCalculationRequest">
                <div *ngIf="contractType === 'TA' || contractType === 'KFZ'"
                     fxLayout="row wrap"
                     fxLayoutAlign="flex-start center"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="0px" fxLayoutGap="16px">
                    <mat-form-field appearance="outline"
                                    [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 40px)'"
                                    [fxFlex.lt-md]="'calc(' + 100 + '%' + ' - 16px)'">
                        <mat-label>{{ 'Restwert €' | translate }}</mat-label>
                        <input type="text"
                               formControlName="residualValue"
                               matInput
                               l7MarkAsTouched
                               l7Calculate
                               placeholder="Restwert €"
                               test-id="inputResidualValue"
                               currencyMask>
                        <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.residualValue.errors">
                            {{'error.value_between_values' | translate : {param1: minResidualValue * 100, param2: '100%'} }}
                        </mat-error>
                    </mat-form-field>
                    <mat-icon class="icon-link"
                              [fontSet]="'l7'"
                              [fontIcon]="'Link'"></mat-icon>
                    <mat-form-field appearance="outline"
                                    [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'"
                                    [fxFlex.lt-md]="'calc(' + 100 + '%' + ')'">
                        <mat-label>{{ 'Restwert %' | translate }}</mat-label>
                        <input type="text"
                               formControlName="residualValuePercentage"
                               matInput
                               l7MarkAsTouched
                               l7Calculate
                               placeholder="Restwert %"
                               test-id="inputResidualValuePercent"
                               currencyMask
                               [l7Min]="0"
                               [l7Max]="100"
                               [options]="{suffix: ' %'}">
                        <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.residualValuePercentage.errors">
                            {{'error.value_between_values' | translate : {param1: minResidualValue * 100, param2: '100 %'} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="contractType === 'TA' || contractType === 'KFZ' || contractType === 'VA' || ((contractType === 'MIETKAUF' || contractType === 'MKN') && (showExtraFields | async).downPayment)"
                     fxLayout="row wrap"
                     fxLayoutAlign="flex-start center"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="0px" fxLayoutGap="16px">
                    <mat-form-field appearance="outline"
                                    [fxFlex]="(contractType === 'MIETKAUF' || contractType === 'MKN') ? '0 1 calc(' + 100 / 2 + '%' + ' - 72px)' : '0 1 calc(' + 100 / 2 + '%' + ' - 40px)'"
                                    [fxFlex.lt-md]="'calc(' + 100 + '%' + ' - 16px)'">
                        <mat-label>{{ 'Anzahlung €' | translate }}</mat-label>
                        <input type="text"
                               formControlName="downPaymentValue"
                               matInput
                               l7MarkAsTouched
                               l7Calculate
                               test-id="inputDownPayment"
                               currencyMask
                               [placeholder]="'Anzahlung in Euro'">
                        <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.downPaymentValue.errors?.allowedValuesRelatedToLeasingValue || form.controls.quoteCalculationRequest.controls.downPaymentPercentage.errors?.allowedValuesRelatedToLeasingValue">
                            {{'error.value_between_values' | translate : {param1: '0', param2: downPaymentSettings.maxThreshold * 100 + '%'} }}
                        </mat-error>
                        <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.downPaymentValue.errors?.valueNotPossible">
                            {{'error.value_not_allowed' | translate }}
                        </mat-error>
                        <mat-icon *ngIf="form.controls.quoteCalculationRequest.controls.downPaymentPercentage.value > (downPaymentSettings.minThreshold * 100) && form.controls.quoteCalculationRequest.controls.downPaymentPercentage.value <= (downPaymentSettings.maxThreshold * 100)" matSuffix
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Alert_Circle'">
                        </mat-icon>
                        <mat-hint *ngIf="form.controls.quoteCalculationRequest.controls.downPaymentPercentage.value > (downPaymentSettings.minThreshold * 100) && form.controls.quoteCalculationRequest.controls.downPaymentPercentage.value <= (downPaymentSettings.maxThreshold * 100)"
                                  class="text-truncate">
                            {{ 'calculation.down_payment_warning' | translate }}
                        </mat-hint>
                    </mat-form-field>
                    <mat-icon class="icon-link"
                              [fontSet]="'l7'"
                              [fontIcon]="'Link'"></mat-icon>
                    <mat-form-field appearance="outline"
                                    [fxFlex]="(contractType === 'MIETKAUF' || contractType === 'MKN') ? '0 1 calc(' + 100 / 2 + '%' + ' - 48px)' : '0 1 calc(' + 100 / 2 + '%' + ' - 16px)'"
                                    [fxFlex.lt-md]="'calc(' + 100 + '%' + ')'">
                        <mat-label>{{ 'Anzahlung %' | translate }}</mat-label>
                        <input type="text"
                               formControlName="downPaymentPercentage"
                               matInput
                               l7MarkAsTouched
                               l7Calculate
                               test-id="inputDownPaymentPercent"
                               currencyMask
                               [placeholder]="'Anzahlung %'"
                               [l7Min]="0"
                               [l7Max]="100"
                               [options]="{suffix: ' %'}">
                        <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.downPaymentPercentage.errors?.allowedValuesRelatedToLeasingValue || form.controls.quoteCalculationRequest.controls.downPaymentValue.errors?.allowedValuesRelatedToLeasingValue">
                            {{'error.value_between_values' | translate : {param1: '0', param2: downPaymentSettings.maxThreshold * 100 + '%'} }}
                        </mat-error>
                        <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.downPaymentPercentage.errors?.invalid || form.controls.quoteCalculationRequest.controls.downPaymentPercentage.errors?.valueNotPossible">
                            {{'error.value_not_allowed' | translate }}
                        </mat-error>
                        <mat-icon *ngIf="form.controls.quoteCalculationRequest.controls.downPaymentPercentage.value > (downPaymentSettings.minThreshold * 100) && form.controls.quoteCalculationRequest.controls.downPaymentPercentage.value <= (downPaymentSettings.maxThreshold * 100)" matSuffix
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Alert_Circle'">
                        </mat-icon>
                        <mat-hint *ngIf="form.controls.quoteCalculationRequest.controls.downPaymentPercentage.value > (downPaymentSettings.minThreshold * 100) && form.controls.quoteCalculationRequest.controls.downPaymentPercentage.value <= (downPaymentSettings.maxThreshold * 100)" class="text-truncate">
                            {{ 'calculation.down_payment_warning' | translate }}
                        </mat-hint>
                    </mat-form-field>
                    <l7-custom-form-control *ngIf="(contractType === 'MIETKAUF' || contractType === 'MKN') && (showExtraFields | async).downPayment"
                                            l7Calculate
                                            formControlName="downPaymentValue"
                                            [attr.test-id]="'removeDP'"
                                            (click)="removeExtraField('downPayment')">
                        <button mat-icon-button
                                type="button"
                                class="button-trash">
                            <mat-icon [fontSet]="'l7'"
                                      [fontIcon]="'Trash'">
                            </mat-icon>
                        </button>
                    </l7-custom-form-control>
                </div>
                <ng-container *ngIf="contractType === 'MIETKAUF' || contractType === 'MKN'">
                    <div *ngIf="(showExtraFields | async).firstInstalment"
                         fxLayout="row wrap"
                         fxLayoutAlign="flex-start center"
                         fxLayout.lt-sm="column"
                         fxLayoutGap.lt-sm="0px" fxLayoutGap="16px">
                        <mat-form-field appearance="outline"
                                        [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 72px)'"
                                        [fxFlex.lt-md]="'calc(' + 100 + '%' + ' - 16px)'"
                                        [ngClass]="{'blocked': valueToCalculate === 'FIRST_INSTALMENT' }">
                            <mat-label>{{ 'calculation.first_instalment' | translate }}</mat-label>
                            <input type="text"
                                   formControlName="firstInstalmentValue"
                                   matInput
                                   l7MarkAsTouched
                                   l7Calculate
                                   test-id="inputFirstInstalment"
                                   currencyMask
                                   [placeholder]="'calculation.first_instalment' | translate">
                            <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.firstInstalmentValue.errors?.invalid || form.controls.quoteCalculationRequest.controls.firstInstalmentValue.errors?.valueNotPossible">
                                {{'error.value_not_allowed' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-icon class="icon-link"
                                  [fontSet]="'l7'"
                                  [ngClass]="{'blocked': valueToCalculate === 'FIRST_INSTALMENT' }"
                                  [fontIcon]="'Link'"></mat-icon>
                        <mat-form-field appearance="outline"
                                        [ngClass]="{'blocked': valueToCalculate === 'FIRST_INSTALMENT' }"
                                        [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 48px)'"
                                        [fxFlex.lt-md]="'calc(' + 100 + '%' + ')'">
                            <mat-label>{{ 'calculation.first_instalment_percent' | translate }}</mat-label>
                            <input type="text"
                                   formControlName="firstInstalmentPercentage"
                                   matInput
                                   l7MarkAsTouched
                                   l7Calculate
                                   test-id="inputFirstInstalmentPercent"
                                   currencyMask
                                   [placeholder]="'calculation.first_instalment_percent' | translate"
                                   [options]="{suffix: ' %'}">
                            <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.firstInstalmentPercentage.errors?.invalid || form.controls.quoteCalculationRequest.controls.firstInstalmentPercentage.errors?.valueNotPossible">
                                {{'error.value_not_allowed' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <l7-custom-form-control *ngIf="valueToCalculate !== 'FIRST_INSTALMENT'"
                                                l7Calculate
                                                formControlName="firstInstalmentValue"
                                                [attr.test-id]="'removeFI'"
                                                (click)="removeExtraField('firstInstalment')">
                            <button mat-icon-button
                                    type="button"
                                    class="button-trash">
                                <mat-icon [fontSet]="'l7'"
                                          [fontIcon]="'Trash'">
                                </mat-icon>
                            </button>
                        </l7-custom-form-control>

                    </div>
                    <div *ngIf="(showExtraFields | async).lastInstalment"
                         fxLayout="row wrap"
                         fxLayoutAlign="flex-start center"
                         fxLayout.lt-sm="column"
                         fxLayoutGap.lt-sm="0px" fxLayoutGap="16px">
                        <mat-form-field appearance="outline"
                                        [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 72px)'"
                                        [fxFlex.lt-md]="'calc(' + 100 + '%' + ' - 16px)'"
                                        [ngClass]="{'blocked': valueToCalculate === 'LAST_INSTALMENT' }">
                            <mat-label>{{ 'calculation.last_instalment' | translate }}</mat-label>
                            <input type="text"
                                   formControlName="lastInstalmentValue"
                                   matInput
                                   l7MarkAsTouched
                                   l7Calculate
                                   test-id="inputLastInstalment"
                                   currencyMask
                                   [placeholder]="'calculation.last_instalment' | translate">
                            <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.lastInstalmentValue.errors?.invalid || form.controls.quoteCalculationRequest.controls.lastInstalmentValue.errors?.valueNotPossible">
                                {{'error.value_not_allowed' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-icon class="icon-link"
                                  [fontSet]="'l7'"
                                  [ngClass]="{'blocked': valueToCalculate === 'LAST_INSTALMENT' }"
                                  [fontIcon]="'Link'"></mat-icon>
                        <mat-form-field appearance="outline"
                                        [ngClass]="{'blocked': valueToCalculate === 'LAST_INSTALMENT' }"
                                        [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 48px)'"
                                        [fxFlex.lt-md]="'calc(' + 100 + '%' + ')'">
                            <mat-label>{{ 'calculation.last_instalment_percent' | translate }}</mat-label>
                            <input type="text"
                                   formControlName="lastInstalmentPercentage"
                                   matInput
                                   l7MarkAsTouched
                                   l7Calculate
                                   test-id="inputLastInstalmentPercent"
                                   currencyMask
                                   [placeholder]="'calculation.last_instalment_percent' | translate"
                                   [options]="{suffix: ' %'}">
                            <mat-error *ngIf="form.controls.quoteCalculationRequest.controls.lastInstalmentPercentage.errors?.invalid || form.controls.quoteCalculationRequest.controls.lastInstalmentPercentage.errors?.valueNotPossible">
                                {{'error.value_not_allowed' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <l7-custom-form-control *ngIf="valueToCalculate !== 'LAST_INSTALMENT'"
                                                l7Calculate
                                                formControlName="lastInstalmentValue"
                                                [attr.test-id]="'removeLI'"
                                                (click)="removeExtraField('lastInstalment')">
                            <button mat-icon-button
                                    type="button"
                                    class="button-trash">
                                <mat-icon [fontSet]="'l7'"
                                          [fontIcon]="'Trash'">
                                </mat-icon>
                            </button>
                        </l7-custom-form-control>
                    </div>
                    <div *ngIf="inactiveFields.length > 0"
                         fxLayout="row"
                         fxLayout.lt-sm="column" fxLayoutGap="16px">
                        <button mat-button
                                class="more-button"
                                type="button"
                                color="primary"
                                [attr.test-id]="'btnAddFields'"
                                [matMenuTriggerFor]="menu">
                            {{'+ ' + 'Anzahlung / Abw. Rate hinzufügen' | translate}}
                        </button>
                        <mat-menu #menu="matMenu" test-id="menuExtraPayments">
                            <button *ngIf="(showExtraFields | async).downPayment === false"
                                    mat-menu-item
                                    test-id="addDownPayment"
                                    (click)="addExtraField('downPayment')">{{ 'Anzahlung' | translate }}</button>
                            <button *ngIf="(showExtraFields | async).firstInstalment === false"
                                    mat-menu-item
                                    test-id="addFirstInstalment"
                                    (click)="addExtraField('firstInstalment')">{{ 'calculation.first_instalment' | translate }}</button>
                            <button *ngIf="(showExtraFields | async).lastInstalment === false"
                                    mat-menu-item
                                    test-id="addLastInstalment"
                                    (click)="addExtraField('lastInstalment')">{{ 'calculation.last_instalment' |translate }}</button>
                        </mat-menu>
                    </div>
                    <l7-summary [maxColumns]="3"
                                [minColumns]="1">
                        <l7-summary-item [title]="'calculation.total_claim' | translate"
                                         [content]="quoteCalculationResponse?.totalInstalments | currency: 'EUR'">
                        </l7-summary-item>
                        <l7-summary-item *ngIf="contractType === 'MIETKAUF'; else mknNoVat"
                                         [title]="'calculation.total_claim_taxes' | translate"
                                         [content]="quoteCalculationResponse?.totalInstalmentsVat | currency: 'EUR'">
                        </l7-summary-item>
                        <ng-template #mknNoVat>
                            <l7-summary-item [title]="'calculation.total_claim_taxes' | translate"
                                             [content]="'Nettobetrag: keine USt.' | translate">
                            </l7-summary-item>
                        </ng-template>
                        <l7-summary-item [title]="'Anzahl lineare Raten' | translate"
                                         [content]="quoteCalculationResponse?.unchangedTerms + ' (je ' + ((quoteCalculationResponse?.instalment ? quoteCalculationResponse?.instalment : 0) | currency: 'EUR') + ')'">
                        </l7-summary-item>
                    </l7-summary>
                </ng-container>

            </mat-card-content>
        </mat-card>
    </div>
</form>
