import { SearchContactView, SearchType, SelectLesseeView } from '@abcfinlab/contacts';
import { DashboardView } from '@abcfinlab/explorer';
import { PageQuoteListComponent } from '@abcfinlab/quote';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SlbDetailsResolver } from '../../../../../libs/quote/src/Resolvers/slb-details/slb-details.resolver';
import { SlbDetailsFormComponent } from '../../../../../libs/quote/src/Views/slb-details-form/slb-details-form.component';
import { CalculationSettingsResolver } from '../../../src/app/private/resolver/CalculationSettings.resolver';
import { CalculationComponent } from './pages/calculation/calculation.component';
import { MoneyLaunderingComponent } from './pages/money-laundering/money-laundering.component';
import { CreateContractComponent } from './pages/route-container-create-quote/create-contract.component';
import { PageChooseLesseeComponent } from './pages/route-container-create-quote/page-choose-lessee/page-choose-lessee.component';
import { ContainerQuotesComponent } from './pages/route-container-quotes/container-quotes.component';
import { PageAddVendorComponent } from './pages/route-container-quotes/page-add-vendor/page-add-vendor.component';
import { QuoteDetailViewComponent } from './pages/route-container-quotes/page-quote-detail-view/quote-detail-view.component';
import { ContainerSignContractComponent } from './pages/route-container-sign-contract/container-sign-contract.component';
import { PageIdentityCardComponent } from './pages/route-container-sign-contract/page-identity-card/page-identity-card.component';
import { ObjectGroupsResolver } from './resolver/objectGroups/objectGroups.resolver';
import { ResetCalculationStateResolver } from './resolver/reset-calculation-state.resolver';

export const privateRoutes: Routes = [
    {
        path: '',
        component: DashboardView,
    },
    {
        path: 'calculation',
        component: CalculationComponent,
        resolve: {
            objectGroups: ObjectGroupsResolver,
            calculationState: ResetCalculationStateResolver,
            calculationSettings: CalculationSettingsResolver,
        },
    },
    {
        path: 'create-quote',
        component: CreateContractComponent,
        children: [
            {
                path: 'search-lessee',
                component: SearchContactView,
                data: {
                    searchType: SearchType.Lessee,
                },
            },
            {
                path: 'choose-lessee',
                component: PageChooseLesseeComponent,
            },
            {
                path: 'calculation',
                component: CalculationComponent,
                resolve: {
                    objectGroups: ObjectGroupsResolver,
                    calculationState: ResetCalculationStateResolver,
                    calculationSettings: CalculationSettingsResolver,
                },
            },

        ],
    },
    {
        path: 'sign-contract',
        component: ContainerSignContractComponent,
        children: [
            {
                path: 'identity-card-front',
                component: PageIdentityCardComponent,
            },
        ],
    },
    {
        path: 'quotes',
        component: ContainerQuotesComponent,
        children: [
            {
                path: 'lessees-overview',
                component: SelectLesseeView,
            },
            {
                path: 'list',
                component: PageQuoteListComponent,
            },
            {
                path: 'search-vendor',
                component: SearchContactView,
                data: {
                    searchType: SearchType.Vendor,
                },
            },
            {
                path: 'add-vendor',
                component: PageAddVendorComponent,
            },
            {
                path: 'detail',
                component: QuoteDetailViewComponent,
            },
            {
                path: 'slb-details/:quote_id',
                component: SlbDetailsFormComponent,
                resolve: {
                    slbDetails: SlbDetailsResolver,
                },
            },
            {
                path: 'change',
                component: CalculationComponent,
                resolve: {
                    objectGroups: ObjectGroupsResolver,
                    calculationSettings: CalculationSettingsResolver,
                },
            },
        ],
    },
    {
        path: 'gwg',
        component: MoneyLaunderingComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('../../../../../libs/legacy/gwg/src/lib/gwg.module').then(mod => mod.GwgModule),
            },
        ],
        // loadChildren: './pages/money-laundering#MoneyLaunderingComponent'
    },
];

@NgModule({
    imports: [RouterModule.forChild(privateRoutes)],
    exports: [RouterModule],
})
export class PrivateRoutingModule {
}
