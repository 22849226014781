import { AuthService, UserService } from '@abcfinlab/auth';
import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { combineLatest, firstValueFrom } from 'rxjs';

export const authGuard: CanActivateChildFn | CanActivateFn = (route, state) => {
    const authService = inject(AuthService);
    const userService = inject(UserService);
    const router = inject(Router);

    return firstValueFrom(combineLatest([
        authService.getSession(),
        userService.getUserInfo(),
    ])).then(([_, userInfo]) => {
        if (!userInfo?.userId) {
            authService.removeSession();
        }
        return !!userInfo?.userId;
    }).catch(() => {
        authService.removeSession();
        return router.createUrlTree(['/login'], { queryParams: { returnUrl: state.url } });
    });
};
