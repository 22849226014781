<!--#region Templates -->

<ng-template #tpl
             let-context
             let-ref>
    <div fxFill
         style="overflow: hidden"
         [@detailExpand]>
        <mat-accordion multi
                       [displayMode]="'flat'">
            <mat-expansion-panel class="inner-expansion mat-elevation-z"
                                 [expanded]="false"
                                 [attr.test-id]="'OverviewTableOverviewExpansion'">
                <mat-expansion-panel-header matRipple>
                    <mat-panel-title fxLayout="row"
                                     fxLayoutAlign="start center">
                        <div class="title-container"
                             fxLayout="row"
                             fxLayoutAlign="start center"
                             fxLayoutGap="16px">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Note_Text'"></mat-icon>
                            <p>{{ 'contract_management.contract_overview' | translate }}</p>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <l7-contract-overview-details-view></l7-contract-overview-details-view>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="context.row.slb_code === 'UNECHTLN_V2' || context.row.slb_code === 'UNECHTLI_V2' || context.row.contract_type === IContractTypeDto.Mkn"
                                 class="inner-expansion mat-elevation-z"
                                 [expanded]="false"
                                 [attr.test-id]="'OverviewTablePreDeliveryInvoiceExpansion'">
                <mat-expansion-panel-header matRipple>
                    <mat-panel-title fxLayout="row"
                                     fxLayoutAlign="start center">
                        <div class="title-container"
                             fxLayout="row"
                             fxLayoutAlign="start center"
                             fxLayoutGap="16px">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Invoice'"></mat-icon>
                            <p>{{ 'contract_management.preDeliveryInvoice.title' | translate }}</p>
                        </div>
                        <span fxFlex></span>
                        <mat-icon *ngIf="!context.row.ready?.pre_vendor_invoice_complete"
                                  class="status"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Record_Filled'"
                                  [color]="'warning'">
                        </mat-icon>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <l7-contract-document-upload [canUploadFiles]="canUploadFiles$ | async"
                                             [documents]="((uploadedDocuments$ | async) | arrayFilter: 'type': 'eq': documentTypes.PRE_VENDOR_INVOICE)"
                                             [documentType]="'PRE_VENDOR_INVOICE'"
                                             [contract]="context.row"
                                             (isReady)="isContractReady($event)"
                                             (uploadedDocuments)="setNewUploadedDocuments($event)">
                </l7-contract-document-upload>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="context.row.slb_code === 'UNECHTLN_V2' || context.row.contract_type === IContractTypeDto.Mkn"
                                 class="inner-expansion mat-elevation-z"
                                 [expanded]="false"
                                 [attr.test-id]="'OverviewTableProofOfPaymentExpansion'">
                <mat-expansion-panel-header matRipple>
                    <mat-panel-title fxLayout="row"
                                     fxLayoutAlign="start center">
                        <div class="title-container"
                             fxLayout="row"
                             fxLayoutAlign="start center"
                             fxLayoutGap="16px">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Credit_Card_Minus'"></mat-icon>
                            <p>{{ 'contract_management.proofOfPayment.title' | translate }}</p>
                        </div>
                        <span fxFlex></span>
                        <mat-icon *ngIf="!context.row.ready?.proof_of_payment_complete"
                                  class="status"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Record_Filled'"
                                  [color]="'warning'">
                        </mat-icon>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <l7-contract-document-upload [canUploadFiles]="canUploadFiles$ | async"
                                             [documents]="((uploadedDocuments$ | async) | arrayFilter: 'type': 'eq': documentTypes.PROOF_OF_PAYMENT)"
                                             [documentType]="'PROOF_OF_PAYMENT'"
                                             [contract]="context.row"
                                             (isReady)="isContractReady($event)"
                                             (uploadedDocuments)="setNewUploadedDocuments($event)">
                </l7-contract-document-upload>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="!context.row.slb"
                                 class="inner-expansion mat-elevation-z"
                                 [expanded]="false"
                                 [attr.test-id]="'OverviewTablePurchaseEntryExpansion'"
                                 (opened)="this.onExpansionExpanded('PURCHASE_ENTRY')">
                <mat-expansion-panel-header matRipple>
                    <mat-panel-title fxLayout="row"
                                     fxLayoutAlign="start center">
                        <div class="title-container"
                             fxLayout="row"
                             fxLayoutAlign="start center"
                             fxLayoutGap="16px">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Thumbs_Up'"></mat-icon>
                            <p>{{ 'contract_management.purchase_entry.title' | translate }}</p>
                        </div>
                        <span fxFlex></span>
                        <mat-icon *ngIf="!((uploadedDocuments$ | async) | arrayFilter: 'type': 'eq': documentTypes.PURCHASE_ENTRY).length && !(selectedPurchaseEntry$ | async)?.creation_date"
                                  class="status"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Record_Filled'"
                                  [color]="'warning'">
                        </mat-icon>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <l7-contract-purchase-entry-view [loading]="(loadingPurchaseEntry$ | async)"
                                                 [contract]="context.row"
                                                 [purchaseEntry]="(selectedPurchaseEntry$ | async)"
                                                 [quoteDetails]="(selectedQuoteDetails$ | async)"
                                                 [purchaseEntryDocuments]="((uploadedDocuments$ | async) | arrayFilter: 'type': 'eq': documentTypes.VENDOR_OFFER)"
                                                 [needsUpload]="context.row.ready?.vendor_offer_complete === false"
                                                 (createPurchaseEntry)="onCreatePurchaseEntry($event)"
                                                 (changeObjectValue)="onChangeObjectValueForPurchaseEntry($event)"
                                                 (uploadedPurchaseEntryDocuments)="setNewUploadedDocuments($event)"
                                                 (isVendorOfferReady)="isContractReady($event)">
                </l7-contract-purchase-entry-view>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="!context.row.slb"
                                 class="inner-expansion mat-elevation-z"
                                 [expanded]="false"
                                 [attr.test-id]="'OverviewTableInvoiceUploadExpansion'"
                                 (opened)="this.onExpansionExpanded('INVOICE_UPLOAD')">
                <mat-expansion-panel-header matRipple>
                    <mat-panel-title fxLayout="row"
                                     fxLayoutAlign="start center"
                                     fxLayoutGap="16px">
                        <div class="title-container"
                             fxFlex="24"
                             fxLayout="row"
                             fxLayoutAlign="start center"
                             fxLayoutGap="16px">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Invoice'">
                            </mat-icon>
                            <p>{{ 'contract_management.invoice_upload.title' | translate }}</p>
                        </div>
                        <span fxFlex></span>
                        <mat-icon *ngIf="!context.row.ready?.invoice_complete"
                                  class="status"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Record_Filled'"
                                  [color]="'warning'">
                        </mat-icon>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <l7-contract-document-upload [canUploadFiles]="canUploadFiles$ | async"
                                             [documents]="((uploadedDocuments$ | async) | arrayFilter: 'type': 'eq': documentTypes.INVOICE)"
                                             [documentType]="'INVOICE'"
                                             [contract]="context.row"
                                             (isReady)="isContractReady($event)"
                                             (uploadedDocuments)="setNewUploadedDocuments($event)">
                </l7-contract-document-upload>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="context.row.slb_code === 'UNECHTLN_V2' || context.row.slb_code === 'UNECHTLI_V2' || context.row.contract_type === IContractTypeDto.Mkn"
                                 class="inner-expansion mat-elevation-z"
                                 [expanded]="false"
                                 [attr.test-id]="'OverviewTablePurchaseAndTransferAgreementExpansion'"
                                 (opened)="this.onExpansionExpanded('PURCHASE_AND_TRANSFER_AGREEMENT')">
                <mat-expansion-panel-header matRipple>
                    <mat-panel-title fxLayout="row"
                                     fxLayoutAlign="start center">
                        <div class="title-container"
                             fxLayout="row"
                             fxLayoutAlign="start center"
                             fxLayoutGap="16px">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Arrow_Swap'"></mat-icon>
                            <p>{{ 'contract_management.purchaseAndTransferAgreement.title' | translate }}</p>
                        </div>
                        <span fxFlex></span>
                        <mat-icon *ngIf="!context.row.ready?.kuev_complete"
                                  class="status"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Record_Filled'"
                                  [color]="'warning'">
                        </mat-icon>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <l7-contract-overview-kuev-view [ready]="context.row.ready?.kuev_complete"
                                                [overview]="this.overview">
                </l7-contract-overview-kuev-view>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="!context.row.slb"
                                 class="inner-expansion mat-elevation-z"
                                 [expanded]="false"
                                 [attr.test-id]="'OverviewTableConfirmationAcceptanceExpansion'"
                                 [disabled]="!((uploadedDocuments$ | async) | arrayFilter: 'type': 'eq': documentTypes.PURCHASE_ENTRY).length && !(selectedPurchaseEntry$ | async)?.creation_date"
                                 (opened)="this.onExpansionExpanded('CONFIRMATION_ACCEPTANCE')">
                <mat-expansion-panel-header matRipple>
                    <mat-panel-title fxLayout="row"
                                     fxLayoutAlign="start center">
                        <div class="title-container"
                             fxLayout="row"
                             fxLayoutAlign="start center"
                             fxLayoutGap="16px">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Box_2'"></mat-icon>
                            <p>{{ 'contract_management.confirmation_acceptance.title' | translate }}</p>
                            <p *ngIf="!((uploadedDocuments$ | async) | arrayFilter: 'type': 'eq': documentTypes.PURCHASE_ENTRY).length && !(selectedPurchaseEntry$ | async)?.creation_date"><b>{{ '(verfügbar nach Kaufeintritt)' }}</b></p>
                        </div>
                        <span fxFlex></span>
                        <mat-icon *ngIf="!context.row.ready?.acknowledgement_complete"
                                  class="status"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Record_Filled'"
                                  [color]="'warning'">
                        </mat-icon>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <l7-contract-overview-takeover-confirmation-view [documents]="((uploadedDocuments$ | async) | arrayFilter: 'type': 'eq': ['ACKNOWLEDGEMENT_SIGNED_PAPER', 'ACKNOWLEDGEMENT_SIGNED'])"
                                                                 [ready]="context.row.ready?.acknowledgement_complete"
                                                                 [overview]="this.overview"
                                                                 (hasAcknowledgement)="this.isContractReady($event)"
                                                                 (uploadedPaperSigning)="this.setNewUploadedDocuments($event)">
                </l7-contract-overview-takeover-confirmation-view>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="!context.row.slb || context.row.slb_code === 'UNECHTLN_V2' || context.row.slb_code === 'UNECHTLI_V2' || context.row.contract_type === IContractTypeDto.Mkn"
                                 class="inner-expansion mat-elevation-z"
                                 [expanded]="false"
                                 [disabled]="!context.row.ready?.kuev_complete && context.row.slb"
                                 [attr.test-id]="'OverviewTableBillingInformationExpansion'"
                                 (opened)="this.onExpansionExpanded('BILLING_INFORMATION')">
                <mat-expansion-panel-header matRipple>
                    <mat-panel-title fxLayout="row"
                                     fxLayoutAlign="start center">
                        <div class="title-container"
                             fxLayout="row"
                             fxLayoutAlign="start center"
                             fxLayoutGap="16px">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Clipboard_Text'">
                            </mat-icon>
                            <p>{{ 'contract_management.billing_information.title' | translate }}</p>
                            <p *ngIf="!context.row.ready?.kuev_complete && context.row.slb"><b>{{ '(verfügbar nach Kauf- und Übereignungsvertrag)' }}</b></p>
                        </div>
                        <span fxFlex></span>
                        <mat-icon *ngIf="!context.row.ready?.accounting_complete"
                                  class="status"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Record_Filled'"
                                  [color]="'warning'">
                        </mat-icon>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <l7-contract-overview-billing-information-view (hasAccounting)="isContractReady($event)">
                </l7-contract-overview-billing-information-view>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="canRecalculate | async"
                                 class="inner-expansion mat-elevation-z"
                                 [expanded]="(this.isRecalculationPanelOpen | async) && (context.row.ready?.acknowledgement_complete || context.row.ready?.kuev_complete)"
                                 [disabled]="(!context.row.ready?.acknowledgement_complete && !context.row.slb) || (!context.row.ready?.kuev_complete && context.row.slb)"
                                 [attr.test-id]="'OverviewTableRecalculationExpansion'"
                                 (opened)="this.onExpansionExpanded('RECALCULATION')">
                <mat-expansion-panel-header matRipple>
                    <mat-panel-title fxLayoutAlign="start center">
                        <div class="title-container"
                             fxLayout="row"
                             fxLayoutAlign="start center"
                             fxLayoutGap="16px">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Sliders_Horiz_2'"></mat-icon>
                            <p>{{ 'contract_management.recalculation.title' | translate }}</p>
                            <p *ngIf="!context.row.ready?.acknowledgement_complete && !context.row.slb"><b>{{ '(verfügbar nach Übernahmebestätigung)' }}</b></p>
                            <p *ngIf="!context.row.ready?.kuev_complete && context.row.slb"><b>{{ '(verfügbar nach Kauf- und Übereignungsvertrag)' }}</b></p>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <l7-contract-overview-recalculation-view [overview]="this.overview"
                                                         [sentToAccounting]="context.row.ready?.sent_to_accounting">
                </l7-contract-overview-recalculation-view>
            </mat-expansion-panel>
            <mat-expansion-panel class="inner-expansion mat-elevation-z"
                                 [expanded]="false"
                                 [attr.test-id]="'OverviewTableDocumentsExpansion'">
                <mat-expansion-panel-header matRipple>
                    <mat-panel-title fxLayoutAlign="start center">
                        <div class="title-container"
                             fxLayout="row"
                             fxLayoutAlign="start center"
                             fxLayoutGap="16px">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Folder_open'"></mat-icon>
                            <p>{{ 'contract_management.documents.title' | translate }}</p>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <l7-contract-overview-documents-view [overview]="this.overview"
                                                     [newFiles]="newDocuments$ | async"
                                                     [canUploadFiles]="canUploadFiles$ | async"
                                                     (isReady)="isContractReady($event)"
                                                     (uploadedDocuments)="setUploadedDocuments($event)">
                </l7-contract-overview-documents-view>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-template>

<!-- #endregion -->

<l7-page motion
         [isEmpty]="(this.dataSourceTotal | async) === 0"
         [attr.test-id]="'ContractOverviewPage'"
         [motionPreset]="'fadeSlideIn'">
    <l7-page-header [title]="'contract_management.title' | translate"></l7-page-header>
    <l7-page-pre-content>
        <l7-page-section fxLayout="row"
                         fxLayoutAlign="start start"
                         fxLayoutGap="16px">
            <mat-form-field fxFlex.lt-md="100"
                            fxFlex="40"
                            appearance="outline">
                <mat-label>{{ 'contract_management.search' | translate }}</mat-label>
                <input #term="matInput"
                       matInput
                       [attr.test-id]="'OverviewTableFilterInput'"
                       [value]="(this.condition | async).term"
                       (keyup.enter)="this.onTermChanged($event.target.value)">
                <button mat-icon-button
                        matSuffix
                        [attr.test-id]="'OverviewTableFilterInputClearButton'"
                        (click)="this.onTermChanged(term.value)">
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Search'">
                    </mat-icon>
                </button>
            </mat-form-field>
            <div class="clear-all-container">
                <button *ngIf="(this.isDefaultSearchCondition | async) === false"
                        mat-button
                        color="warn"
                        [attr.test-id]="'ResetConditionButton'"
                        (click)="this.onResetConditions()">{{ 'settlement.overview.filter.reset' | translate }}</button>
            </div>
        </l7-page-section>
    </l7-page-pre-content>
    <l7-page-content>
        <mat-table fxFill
                   matSort
                   [attr.test-id]="'OverviewTable'"
                   [dataSource]="this.dataSource">
            <ng-container *ngFor="let kvColumn of this.columns | keyvalue"
                          [matColumnDef]="kvColumn.key">
                <mat-header-cell *matHeaderCellDef
                                 mat-sort-header
                                 [hidden]="(kvColumn.value.visible | of | async)  === false">
                    <span class="text-truncate">{{ 'contract_management.' + kvColumn.key | translate }}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let row"
                          [hidden]="(kvColumn.value.visible | of  | async)  === false">
                    <ng-container [ngSwitch]="kvColumn.key">
                        <ng-container *ngSwitchCase="'signing_date'">
                            <span class="text-truncate">{{ row[kvColumn.key] | date }}</span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'contract_type'">
                            <span class="text-truncate">

                                {{ row[kvColumn.key] }}
                                <ng-container *ngIf="row.slb && row.contract_type !== 'MKN'" >{{' + SLB'}}</ng-container>
                                <ng-container *ngIf="row.slb && row.contract_type !== 'MKN' && (row.slb_code === 'UNECHTLN' || row.slb_code === 'UNECHTLI' ) ">
                                   <span [matTooltip]="'slb.oldSLbToolTip' | translate">{{ '*' }}</span>
                                </ng-container>
                                <ng-container *ngIf="row.contract_type === 'MKN'">{{ ' + SMB' }}</ng-container>
                            </span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'state'">
                            <button fxFlex
                                    mat-stroked-button
                                    color="primary"
                                    [disabled]="row.status"
                                    (click)="this.onCheckState($event, row.contract_number)">
                                <span *ngIf="!row.status; else status"
                                      fxLayoutAlign="center center">
                                    <mat-progress-spinner style="display: none;"
                                                          [diameter]="16"
                                                          [strokeWidth]="2"
                                                          [color]="'primary'"
                                                          [mode]="'indeterminate'"></mat-progress-spinner>
                                    <p>{{ 'contract_management.state_check' | translate }}</p>
                                </span>
                                <ng-template #status>
                                    <l7-text-block [text]="row.status"
                                                   [alignment]="'center'"
                                                   [truncate]="true"></l7-text-block>
                                </ng-template>
                            </button>
                        </ng-container>
                        <ng-container *ngSwitchCase="'send'">
                            <button *ngIf="!row.ready?.sent_to_accounting; else sentToAccounting"
                                    fxFlex
                                    mat-flat-button
                                    color="primary"
                                    [disabled]="!row.ready?.complete || contractStatusGreaterThen50"
                                    [attr.test-id]="'PushButton'"
                                    (click)="this.onSendContract($event, row.quote_id, row.contract_number, row.slb, row.slb_code)">
                                {{ 'contract_management.send_button' | translate }}
                            </button>
                            <ng-template #sentToAccounting>
                                <div fxFlex
                                     fxLayoutAlign="center center">
                                    <mat-icon [attr.test-id]="'SuccessIcon'"
                                              [color]="'success'"
                                              [fontSet]="'l7'"
                                              [fontIcon]="'Check_Circle'"></mat-icon>
                                </div>
                            </ng-template>
                        </ng-container>

                        <ng-container *ngSwitchCase="'processing_status'">
                            <mat-icon *ngIf="row[kvColumn.key] === 'TO_BE_DISCUSSED'"
                                      matSuffix
                                      [matTooltipClass]="'allow-cr'"
                                      [matTooltip]="row['processing_status'] === 'TO_BE_DISCUSSED' ? ('contract_management.clarification_tooltip' | translate) : null"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Info'"
                                      [color]="'info'">
                            </mat-icon>
                            <mat-icon *ngIf="row.ready?.vendor_offer_complete === false"
                                      matSuffix
                                      [matTooltipClass]="'allow-cr'"
                                      [matTooltip]="('contract_management.vendor_offer_tooltip' | translate)"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Alert_Circle'"
                                      [color]="'warning'">
                            </mat-icon>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            <span class="text-truncate">{{ row[kvColumn.key] }}</span>
                        </ng-container>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="this.columns | keys"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columns | keys"
                     matRipple
                     class="detail-row"
                     [l7DetailRow]="row"
                     [l7DetailRowTemplate]="tpl"
                     [attr.test-id]="row['quote_id']"
                     (l7DetailRowExpanded)="this.onRowSelected(row)"
                     (l7DetailRowCollapsed)="this.onRowCollapsed()">
            </mat-row>
        </mat-table>
        <mat-paginator showFirstLastButtons
                       [length]="this.dataSourceTotal | async"
                       [pageSize]="this.pageSize | async"
                       [pageSizeOptions]="this.pageSizes | async"
                       (page)="this.onPageChanged($event)">
        </mat-paginator>
    </l7-page-content>
</l7-page>
