<form fxFill
      fxFlex="100"
      fxLayout="column"
      [formGroup]="invoiceForm"
      (submit)="saveInvoice()">
    <div fxLayout="row"
         class="first-row">
        <span class="form-title">Navision Werte</span>
        <button mat-icon-button
                type="button"
                [attr.test-id]="'ButtonOcr'"
                (click)="showOcrFields()">
            <mat-icon [inline]="true"
                      [fontSet]="'l7'"
                      [fontIcon]="'Edit_3'"
                      [style.color]="showFields ? '#000' : '#8800ff'">
            </mat-icon>
        </button>
    </div>

    <div fxFill
         fxFlex="100"
         fxLayout="column"
         fxLayoutGap="16px"
         class="form-scroll">
        <div fxLayout="column">
            <mat-form-field fxFlex
                            appearance="fill">
                <mat-label>Objektname</mat-label>
                <input matInput
                       type="text"
                       formControlName="objectName"
                       [attr.test-id]="'ObjectName'">
            </mat-form-field>
        </div>
        <div fxLayout="column">
            <l7-date-picker fxFlex
                            [label]="'backoffice.invoiceForm.invoiceDateLabel'"
                            [controlName]="'invoiceDate'"
                            [uniqueId]="'invoiceDate'"
                            [formFieldAppearance]="'fill'"
                            [parentFormGroup]="invoiceForm">
            </l7-date-picker>
        </div>
        <div fxLayout="column">
            <mat-form-field fxFlex
                            appearance="fill"
                            [ngClass]="{'warning': invoiceForm.get('invoiceNumber').getError('invoiceNumberVendorUnique') }">
                <mat-label>Rechnungsnummer</mat-label>
                <input matInput
                       l7AddClass
                       l7NullValue
                       type="text"
                       formControlName="invoiceNumber"
                       [attr.test-id]="'InvoiceNumber'"
                       (emptyToNull)="handleEmptyString($event)">
                <mat-error *ngIf="invoiceForm.controls.invoiceNumber.getError('invoiceNumberVendorUnique')">
                    {{'Rechnungsnummer bereits verwendet. Übermittlung an Navision wird fehlschlagen' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column">
            <mat-form-field fxFlex
                            appearance="fill">
                <mat-label>Rechnungsbetrag netto</mat-label>
                <input matInput
                       l7AddClass
                       l7NullValue
                       l7MarkAsTouched
                       type="text"
                       formControlName="invoiceNetAmount"
                       currencyMask
                       l7SelectOnDblClick
                       [markOnInit]="true"
                       [attr.test-id]="'InvoiceNetAmount'"
                       (emptyToNull)="handleEmptyString($event)">
                <mat-error *ngIf="invoiceForm.controls.invoiceNetAmount.getError('notEqual')">
                    Ungleich Finanzierungsbetrag ({{ackLeasingValue | currency}})
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column">
            <l7-date-picker fxFlex
                            [label]="'backoffice.invoiceForm.deliveryDateLabel'"
                            [controlName]="'deliveryDate'"
                            [uniqueId]="'DeliveryDate'"
                            [minDate]="minDeliveryDate"
                            [minDateErrorMessage]="'backoffice.invoiceForm.' + minDeliveryDateErrorMessage | translate: {date: minDeliveryDate | date: 'shortDate'}"
                            [formFieldAppearance]="'fill'"
                            [parentFormGroup]="invoiceForm">
            </l7-date-picker>
        </div>
        <div fxLayout="column">
            <mat-form-field fxFlex
                            appearance="fill">
                <mat-label>USt-IdNr.</mat-label>
                <input matInput
                       l7AddClass
                       l7NullValue
                       type="text"
                       formControlName="vatNumber"
                       [attr.test-id]="'VatNumber'"
                       (emptyToNull)="handleEmptyString($event)">
                <mat-error *ngIf="invoiceForm.controls.vatNumber.getError('isInvalidVatNumber')">
                    {{'Achtung: USt.-ID der abcfinance' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column">
            <mat-form-field fxFlex
                            appearance="fill">
                <mat-label>Rechnungsempfänger</mat-label>
                <input matInput
                       l7AddClass
                       l7NullValue
                       type="text"
                       formControlName="invoiceRecipient"
                       [attr.test-id]="'InvoiceRecipient'"
                       (emptyToNull)="handleEmptyString($event)">
            </mat-form-field>
        </div>
        <div fxLayout="column"
             fxLayout.gt-sm="row">
            <mat-form-field fxFlex
                            appearance="fill">
                <mat-label>Rechnungssteller</mat-label>
                <input matInput
                       l7AddClass
                       l7NullValue
                       type="text"
                       formControlName="invoiceBiller"
                       [attr.test-id]="'InvoiceBiller'"
                       (emptyToNull)="handleEmptyString($event)">
            </mat-form-field>
        </div>
        <div fxLayout="column"
             fxLayout.gt-sm="row">
            <mat-form-field fxFlex
                            appearance="fill">
                <mat-label>IBAN</mat-label>
                <input matInput
                       l7AddClass
                       l7NullValue
                       l7MarkAsTouched
                       type="text"
                       formControlName="invoiceIban"
                       mask="AAAA AAAA AAAA AAAA AAAA AA"
                       [markOnInit]="true"
                       [attr.test-id]="'InvoiceIban'"
                       [validation]="false"
                       (emptyToNull)="handleEmptyString($event)"
                       (keyup)="transformToUpperCase($event)">
                <div *ngIf="(invoiceForm.get('invoiceIban').statusChanges | async) === 'PENDING'"
                     matSuffix>
                    <mat-progress-spinner [diameter]="16"
                                          [strokeWidth]="2"
                                          [color]="'primary'"
                                          [mode]="'indeterminate'"></mat-progress-spinner>
                </div>
                <mat-error *ngIf="invoiceForm.controls.invoiceIban.getError('isInvalidIban')">
                    {{'Ungleich Auszahlungskonto Fachhändler' | translate}}
                </mat-error>
                <mat-error *ngIf="invoiceForm.controls.invoiceIban.getError('invalidIban')">
                    <p>{{ 'iban.errors.' + invoiceForm.controls.invoiceIban.errors.error | translate }}</p>
                </mat-error>
                <mat-error *ngIf="invoiceForm.controls.invoiceIban.getError('required')">
                    <p>{{ 'iban.errors.required' | translate }}</p>
                </mat-error>
                <mat-icon *ngIf="invoiceForm.get('invoiceIban').valid && (invoiceForm.get('invoiceIban').statusChanges | async) !== 'PENDING'"
                          matSuffix
                          [color]="'success'"
                          [fontSet]="'l7'"
                          [fontIcon]="'Check'">
                </mat-icon>
            </mat-form-field>
        </div>

        <div fxLayout="column"
             fxLayout.gt-sm="row">
            <mat-checkbox formControlName="invoiceConfirmed"
                          [attr.test-id]="'InvoiceCompleted'">
                <span class="radio-label">{{'settlement.invoice.invoice_confirmed' | translate}}</span>
            </mat-checkbox>
        </div>

        <div fxLayout="row"
             fxLayoutAlign="end"
             fxLayoutGap="16px">
            <button *ngIf="!isReadonlyUser"
                    mat-flat-button
                    type="submit"
                    [color]="'accent'"
                    [attr.test-id]="'SaveInvoiceButton'"
                    [disabled]="(areValuesChanged$ | async) === false||
                                (contractStatus !== 'BILLABLE' && contractStatus !== 'TO_BE_DISCUSSED') ||
                                navStatus >= 50">
                {{ 'global.save' | translate }}
            </button>
        </div>
    </div>
</form>
