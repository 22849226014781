/* libs/ui/src/lib/skeleton/skeleton-form/skeleton-form.component.scss */
.skeleton-form {
  width: 100%;
  margin: 0 auto;
}
.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}
.skeleton-title {
  height: 24px;
  width: 60%;
  margin-bottom: 24px;
}
.skeleton-input {
  height: 40px;
  width: 100%;
  flex: 1;
}
.skeleton-select {
  height: 40px;
  width: 100%;
  flex: 1;
}
.skeleton-button {
  height: 36px;
  width: 30%;
  margin-top: 16px;
}
@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}
/*# sourceMappingURL=skeleton-form.component.css.map */
