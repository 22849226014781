<div fxLayout="column">
  <div fxLayout="row"
       fxFlex="50"
       fxFlex.lt-md="100">
    <div fxLayout="column"
         fxFlex="50"
         fxFlex.lt-md="100">
      <h2>Vertragsabschluss</h2>
      <h1>Vertragsunterschrift</h1>
      <h3>
        Ich habe alles gelesen und verpflichte mich mit einer Unterschrift zu dem Vertragswerk und dem
        SEPA-Lastschrift-Mandat.
        <br /><br />
        Bitte unterschreiben Sie auf der vorgegebenen Fläche.
      </h3>
    </div>
  </div>

  <div fxLayout="column">
    <div class="block align-center">
      <div class="background"
           id="div_signature-container">
        <l7-signature-pad #signaturePad
                          [options]="signaturePadOptions"
                          (beginSignEvent)="drawStart()"
                          (endSignEvent)="drawComplete()">
        </l7-signature-pad>
      </div>
    </div>
    <div class="align-center">
      <a id="anchor_clear-signature-pad"
         (click)="clearSignaturePad()">Unterschrift unleserlich? Erneut
        unterschreiben</a>
    </div>
    <div class="form-group">
      <mat-form-field appearance="outline"
                      style="width: 100%">
        <mat-label>Ort der Vertragsunterzeichnung</mat-label>
        <input l7InputRef
               l7MarkAsTouched
               matInput
               type="text"
               id="input_city-of-signature"
               placeholder="Ort der Vertragsunterzeichnung"
               [(ngModel)]="cityOfSignature"
               (ngModelChange)="checkInput()">
      </mat-form-field>
    </div>

    <div class="align-center">
      <button mat-flat-button
              id="button_abort-calculation"
              [color]="'accent'"
              [disabled]="disabled"
              (click)="confirmContract()">
          Vertrag verbindlich abschließen
      </button>
    </div>
  </div>
</div>
