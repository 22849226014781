<div fxLayout="row"
     fxLayoutAlign="space-between center"
     class="main-container">
    <div class="banner-icon-container"
         fxFlex="none">
        <mat-icon *ngIf="this.icon"
                  [inline]="false"
                  [fontSet]="this.iconFontSet"
                  [fontIcon]="this.icon">
        </mat-icon>
    </div>
    <div class="banner-label-container"
         fxFlex>
        <l7-text-block *ngIf="this.header"
                       class="banner-header"
                       [text]="this.header"
                       [wrap]="true"
                       [maxLines]="1"></l7-text-block>
        <l7-text-block class="banner-content"
                       [text]="this.content"
                       [wrap]="true"
                       [maxLines]="3"></l7-text-block>
    </div>
    <button *ngIf="this.closable"
            mat-icon-button
            (click)="this.closeBanner()">
        <mat-icon class="close-button"
                  [fontSet]="'l7'"
                  [fontIcon]="'Cross'"></mat-icon>
    </button>
</div>