<div fxLayout="column"
     fxLayoutGap="24px" cdkScrollable>
    <form l7ScrollToError [formGroup]="presenter.form" [isScrollOnWindow]="type === 'REMOTE' || type === 'REMOTE_WITHOUT_IDENTIFICATION'" (ngSubmit)="presenter.onSubmit()">
        <mat-card *ngIf="(this.presenter.quoteDetails | async)" appearance="outlined" class="small-card more-padding mat-card-outlined">
            <mat-card-header class="no-padding">
                <mat-card-title>
                    {{ 'quotes.object_details_title' | translate }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="more-padding" fxLayout="column">
                <div fxFlex
                     fxLayout="row wrap"
                     fxLayoutAlign="start center"
                     fxLayoutGap="16px">
                    <fieldset fxFlex="0 0 calc(20% - 16px)"
                              fxFlex.lt-md="0 1 calc(50% - 16px)"
                              fxFlex.lt-sm="0 1 100%"
                              fxLayout="column">
                        <legend>{{ 'quote.retailers.calculation.input.objectName.label' | translate }}</legend>
                        <l7-text-block class="small-text"
                                       [truncate]="true"
                                       [text]="(this.presenter.quoteDetails | async).detail.objectDescription"></l7-text-block>
                    </fieldset>
                    <fieldset fxFlex="0 1 calc(20% - 16px)"
                              fxFlex.lt-md="0 1 calc(50% - 16px)"
                              fxFlex.lt-sm="0 1 100%"
                              fxLayout="column">
                        <legend>{{ 'quote.retailers.calculation.input.contract_type.label' | translate }}</legend>
                        <l7-text-block class="small-text"
                                       [truncate]="true"
                                       [text]="'contractType.' + (this.presenter.quoteDetails | async).quote.type | translate"></l7-text-block>
                    </fieldset>
                    <fieldset fxFlex="0 1 calc(20% - 16px)"
                              fxFlex.lt-md="0 1 calc(50% - 16px)"
                              fxFlex.lt-sm="0 1 100%"
                              fxLayout="column">
                        <legend>{{ 'Rate' | translate }}</legend>
                        <l7-text-block class="small-text"
                                       [truncate]="true"
                                       [text]="(this.presenter.quoteDetails | async).quote.calculation.instalment | currency"></l7-text-block>
                    </fieldset>
                    <fieldset fxFlex="0 1 calc(20% - 16px)"
                              fxFlex.lt-md="0 1 calc(50% - 16px)"
                              fxFlex.lt-sm="0 1 100%"
                              fxLayout="column">
                        <legend>{{ 'quote.retailers.quoteDetails.labels.duration' | translate }}</legend>
                        <l7-text-block class="small-text"
                                       [truncate]="true"
                                       [text]="(this.presenter.quoteDetails | async).quote.calculation.terms + ' Monate'"></l7-text-block>
                    </fieldset>
                    <fieldset fxFlex="0 0 calc(20% - 16px)"
                              fxFlex.lt-md="0 1 calc(50% - 16px)"
                              fxFlex.lt-sm="0 1 100%"
                              fxLayout="column">
                        <legend>{{ 'global.object_state' | translate }}</legend>
                        <l7-text-block class="small-text"
                                       [truncate]="true"
                                       [text]="('condition.' + (this.presenter.quoteDetails | async).quote.objects[0].condition) | translate"></l7-text-block>
                    </fieldset>
                    <fieldset fxFlex="0 1 calc(20% - 16px)"
                              fxFlex.lt-md="0 1 calc(50% - 16px)"
                              fxFlex.lt-sm="0 1 100%"
                              fxLayout="column">
                        <legend>{{ 'settlement.details.handling_fee' | translate }}</legend>
                        <l7-text-block class="small-text"
                                       [truncate]="true"
                                       [text]="(this.presenter.quoteDetails | async).quote.handlingFeeValue | currency"></l7-text-block>
                    </fieldset>
                    <fieldset fxFlex="0 1 calc(20% - 16px)"
                              fxFlex.lt-md="0 1 calc(50% - 16px)"
                              fxFlex.lt-sm="0 1 100%"
                              fxLayout="column">
                        <legend>{{ 'settlement.details.monthlyInsurance' | translate }}</legend>
                        <l7-text-block class="small-text"
                                       [truncate]="true"
                                       [text]="(this.presenter.quoteDetails | async).quote.monthlyInsuranceValue | currency"></l7-text-block>
                    </fieldset>
                </div>
            </mat-card-content>
        </mat-card>
        <l7-spacer [thickness]="'bottom'" [multiplier]="24">
        </l7-spacer>
        <mat-card appearance="outlined" class="mat-card-outlined">
            <mat-card-header>
                <mat-card-title>
                    {{ 'quote.retailers.quoteDetails.tasks.createContract.titlePaymentType' | translate }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="column"
                     fxLayoutGap="16px">
                    <div fxLayout="row">
                        <mat-radio-group name="paymentType" color="primary"
                                         [attr.test-id]="'paymentType'"
                                         (change)="presenter.paymentTypeChanged($event)">
                            <mat-radio-button color="primary" value="BANK_ACCOUNT" [checked]="true">
                                {{'quote.retailers.quoteDetails.tasks.createContract.labelBankAccount' | translate}}
                            </mat-radio-button>
                            <div fxLayout="row"
                                 fxLayout.lt-sm="column"
                                 fxLayoutGap="16px"
                                 class="more-margin"
                                 [formGroup]="presenter.form.controls.bankAccount">
                                <mat-form-field appearance="outline"
                                                [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                                                [fxFlex.lt-sm]="'0 1 100%'">
                                    <mat-label>{{ 'Kontoinhaber' | translate }}</mat-label>
                                    <input matInput
                                           l7MarkAsTouched
                                           type="text"
                                           [attr.test-id]="'bankAccountName'"
                                           [formControlName]="'bankAccountName'">
                                    <mat-error *ngIf="presenter.form.get('bankAccount.bankAccountName').hasError('required')">
                                        <p>{{ 'error.field_required' | translate }}</p>
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline"
                                                [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                                                [fxFlex.lt-sm]="'0 1 100%'">
                                    <mat-label>{{ 'IBAN' | translate }}</mat-label>
                                    <input matInput
                                           l7MarkAsTouched
                                           mask="AAAA AAAA AAAA AAAA AAAA AA"
                                           l7ValidateBankAccount
                                           l7IbanValidator
                                           type="text"
                                           [l7isRemote]="type === 'REMOTE' || type === 'REMOTE_WITHOUT_IDENTIFICATION'"
                                           [validation]="false"
                                           [attr.test-id]="'iban'"
                                           [formControlName]="'iban'"
                                           (keyup)="presenter.transformToUpperCase($event)"
                                           (bankAccount)="presenter.handleBankAccountInfo($event)">
                                    <div *ngIf="(presenter.form.controls.bankAccount.controls.iban.statusChanges | async) === 'PENDING'"
                                         matSuffix>
                                        <mat-progress-spinner [diameter]="16"
                                                              [strokeWidth]="2"
                                                              [color]="'primary'"
                                                              [mode]="'indeterminate'"></mat-progress-spinner>
                                    </div>
                                    <mat-error *ngIf="presenter.form.controls.bankAccount.controls.iban.getError('iban')">
                                        <p>{{ 'iban.errors.invalid_iban_checksum' | translate }}</p>
                                    </mat-error>
                                    <mat-error *ngIf="presenter.form.controls.bankAccount.controls.iban.getError('required')">
                                        <p>{{ 'iban.errors.required' | translate }}</p>
                                    </mat-error>
                                    <mat-icon *ngIf="presenter.form.get('bankAccount.iban').valid && presenter.form.get('bankAccount.iban').getRawValue() &&
                                               (presenter.paymentType | async) === 'BANK_ACCOUNT'"
                                              matSuffix
                                              [color]="'success'"
                                              [fontSet]="'l7'"
                                              [fontIcon]="'Check'">
                                    </mat-icon>
                                </mat-form-field>
                            </div>
                            <div *ngIf="presenter.bankAccount | async"
                                 fxLayout="row"
                                 fxLayout.lt-sm="column"
                                 class="more-margin" fxLayoutGap="16px">
                                <l7-text-block [color]="'success'"
                                               [attr.test-id]="'bicAndBankAccount'"
                                               [text]="'BIC: ' + (presenter.bankAccount | async).bic + ' / ' + (presenter.bankAccount | async).bank_name"
                                               [wrap]="true"></l7-text-block>
                            </div>
                            <l7-spacer [thickness]="'bottom'" [multiplier]="12">
                            </l7-spacer>
                            <mat-radio-button color="primary" value="SELF_PAYER">
                                {{'quote.retailers.quoteDetails.tasks.createContract.labelSelfPayer' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <l7-spacer [thickness]="'bottom'" [multiplier]="24">
        </l7-spacer>
        <mat-card *ngIf="(presenter.lessee | async)" appearance="outlined" class="mat-card-outlined">
            <mat-card-header>
                <mat-card-title>
                    {{ 'Objektstandort' | translate }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="column"
                     fxLayoutGap="16px">
                    <l7-text-block [text]="(presenter.lessee | async).name"
                                   [wrap]="true"></l7-text-block>
                    <l7-text-block [text]="(presenter.lessee | async).street + ' ' + (presenter.lessee | async).house_number | removeNull"
                                   [wrap]="true"></l7-text-block>
                    <l7-text-block [text]="(presenter.lessee | async).postcode + ' ' + (presenter.lessee | async).city"
                                   [wrap]="true"></l7-text-block>
                </div>
            </mat-card-content>
        </mat-card>
        <l7-spacer [thickness]="'bottom'" [multiplier]="24">
        </l7-spacer>
        <mat-card *ngIf="(presenter.quoteDetails | async)" appearance="outlined" class="mat-card-outlined">
            <mat-card-header>
                <mat-card-title>
                    {{ 'Erklärungen des Kunden' | translate }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="column"
                     fxLayoutGap="16px">
                    <div fxLayout="row">
                        <div fxLayout="column">
                            <mat-checkbox name="GDPR" l7MandatoryCheckboxWithError [attr.test-id]="'GDPR'" (change)="presenter.confirmationsChanged($event)">
                                {{'Ich habe die ' | translate}}<a id="anchor_download-gdpr" (click)="openGPDRFile($event)">
                                {{ 'Datenschutzerklärung' | translate }}</a>{{' zur Kenntnis genommen.*' | translate}}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div fxLayout="column">
                            <mat-checkbox l7MandatoryCheckboxWithError  name="TERMS_AND_CONDITIONS" [attr.test-id]="'TERMS_AND_CONDITIONS'" (change)="presenter.confirmationsChanged($event)">
                                {{'Ich stimme den ' | translate}} <a id="anchor_download-agb" (click)="openContractDraft($event)">
                                {{'allgemeinen Leasingbedingungen' | translate}}</a>{{' zu und bin befugt den Vertrag alleine zu unterzeichnen.*' | translate}}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div *ngIf="(presenter.isSoftware | async)" fxLayout="row">
                        <div fxLayout="column">
                            <mat-checkbox l7MandatoryCheckboxWithError name="CONTRACT_INCLUDES_SOFTWARE" [attr.test-id]="'CONTRACT_INCLUDES_SOFTWARE'" (change)="presenter.confirmationsChanged($event)">
                                {{'Gegenstand dieses Vertrages ist (auch) Computersoftware. Ich stimme den Geschäftsbedingungen des Herstellers/Lieferanten  zu.*' | translate}}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div *ngIf="(presenter.quoteDetails | async).quote.objects[0].exclusionOfWarranty" fxLayout="row">
                        <div fxLayout="column">
                            <mat-checkbox l7MandatoryCheckboxWithError name="EXCLUSION_OF_WARRANTY" [attr.test-id]="'EXCLUSION_OF_WARRANTY'" (change)="presenter.confirmationsChanged($event)">
                                {{'Ich stimme dem ' | translate}} <a (click)="onOpenExclusionOfWarranty($event)">{{'Gewährleistungsausschluss' | translate}}</a> {{'  zu.*'| translate}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <l7-spacer [thickness]="'bottom'" [multiplier]="24">
        </l7-spacer>
        <mat-card appearance="outlined" class="mat-card-outlined">
            <mat-card-header>
                <mat-card-title>
                    {{ 'Zugang zum Vertragsdokument' | translate }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <l7-text-block
                [text]="'quote.retailers.quoteDetails.tasks.createContract.textDocumentAccess' | translate"
                [wrap]="true"></l7-text-block>
                <l7-spacer [thickness]="'bottom'" [multiplier]="24"></l7-spacer>
                <div fxLayout="column"
                     fxLayoutGap="16px">
                    <div fxLayout="row">
                        <mat-radio-group formControlName="deliveryType" color="primary"
                                         [attr.test-id]="'documentsAccess'">
                            <mat-radio-button color="primary" value="EMAIL">
                                {{'quote.retailers.quoteDetails.tasks.createContract.labelDocumentPerEmail' | translate}}
                            </mat-radio-button>
                            <mat-radio-button color="primary" value="OLD_SCHOOL_MAIL">
                                {{'Ich möchte meinen Leasingvertrag per Post erhalten (Adresse: ' + (presenter.lessee | async)?.name + ', ' + (presenter.lessee | async)?.street  + ' ' + (presenter.lessee | async)?.house_number + ', ' + (presenter.lessee | async)?.postcode  + ' ' + (presenter.lessee | async)?.city +').' | translate | removeNull }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <l7-spacer [thickness]="'bottom'" [multiplier]="24">
        </l7-spacer>
        <mat-card appearance="outlined" class="mat-card-outlined">
            <mat-card-header>
                <mat-card-title>
                    {{ 'quote.retailers.quoteDetails.tasks.createContract.titleSignature' | translate }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <l7-spacer [thickness]="'bottom'" [multiplier]="24">
                </l7-spacer>
                <div fxLayout="column"
                     fxLayoutGap="16px">
                    <l7-text-block *ngIf="(presenter.paymentType | async) === 'BANK_ACCOUNT'; else textSelfPayer"
                                   [color]="'bold'"
                                   [text]="'quote.retailers.quoteDetails.tasks.createContract.textConfirmationBankAccount' | translate" [wrap]="true">
                    </l7-text-block>
                    <ng-template #textSelfPayer>
                        <l7-text-block [color]="'bold'"
                                       [text]="'quote.retailers.quoteDetails.tasks.createContract.textConfirmationSelfPayer' | translate"
                                       [wrap]="true">
                        </l7-text-block>
                    </ng-template>
                    <l7-spacer [thickness]="'bottom'" [multiplier]="24">
                    </l7-spacer>
                    <l7-text-block [color]="'bold'"
                                   [text]="'quote.retailers.quoteDetails.tasks.createContract.labelSignaturePad' | translate"
                                   [wrap]="true"></l7-text-block>
                    <div fxLayout="column">
                        <div class="block align-center">
                            <div class="background"
                                 id="div_signature-container">
                                <l7-signature-pad #signaturePad
                                                  [options]="signaturePadOptions"
                                                  (beginSignEvent)="drawStart()"
                                                  (endSignEvent)="drawComplete()">
                                </l7-signature-pad>
                            </div>
                        </div>
                        <l7-spacer [thickness]="'bottom'" [multiplier]="12">
                        </l7-spacer>
                        <div class="align-center">
                            <a id="anchor_clear-signature-pad"
                               (click)="clearSignaturePad()">{{'Unterschrift unleserlich ? Erneut unterschreiben' | translate}}</a>
                        </div>
                        <l7-spacer [thickness]="'bottom'" [multiplier]="24">
                        </l7-spacer>
                        <div  class="align-center">
                            <mat-form-field appearance="outline"
                                            style="width: 60%">
                                <mat-label>{{'Ort der Vertragsunterzeichnung' | translate}}</mat-label>
                                <input l7MarkAsTouched
                                       matInput
                                       formControlName="city"
                                       type="text"
                                       placeholder="Ort der Vertragsunterzeichnung"
                                       [attr.test-id]="'cityOfSignature'">
                                <mat-error *ngIf="presenter.form.get('city').hasError('required')">
                                    <p>{{ 'error.field_required' | translate }}</p>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <l7-spacer [thickness]="'bottom'" [multiplier]="24">
                        </l7-spacer>
                        <div class="align-center">
                            <button mat-flat-button
                                    [color]="'accent'"
                                    [attr.test-id]="'buttonCreateContract'">
                                {{'Vertrag verbindlich abschließen' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </form>
</div>
