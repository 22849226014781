<ng-container *ngIf="canUploadFiles && !overview?.ready?.sent_to_accounting">
    <l7-upload-documents [navContractNr]="contractNumber$ | async"
                         [quoteId]="quoteId$ | async"
                         [loading]="loading"
                         [removeAddCategory]="categoryToAddOrToRemove$ | async"
                         (documentUploaded)="updateDocumentsTable($event)"
                         (allDocumentsUploaded)="onAllDocumentsUploaded($event)">
    </l7-upload-documents>
</ng-container>

<div fxLayout="row"
     fxLayoutAlign="start center">
    <h2 fxFlex>{{'contract_management.documents.table_title' | translate}}</h2>
    <a mat-button
       [color]="'accent'"
       [attr.test-id]="'ButtonCheckSignatures'"
       (click)="openSignaturesDialog()">
        <div fxLayout="row"
             fxLayoutGap="8px"
             fxLayoutAlign="center center ">
            <mat-icon [fontSet]="'l7'"
                      [fontIcon]="'Validate'"></mat-icon>
            <span>{{'contract_management.documents.checkSignatures' | translate}}</span>
        </div>
    </a>
    <a mat-button
       target="_blank"
       rel="noopener noreferrer"
       [color]="'accent'"
       [href]="download_all_files_link"
       [attr.test-id]="'ButtonDownloadAllFile'">
        <div fxLayout="row"
             fxLayoutGap="8px"
             fxLayoutAlign="center center ">
            <mat-icon [fontSet]="'l7'"
                      [fontIcon]="'Import_2'"></mat-icon>
            <span>{{'contract_management.documents.all_documents_link' | translate}}</span>
        </div>
    </a>
</div>

<mat-table fxFill
           matSort
           [dataSource]="this.dataSource"
           [attr.test-id]="'OverviewDocumentsTable'">
    <ng-container *ngFor="let column of columns"
                  [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef
                         mat-sort-header>
            <span *ngIf="column !== 'actions'"
                  class="text-truncate">{{ 'contract_management.documents.' + column | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row ; let i = index;">
            <ng-container [ngSwitch]="column">
                <ng-container *ngSwitchCase="'creationDate'">
                    <span class="text-truncate">{{ row[column] | date }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'type'">
                    <mat-chip-listbox>
                        <mat-chip class="text-truncate no-transform"
                                  [color]="'primary'">{{ 'contract_management.documents.' + row[column] | translate }}</mat-chip>
                    </mat-chip-listbox>
                </ng-container>
                <ng-container *ngSwitchCase="'actions'">
                    <div fxFlex
                         fxLayout="row"
                         fxLayoutAlign="end center">
                        <ng-container *ngIf="canDeleteFile(row['type'])">
                            <button mat-icon-button
                                    [attr.test-id]="'ButtonDeleteFile'"
                                    [disabled]="overview?.ready?.sent_to_accounting"
                                    (click)="deleteFile(row, i)">
                                <mat-icon [fontSet]="'l7'"
                                          [fontIcon]="'Trash'"></mat-icon>
                            </button>
                        </ng-container>
                        <button mat-icon-button
                                [attr.test-id]="'ButtonDownloadFile'"
                                (click)="this.fileDownload(row)">
                            <mat-icon [fontSet]="'l7'"
                                      [fontIcon]="'External_Link'"></mat-icon>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <span class="text-truncate">{{ row[column] }}</span>
                </ng-container>
            </ng-container>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns; let i = index"
             matRipple
             [attr.test-id]="'OverviewDocumentsTableRow' + i">
    </mat-row>
</mat-table>
