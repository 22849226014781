<l7-page>
    <l7-page-header [title]="'configuration.tile' | translate"></l7-page-header>
    <l7-page-content>
        <l7-page-section>
            <mat-card appearance="outlined" class="mat-card-outlined">
                <h4 mat-card-title>{{'configuration.logo.title_logo' | translate}}</h4>
                <p mat-card-subtitle>
                    {{'configuration.logo.description_logo' | translate}}
                </p>
                <mat-card-content>
                    <div fxFlex="100" fxLayout="row" fxLayoutGap="32px">
                        <div fxLayout="column">
                            <div class="image-container">
                                <img *ngIf="(presenter.logoImageURL | async)" alt="image" [width]="defaultLogoConfig.width" [height]="defaultLogoConfig.height" [src]="(presenter.logoImageURL | async) | safe: 'resource'"/>
                            </div>
                        </div>
                        <div fxFlex="0 1 calc(60% - 16px)"
                             fxFlex.lt-md="calc(50% - 16px)"
                             fxFlexAlign="center"
                             fxLayoutGap="16px"
                             fxLayout="column">
                            <div fxFlex="100" fxLayout="row wrap">
                                <p>{{'configuration.logo.recommended_size' | translate: {height: defaultLogoConfig.height, width: defaultLogoConfig.width } }}</p>
                                <p>{{"configuration.logo.recommended_format" | translate}}</p>
                            </div>
                            <div fxFlex="100" fxLayout="row" fxLayoutGap="8px" fxFlexAlign="center left">
                                <input #fileInput
                                       hidden
                                       type="file"
                                       id="uploaded-file"
                                       [accept]="'image/*'"
                                       (change)="uploadNewLogo($event)">
                                <button *ngIf="!isCustomLogoUsed"
                                        mat-flat-button
                                        [color]="'accent'"
                                        [attr.test-id]="'buttonChangeLogo'"
                                        (click)="triggerFileUpload()">{{"configuration.logo.button_logo_change" | translate}}
                                </button>
                                <button *ngIf="isCustomLogoUsed"
                                        mat-flat-button
                                        [color]="'accent'"
                                        [attr.test-id]="'buttonResetLogo'"
                                        (click)="resetCustomLogo()">{{"configuration.logo.button_logo_reset" | translate}}
                                </button>
                                <button mat-button
                                        [color]="'accent'"
                                        [attr.test-id]="'buttonPdfPreview'"
                                        (click)="showContractDraft()">{{"configuration.logo.pdf_preview" | translate}}</button>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </l7-page-section>
    </l7-page-content>
</l7-page>
