// #region Imports

import {
    ContractsDocumentService,
    IContractDocumentDto,
    IDocumentTypeDto,
    ISaleAndLeaseBackCodeDto,
} from '@abcfinlab/api/contract';
import { EventHub, once, TranslationFacade } from '@abcfinlab/core';
import { CheckSignaturesDialogComponent } from '@abcfinlab/presentation';
import { MessageBoxService, ToastService } from '@abcfinlab/ui';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import {
    MatTable,
    MatTableDataSource,
} from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DialogService } from '../../../../apps/l7/src/app/private/services/dialog/dialog.service';
import {
    GenericDialogComponent,
    GenericDialogData,
} from '../../../../apps/l7/src/app/shared/modals/generic-dialog/generic-dialog.component';
import { environment } from '../../../../apps/l7/src/environments/environment';
import { IContractManagementOverviewInfo } from '../Models/IContractManagementOverviewInfo';
import { KnownEvents } from '../Models/KnownEvents';

// #endregion

/**
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-contract-overview-documents-view',
    templateUrl: './ContractOverviewDocumentsView.html',
    styleUrls: ['./ContractOverviewDocumentsView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ContractOverviewDocumentsView implements OnChanges, OnInit {

    @Input() canUploadFiles: boolean;

    @Input() set newFiles(value: Array<IContractDocumentDto>) {
        if (value && this.dataSource) {
            value.forEach((_file) => {
                this.dataSource.data.push(_file);
                this.documentsTable.dataSource = this.dataSource.data;
                this.documentsTable.renderRows();
                if (_file.type === IDocumentTypeDto.Invoice) {
                    this.categoryToAddOrToRemove$.next({ add: false, category: IDocumentTypeDto.Invoice });
                }
            });
        }
    }

    @Output()
    uploadedDocuments: EventEmitter<Array<IContractDocumentDto>> = new EventEmitter<Array<IContractDocumentDto>>();

    @Output()
    isReady: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

    @ViewChild(MatSort, { static: true })
    private readonly _sort!: MatSort;

    @ViewChild(MatTable, { static: true })
    documentsTable: MatTable<any>;

    private readonly _dialogService: DialogService;
    private readonly _dialog: MatDialog;
    private readonly _cdr: ChangeDetectorRef;
    private readonly _columns: Array<string>;
    private _dataSource: MatTableDataSource<IContractDocumentDto>;
    private _overview: IContractManagementOverviewInfo | null;

    private readonly requestSource = new BehaviorSubject(null);

    public loading: boolean;

    public clearUploadedFiles: boolean;

    public contractNumber$ = new BehaviorSubject<string>(null);

    public quoteId$ = new BehaviorSubject<string>(null);

    public categoryToAddOrToRemove$ = new BehaviorSubject<{ add: boolean; category: IDocumentTypeDto }>(null);

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `ContractOverviewDocumentsView` class.
     *
     * @public
     */
    public constructor(
        private readonly contractsDocumentService: ContractsDocumentService,
        cdr: ChangeDetectorRef,
        private readonly _translationFacade: TranslationFacade,
        private readonly _notify: ToastService,
        private readonly _eventHub: EventHub,
        private readonly _messageBoxService: MessageBoxService,
        dialogService: DialogService,
        dialog: MatDialog,
    ) {
        this._cdr = cdr;
        this._dialogService = dialogService;
        this._dialog = dialog;
        this._overview = null;
        this._columns = ['name', 'creationDate', 'createdBy', 'type', 'actions'];
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `details` property.
     *
     * @public
     */
    @Input()
    public set overview(value: IContractManagementOverviewInfo) {
        this._overview = value;
        if (value) {
            this.contractNumber$.next(value.contract_number);
            this.quoteId$.next(value.quote_id);
        }
    }

    public get overview() {
        return this._overview;
    }

    /**
     * Returns the `columns` property.
     *
     * @public
     * @readonly
     */
    public get columns(): Array<string> {
        return this._columns;
    }

    /**
     * Returns the `dataSource` property.
     *
     * @public
     * @readonly
     */
    public get dataSource(): MatTableDataSource<IContractDocumentDto> {
        return this._dataSource;
    }

    public canDeleteFile(fileType: IDocumentTypeDto): boolean {
        return this.canUploadFiles && fileType !== IDocumentTypeDto.PurchaseEntry && fileType !== IDocumentTypeDto.Contract
            && fileType !== IDocumentTypeDto.ExclusionOfWarranty && fileType !== IDocumentTypeDto.KuevArchived && (fileType !== IDocumentTypeDto.Kuev && (this._overview?.slb_code === ISaleAndLeaseBackCodeDto.UnechtlnV2 || this._overview?.slb_code === ISaleAndLeaseBackCodeDto.UnechtliV2 || this._overview?.slb_code === ISaleAndLeaseBackCodeDto.Unechtlimk || this._overview?.slb_code === ISaleAndLeaseBackCodeDto.Unechtlnmk || !this._overview?.slb));
    }

    public get download_all_files_link(): string {
        return `${environment.host}/contract-service/api/v1/contracts/documents/composed/${this._overview?.contract_number}`;
    }

    // #endregion

    // #region Methods

    ngOnInit() {
        this.requestSource
            .pipe(
                untilDestroyed(this),
                switchMap((quoteId) => {
                    if (quoteId) {
                        return this.contractsDocumentService.getContractsDocuments({ contractNumber: this._overview.contract_number });
                    }
                    return of([]);
                }),
            )
            .subscribe((response) => {
                if (response) {
                    response = Array.isArray(response) ? response : response ? [response] : [];

                    this.uploadedDocuments.emit(response);
                    this._dataSource = new MatTableDataSource<IContractDocumentDto>(response);
                    this._dataSource.sort = this._sort;
                    this.dataSource.sortingDataAccessor = (data, sortHeaderId): string | number => {
                        const propPath = sortHeaderId.split('.');
                        const value: any = propPath.reduce(
                            (curObj, property) => curObj[property], data,
                        );
                        return !isNaN(value) ? Number(value) : value;
                    };
                    this._cdr.markForCheck();
                }
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.overview?.currentValue) {
            this.requestSource.next(changes.overview?.currentValue.quote_id);
        }
    }

    public fileDownload(document: IContractDocumentDto): void {
        window.open(`presentation/gwg/pdf-view/${btoa(`${environment.host}/contract-service/api/v1/contracts/documents/${document.id}`)}`, '_blank');
    }

    public updateDocumentsTable(document: IContractDocumentDto) {
        this.dataSource.data.push(document);
        this.documentsTable.dataSource = this.dataSource.data;
        this.documentsTable.renderRows();
        this._cdr.detectChanges();
    }

    public onAllDocumentsUploaded(value: boolean) {
        if (value) {
            this.uploadedDocuments.emit(this.dataSource.data);
            this.isReady.emit([this._overview.contract_number]);
            this._cdr.detectChanges();
        }
    }

    public openSignaturesDialog(): void {
        once(this._dialog.open(CheckSignaturesDialogComponent, {
            width: '70vw',
            data: {
                contractNumber: this.contractNumber$.getValue(),
            },
        }).afterClosed());
    }

    public deleteFile(row: IContractDocumentDto, index: number) {
        const data: GenericDialogData = {
            id: 'dialog_delete_document',
            image: 'assets/images/icon-create-contract.svg',
            title: this._translationFacade.instant('contract_management.documents.dialog_delete_title'),
            body: this._translationFacade.instant('contract_management.documents.dialog_delete_body', { param1: row.name }),
            negativeText: 'global.cancel',
            positiveText: 'global.confirm',
        };
        this._dialogService.openDialog(GenericDialogComponent, {}, data).afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.loading = true;
                this.contractsDocumentService.deleteDocument({ documentId: row.id }).pipe(untilDestroyed(this))
                    .subscribe((_) => {
                        this.loading = false;
                        this.dataSource.data.splice(index, 1);
                        this.documentsTable.dataSource = this.dataSource.data;
                        this.documentsTable.renderRows();
                        this.uploadedDocuments.emit(this.dataSource.data);
                        this._notify.showSuccess('Dokument gelöscht!');
                        this._eventHub.publish<IContractDocumentDto>(KnownEvents.DOCUMENT_DELETED, row);
                        this.isReady.emit([this._overview.contract_number]);
                        if (row.type === IDocumentTypeDto.Invoice) {
                            this.categoryToAddOrToRemove$.next({ add: true, category: IDocumentTypeDto.Invoice });
                        }
                        this._cdr.detectChanges();
                    },
                    (_error) => {
                        this.loading = false;
                        this._notify.showError(`${_error.error.error_description}`);
                        this.isReady.emit([this._overview.contract_number]);
                        this._cdr.detectChanges();
                    });
            }
        });
    }

    // #endregion

}
