import { Component, inject } from '@angular/core';
import {
    RetailerCalculateLeasingQuoteFormService,
} from '../../Services/forms/retailer-calculate-leasing-quote-form/retailer-calculate-leasing-quote-form.service';

@Component({
    selector: 'l7-retailer-calculation-monthly-insurance-toggle',
    templateUrl: './retailer-calculation-monthly-insurance-toggle.component.html',
    styleUrl: './retailer-calculation-monthly-insurance-toggle.component.scss',
    standalone: false,
})
export class RetailerCalculationMonthlyInsuranceToggleComponent {

    private readonly _calculateLeasingQuoteFormService = inject(RetailerCalculateLeasingQuoteFormService, { skipSelf: true });
    toggleControl = this._calculateLeasingQuoteFormService.monthlyInsuranceControl;
    monthlyInsuranceValue = this._calculateLeasingQuoteFormService.monthlyInsuranceValue.asReadonly();

    onChange(checked: boolean): void {
        this._calculateLeasingQuoteFormService.onMonthlyInsuranceCheckboxChange(checked);
    }

}
