<l7-page [isEmpty]="(presenter.lessees | async | lesseeFilterBy:(presenter.condition | async).term).length ? false : true">
    <l7-page-header #header
                    [title]="'Leasingnehmer mit kalkuliertem Angebot' | translate"></l7-page-header>
    <l7-page-pre-content fxLayout="row"
                         fxLayoutAlign="start start">
        <l7-page-section fxFlex.lt-md="100"
                         fxFlex="40">
            <mat-form-field fxFlex
                            appearance="outline">
                <mat-label>{{ 'Suchbegriff eingeben' | translate }}</mat-label>
                <input #term="matInput"
                       matInput
                       [attr.test-id]="'TermInput'"
                       [value]="(presenter.condition| async).term"
                       (keyup.enter)="presenter.onTermChanged(term.value)">
                <button mat-icon-button
                        matSuffix
                        (click)="presenter.onTermChanged(term.value)">
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Search'"></mat-icon>
                </button>
            </mat-form-field>
        </l7-page-section>
    </l7-page-pre-content>
    <l7-page-content fxFlex>
        <mat-nav-list fxFlex
                      fxFill>
            <mat-list-item *ngFor="let lessee of presenter.lessees | async | lesseeFilterBy:(presenter.condition | async).term; last as last"
                           [attr.test-id]="lessee.navContactNumber"
                           (click)="presenter.onLesseeSelected(lessee)">
                <l7-text-block matListItemTitle
                               [truncate]="true"
                               [text]="lessee.name"></l7-text-block>
                <l7-text-block matListItemLine
                               [truncate]="true"
                               [readonly]="true"
                               [text]="presenter.formatAddress(lessee)"></l7-text-block>
                <l7-text-block matListItemLine
                               [readonly]="true"
                               [text]="(lessee.lastUpdate | date: 'short') + ' Uhr' | translate "></l7-text-block>
                <div matListItemMeta>
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Chevron_Right'"></mat-icon>
                </div>
                <mat-divider *ngIf="!last"
                             class="without-margin"
                             [inset]="true"></mat-divider>
            </mat-list-item>
        </mat-nav-list>
    </l7-page-content>
    <div fxLayoutAlign="center center">
        <l7-spacer [thickness]="'all'"
                   [multiplier]="24">
            <button *ngIf="!header.hasBackNavigation"
                    mat-button
                    id="button_back-to-start"
                    [color]="'accent'"
                    (click)="presenter.onBackToStart()">{{'Zurück zur Startseite' | translate}}</button>
        </l7-spacer>
    </div>
</l7-page>
