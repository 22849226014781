// #region Imports

import { FinanceAdminService, IUserRequestDto } from '@abcfinlab/api/retailer';
import { IUserGroupRetailerDto } from '@abcfinlab/auth';
import { ControlsOf, Validators as CoreValidators, DeepRequired, FormChangesObserver, FormValidator, once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { inject, Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, throwError } from 'rxjs';
import { FinanceAdminValidators } from '../../Validators/Validators';

// #endregion

interface ICreateRetailerUserViewPresenterDialogData {
    retailerId: string;
}

/**
 * The presenter of the {@link CreateRetailerUserView} view.
 *
 * @internal
 */
@Injectable()
export class CreateRetailerUserViewPresenter {

    // #region Fields
    private readonly _financeAdminValidatorService = inject(FinanceAdminValidators);
    private readonly _messageBoxService = inject(MessageBoxService);
    private readonly _translationFacade = inject(TranslationFacade);
    private readonly _financeAdminService = inject(FinanceAdminService);
    private readonly _formValidator = inject(FormValidator);
    private readonly _formChangesObserver = inject(FormChangesObserver);
    private readonly _busyBoxService = inject(BusyBoxService);
    private readonly _toastService = inject(ToastService);
    private readonly _dialogRef: MatDialogRef<any>;
    private readonly _dialogData: BehaviorSubject<ICreateRetailerUserViewPresenterDialogData>;
    private readonly _form: FormGroup<ControlsOf<DeepRequired<IUserRequestDto>>>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `CreateRetailerUserViewPresenter` class.
     *
     * @public
     */
    public constructor(dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) dialogData: ICreateRetailerUserViewPresenterDialogData) {
        this._dialogRef = dialogRef;
        this._dialogData = new BehaviorSubject(dialogData);
        this._form = new FormGroup<ControlsOf<DeepRequired<IUserRequestDto>>>({
            email: new FormControl(
                '', [Validators.required, CoreValidators.email()],
                [this._financeAdminValidatorService.emailInvalidAsync().bind(this._financeAdminValidatorService), this._financeAdminValidatorService.emailExistsAsync().bind(this._financeAdminValidatorService)],
            ),
            familyName: new FormControl('', Validators.required),
            givenName: new FormControl('', Validators.required),
            groups: new FormControl<Array<any>>([IUserGroupRetailerDto.RetailerAdmin], Validators.required),
            mobileNumber: new FormControl('', [CoreValidators.phoneNumber()]),
            phoneNumber: new FormControl('', [CoreValidators.phoneNumber()]),
            active: new FormControl<boolean>(true),
        });
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `userGroup` property.
     * A Static helper property to bind enums in the view
     *
     * @public
     * @readonly
     */
    public get userGroup(): typeof IUserGroupRetailerDto {
        return IUserGroupRetailerDto;
    }

    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): FormGroup<ControlsOf<DeepRequired<IUserRequestDto>>> {
        return this._form;
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        //
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        //
    }

    /**
     * @internal
     */
    public onSubmit(cancel: boolean): void {
        if (cancel) {
            this._messageBoxService.show('Vorgang abbrechen', 'Sind sie sicher, dass Sie den Vorgang abbrechen wollen? Ihre Angaben werden nicht gespeichert.', MessageBoxButton.YesNo, {
                labels: {
                    yes: this._translationFacade.translate('global.yes'),
                    no: this._translationFacade.translate('global.no'),
                },
            }).subscribe((result) => {
                if (result === MessageBoxResult.Yes) {
                    this._dialogRef.close(false);
                }
            });

            return;
        }

        const errors = this._formValidator
            .validate(this._form)
            .errors(this._form);

        // ignore email emailInvaid error because it is only a warning.
        if ((errors.length - (errors.some(x => x.name === 'email' && x.errors.emailInvaid) ? 1 : 0)) > 0) {
            this._toastService.show(this._translationFacade.translate('administration.retailers.user.create.toast.form.error'), 'danger');
        } else {
            once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
                this._financeAdminService.createRetailerUser({
                    retailerId: this._dialogData.value.retailerId,
                    body: this._form.getRawValue(),
                }),
            ), () => {
                this._toastService.show(this._translationFacade.translate('administration.retailers.user.create.toast.success'), 'success');
                this._dialogRef.close(true);
            }, (error) => {
                this._toastService.show(this._translationFacade.translate('administration.retailers.user.create.toast.error'), 'danger');
                throwError(error);
            });
        }
    }

    // #endregion

}
