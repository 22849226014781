/* libs/quote/src/lib/components/feature-retailer-calculation/feature-retailer-calculation.component.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
::ng-deep .ng-animating div mat-accordion mat-expansion-panel mat-expansion-panel-header {
  height: 48px;
}
::ng-deep .ng-animating div mat-accordion mat-expansion-panel div.mat-expansion-panel-content {
  height: 0px;
  visibility: hidden;
}
:host mat-stepper.stepper-without-header ::ng-deep .mat-horizontal-stepper-header-container {
  display: none !important;
}
:host mat-stepper.stepper-without-header ::ng-deep .mat-vertical-stepper-header,
:host mat-stepper.stepper-without-header ::ng-deep .mat-stepper-vertical-line::before {
  display: none !important;
}
:host mat-stepper.stepper-without-header ::ng-deep .mat-vertical-content-container .mat-vertical-content {
  padding: 0;
}
:host .hide {
  display: none !important;
}
:host .alignBottom {
  margin-bottom: 19px;
}
:host .mat-stepper-horizontal {
  background: none;
}
:host .rate-card {
  flex-shrink: 1;
  height: 146px;
  max-width: 146px;
  min-width: 115px;
}
:host .rate-card l7-text-block:nth-of-type(1) ::ng-deep {
  margin-top: 10px;
}
:host .rate-card l7-text-block:nth-of-type(1) ::ng-deep p {
  font-weight: bold;
}
:host .rate-card l7-text-block {
  margin-bottom: 19px;
}
:host .small-grey {
  margin-top: 4px;
  font-size: 12px;
  text-align: center;
  color: #71879c;
}
:host .mail-to {
  text-decoration: none;
  color: #1b7e9a;
}
:host .loading-container {
  min-height: 70vh;
}
:host .info-icon {
  margin-right: 0.25rem;
}
:host .linked-fields {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
}
:host .linked-fields mat-icon {
  margin: 1.25rem 0.5rem 0;
  overflow: visible;
  transform: translateY(0.25rem);
}
:host .linked-label {
  display: flex;
  align-items: center;
  height: 40px;
}
/*# sourceMappingURL=feature-retailer-calculation.component.css.map */
