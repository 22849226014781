// #region Imports

import { ICommand } from '@abcfinlab/core';
import { inject, Injectable, Injector, Type } from '@angular/core';

// #endregion

/**
 * @public
 */
@Injectable()
export class CommandFactory {

    // #region Fields

    private readonly _injector: Injector;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `CommandFactory` class.
     *
     * @public
     */
    public constructor() {
        this._injector = inject(Injector);
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public create(commandType: Type<ICommand>): ICommand {
        return this._injector.get(commandType);
    }

    // #endregion

}
