import { AppConfig } from '@abcfinlab/core';
import { IGWGStrategyInterface } from '../interfaces/IGWGStrategy.interface';

export class HirePurchaseTypeGWGStrategy implements IGWGStrategyInterface {

    private readonly _BASE_URL: string;
    private readonly _host: string;

    constructor(appConfig: AppConfig) {
        this._host = appConfig.get('host');
        this._BASE_URL = `${this._host}/api/v1/quote`;
    }

    baseURL(): string {
        return `${this._BASE_URL}/hirepurchase`;
    }

}

export class DefaultTypeGWGStrategy implements IGWGStrategyInterface {

    private readonly _BASE_URL: string;
    private readonly _host: string;

    constructor(appConfig: AppConfig) {
        this._host = appConfig.get('host');
        this._BASE_URL = `${this._host}/api/v1/quote`;
    }

    baseURL(): string {
        return `${this._BASE_URL}/leasing`;
    }

}
