import { LaunchDarklyFeatureManager } from '@abcfinlab/core';
import { effect, inject, untracked } from '@angular/core';
import { CanMatchFn } from '@angular/router';

export const launchDarklyFeatureGuard: (featureName: string) => CanMatchFn = (featureName: string) => (_route, _segments) => {
    const featureManager = inject(LaunchDarklyFeatureManager);
    const matcher = featureManager.getActiveFeature({ featureName });

    return new Promise((resolve) => {
        effect(() => {
            if (!featureManager.featureResource.isLoading()) {
                untracked(() => {
                    if (featureManager.featureResource.error()) {
                        resolve(false);
                    } else if (featureManager.features()) {
                        resolve(matcher());
                    }
                });
            }
        });
    });
};
