@if(monthlyInsuranceValue()) {
  @let monthlyInsuranceFormated = monthlyInsuranceValue() | currency;
  <mat-card class="monthly-insurance-card">
    <mat-card-content>
      <mat-slide-toggle labelPosition="before"
                        [checked]="toggleControl.value"
                        [attr.test-id]="'insurance'"
                        (change)="onChange($event.checked)"
      >
        <p class="mat-h3" fxLayout="row" fxLayoutAlign="center center">
          <mat-icon class="info-icon" [matTooltip]="'quote.retailers.calculation.input.monthlyInsurance.tooltipInfoText' | translate" [fontSet]="'l7'" [fontIcon]="'Info'"></mat-icon>{{ 'quote.retailers.calculation.input.monthlyInsurance.label' | translate }}</p>
      </mat-slide-toggle>
      <p class="mat-body-1">{{'quote.retailers.calculation.input.monthlyInsurance.subText' | translate: { monthlyInsuranceValue: monthlyInsuranceFormated } }}</p>
    </mat-card-content>
  </mat-card>
}