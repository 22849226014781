<ng-template #itemTemplate
             let-version
             let-last="last">
    <mat-card appearance="outlined" class="mat-card-outlined item"
              fxFlex="0 1 calc(33.3% - 16px)"
              fxFlex.lt-lg="0 1 calc(50% - 16px)"
              ngClass.lt-lg="lt-lg"
              fxFlex.lt-md="100%"
              ngClass.lt-md="lt-md"
              [attr.test-id]="'versionCard' + version.id">
        <mat-card-header>
            <l7-text-block matCardTitle
                           [truncate]="true"
                           [text]="version.object_name"></l7-text-block>
            <l7-text-block matCardSubtitle
                           [truncate]="true"
                           [text]="(version.last_update | date) + ' | ' + (version | quoteName)"></l7-text-block>
            <l7-spacer style="flex: 1;"
                       [thickness]="'all'"></l7-spacer>
            <div fxFlex
                 fxLayout="row"
                 fxLayoutAlign="end center">
                <l7-quote-version-menu [version]="version"
                                       (addVendor)="onAddVendor($event)"></l7-quote-version-menu>
            </div>
        </mat-card-header>

        <mat-card-content>
            <div *ngFor="let row of rows"
                 fxLayout="row">
                <l7-spacer fxFlex
                           [thickness]="'top'">
                    <l7-text-block fxFlex="50"
                                   fxLayout="column"
                                   [truncate]="true"
                                   [readonly]="true"
                                   [text]="'quote.versions.' + row | translate"></l7-text-block>
                    <div fxFlex="50"
                         fxLayout="column"
                         fxLayoutAlign="center flex-end"
                         class="item-value">
                        <ng-container [ngSwitch]="row">
                            <ng-container *ngSwitchCase="'total_leasing_value'">
                                <l7-text-block class="bold"
                                               [text]="version.quote_calculation.total_leasing_value | currency"></l7-text-block>
                            </ng-container>
                            <ng-container *ngSwitchCase="'total_terms'">
                                <l7-text-block class="bold"
                                               [text]="version.quote_calculation.total_terms + ' Monate'"></l7-text-block>
                            </ng-container>
                            <ng-container *ngSwitchCase="'instalment'">
                                <l7-text-block class="bold"
                                               [text]="version.quote_calculation.instalment | currency"></l7-text-block>
                            </ng-container>
                            <ng-container *ngSwitchCase="'yearly_interest'">
                                <l7-text-block class="bold"
                                               [text]="(version.quote_calculation.yearly_interest | number:'1.2') + ' %'"></l7-text-block>
                            </ng-container>
                            <ng-container *ngSwitchCase="'contract_type'">
                                <ng-container *ngIf="version.quote_calculation.contract_type === 'MIETKAUF'; else notMiekauf">
                                    <l7-text-block class="bold"
                                                   [text]="'MK'"></l7-text-block>
                                </ng-container>
                                <ng-template #notMiekauf>
                                    <div fxLayout="row">
                                        <l7-text-block class="bold"
                                                       [text]="version.quote_calculation.contract_type"></l7-text-block>
                                        <l7-text-block *ngIf="version.sale_and_lease_back && version.quote_calculation.contract_type !== 'MKN'"
                                                       class="bold"
                                                       [text]="'+ SLB'"></l7-text-block>
                                        <l7-text-block *ngIf="version.quote_calculation.contract_type === 'MKN'"
                                                       class="bold"
                                                       [text]="'+ SMB'"></l7-text-block>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </ng-container>
                    </div>
                </l7-spacer>
            </div>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions fxLayoutAlign="center">
            <button fxFlex
                    mat-button
                    [color]="'accent'"
                    (click)="goToDetailView($event, version)">Zum Angebot</button>
        </mat-card-actions>
    </mat-card>
</ng-template>

<div fxLayout="row wrap"
     fxLayout.lt-md="column"
     fxLayoutAlign="flex-start"
     style="margin: -8px;">
    <ng-container *ngFor="let version of versions; let last = last">
        <ng-content *ngTemplateOutlet="itemTemplate; context: { $implicit: version, last: last }"></ng-content>
    </ng-container>
</div>
