<form *ngIf="form"
      [formGroup]="form">
    <input type="hidden" l7Calculate test-id="insuranceType" formControlName="insuranceType" />
    <div fxLayout="column"
         fxLayoutGap="16px">
        <mat-card>
            <mat-card-header>
                <mat-card-title fxFlex
                                fxLayout="row"
                                fxLayoutAlign="space-between center">
                    <div>{{ 'quote.calculation.insurance.title' | translate }}</div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="more-padding" fxLayout="column" fxLayoutGap="16px">
                <!-- Handling Fee-->
                <div fxLayout="row"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="0px"
                     fxLayoutGap="16px">
                    <mat-slide-toggle labelPosition="before"
                                      color="primary"
                                      class="justify-content-space-between"
                                      formControlName="handlingFee"
                                      fxFlex="100"
                                      [attr.test-id]="'toggleHandlingFee'"
                                      (change)="toggleHandlingFee($event)">
                        {{'settlement.details.handling_fee' | translate}}
                    </mat-slide-toggle>
                </div>
                <div fxLayout="row"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="0px"
                     fxLayoutGap="16px">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>{{'settlement.details.handling_fee' | translate}}</mat-label>
                        <input matInput
                               l7MarkAsTouched
                               type="text"
                               currencyMask
                               l7Calculate
                               formControlName="handlingFeeValue"
                               l7SelectOnDblClick
                               [markOnInit]="true"
                               [attr.test-id]="'handlingFeeValue'">
                        <mat-error *ngIf="form.controls.handlingFeeValue.invalid && form.controls.handlingFeeValue.hasError('required')">
                            {{'error.field_required' | translate}}
                        </mat-error>
                        <mat-icon *ngIf="form.controls.handlingFeeValue.value > 500" matSuffix
                                  color="warning"
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Alert_Circle'">
                        </mat-icon>
                        <mat-hint *ngIf="form.controls.handlingFeeValue.value > 500">
                            {{ 'calculation.handling_fee_warning' | translate }}
                        </mat-hint>
                    </mat-form-field>
                </div>
                <!-- Handling Fee end -->
                <!-- Insurance start -->
                <div fxLayout="row"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="0px"
                     fxLayoutGap="16px" [formGroup]="insuranceAndSlbForm">
                    <mat-slide-toggle labelPosition="before"
                                      color="primary"
                                      class="justify-content-space-between"
                                      formControlName="insurance"
                                      fxFlex="100"
                                      [attr.test-id]="'toggleInsurance'"
                                      (change)="handleInsuranceSelection($event)">
                        <ng-container *ngIf="contractType === 'TA' || contractType === 'VA' || contractType === null">
                            {{'calculation.add_ordinary_insurance_label' | translate}}
                        </ng-container>

                        <ng-container *ngIf="contractType === 'KFZ'">
                            {{'calculation.add_100pro_insurance_label' | translate}}
                        </ng-container>

                        <ng-container *ngIf="contractType === 'MIETKAUF' || contractType === 'MKN'">
                            {{'calculation.insurance_or_100_pro' | translate}}
                        </ng-container>

                    </mat-slide-toggle>
                </div>
                <div *ngIf="contractType === 'MIETKAUF' || contractType === 'MKN'"
                     fxLayout="row"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="0px" fxLayoutGap="16px">
                    <mat-form-field fxFlex="100"
                                    appearance="outline"
                                    [attr.test-id]="'selectInsurance'">
                        <mat-label>{{'calculation.insurance_or_100_pro' | translate}}</mat-label>
                        <mat-select formControlName="insuranceType"
                                    l7Calculate
                                    [attr.test-id]="'selectInsuranceType'"
                                    (selectionChange)="handleInsuranceSelection($event)">
                            <ng-container *ngFor="let insurance of insuranceTypes">
                                <mat-option [value]="insurance"
                                            [disabled]="insuranceTypeDisabled(insurance)"
                                            [style.text-transform]="'capitalize'">
                                    {{ 'states.insurance.' + insurance | translate | lowercase }}
                                </mat-option>
                            </ng-container>

                        </mat-select>
                    </mat-form-field>
                </div>
                <ng-container *ngIf="insuranceAndSlbForm.controls.insurance.getRawValue() || ((contractType === 'MIETKAUF' || contractType === 'MKN') && form.controls.insuranceType.value !== 'NO')">
                    <div fxLayout="row"
                         fxLayout.lt-sm="column"
                         fxLayoutGap.lt-sm="0px"
                         class="no-margin"
                         fxLayoutGap="16px" >
                        <mat-form-field appearance="outline"
                                        class="error-p-0-8"
                                        [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'"
                                        [fxFlex.lt-md]="'calc(' + 100 + '%' + ' - 16px)'">
                            <mat-label>{{'quote.calculation.insurance.monthRateLabel' | translate}}</mat-label>
                            <input matInput
                                   l7MarkAsTouched
                                   type="text"
                                   l7Calculate
                                   formControlName="insuranceValue"
                                   currencyMask
                                   l7SelectOnDblClick
                                   [markOnInit]="true"
                                   [attr.test-id]="'InsuranceValue'">
                            <mat-error *ngIf="form.controls.insuranceValue.invalid && form.controls.insuranceValue.hasError('required')">
                                {{'error.field_required' | translate}}
                            </mat-error>
                            <mat-error *ngIf="form.controls.insuranceValue.invalid && form.controls.insuranceType.getRawValue() === 'STANDARD' &&
                                        (form.controls.insuranceValue.hasError('max') || form.controls.insuranceValue.hasError('min'))">
                                {{'error.value_between_values' | translate: {param1: insuranceAndHandlingFeeResponse?.insuranceValueMin | currency, param2: insuranceAndHandlingFeeResponse?.insuranceValueMax | currency} }}
                            </mat-error>
                            <mat-error *ngIf="form.controls.insuranceValue.invalid && form.controls.insuranceType.getRawValue() === 'PRO100' &&
                                        form.controls.insuranceValue.hasError('min')">
                                {{'error.value_bigger_than' | translate: {param1: insuranceAndHandlingFeeResponse?.insuranceValueMin | currency} }}
                            </mat-error>
                        </mat-form-field>
                        <l7-summary *ngIf="form.controls.insuranceType.getRawValue() === 'STANDARD'"
                                    [maxColumns]="1" [minColumns]="1">
                            <l7-summary-item [title]="'calculation.pro_mille_placeholder' | translate"
                                             [content]="insuranceAndHandlingFeeResponse?.insurancePromille | currency:  '‰': 'code': '1.4-4'">
                            </l7-summary-item>
                        </l7-summary>
                    </div>

                    <l7-summary *ngIf="contractType === 'MIETKAUF' || contractType === 'MKN'"
                                [maxColumns]="2" [minColumns]="1">
                        <l7-summary-item [title]="'calculation.total_claim' | translate"
                                         [content]="insuranceAndHandlingFeeResponse?.totalInsuranceInstalments | currency: 'EUR'">
                        </l7-summary-item>
                        <l7-summary-item *ngIf="contractType === 'MIETKAUF' || (contractType === 'MKN' && form.controls.insuranceType.getRawValue() === 'STANDARD'); else withoutTaxes"
                                         [title]="'calculation.total_claim_taxes' | translate"
                                         [content]="insuranceAndHandlingFeeResponse?.totalInsuranceInstalmentsVat | currency: 'EUR'">
                        </l7-summary-item>
                        <ng-template #withoutTaxes>
                            <l7-summary-item [title]="'calculation.total_claim_taxes' | translate"
                                             [content]="'Nettobetrag: keine USt.' | translate">
                            </l7-summary-item>
                        </ng-template>
                    </l7-summary>
                </ng-container>
                <!-- Insurance end -->
                <!-- SLB -->
                <ng-container *ngIf="lessee && (contractType === 'TA' || contractType === 'VA' || contractType === 'KFZ' || contractType === 'MKN')"
                              [formGroup]="insuranceAndSlbForm">
                    <div *ngIf="contractType !== 'MKN'; else smbTitle"
                         fxLayout="row"
                         class="mt-8"
                         fxLayout.lt-sm="column"
                         fxLayoutGap.lt-sm="0px" fxLayoutGap="16px">
                        <mat-slide-toggle labelPosition="before"
                                          color="primary"
                                          class="justify-content-space-between"
                                          formControlName="slb"
                                          fxFlex="100"
                                          [attr.test-id]="'toggleSlb'"
                                          (change)="toggleSlb($event)">
                            {{'calculation.slb_toggle_label' | translate}}
                        </mat-slide-toggle>
                    </div>
                    <ng-template #smbTitle>
                        <div fxLayout="row"
                             fxLayout.lt-sm="column"
                             fxLayoutGap.lt-sm="0px"
                             fxLayoutGap="16px">
                            <p>{{'calculation.smb_toggle_label' | translate}}</p>
                        </div>
                    </ng-template>

                    <div fxLayout="row"
                         fxLayout.lt-sm="column"
                         fxLayoutGap.lt-sm="0px"
                         fxLayoutGap="16px">
                        <mat-form-field fxFlex="100"
                                        appearance="outline"
                                        [ngClass]="{'error': insuranceAndSlbForm.get('slbCode').errors}">
                            <mat-label *ngIf="contractType !== 'MKN'; else smbLabel">{{'calculation.slb_select_label' | translate}}</mat-label>
                            <ng-template #smbLabel>
                                <mat-label>{{'calculation.smb_select_label' | translate}}</mat-label>
                            </ng-template>
                            <mat-select ngDefaultControl
                                        id="select-slb-code"
                                        formControlName="slbCode"
                                        [attr.test-id]="'selectSlbCode'">
                                <mat-select-trigger>
                                      <span matTooltip="{{ 'slb.codes.' + insuranceAndSlbForm.controls.slbCode.value | translate }}">
                                        {{'slb.codes.new.' + insuranceAndSlbForm.controls.slbCode.value | translate}}
                                      </span>
                                </mat-select-trigger>
                                <ng-container *ngIf="contractType !== 'MKN'; else smb">
                                    <mat-option *ngFor="let code of slbCodes; let i = index" [value]="code">
                                        <p>{{ 'slb.codes.new.' + code | translate}}</p>
                                        <p class="code-description">{{ 'slb.codes.' + code | translate }}</p>
                                    </mat-option>
                                </ng-container>
                                <ng-template #smb>
                                    <mat-option *ngFor="let code of smbCodes; let i = index" [value]="code">
                                        <p>{{ 'slb.codes.new.' + code | translate}}</p>
                                        <p class="code-description">{{ 'slb.codes.' + code | translate }}</p>
                                    </mat-option>
                                </ng-template>
                            </mat-select>
                            <mat-error *ngIf="insuranceAndSlbForm.get('slbCode').errors?.required">
                                {{'error.field_required' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>

            </mat-card-content>
        </mat-card>
    </div>
</form>
