<div fxFlex
     fxLayout="row wrap"
     fxLayoutGap="16px">
    <ng-container *ngFor="let item of this.items">
        <fieldset fxLayout="column"
                  [fxFlex]="'0 1 calc(' + 100 / this.maxColumns + '%' + ' - 16px)'"
                  [fxFlex.lt-md]="'calc(' + 100 / this.minColumns + '%' + ' - 16px)'">
            <legend>{{ item.title }}</legend>
            <div fxLayoutGap="8px"
                 [fxLayout]="item?.icon?.position === 'after' ? 'row' : 'row-reverse'"
                 [fxLayoutAlign]="item?.icon?.position === 'after' ? 'start center' : 'end center'">
                <l7-text-block [truncate]="true"
                               [text]="item.content"></l7-text-block>
                <ng-container *ngTemplateOutlet="item?.icon?.template"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="item?.contentTemplate"></ng-container>
        </fieldset>
    </ng-container>
</div>