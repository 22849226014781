<l7-dialog-header [title]="'contract_management.retailers.dialogs.digitalSigning.title' | translate"
                  [closeable]="true"
                  (closed)="presenter.onClose(true)"></l7-dialog-header>
<mat-dialog-content fxLayout="column"
                    fxLayoutGap="16px">
    <form l7ScrollToError
          [formGroup]="presenter.form" (ngSubmit)="presenter.onSubmit(false)">
        <mat-stepper #stepper>
            <mat-step [label]="'contract_management.retailers.dialogs.digitalSigning.step1.stepName' | translate"
                      [state]="'number'"
                      [stepControl]="presenter.form.controls.general">
                <mat-card appearance="outlined" class="mat-card-outlined">
                    <mat-card-header>
                        <l7-text-block matCardTitle
                                       [wrap]="true"
                                       [text]="'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceTitle' | translate"></l7-text-block>
                        <l7-text-block matCardSubtitle
                                       [wrap]="true"
                                       [text]="'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceSubTitle' | translate:
                                       {maxPercentage: (presenter.calculationSettings | async)?.leasingQuoteCalculationProperties.retailer.acknowledgement.maxObjectValueChangePercent}">
                        </l7-text-block>
                    </mat-card-header>
                    <mat-card-content fxLayout="column"
                                      [formGroupName]="'general'">
                        <mat-slide-toggle #toggle
                                          [attr.test-id]="'purchasePriceChanged'"
                                          [formControlName]="'purchasePriceChanged'"
                                          (change)="presenter.onPurchasePriceChangeToggle($event)">{{ 'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceToggle' | translate }}</mat-slide-toggle>
                        <l7-spacer [thickness]="'top'"
                                   [multiplier]="16"></l7-spacer>
                        <mat-form-field appearance="outline"
                                        class="formFieldItem">
                            <mat-label>{{'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceTitle' | translate}}</mat-label>
                            <input matInput
                                   l7MarkAsTouched
                                   l7SelectOnDblClick
                                   currencyMask
                                   [attr.test-id]="'purchasePrice'"
                                   [formControlName]="'purchasePrice'"
                                   [readonly]="!toggle.checked"
                                   (keyup)="presenter.purchasePriceSubject.next($event)">
                            <mat-spinner *ngIf="(presenter.isBusyCalculating | async)"
                                         matSuffix
                                         [diameter]="27"></mat-spinner>
                            <mat-error *ngIf="presenter.form.controls.general.controls.purchasePrice.hasError('percentage') || presenter.form.controls.general.controls.purchasePrice.hasError('max')">
                                <p>{{ 'Der Nettokaufpreis ist zu hoch.' | translate }}</p>
                            </mat-error>
                            <mat-error *ngIf="presenter.form.controls.general.controls.purchasePrice.hasError('min')">
                                <p>{{ 'Der Nettokaufpreis ist zu niedrig.' | translate }}</p>
                            </mat-error>
                        </mat-form-field>
                        <ng-container *ngIf="presenter.isPriceChanged && (presenter.calculationHasError | async) === false && !presenter.form.controls.general.controls.purchasePrice.invalid && (presenter.isBusyCalculating | async) === false">
                            <l7-banner [color]="'warn'"
                                       [appearance]="'outlined'"
                                       [iconFontSet]="'l7'"
                                       [icon]="'Alert_Triangle'"
                                       [content]="presenter.warningMessageIfPriceChanged | async">
                            </l7-banner>
                        </ng-container>
                        <ng-container *ngIf="presenter.isPriceChanged && (presenter.calculationHasError | async) === true && (presenter.isBusyCalculating | async) === false">
                            <l7-banner [color]="'danger'"
                                       [appearance]="'outlined'"
                                       [iconFontSet]="'l7'"
                                       [icon]="'Alert_Triangle'"
                                       [content]="'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceChangedError' | translate">
                            </l7-banner>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
                <mat-card appearance="outlined" class="mat-card-outlined">
                    <mat-card-header>
                        <l7-text-block matCardTitle
                                       [wrap]="true"
                                       [text]="'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceDateTitle' | translate"></l7-text-block>
                        <l7-text-block matCardSubtitle
                                       [wrap]="true"
                                       [text]="'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceDateSubTitle' | translate"></l7-text-block>
                    </mat-card-header>
                    <mat-card-content [formGroupName]="'general'">
                        <l7-date-picker fxFlex="40" fxFlex.lt-md="100"
                                        [label]="'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceDateSubInput'"
                                        [controlName]="'acknowledgementDate'"
                                        [requiredErrorMessage]="'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceDateSubInputErrorMandatory'"
                                        [minDateErrorMessage]="'contract_management.retailers.dialogs.digitalSigning.step1.purchasePriceDateSubInputErrorMinDate'"
                                        [uniqueId]="'acknowledgementDate'"
                                        [parentFormGroup]="presenter.form.get('general')">
                        </l7-date-picker>
                    </mat-card-content>
                </mat-card>
                <l7-spacer [thickness]="'top'"
                           [multiplier]="16"></l7-spacer>
                <div fxLayout="row"
                     fxLayoutAlign="flex-end center"
                     fxLayout.lt-sm="column">
                    <button mat-flat-button
                            color="primary"
                            [attr.test-id]="'btnNextStep1'"
                            [disabled]="!presenter.form.get('general.acknowledgementDate').valid"
                            (click)="nextStep()">{{'global.further' | translate}}</button>
                </div>
            </mat-step>
            <mat-step [label]="'contract_management.retailers.dialogs.digitalSigning.step2.stepName' | translate"
                      [state]="'number'"
                      [stepControl]="presenter.form.controls.choices">
                <mat-card appearance="outlined" class="mat-card-outlined">
                    <mat-card-header>
                        <l7-text-block matCardTitle
                                       [text]="'contract_management.retailers.dialogs.digitalSigning.step2.stepTitle' | translate"></l7-text-block>
                        <l7-text-block matCardSubtitle
                                       [text]="'contract_management.retailers.dialogs.digitalSigning.step2.stepSubTitle' | translate"></l7-text-block>
                    </mat-card-header>
                    <mat-card-content fxLayout="column"
                                      fxLayoutGap="32px"
                                      [formGroupName]="'choices'">
                        <mat-radio-group fxLayout="column"
                                         fxLayoutGap="16px"
                                         [attr.test-id]="'isSerialNumberAuto'"
                                         [formControlName]="'isSerialNumberAuto'"
                                         (change)="presenter.checkSerialNumberType()">
                            <mat-radio-button [value]="false">
                                <l7-text-block [wrap]="true"
                                               [text]="'contract_management.retailers.dialogs.digitalSigning.step2.radioSerialNumberManual' | translate"></l7-text-block>
                            </mat-radio-button>
                            <mat-radio-button [value]="true">
                                <l7-text-block [wrap]="true"
                                               [text]="'contract_management.retailers.dialogs.digitalSigning.step2.radioSerialNumberAuto' | translate"></l7-text-block>
                            </mat-radio-button>
                        </mat-radio-group>
                    </mat-card-content>
                </mat-card>
                <l7-spacer [thickness]="'top'"
                           [multiplier]="16"></l7-spacer>
                <div fxLayout="row"
                     fxLayoutAlign="flex-end center"
                     fxLayout.lt-sm="column"
                     fxLayoutGap="8px">
                    <button mat-stroked-button
                            color="primary"
                            [attr.test-id]="'btnPrevious1'"
                            (click)="previousStep()">{{'contract_management.back' | translate}}</button>
                    <button mat-flat-button
                            color="primary"
                            [attr.test-id]="'btnNext2'"
                            (click)="nextStep()">{{'global.further' | translate}}</button>
                </div>
            </mat-step>
            <mat-step [label]="'contract_management.retailers.dialogs.digitalSigning.step3.stepName' | translate"
                      [state]="'number'"
                      [stepControl]="presenter.form.controls.files"
                      (interacted)="serialNumberStepTouched = true">
                <mat-card *ngIf="presenter.form.controls.choices.controls.isSerialNumberAuto.value === true" appearance="outlined"
                          class="mat-card-outlined">
                    <mat-card-header>
                        <l7-text-block matCardTitle
                                       [wrap]="true"
                                       [text]="'contract_management.retailers.dialogs.digitalSigning.step3.uploadSerialNumberTitle' | translate"></l7-text-block>
                        <l7-text-block matCardSubtitle
                                       [wrap]="true"
                                       [text]="'contract_management.retailers.dialogs.digitalSigning.step3.uploadSerialNumberSubTitle' | translate"></l7-text-block>
                    </mat-card-header>
                    <mat-card-content fxLayout="column"
                                      fxLayoutGap="32px">
                        <l7-file-uploader [allowedFileTypes]="['pdf']"
                                          [maxFiles]="1"
                                          [orientation]="'vertical'"
                                          [fileListClass]="'new-style'"
                                          [attr.test-id]="'uploadSerialNumberFile'"
                                          (filesChanged)="presenter.onSerialNumberUpload( $event)"></l7-file-uploader>
                        <mat-hint>
                            <l7-text-block [wrap]="true"
                                           [text]="'contract_management.retailers.dialogs.digitalSigning.step3.uploadSerialNumberHint' | translate"></l7-text-block>
                        </mat-hint>
                    </mat-card-content>
                </mat-card>
                <mat-card *ngIf="presenter.form.controls.choices.controls.isSerialNumberAuto.value === false" appearance="outlined"
                          class="mat-card-outlined">
                    <mat-card-header>
                        <l7-text-block matCardTitle
                                       [wrap]="true"
                                       [text]="'Seriennummern eingeben' | translate"></l7-text-block>
                        <l7-text-block matCardSubtitle
                                       [wrap]="true"
                                       [text]="'Bitte teilen Sie uns die Seriennummernn der im Leasingvertrag enthaltenen Objekte mit' | translate"></l7-text-block>
                    </mat-card-header>
                    <mat-card-content [formGroupName]="'files'">
                        <ng-container [formArrayName]="'serialNumbers'">
                            <div *ngFor="let sn of presenter.serialNumbers.controls; let i = index"
                                 fxLayout="row"
                                 fxLayout.lt-sm="column"
                                 fxLayoutGap="16px"
                                 fxLayoutAlign="flex-start baseline">
                                <ng-container [formGroupName]="i">
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'Position' | translate }}</mat-label>
                                        <input matInput
                                               type="text"
                                               [attr.test-id]="'position' + i"
                                               [formControlName]="'position'">
                                    </mat-form-field>
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'Objektbezeichnung' | translate }}</mat-label>
                                        <input matInput
                                               type="text"
                                               [attr.test-id]="'objectDescription' + i"
                                               [formControlName]="'objectDescription'">
                                    </mat-form-field>
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{'Baujahr' | translate}}</mat-label>
                                        <mat-select [formControlName]="'yearOfManufacture'"
                                                    [attr.test-id]="'yearOfManufacture' + i">
                                            <mat-option *ngFor="let year of presenter.years"
                                                        [value]="year">{{ year }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field fxFlex
                                                    appearance="outline">
                                        <mat-label>{{ 'Geräte-/Seriennummer' | translate }}</mat-label>
                                        <input matInput
                                               type="text"
                                               [attr.test-id]="'serialNumber' + i"
                                               [formControlName]="'serialNumber'">
                                    </mat-form-field>
                                    <button *ngIf="i !==0"
                                            mat-icon-button
                                            [attr.test-id]="'btnDeleteSerialNumber' + i"
                                            (click)="presenter.onDeleteSerialNumber(sn.getRawValue().id, i)">
                                        <mat-icon [fontSet]="'l7'"
                                                  [fontIcon]="'Trash'">
                                        </mat-icon>
                                    </button>
                                </ng-container>
                            </div>
                        </ng-container>
                        <div fxLayout="row"
                             fxLayout.lt-sm="column"
                             fxLayoutGap="16px">
                            <button mat-button
                                    [color]="'primary'"
                                    [attr.test-id]="'addSerialNumber'"
                                    (click)="presenter.onAddSerialNumber()">
                                <mat-icon [fontSet]="'l7'"
                                          [fontIcon]="'Plus'"></mat-icon>
                                {{'Weitere Seriennummer hinzufügen' | translate}}
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>

                <l7-spacer [thickness]="'top'"
                           [multiplier]="16"></l7-spacer>
                <div fxLayout="row"
                     fxLayoutAlign="flex-end center"
                     fxLayout.lt-sm="column"
                     fxLayoutGap="8px">
                    <button mat-stroked-button
                            color="primary"
                            [attr.test-id]="'btnPrevious2'"
                            (click)="previousStep()">{{'contract_management.back' | translate}}</button>
                    <button mat-flat-button
                            color="primary"
                            [attr.test-id]="'btnNext3'"
                            (click)="nextStep()">{{'global.further' | translate}}</button>
                </div>
            </mat-step>
            <mat-step [label]="'contract_management.retailers.dialogs.digitalSigning.step4.stepName' | translate"
                      [state]="'number'"
                      [stepControl]="presenter.form.controls.choices">

                <mat-card *ngIf="!isOnLastStep" appearance="outlined"
                          class="mat-card-outlined">
                    <mat-card-header>
                        <l7-text-block matCardTitle
                                       [text]="'contract_management.retailers.dialogs.digitalSigning.step4.stepTitle' | translate"></l7-text-block>
                        <l7-text-block matCardSubtitle
                                       [text]="'contract_management.retailers.dialogs.digitalSigning.step4.stepSubTitle' | translate"></l7-text-block>
                    </mat-card-header>
                    <mat-card-content fxLayout="column"
                                      fxLayoutGap="32px"
                                      [formGroupName]="'choices'">
                        <mat-radio-group fxLayout="column"
                                         fxLayoutGap="16px"
                                         [attr.test-id]="'isSigningRemote'"
                                         [formControlName]="'isSigningRemote'">
                            <mat-radio-button [value]="false">
                                <l7-text-block [wrap]="true"
                                               [text]="'contract_management.retailers.dialogs.digitalSigning.step4.radioSigningLocal' | translate"></l7-text-block>
                            </mat-radio-button>
                            <mat-radio-button [value]="true">
                                <l7-text-block [wrap]="true"
                                               [text]="'contract_management.retailers.dialogs.digitalSigning.step4.radioSigningRemote' | translate"></l7-text-block>
                            </mat-radio-button>
                        </mat-radio-group>
                    </mat-card-content>
                </mat-card>
                <mat-card *ngIf="isOnLastStep && presenter.form.controls.choices.controls.isSigningRemote.value === true" appearance="outlined"
                          class="mat-card-outlined">
                    <mat-card-header>
                        <l7-text-block matCardTitle
                                       [text]="'contract_management.retailers.dialogs.digitalSigning.step4.signRemoteTitle' | translate"></l7-text-block>
                        <l7-text-block matCardSubtitle
                                       [text]="'contract_management.retailers.dialogs.digitalSigning.step4.signRemoteSubTitle' | translate"></l7-text-block>
                    </mat-card-header>
                    <mat-card-content fxLayout="column"
                                      fxLayoutGap="32px"
                                      [formGroupName]="'finalRemote'">
                        <mat-form-field appearance="outline"
                                        style="width: 80%"
                                        [ngClass]="{'warning': presenter.form.get('finalRemote.signerEmail').hasError('emailInvaid') }">
                            <mat-label>{{'E-Mail Adresse' | translate}}</mat-label>
                            <input matInput
                                   type="email"
                                   l7MarkAsTouched
                                   [attr.test-id]="'signerEmail'"
                                   [formControlName]="'signerEmail'">
                            <div *ngIf="(presenter.form.get('finalRemote.signerEmail').statusChanges | async) === 'PENDING'"
                                 matSuffix>
                                <mat-progress-spinner [diameter]="16"
                                                      [strokeWidth]="2"
                                                      [color]="'primary'"
                                                      [mode]="'indeterminate'"></mat-progress-spinner>
                            </div>
                            <mat-error *ngIf="presenter.form.get('finalRemote.signerEmail').hasError('required')">
                                <p>{{ 'administration.retailers.create.input.email.error.required' | translate }}</p>
                            </mat-error>
                            <mat-error *ngIf="presenter.form.get('finalRemote.signerEmail').hasError('email')">
                                <p>{{ 'administration.retailers.create.input.email.error.email' | translate }}</p>
                            </mat-error>
                            <mat-error *ngIf="presenter.form.get('finalRemote.signerEmail').hasError('emailInvaid')">
                                <p>{{ 'administration.retailers.create.input.email.error.emailInvaid' | translate }}</p>
                            </mat-error>
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
                <ng-container *ngIf="isOnLastStep && presenter.form.controls.choices.controls.isSigningRemote.value === false">
                    <l7-text-block matCardSubtitle
                                   [text]="'contract_management.retailers.dialogs.digitalSigning.step4.acknowledgementFor' | translate:{contractName: presenter.dialogData.contractDetails.contractNo, approvalDate: presenter.approvalDate | date: 'shortDate'} "></l7-text-block>
                    <l7-text-block matCardTitle
                                   [text]="presenter.dialogData.contactInfo.name | translate"></l7-text-block>
                    <l7-text-block matCardSubtitle
                                   [text]="presenter.dialogData.contactInfo.street + ' ' + presenter.dialogData.contactInfo.house_number + ', ' + presenter.dialogData.contactInfo.postcode + ' ' + presenter.dialogData.contactInfo.city | translate | removeNull"></l7-text-block>
                    <l7-spacer [thickness]="'top'"
                               [multiplier]="16"></l7-spacer>
                    <l7-text-block [wrap]="true"
                                   [text]="'contract_management.retailers.dialogs.digitalSigning.step4.signRemoteConfirmationTitle' | translate:{fhCompany: presenter.vendorCompanyName} | removeNull "></l7-text-block>
                    <l7-spacer [thickness]="'top'"
                               [multiplier]="16"></l7-spacer>

                    <mat-card appearance="outlined" class="mat-card-outlined">
                        <mat-card-header>
                            <l7-text-block matCardTitle
                                           [text]="'contract_management.retailers.dialogs.digitalSigning.step1.stepName' | translate"></l7-text-block>
                        </mat-card-header>
                        <mat-card-content class="more-padding"
                                          fxLayout="column">
                            <div fxFlex
                                 fxLayout="row wrap"
                                 fxLayoutAlign="space-between center"
                                 fxLayoutGap="16px">
                                <fieldset fxFlex="0 1 calc(33,33% - 16px)"
                                          fxFlex.lt-md="calc(50% - 16px)"
                                          fxLayout="column">
                                    <legend>{{ 'Bezeichnung (Typ/Hersteller)' | translate }}</legend>
                                    <l7-text-block class="small-text"
                                                   [truncate]="true"
                                                   [text]="presenter.dialogData.contractDetails.objectDescription"></l7-text-block>
                                </fieldset>
                                <fieldset fxFlex="0 1 calc(33,33% - 16px)"
                                          fxFlex.lt-md="calc(50% - 16px)"
                                          fxLayout="column">
                                    <legend>{{ 'Geräte-Nr./Serien-Nr.' | translate }}</legend>
                                    <button *ngIf="presenter.form.controls.files.controls.serialNumber.value"
                                            mat-icon-button
                                            class="textAndIcon"
                                            [attr.test-id]="'ButtonDownloadFile'"
                                            (click)="presenter.showSerialNumberDocument()">
                                        <span>Siehe Anhang</span>
                                        <mat-icon [fontSet]="'l7'"
                                                  [fontIcon]="'External_Link'"
                                                  [inline]="true"></mat-icon>
                                    </button>
                                    <l7-text-block *ngIf="presenter.form.controls.files.controls.serialNumbers.length === 1 && !presenter.form.controls.files.controls.serialNumber.value"
                                                   [text]="formatSerialNumber(presenter.form.controls.files.controls.serialNumbers.controls[0].value)">
                                    </l7-text-block>
                                    <ng-container *ngIf="presenter.form.controls.files.controls.serialNumbers.length > 1 && !presenter.form.controls.files.controls.serialNumber.value">
                                        <l7-text-block class="small-text"
                                                       [text]="'Siehe Anlage (wird im Nachgang per Mail zugesendet)'"
                                                       [truncate]="true">
                                        </l7-text-block>
                                    </ng-container>

                                </fieldset>
                                <fieldset fxFlex="0 1 calc(33,33% - 16px)"
                                          fxFlex.lt-md="calc(50% - 16px)"
                                          fxLayout="column">
                                    <legend>{{ 'Monatliche Rate' | translate }}</legend>
                                    <l7-text-block *ngIf="presenter.isPriceChanged"
                                                   class="small-text"
                                                   [truncate]="true"
                                                   [text]="(presenter.newInstalment | async) | currency">
                                    </l7-text-block>
                                    <l7-text-block *ngIf="!presenter.isPriceChanged"
                                                   class="small-text"
                                                   [truncate]="true"
                                                   [text]="(presenter.quoteInfo | async).quote_calculation.instalment | currency">
                                    </l7-text-block>
                                </fieldset>
                                <fieldset fxFlex="0 1 calc(33,33% - 16px)"
                                          fxFlex.lt-md="calc(50% - 16px)"
                                          fxLayout="column">
                                    <legend>{{ 'Zustand' | translate }}</legend>
                                    <l7-text-block class="small-text"
                                                   [truncate]="true"
                                                   [wrap]="true"
                                                   [text]="'contract_management.retailers.dialogs.digitalSigning.step4.condition.' + presenter.dialogData.contractDetails.objectCondition | translate"></l7-text-block>
                                </fieldset>
                                <fieldset *ngIf="presenter.form.controls.choices.controls.isSerialNumberAuto.value === false && presenter.serialNumbers.controls.length === 1"
                                          fxFlex="0 1 calc(33,33% - 16px)"
                                          fxFlex.lt-md="calc(50% - 16px)"
                                          fxLayout="column">
                                    <legend>{{ 'Baujahr' | translate }}</legend>
                                    <l7-text-block class="small-text"
                                                   [truncate]="true"
                                                   [text]="presenter.serialNumbers.controls[0].controls.yearOfManufacture.value"></l7-text-block>
                                </fieldset>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <l7-spacer [thickness]="'top'"
                               [multiplier]="16"></l7-spacer>
                    <mat-card appearance="outlined" class="mat-card-outlined">
                        <l7-text-block matCardTitle
                                       class="padding"
                                       [wrap]="true"
                                       [text]="'Einverständniserklärung' | translate"></l7-text-block>
                        <mat-card-content [formGroupName]="'general'">
                            <div fxLayout="column"
                                 fxLayoutGap="16px">
                                <div *ngIf="presenter.isPriceChanged" fxLayout="row">
                                    <div fxLayout="column">
                                        <mat-checkbox l7MandatoryCheckboxWithError [formControlName]="'localSigningCheckBox1'"
                                                      [attr.test-id]="'localSigningCheckBox1'">
                                            {{ presenter.checkBoxLabelIfPriceChanged | async }}
                                        </mat-checkbox>
                                    </div>
                                </div>

                                <div fxLayout="row">
                                    <div fxLayout="column">
                                        <mat-checkbox l7MandatoryCheckboxWithError [formControlName]="'localSigningCheckBox2'"
                                                      [attr.test-id]="'localSigningCheckBox2'">
                                            Dem Leasingnehmer (nachfolgend LN) ist bekannt, dass mit der vorbehaltlosen Unterzeichnung der Übernahmebestätigung die Rechnung des Lieferanten zur Zahlung fällig wird. Die abcfinance GmbH leistet Zahlung an den Lieferanten nur im Vertrauen auf die Richtigkeit der Übernahmebestätigung. Die Unterzeichnung der Übernahmebestätigung vor tatsächlicher Inbesitznahme der Geräte ist daher unzulässig. Sämtliche der abcfinance GmbH aus unzutreffenden Angaben in dieser Übernahmebestätigung sowie deren unberechtigter Erteilung oder Verweigerung entstehende Schäden gehen voll zu Lasten des LN. Dieses gilt auch für Schäden, welche die abcfinance GmbH erleidet, wenn die Übernahmebestätigung ohne Vorbehalt abgegeben wird, obwohl das Leasingobjekt offensichtliche Mängel aufweist, nicht funktionsfähig ist oder Teile des Objekts nicht geliefert wurden. Das vom LN bestätigte Übernahmedatum ist maßgebend für den Beginn der Leasinglaufzeit.
                                        </mat-checkbox>
                                    </div>
                                </div>

                                <div fxLayout="row">
                                    <div fxLayout="column">
                                        <mat-checkbox l7MandatoryCheckboxWithError [formControlName]="'localSigningCheckBox3'"
                                                      [attr.test-id]="'localSigningCheckBox3'">
                                            Diese Übernahmebestätigung ist ein wesentlicher Bestandteil des über das Leasingobjekt abgeschlossenen Leasingvertrages. Der LN erklärt hiermit ausdrücklich, dass weder mit der Lieferfirma noch mit der abcfinance GmbH irgendwelche Nebenabreden getroffen wurden.
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <l7-spacer [thickness]="'top'"
                               [multiplier]="16"></l7-spacer>
                    <mat-card appearance="outlined" class="mat-card-outlined">
                        <mat-card-header>
                            <l7-text-block matCardTitle
                                           [wrap]="true"
                                           [text]="'contract_management.retailers.dialogs.digitalSigning.step4.singedByTitle' | translate: {companyName: presenter.dialogData.contactInfo.name}"></l7-text-block>
                        </mat-card-header>
                        <mat-card-content fxLayout="column"
                                          [formGroup]="this.presenter.form.controls.finalLocal">
                            <l7-spacer [thickness]="'top'"
                                       [multiplier]="16"></l7-spacer>
                            <div fxLayout="row"
                                 fxLayoutGap="8px">
                                <mat-form-field fxFlex="30"
                                                appearance="outline"
                                                class="formFieldItem">
                                    <mat-label>{{'contract_management.retailers.dialogs.digitalSigning.step4.singedByFirstName' | translate}}</mat-label>
                                    <input matInput
                                           [attr.test-id]="'signedByFirstName'"
                                           [formControlName]="'signedByFirstName'">
                                </mat-form-field>
                                <mat-form-field fxFlex="30"
                                                appearance="outline"
                                                class="formFieldItem">
                                    <mat-label>{{'contract_management.retailers.dialogs.digitalSigning.step4.singedByLastName' | translate}}</mat-label>
                                    <input matInput
                                           [attr.test-id]="'signedByLastName'"
                                           [formControlName]="'signedByLastName'">
                                </mat-form-field>
                                <l7-date-picker fxFlex="30"
                                                [label]="'contract_management.retailers.dialogs.digitalSigning.step4.singedByDate'"
                                                [controlName]="'signedByDate'"
                                                [uniqueId]="'signedByDate'"
                                                [minDate]="presenter.approvalDate"
                                                [maxDate]="presenter.currentDate"
                                                [parentFormGroup]="presenter.form.get('finalLocal')">
                                </l7-date-picker>
                            </div>
                            <div fxLayout="column"
                                 fxLayout.gt-sm="row"
                                 fxFlexAlign="center">
                                <l7-signature [attr.test-id]="'signature'"
                                              [resetSignature]="resetSignature$ | async"
                                              (canvasDirty)="this.isSignatureDirty$.next($event)"
                                              (signatureBlob)="signature$.next($event)">
                                </l7-signature>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </ng-container>


                <l7-spacer [thickness]="'top'"
                           [multiplier]="16"></l7-spacer>
                <div fxLayout="row"
                     fxLayoutAlign="flex-end center"
                     fxLayout.lt-sm="column"
                     fxLayoutGap="8px">
                    <button *ngIf="!isOnLastStep"
                            mat-stroked-button
                            color="primary"
                            [attr.test-id]="'btnPrevious3'"
                            (click)="previousStep()">{{'contract_management.back' | translate}}</button>
                    <button *ngIf="isOnLastStep"
                            mat-stroked-button
                            color="primary"
                            [attr.test-id]="'btnIsOnLastStep'"
                            (click)="setLastStep(false)">{{'contract_management.back' | translate}}</button>
                    <button *ngIf="!isOnLastStep"
                            mat-flat-button
                            color="primary"
                            [attr.test-id]="'btnSetLastStep'"
                            (click)="setLastStep(true)">{{'global.further' | translate}}</button>
                    <button *ngIf="isOnLastStep && presenter.form.controls.choices.controls.isSigningRemote.value === false"
                            mat-flat-button
                            color="primary"
                            [attr.test-id]="'btnDoLocal'"
                            (click)="doAcknowledgmentLocal()">{{'contract_management.retailers.dialogs.digitalSigning.step4.btnSignLocal' | translate}}</button>
                    <button *ngIf="isOnLastStep && presenter.form.controls.choices.controls.isSigningRemote.value === true"
                            mat-flat-button
                            color="primary"
                            [attr.test-id]="'btnDoRemote'"
                            (click)="doAcknowledgmentRemote()">{{'contract_management.retailers.dialogs.digitalSigning.step4.btnSignRemote' | translate}}</button>
                </div>
            </mat-step>

        </mat-stepper>
    </form>
</mat-dialog-content>