<div *ngIf="this.presenter.data | async as info"
     fxLayout="column"
     fxLayoutGap="16px">
    <mat-card>
        <mat-card-header>
            <h3 matCardTitle>{{ 'settlement.details.billing_information' | translate }}</h3>
        </mat-card-header>
        <mat-card-content>
            <div fxFlex
                 fxLayout="row wrap"
                 fxLayoutGap="16px">
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)"
                          fxLayout="column">
                    <legend>{{ 'settlement.details.start_date' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="(this.presenter.data | async).contract?.startOfContract | date | na"></l7-text-block>
                </fieldset>
                <fieldset *ngIf="(this.presenter.data | async).calculation as calculation"
                          fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)"
                          fxLayout="column">
                    <legend>{{ 'settlement.details.insurance' | translate }}</legend>
                    <div fxLayout="row"
                         fxLayoutGap="8px"
                         fxLayoutAlign="start center">
                        <div fxLayout="row">
                            <l7-text-block [truncate]="true"
                                           [text]="(('states.insurance.' + (this.presenter.data | async).calculation?.insurance_type) | translate)"></l7-text-block>
                            <l7-text-block *ngIf="(this.presenter.data | async).calculation?.insurance_type !== 'NO'"
                                           [text]="': '"></l7-text-block>
                            <l7-text-block *ngIf="(this.presenter.data | async).calculation?.insurance_type !== 'NO'"
                                           [text]="((this.presenter.data | async).calculation?.insurance_value) | currency | na"></l7-text-block>
                        </div>
                        <mat-icon *ngIf="calculation.insurance_type === 'STANDARD'"
                                  [class]="calculation | insuranceTypeIcon:'color'"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="calculation | insuranceTypeIcon:'icon'"></mat-icon>
                    </div>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)"
                          fxLayout="column">
                    <legend>{{ 'settlement.details.old_object_value' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="(this.presenter.data | async).row?.object?.value | currency | na"></l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'settlement.details.navision_status' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="(this.presenter.data | async).contract?.status | na"></l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)"
                          fxLayout="column">
                    <legend>{{ 'settlement.details.pre_rent' | translate }}</legend>
                    <l7-text-block *ngIf="(this.presenter.data | async).accounting?.pre_rent === 'NEXT_MONTH_CALCULATED'"
                                   [truncate]="true"
                                   [block]="false"
                                   [text]="'states.pre_rent.' + (this.presenter.data | async).accounting?.pre_rent | translate">
                    </l7-text-block>
                    <l7-text-block *ngIf="(this.presenter.data | async).accounting?.pre_rent === 'NEXT_MONTH_WITHOUT'"
                                   [truncate]="true"
                                   [block]="false"
                                   [text]="'states.pre_rent.' + (this.presenter.data | async).accounting?.pre_rent | translate">
                    </l7-text-block>
                    <l7-text-block *ngIf="(this.presenter.data | async).accounting?.pre_rent === 'NEXT_MONTH_MANUAL_ENTERED'"
                                   [truncate]="true"
                                   [wrap]="true"
                                   [block]="false"
                                   [text]="'states.pre_rent.' + (this.presenter.data | async).accounting?.pre_rent | translate: {value: (this.presenter.data | async).accounting?.pre_rent_value | currency}">
                    </l7-text-block>
                    <l7-text-block *ngIf="(this.presenter.data | async).accounting?.pre_rent === 'FIRST_DAY_OF_THIS_MONTH'"
                                   [truncate]="true"
                                   [block]="false"
                                   [text]="'states.pre_rent.' + (this.presenter.data | async).accounting?.pre_rent | translate">
                    </l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'settlement.details.registration_certificate' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="(this.presenter.data | async).hasRegistrationCertificate ? ('global.yes' | translate) : ('global.no' | translate)"></l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'Bearbeitungsgebühr' | translate }}</legend>
                    <l7-text-block [truncate]="true"
                                   [text]="(this.presenter.data | async).calculation?.handling_fee_value | currency | na"></l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'Vertragsart' | translate }}</legend>
                    <l7-text-block *ngIf="!(this.presenter.isSLBContract | async) else isSLB"
                                   [truncate]="true"
                                   [text]="'states.contract_type.' + (this.presenter.data | async).quote?.contract_type | translate"></l7-text-block>
                    <ng-template #isSLB>
                        <div fxLayout="row">
                            <l7-text-block *ngIf="(this.presenter.data | async).quote?.contract_type !== 'MKN'; else isSMB"
                                           [truncate]="true"
                                           [text]="('states.contract_type.' + (this.presenter.data | async).quote?.contract_type | translate) + ' + SLB (' + ('slb.codes.new.' + (this.presenter.data | async).quote?.slb_code | translate) + ')'">
                            </l7-text-block>
                            <span *ngIf="(this.presenter.data | async).quote?.slb_code === 'UNECHTLN' || (this.presenter.data | async).quote?.slb_code === 'UNECHTLI'" [matTooltip]="'slb.oldSLbToolTip' | translate">{{ '*' }}</span>
                        </div>
                    </ng-template>
                    <ng-template #isSMB>
                        <l7-text-block [truncate]="true"
                                       [text]="('states.contract_type.' + (this.presenter.data | async).quote?.contract_type | translate) + ' + SMB ('+ ((this.presenter.data | async).quote?.slb_code | translate) + ')'">
                        </l7-text-block>
                    </ng-template>
                </fieldset>

                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'Genehmigungsstatus' | translate }}</legend>
                    <div *ngIf="(this.presenter.data | async).creditCheck as creditCheck"
                         fxLayout="row wrap"
                         fxLayoutGap="8px"
                         fxLayoutAlign="start center">
                        <mat-icon [color]="creditCheck.credit_rating === 'POSITIVE' ? 'success' : 'warning'"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="creditCheck.credit_rating | creditCheckIcon:'icon'"></mat-icon>
                        <l7-text-block [truncate]="true"
                                       [text]="'states.credit_check.' + creditCheck.credit_rating | translate"></l7-text-block>
                        <l7-text-block [color]="(this.presenter.isCreditRatingExpired| async) ? 'danger' : 'neutral'"
                                       [text]="'(' + (creditCheck.timestamp | date) + ')'"></l7-text-block>
                    </div>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>Einstandskondition</legend>

                    <l7-text-block [wrap]="true"
                                   [maxLines]="3"
                                   [text]="'Kalkuliert: ' + ((this.presenter.data | async).calculation?.refinancing_rate | number) + ' % | Aktuell: ' + ((this.presenter.data | async)?.refinancingInterest?.interest | number) + ' %'">
                    </l7-text-block>

                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{'contract_management.documents.TRANSPARENCY_REGISTER' | translate }}</legend>
                    <div *ngIf="(this.presenter.data | async).quote?.transparency_register_entry  === false && (presenter.referenceContracts | async) === null;">
                        <l7-text-block [wrap]="true"
                                       [maxLines]="3"
                                       [text]="'states.transparency_register.not_available' | translate">
                        </l7-text-block>
                    </div>
                    <div *ngIf="(this.presenter.data | async).quote?.transparency_register_entry && (presenter.referenceContracts | async) === null;"
                         fxLayout="row"
                         fxLayoutGap="8px">
                        <mat-icon [color]="'warning'"
                                  [inline]="true"
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Alert_Circle_Filled'"></mat-icon>
                        <l7-text-block [wrap]="true"
                                       [maxLines]="3"
                                       [text]="'states.transparency_register.available'| translate">
                        </l7-text-block>
                    </div>
                    <div *ngIf="(presenter.referenceContracts | async);"
                         fxLayout="row"
                         fxLayoutGap="8px">

                        <div [matMenuTriggerFor]="menu">{{'siehe Referenzverträge' | translate}} <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Chevron_Down'"></mat-icon>
                        </div>
                        <mat-menu #menu="matMenu">
                            <ng-container *ngFor="let contract of (presenter.referenceContracts | async)">
                                <p mat-menu-item>{{contract}}</p>
                            </ng-container>
                        </mat-menu>
                    </div>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'settlement.details.assistant' | translate }}</legend>
                    <l7-persona-group *ngIf="(this.presenter.data | async).salesLead">
                        <a [href]="'mailto:' + (this.presenter.data | async).salesLead.email">
                            <l7-persona [color]="'primary'"
                                        [text]="(this.presenter.data | async).salesLead.full_name"
                                        [matTooltip]="(this.presenter.data | async).salesLead.l7_company_name ?
                                                                              (this.presenter.data | async).salesLead.full_name + ' (' + (this.presenter.data | async).salesLead.l7_company_name + ')' :
                                                                              (this.presenter.data | async).salesLead.full_name">
                            </l7-persona>
                        </a>
                    </l7-persona-group>
                    <l7-persona-group>
                        <ng-container *ngFor="let assistance of (this.presenter.data | async).salesAssistance">
                            <a *ngIf="assistance.email !== (this.presenter.data | async).salesLead?.email"
                               [href]="'mailto:' + assistance.email">
                                <l7-persona [color]="'primary'"
                                            [text]="assistance.full_name"
                                            [matTooltip]="assistance.full_name"></l7-persona>
                            </a>
                        </ng-container>
                    </l7-persona-group>
                    <l7-text-block *ngIf="!(this.presenter.data | async).salesAssistance"
                                   [truncate]="true"
                                   [text]="(this.presenter.data | async).salesAssistance | na"></l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'Bezahlmethode' | translate }}</legend>
                    <ng-container *ngIf="(this.presenter.data | async)?.quote?.sales_channel === 'RETAILER' && (this.presenter.data | async)?.quote?.bank_account_id === null; else sepaMandate">
                        <l7-text-block [wrap]="true"
                                       [maxLines]="3"
                                       [text]="'Selbstzahler'">
                        </l7-text-block>
                    </ng-container>
                    <ng-template #sepaMandate>
                        <div *ngIf="(this.presenter.sepaMandate | async) as sepa"
                             fxLayout="column">
                            <div fxLayout="row"
                                 fxLayoutGap="8px"
                                 fxLayoutAlign="start center">
                                <mat-icon [color]="sepa?.status === 'ACTIVE' ? 'success' : 'danger'"
                                          [inline]="true"
                                          [fontSet]="'l7'"
                                          [fontIcon]="sepa?.status | sepaMandateStatusIcon:'icon'"></mat-icon>
                                <l7-text-block [truncate]="true"
                                               [text]="'states.sepa_mandate_status.' + sepa?.status | lowercase | translate"></l7-text-block>
                            </div>
                            <div *ngIf="sepa.mandate_id"
                                 fxLayout="row"
                                 fxLayoutGap="8px">
                                <l7-text-block [text]="'ID:'"></l7-text-block>
                                <l7-text-block [truncate]="true"
                                               [text]="sepa.mandate_id | na"></l7-text-block>
                            </div>
                            <div *ngIf="sepa.iban"
                                 fxLayout="row"
                                 fxLayoutGap="8px">
                                <l7-text-block [text]="'IBAN:'"></l7-text-block>
                                <l7-text-block [truncate]="true"
                                               [text]="sepa.iban | na"></l7-text-block>
                            </div>
                            <div *ngIf="sepa.status !== 'ACTIVE'"
                                 fxLayout="row"
                                 fxLayoutGap="8px">
                                <button mat-button
                                        color="primary"
                                        [disabled]="isReadonlyUser"
                                        (click)="patchSepaMandate()">{{'settlement.details.button_sepa' | translate}}</button>
                            </div>
                        </div>
                    </ng-template>

                </fieldset>
                <fieldset fxFlex="0 1 calc(25% - 16px)"
                          fxFlex.lt-md="calc(50% - 16px)">
                    <legend>{{ 'settlement.details.note' | translate }}</legend>
                    <l7-text-block [wrap]="true"
                                   [maxLines]="3"
                                   [truncate]="true"
                                   [text]="(this.presenter.data | async).contract?.annotation | na"></l7-text-block>
                </fieldset>
                <ng-container *ngIf="(this.presenter.data | async)?.quote?.sales_channel === 'RETAILER'">
                    <fieldset fxFlex="0 1 calc(25% - 16px)"
                              fxFlex.lt-md="calc(50% - 16px)">
                        <legend>{{'Auflagen' | translate}}</legend>

                        <l7-text-block [wrap]="true"
                                       [maxLines]="3"
                                       [truncate]="true"
                                       [text]="(this.presenter.obligations | async) ? (this.presenter.obligations | async) : ''">
                        </l7-text-block>

                    </fieldset>
                </ng-container>

            </div>
        </mat-card-content>
    </mat-card>
    @if ((presenter.changedPurchasePriceValues | async) && (presenter.changedPurchasePriceValues | async).totalLeasingValueNet !== null) {
        <l7-object-value-changed [contractType]="info.quote.contract_type"
                                 [calculationValues]="presenter.changedPurchasePriceValues | async"
                                 [insuranceValues]="{insuranceType: info.insuranceAndHandlingFee?.insurance_type, insuranceValue: info.insuranceAndHandlingFee?.insurance_value}"
                                 [slbCalculation]="info.slb?.activeSlbResponse?.calculation"
                                 [isPurchasePriceChangedInactive]="false">
        </l7-object-value-changed>
    }

    <mat-card *ngIf="(this.presenter.data | async).changedCalculation && (this.presenter.data | async).changedCalculation?.yearlyInterest !== (this.presenter.data | async).calculation?.yearly_interest">
        <mat-card-header>
            <p matCardTitle>Ratenanpassung nach Zinsänderung</p>
        </mat-card-header>
        <mat-card-content>
            <l7-summary [maxColumns]="4"
                        [minColumns]="2">
                <l7-summary-item [title]="'Alter Nominalzins' | translate"
                                 [content]="((this.presenter.data | async).calculation.yearly_interest | number) + '%'"></l7-summary-item>
                <l7-summary-item [title]="'Neuer Nominalzins' | translate"
                                 [content]="((this.presenter.data | async).changedCalculation?.yearlyInterest | number) + '%'"></l7-summary-item>
                <l7-summary-item [title]="'Neue mtl. Rate' | translate"
                                 [content]="(this.presenter.data | async).changedCalculation?.instalment | currency"></l7-summary-item>
            </l7-summary>
        </mat-card-content>
    </mat-card>
</div>
