<l7-page>
  <l7-page-header [title]="'Identifizierung nach dem Geldwäschegesetz*'"
                  [subTitle]="'Daten des Unterzeichners*'"
                  [supportiveTitle]="'Bitte füllen Sie die Eingabefelder der Reihe nach aus.*'"></l7-page-header>
  <l7-page-content>
    <l7-page-section fxLayout="column">
      <div style="margin-bottom: 30px;">
        <gwg-signer-form [disabled]="false"
                         [(signer)]="signer"
                         (valid)="disabled = !$event">
          <div fxFlex
               fxLayoutAlign="end end">
            <button *ngIf="(isIdentificationRequired | async); else noIdentificationRequired"
                    mat-flat-button
                    id="button-record-identification"
                    color="accent"
                    type="submit"
                    [disabled]="disabled"
                    (click)="submitSigner()">
                {{ 'Personalausweis aufnehmen' | translate }}
            </button>
            <ng-template #noIdentificationRequired>
                <button mat-flat-button
                        test-id="goToContractConclusion"
                        color="accent"
                        type="submit"
                        [disabled]="disabled"
                        (click)="submitSigner()">
                    {{ 'global.contractConclusion' | translate }}
                </button>
            </ng-template>
          </div>
        </gwg-signer-form>
      </div>
      <p>
        Ihr Vertrauen ist uns wichtig. Deshalb respektieren wir den Datenschutz und informieren Sie per Mail über die
        erhobenen und gespeicherten Daten und Ihre Rechte auf Auskunft, Berichtigung, Sperrung und Löschung. Sie können die
        Datenschutzbestimmungen <a target="_blank"
           rel="noopener noreferrer"
           routerLinkActive="router-link-active"
           [routerLink]="'../pdf-view/' + file">hier</a> einsehen.
      </p>
    </l7-page-section>

  </l7-page-content>
</l7-page>
