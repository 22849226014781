<main>
  <l7-headline *ngIf="lessees_present"
               [headline]="headline"
               [subheadline]="subheadline"></l7-headline>
  <l7-lessee-list [lessees]="lessees"
                  (nextScreen)="onCalculate($event)"></l7-lessee-list>
  <div class="button-row">
    <button id="button_back-to-input"
            mat-button
            [disabled]="disableButtons"
            [color]="'accent'"
            [routerLink]="['../cam-search-input']">Zurück zur Suche</button>
  </div>
</main>