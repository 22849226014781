<mat-card appearance="outlined" class="mat-card-outlined">
    <mat-card-header>
        <mat-card-title>{{ 'Daten des Unterzeichners' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutGap="16px" [formGroup]="signerForm">
            <mat-form-field fxFlex
                            appearance="outline">
                <mat-label>{{ 'Vorname' | translate }}</mat-label>
                <input matInput
                       l7MarkAsTouched
                       type="text"
                       [attr.test-id]="'firstName'"
                       [formControlName]="'firstName'">
                <mat-error *ngIf="signerForm.controls.firstName.hasError('required')">
                    <p>{{ 'administration.retailers.create.input.givenName.error.required' | translate }}</p>
                </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex
                            appearance="outline">
                <mat-label>{{ 'Nachname' | translate }}</mat-label>
                <input matInput
                       l7MarkAsTouched
                       type="text"
                       [attr.test-id]="'lastName'"
                       [formControlName]="'lastName'">
                <mat-error *ngIf="signerForm.controls.firstName.hasError('required')">
                    <p>{{ 'administration.retailers.create.input.familyName.error.required' | translate }}</p>
                </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex
                            appearance="outline"
                            [ngClass]="{'warning': signerForm.controls.emailAddress.hasError('emailInvaid') }">
                <mat-label>{{ 'E-Mail Adresse' | translate }}</mat-label>
                <input matInput
                       l7MarkAsTouched
                       type="text"
                       [attr.test-id]="'emailAddress'"
                       [formControlName]="'emailAddress'">
                <div *ngIf="(signerForm.controls.emailAddress.statusChanges | async) === 'PENDING'"
                     matSuffix>
                    <mat-progress-spinner [diameter]="16"
                                          [strokeWidth]="2"
                                          [color]="'primary'"
                                          [mode]="'indeterminate'"></mat-progress-spinner>
                </div>
                <mat-error *ngIf="signerForm.controls.emailAddress.hasError('required')">
                    <p>{{ 'administration.retailers.create.input.email.error.required' | translate }}</p>
                </mat-error>
                <mat-error *ngIf="signerForm.controls.emailAddress.hasError('email')">
                    <p>{{ 'administration.retailers.create.input.email.error.email' | translate }}</p>
                </mat-error>
                <mat-error *ngIf="signerForm.controls.emailAddress.hasError('emailInvaid')">
                    <p>{{ 'administration.retailers.create.input.email.error.emailInvaid' | translate }}</p>
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutAlign="space-between"
             fxLayoutGap="16px">
            <mat-checkbox #checkBankAccount
                          [attr.test-id]="'checkBoxBankAccount'"
                          (change)="setBankAccountFields($event)">
                {{'quote.retailers.quoteDetails.tasks.identification.inputs.checkBankAccount.label' | translate}}
            </mat-checkbox>
        </div>
        <l7-spacer [thickness]="'bottom'"
                   [multiplier]="12">
        </l7-spacer>
        <div *ngIf="showBankAccountFields | async"
             fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutGap="16px"
             [formGroup]="bankAccountForm">
            <mat-form-field appearance="outline"
                            [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                            [fxFlex.lt-sm]="'0 1 100%'">
                <mat-label>{{ 'Kontoinhaber' | translate }}</mat-label>
                <input matInput
                       l7MarkAsTouched
                       type="text"
                       [attr.test-id]="'bankAccountName'"
                       [formControlName]="'bankAccountName'">
                <mat-error *ngIf="bankAccountForm.controls.bankAccountName.hasError('required')">
                    <p>{{ 'error.field_required' | translate }}</p>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline"
                            [fxFlex]="'0 1 calc(' + 100 / 3 + '%' + ' - 16px)'"
                            [fxFlex.lt-sm]="'0 1 100%'">
                <mat-label>{{ 'IBAN' | translate }}</mat-label>
                <input matInput
                       l7MarkAsTouched
                       mask="AAAA AAAA AAAA AAAA AAAA AA"
                       l7IbanValidator
                       l7ValidateBankAccount
                       type="text"
                       [attr.test-id]="'iban'"
                       [validation]="false"
                       [formControlName]="'iban'"
                       (bankAccount)="handleBankAccountInfo($event)"
                       (keyup)="transformToUpperCase($event)">
                <div *ngIf="(bankAccountForm.controls.iban.statusChanges | async) === 'PENDING'"
                     matSuffix>
                    <mat-progress-spinner [diameter]="16"
                                          [strokeWidth]="2"
                                          [color]="'primary'"
                                          [mode]="'indeterminate'"></mat-progress-spinner>
                </div>
                <mat-error *ngIf="bankAccountForm.controls.iban.getError('iban')">
                    <p>{{ 'iban.errors.invalid_iban_checksum' | translate }}</p>
                </mat-error>
                <mat-error *ngIf="bankAccountForm.controls.iban.getError('required')">
                    <p>{{ 'iban.errors.required' | translate }}</p>
                </mat-error>

                <mat-icon *ngIf="bankAccountForm.controls.iban.valid && (bankAccountForm.controls.iban.statusChanges | async) !== 'PENDING'"
                          matSuffix
                          [color]="'success'"
                          [fontSet]="'l7'"
                          [fontIcon]="'Check'">
                </mat-icon>
            </mat-form-field>
        </div>
        <div *ngIf="bankAccount | async"
             fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutGap="16px">
            <l7-text-block [color]="'success'"
                           [attr.test-id]="'bicAndBankName'"
                           [text]="'BIC: ' + (bankAccount | async).bic + ' / ' + (bankAccount | async).bank_name"
                           [wrap]="true"></l7-text-block>
        </div>
    </mat-card-content>
</mat-card>
