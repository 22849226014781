// #region Imports

import { IRetailerUserResponseDto } from '@abcfinlab/api/retailer';
import { ObjectgroupControllerService, ObjectGroupDto } from '@abcfinlab/api-global';
import {
    Client,
    FinanceAdminControllerService,
    UpdateRetailerRequest,
    ClientControllerService, RetailerUserResponse, RetailerConfigRequest, HandlingFeeOption,
} from '@abcfinlab/api-retailer';
import {
    ControlsOf,
    Validators as CoreValidators,
    CountryCode,
    DeepRequired,
    FormValidator,
    Globals,
    once,
    TranslationFacade,
} from '@abcfinlab/core';
import { MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { computed, effect, inject, Injectable, signal, untracked } from '@angular/core';
import { rxResource, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { IRetailersSearchCondition } from '../Models/Interfaces/IRetailersSearchCondition';
import { IRetailerUsersSearchCondition } from '../Models/Interfaces/IRetailerUsersSearchCondition';
import { RETAILER_OVERVIEW_ROUTE_PATH, RETAILER_USER_EDIT_ROUTE_PATH } from '../Routing/RoutePaths';
import { FinanceAdminValidators } from '../Validators/Validators';
import { CreateRetailerUserView } from './Dialogs/CreateRetailerUserView';
// #endregion

type EditRetailerFormInterface = ControlsOf<DeepRequired<Omit<UpdateRetailerRequest, 'retailerConfig'> & { retailerConfig: Omit<RetailerConfigRequest, 'availableContractTypes'> }>>;

/**
 * @private
 */
const EMPTY_SEARCH_CONDITION: IRetailerUsersSearchCondition = {
    term: '',
};

/**
 * @private
 */
const isEmptySearchCondition = (other: IRetailerUsersSearchCondition): boolean => EMPTY_SEARCH_CONDITION.term.toLowerCase() === other.term.toLowerCase();

/**
 * The presenter of the {@link EditRetailerView} view.
 *
 * @internal
 */
@Injectable()
export class EditRetailerViewPresenter {

    // #region Fields
    private readonly _financeAdminService = inject(FinanceAdminControllerService);
    private readonly _financeAdminValidatorService = inject(FinanceAdminValidators);
    private readonly _route = inject(ActivatedRoute);
    private readonly _translationFacade = inject(TranslationFacade);
    private readonly _formValidator = inject(FormValidator);
    private readonly _toastService = inject(ToastService);
    private readonly _clientService = inject(ClientControllerService);
    private readonly _messageBoxService = inject(MessageBoxService);
    private readonly _objectgroupService = inject(ObjectgroupControllerService);
    private readonly _router = inject(Router);
    private readonly _dialog = inject(MatDialog);
    paramsSignal = toSignal<Partial<Record<'id' | 'tabIndex', string>>>(this._route.queryParams, { initialValue: undefined });
    private readonly _retailerId = computed<string>(() => this.paramsSignal()?.id ?? '');
    private readonly _factorsSubject = new BehaviorSubject<Array<string>>([]);
    private readonly _clientsSubject = new BehaviorSubject<Array<Client>>([Client.Pag, Client.Fhl]);
    private readonly _clientHandlingFeeSubject = new BehaviorSubject<number | undefined>(undefined);
    private readonly _form: FormGroup<EditRetailerFormInterface>;
    readonly condition = signal<IRetailersSearchCondition>(EMPTY_SEARCH_CONDITION);
    private readonly _isDefaultSearchConditionSubject = new BehaviorSubject(true);
    private readonly _pageSizeSignal = signal<number>(Globals.Page.DEFAULT_PAGE_SIZE);
    private readonly _currentPageSignal = signal<number>(0);
    readonly pageSize = this._pageSizeSignal.asReadonly();
    private readonly _pageSizesSubject = new BehaviorSubject<Array<number>>(Globals.Page.DEFAULT_PAGE_SIZES);
    readonly selectedTabIndex = computed(() => this.paramsSignal()?.tabIndex ?? 0);
    private readonly _objectGroupsTerm = signal<string>('');
    private readonly _isNavigating: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _columns: Array<string> = [
        'givenName',
        'familyName',
        'email',
        'phoneNumber',
        'groups',
        'active',
    ];

    private readonly _financeAdminOpenAPIService = inject(FinanceAdminControllerService);
    private readonly _retailerAdminConfigResource = rxResource({
        request: () => ({ id: this._retailerId() }),
        loader: ({ request }) => {
            const { id } = request;
            return id ? this._financeAdminOpenAPIService.getRetailerById({ id }) : EMPTY;
        },
    });

    private readonly _objectGroupsResource = rxResource({ loader: () => this._objectgroupService.getObjectgroups() });
    objectGroups = this._objectGroupsResource.value;
    readonly filteredObjectGroups = computed(() => this._objectGroupsResource.value()?.filter((objectGroup) => {
        const { code, name } = objectGroup;
        let hasResult;
        // filter out the selected ones...
        hasResult = !this._form.controls.retailerConfig.controls.objectGroupCodes.value.includes(code.toString());

        if (hasResult && this._objectGroupsTerm()) {
            // filter by term in input
            const key = `${code}: ${name}`;
            hasResult = key.toLowerCase().includes(this._objectGroupsTerm().toLowerCase());
        }

        return hasResult;
    }));

    readonly retailer = computed(() => this._retailerAdminConfigResource.value());
    readonly availableContractTypes = computed(() => {
        const retailerConfig = this._retailerAdminConfigResource.value()?.retailerConfig;
        return retailerConfig?.availableContractTypes ?? {};
    });

    private readonly _allowedTopLevelDomainsResource = rxResource({ loader: () => this._financeAdminOpenAPIService.getAllowedDomains() });
    readonly allowedTopLevelDomains = computed(() => this._allowedTopLevelDomainsResource.value()?.join(', '));

    private readonly _availableUsersResource = rxResource({ request: () => ({
        retailerId: this._retailerId(),
        page: this._currentPageSignal(),
        pageSize: this._pageSizeSignal(),
        searchString: this.condition()?.term,
    }), loader: ({ request }) => this._financeAdminService.searchUser(request) });

    readonly availableUsers = this._availableUsersResource.value;
    dataSourceTotal = computed(() => {
        return this.availableUsers()?.totalElements;
    });

    // ToDo: do not create new instance on every http request
    dataSource = computed(() => {
        return new MatTableDataSource<RetailerUserResponse>(this.availableUsers()?.content ?? []);
    });

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `EditRetailerViewPresenter` class.
     *
     * @public
     */
    public constructor() {
        this._form = new FormGroup<EditRetailerFormInterface>({
            retailer: new FormGroup({
                contactId: new FormControl(''),
                id: new FormControl('', Validators.required),
                city: new FormControl('', Validators.required),
                client: new FormControl<Client | undefined>({ value: '' as Client, disabled: true }, Validators.required),
                contactNumber: new FormControl('', Validators.required),
                factor: new FormControl<string>({ value: '', disabled: false }, Validators.required),
                name: new FormControl('', Validators.required),
                partnerNumber: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]),
                postalCode: new FormControl('', [Validators.required, CoreValidators.postalCode(CountryCode.DE)]),
                streetAndHousenumber: new FormControl('', Validators.required),
            }),
            retailerConfig: new FormGroup({
                active: new FormControl<boolean>(true),
                adjustableProvision: new FormControl<boolean>(false),
                contractTypes: new FormControl<any>([''], Validators.required),
                handlingFeeOption: new FormControl<HandlingFeeOption>(HandlingFeeOption.AlwaysCharge, Validators.required),
                objectGroupCodes: new FormControl([], Validators.required),
                preRent: new FormControl<boolean>(true),
                provision: new FormControl<number>(null, [Validators.required, Validators.min(0), Validators.max(5)]),
            }),
            contact: new FormGroup({
                officeService: new FormGroup({
                    familyName: new FormControl('', Validators.required),
                    givenName: new FormControl('', Validators.required),
                    email: new FormControl('', [Validators.required, CoreValidators.email()], CoreValidators.emailEndsWithDomainAsync(this._financeAdminService.getAllowedDomains())),
                    phoneNumber: new FormControl('', [Validators.required, CoreValidators.phoneNumber()]),
                }),
                fieldService: new FormGroup({
                    familyName: new FormControl('', Validators.required),
                    givenName: new FormControl('', Validators.required),
                    email: new FormControl('', [Validators.required, CoreValidators.email()], CoreValidators.emailEndsWithDomainAsync(this._financeAdminService.getAllowedDomains())),
                    phoneNumber: new FormControl('', [Validators.required, CoreValidators.phoneNumber()]),
                }),
            }),
        });

        effect(() => {
            const retailerResponse = this.retailer();
            const retailerId = this._retailerId();
            // const users = this.availableUsers();

            untracked(() => {
                if (!retailerId) {
                    this._messageBoxService.show('Nicht gefunden', 'Retailer ID nicht gefuncen', MessageBoxButton.OK, {
                        labels: {
                            ok: this._translationFacade.translate('global.ok'),
                        },
                    });
                }
                // if (users?.content) {
                //     this.dataSource.data = users?.content;
                // }

                if (retailerResponse && this._form) {
                    const { retailer, retailerConfig, contact } = retailerResponse;
                    this._form.patchValue({ contact, retailer, retailerConfig });
                    const contactNumber = this._form.controls.retailer.get('contactNumber');
                    const partnerNumber = this._form.controls.retailer.get('partnerNumber');
                    if (contactNumber) {
                        contactNumber?.setAsyncValidators(this._financeAdminValidatorService.contactNumberExistsAsync(() => [retailer?.contactNumber]).bind(this._financeAdminValidatorService));
                        contactNumber?.updateValueAndValidity();
                    }
                    if (partnerNumber) {
                        partnerNumber?.setAsyncValidators(this._financeAdminValidatorService.partnerNumberExistsAsync(() => [retailer?.partnerNumber]).bind(this._financeAdminValidatorService));
                        partnerNumber?.updateValueAndValidity();
                    }

                    if (retailer?.factor) {
                        this.onClientChanged(retailer?.factor);
                    }
                    this._router.events.subscribe((evt) => {
                        if (evt instanceof NavigationStart) {
                            this._isNavigating.next(true);
                        } else if (evt instanceof NavigationEnd) {
                            this._isNavigating.next(false);
                        }
                    });
                    this._formValidator.validate(this._form);
                }
            });
        });
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): FormGroup<EditRetailerFormInterface> {
        return this._form;
    }

    /**
     * Returns an array of error fields on the input form
     * @public
     */
    public getInputErrors(path: string): Array<string> {
        const errors = this._form?.get(path)?.errors ?? {};
        return Object.keys(errors);
    }

    /**
     * Returns the translation for a given input field error based on its context
     * @public
     */
    public getErrorMessage(context: string, error: string): string {
        return this._translationFacade.instant(`input.${context}.error.${error}`);
    }

    /**
     * Returns the `clients` property.
     *
     * @public
     * @readonly
     */
    public get clients(): Observable<Array<Client>> {
        return this._clientsSubject.asObservable();
    }

    /**
     * Returns the `factors` property.
     *
     * @public
     * @readonly
     */
    public get factors(): Observable<Array<string>> {
        return this._factorsSubject.asObservable();
    }

    /**
     * Returns the `clientHandlingFee` property.
     *
     * @public
     * @readonly
     */
    public get clientHandlingFee(): Observable<number | undefined> {
        return this._clientHandlingFeeSubject.asObservable();
    }

    /**
     * Returns the `columns` property.
     *
     * @public
     * @readonly
     */
    public get columns(): Array<string> {
        return this._columns;
    }

    /**
     * Returns the `pageSizes` property.
     *
     * @public
     * @readonly
     */
    public get pageSizes(): Observable<Array<number>> {
        return this._pageSizesSubject.asObservable();
    }

    /**
     * Returns the `isDefaultSearchCondition` property.
     *
     * @public
     * @readonly
     */
    public get isDefaultSearchCondition(): Observable<boolean> {
        return this._isDefaultSearchConditionSubject.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {}

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        // nothing
    }

    /**
     * @internal
     */
    public onFilterObjectGroups(value: string): void {
        this._objectGroupsTerm.set(value);
    }

    /**
     * @internal
     */
    public getObjectGroupFromCode(code: string): ObjectGroupDto | undefined {
        return this.objectGroups()?.find(y => y.code === parseInt(code)) ?? undefined;
    }

    /**
     * @internal
     */
    public translateRoles(roles: Array<string>): Array<string> {
        return roles.map(x => this._translationFacade.instant(`roles.${x}`));
    }

    /**
     * @internal
     */
    public onObjectGroupSelected(value: ObjectGroupDto, autocompleteTrigger: MatAutocompleteTrigger, input: HTMLInputElement): void {
        this._form.controls.retailerConfig.controls.objectGroupCodes.value.push(value.code?.toString());
        this._form.controls.retailerConfig.controls.objectGroupCodes.updateValueAndValidity();

        // hack to reopen the auto complete popup
        setTimeout(() => {
            autocompleteTrigger.openPanel();
        }, 100);

        // reset the input element
        input.value = '';
        this.onFilterObjectGroups('');
    }

    /**
     * @internal
     */
    public onObjectGroupRemove(value: ObjectGroupDto | undefined): void {
        if (value) {
            const index = this._form.controls.retailerConfig.controls.objectGroupCodes.value.indexOf(value.code?.toString());
            this._form.controls.retailerConfig.controls.objectGroupCodes.value.splice(index, 1);
            this._form.controls.retailerConfig.controls.objectGroupCodes.updateValueAndValidity();
        }
    }

    /**
     * @internal
     */
    public onTermChanged(value: string): void {
        const condition = {
            ...this.condition(),
            term: value.trim().toLowerCase(),
        };

        this.condition.set(condition);
        this._currentPageSignal.set(0);
        this._isDefaultSearchConditionSubject.next(isEmptySearchCondition(this.condition()));
    }

    /**
     * @internal
     */
    public onResetConditions(): void {
        this.condition.set(EMPTY_SEARCH_CONDITION);
        this._currentPageSignal.set(0);
        this._isDefaultSearchConditionSubject.next(true);
    }

    /**
     * @internal
     */
    public onPageChanged(page: PageEvent): void {
        this._pageSizeSignal.set(page.pageSize);
        this._currentPageSignal.set(page.pageIndex);
    }

    /**
     * @internal
     */
    public onRowSelected(row: IRetailerUserResponseDto): void {
        void this._router.navigate([`../../${RETAILER_USER_EDIT_ROUTE_PATH}`], {
            relativeTo: this._route,
            queryParams: {
                retailerId: this._retailerId(),
                retailerUserId: row.id,
            },
        });
    }

    /**
     * @internal
     */
    public onCreateRetailerUser(): void {
        once(this._dialog.open(CreateRetailerUserView, {
            closeOnNavigation: true,
            disableClose: true,
            autoFocus: true,
            maxWidth: '100vw',
            maxHeight: '100vh',
            data: { retailerId: this._retailerId() },
        }).afterClosed(), (result) => {
            if (result) {
                this.initialize();
            }
        });
    }

    /**
     * @internal
     */
    public onClientChanged(newValue?: string): void {
        const client = this._form.controls.retailer.controls.client.value;

        once(this._clientService.getClientInformation({ clientId: client }), (x) => {
            if (x) {
                this._clientHandlingFeeSubject.next(x.handlingFee);
                this._factorsSubject.next(x.factors ?? []);
            }

            if (newValue) {
                this._form.controls.retailer.controls.factor.setValue(newValue);
            } else {
                this._form.controls.retailer.controls.factor.reset();
            }
            if (!x.factors) {
                this._form.controls.retailer.controls.factor.disable();
                this._form.updateValueAndValidity();
            }
        });
    }

    /**
     * @internal
     */
    public onSubmit(cancel: boolean = false): void {
        if (cancel) {
            this._messageBoxService.show('Vorgang abbrechen', 'Sind Sie sicher, dass Sie den Vorgang abbrechen wollen? Ihre Änderungen werden nicht gespeichert.', MessageBoxButton.YesNo, {
                labels: {
                    yes: this._translationFacade.translate('global.yes'),
                    no: this._translationFacade.translate('global.no'),
                },
            }).subscribe((result) => {
                if (result === MessageBoxResult.Yes) {
                    void this._router.navigate([`../../${RETAILER_OVERVIEW_ROUTE_PATH}`], {
                        relativeTo: this._route,
                    });
                }
            });

            return;
        }

        this._formValidator.validate(this._form);

        if (this._formValidator.errors(this._form).length > 0) {
            this._toastService.show(this._translationFacade.instant('administration.retailers.edit.toast.form.error'), 'danger');
        } else {
            once(this._financeAdminService.updateRetailer({
                updateRetailerRequest: this._form.getRawValue(),
            })
            , () => {
                this._toastService.show(this._translationFacade.instant('administration.retailers.edit.toast.success'), 'success');
                void this._router.navigate([`../../${RETAILER_OVERVIEW_ROUTE_PATH}`], {
                    relativeTo: this._route,
                });
            }, (error) => {
                this._toastService.show(this._translationFacade.instant('administration.retailers.edit.toast.error'), 'danger');
                throwError(error);
            });
        }
    }

    /**
     * @internal
     */
    public onTabIndexChanged(index: number): void {
        if (this._isNavigating.getValue()) {
            return;
        }
        void this._router.navigate([], {
            relativeTo: this._route,
            queryParamsHandling: 'merge',
            replaceUrl: true,
            queryParams: {
                id: this._retailerId(),
                tabIndex: index,
            },
        });
    }

    // #endregion

}
