<div fxLayout="column"
     fxLayoutGap="24px">
    <mat-card *ngIf="(this.presenter.quoteDetails | async)"
              class="small-card more-padding">
        <mat-card-content class="more-padding"
                          fxLayout="column">
            <div fxFlex
                 fxLayout="row wrap"
                 fxLayoutAlign="space-between center"
                 fxLayoutGap="16px">
                <fieldset fxFlex="0 0 calc(20% - 16px)"
                          fxFlex.lt-md="0 1 calc(50% - 16px)"
                          fxFlex.lt-sm="0 1 100%"
                          fxLayout="column">
                    <legend>{{ 'quote.retailers.calculation.input.objectName.label' | translate }}</legend>
                    <l7-text-block class="small-text"
                                   [truncate]="true"
                                   [text]="(this.presenter.quoteDetails | async).objectDescription"></l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(20% - 16px)"
                          fxFlex.lt-md="0 1 calc(50% - 16px)"
                          fxFlex.lt-sm="0 1 100%"
                          fxLayout="column">
                    <legend>{{ 'quote.retailers.calculation.input.contract_type.label' | translate }}</legend>
                    <l7-text-block class="small-text"
                                   [truncate]="true"
                                   [text]="'contractType.' + (this.presenter.quoteDetails | async).contractType | translate"></l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(20% - 16px)"
                          fxFlex.lt-md="0 1 calc(50% - 16px)"
                          fxFlex.lt-sm="0 1 100%"
                          fxLayout="column">
                    <legend>{{ 'quote.retailers.quoteDetails.labels.objectValue' | translate }}</legend>
                    <l7-text-block class="small-text"
                                   [truncate]="true"
                                   [text]="(this.presenter.quoteDetails | async).objectValue | currency"></l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(20% - 16px)"
                          fxFlex.lt-md="0 1 calc(50% - 16px)"
                          fxFlex.lt-sm="0 1 100%"
                          fxLayout="column">
                    <legend>{{ 'quote.retailers.quoteDetails.labels.duration' | translate }}</legend>
                    <l7-text-block class="small-text"
                                   [truncate]="true"
                                   [text]="(this.presenter.quoteDetails | async).term + ' Monate'"></l7-text-block>
                </fieldset>
                <fieldset fxFlex="0 1 calc(20% - 16px)"
                          fxFlex.lt-md="0 1 calc(50% - 16px)"
                          fxFlex.lt-sm="0 1 100%"
                          fxLayout="column">
                    <legend>{{ 'Rate' | translate }}</legend>
                    <l7-text-block class="small-text"
                                   [truncate]="true"
                                   [text]="(this.presenter.quoteDetails | async).instalment | currency"></l7-text-block>
                </fieldset>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card *ngIf="(presenter.quoteTasks | async) && (presenter.isDraftLessee | async)"
              class="small-card">
        <mat-card-content fxLayout="column">
            <l7-banner [appearance]="'outlined'"
                       [color]="'info'"
                       [icon]="'Alert_Triangle'"
                       [iconFontSet]="'l7'"
                       [content]="'quote.draftBanner' | translate"></l7-banner>
            <mat-nav-list fxFlex
                          fxFill>
                <mat-list-item
                               class="no-padding"
                               [attr.test-id]="'step_search_lessee'"
                               [style]="'margin-bottom: 16px; height: auto;'"
                               [disableRipple]="true"
                               (click)="presenter.onSearchLesseeTaskSelected()">
                    <l7-tile fxFlex
                             fxLayout="column">
                        <div fxFlex="100"
                             fxLayout="row"
                             fxLayoutAlign="space-between center">
                            <div fxFlex="90"
                                 fxFlex.lt-sm="100"
                                 fxLayout="row"
                                 fxLayoutAlign="flex-start center">
                                <mat-icon [fontSet]="'l7'"
                                          [color]="'primary'"
                                          [fontIcon]="'Search'"></mat-icon>
                                <l7-text-block mat-line
                                               class="block-with-margin"
                                               [text]="'quote.retailers.quoteDetails.tasks.labels.CREFO_CONFIRMATION' | translate">
                                </l7-text-block>
                            </div>
                        </div>
                        <mat-icon [fontSet]="'l7'"
                                  [color]="'primary'"
                                  [fontIcon]="'Chevron_Right'">
                        </mat-icon>
                    </l7-tile>
                </mat-list-item>
            </mat-nav-list>
        </mat-card-content>
    </mat-card>

    <mat-card *ngIf="(presenter.quoteTasks | async) && (presenter.isDraftLessee | async)=== false"
              class="small-card">
        <mat-card-header>
            <mat-card-title fxFlex
                            fxLayout="row"
                            fxLayoutAlign="space-between center">
                <div>
                    {{ 'quote.retailers.quoteDetails.openTasksTitle' | translate }} ({{presenter.openTasks | async}})
                </div>
                <l7-retailer-quote-step-chip [label]="(presenter.stepchipLabel$ | async)"
                                             [tooltip]="(presenter.workflowStepTooltip | async)"
                                             [icon]="(presenter.retailerQuoteStepChipConfig$ | async)?.icon"
                                             [variant]="(presenter.retailerQuoteStepChipConfig$ | async)?.variant">
                </l7-retailer-quote-step-chip>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content fxLayout="column">

            <!-- seperated boni check list item -->
            <ng-container *ngFor="let task of (presenter.migratedTasks | async)">
                <ng-container *ngIf="task.stepType === 'CREDIT_CHECK'">
                    <l7-boni-check-list-item style="margin-bottom: 8px;"
                                             [pending]="presenter.creditCheckIsPending | async"
                                             [quoteTask]="task"
                                             [menuItems]="task.actions"
                                             (openModal)="presenter.openModal($event)"
                                             (menuItemClicked)="presenter.triggerCreditCheckAction($event)"
                                             (clicked)="presenter.onTaskSelected(task)">
                    </l7-boni-check-list-item>
                </ng-container>
            </ng-container>
            <!-- /seperated boni check list item -->
            <mat-nav-list fxFlex
                          fxFill>
                <mat-list-item *ngFor="let task of (presenter.quoteTasks | async);"
                               class="no-padding"
                               [attr.test-id]="task.stepType"
                               [ngClass]="{'item-disabled': task.startable===false && task.status !== 'COMPLETED' && task.status !== 'PENDING' && task.status !== 'IN_PROGRESS'}"
                               [style]="'margin-bottom: 16px; height: auto;'"
                               [disableRipple]="true"
                               (click)="presenter.onTaskSelected(task)">
                    <l7-tile fxFlex
                             fxLayout="column"
                             [matTooltip]="task.stepType === 'CREATE_CONTRACT' && task.status === 'COMPLETED' && (presenter.identificationTaskStatus | async) !== 'COMPLETED' ? ('quote.retailers.quoteDetails.tasks.createContract.tooltip' | translate) : ''"
                             [disabled]="task.startable===false && task.status !== 'COMPLETED' && task.status !== 'PENDING' && task.status !== 'IN_PROGRESS'"
                             [readonly]="task.status !== 'OPEN' && task.status !== 'IN_PROGRESS'">
                        <div fxFlex="100"
                             fxLayout="row"
                             fxLayoutAlign="space-between center">
                            <div fxFlex="90"
                                 fxFlex.lt-sm="100"
                                 fxLayout="row"
                                 fxLayoutAlign="flex-start center">
                                <ng-container [ngSwitch]="task.stepType">
                                    <ng-container *ngSwitchCase="'IDENTIFICATION'">
                                        <mat-icon *ngIf="task.status === 'OPEN'"
                                                  [fontSet]="'l7'"
                                                  [color]="'primary'"
                                                  [fontIcon]="'User'"></mat-icon>
                                        <mat-icon *ngIf="task.status === 'IN_PROGRESS'"
                                                  [fontSet]="'l7'"
                                                  [color]="'primary'"
                                                  [fontIcon]="'User'"></mat-icon>
                                        <mat-icon *ngIf="task.status === 'COMPLETED'"
                                                  [fontSet]="'l7'"
                                                  [color]="'success'"
                                                  [fontIcon]="'User_Check_Filled'"></mat-icon>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'CREATE_CONTRACT'">
                                        <mat-icon *ngIf="!task.startable && task.status === 'OPEN'"
                                                  [fontSet]="'l7'"
                                                  [color]="'primary'"
                                                  [fontIcon]="'Pen_Tool_2_Filled'"></mat-icon>
                                        <mat-icon *ngIf="!task.startable && task.status === 'PENDING'"
                                                  [fontSet]="'l7'"
                                                  [color]="'highlight'"
                                                  [fontIcon]="'Time'"></mat-icon>
                                        <mat-icon *ngIf="!task.startable && task.status === 'ERROR'"
                                                  [fontSet]="'l7'"
                                                  [color]="'danger'"
                                                  [fontIcon]="'Time'"></mat-icon>
                                        <mat-icon *ngIf="task.startable"
                                                  [fontSet]="'l7'"
                                                  [color]="'primary'"
                                                  [fontIcon]="'Edit_2'"></mat-icon>
                                        @if (!task.startable && task.status === 'COMPLETED' && (presenter.identificationTaskStatus | async) === 'COMPLETED') {
                                            <mat-icon [fontSet]="'l7'"
                                                      [color]="'success'"
                                                      [fontIcon]="'Check_Circle_Filled'"></mat-icon>
                                        }
                                        @if (!task.startable && task.status === 'COMPLETED' && (presenter.identificationTaskStatus | async) !== 'COMPLETED') {
                                            <mat-icon [fontSet]="'l7'"
                                                      [color]="'success'"
                                                      [fontIcon]="'Alert_Circle_Filled'"></mat-icon>
                                        }

                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <l7-text-block mat-line
                                                       class="block-with-margin"
                                                       [color]="'danger'"
                                                       [text]="('STEP TYPE NOT IMPLEMENTED ' | translate) + task.stepType"></l7-text-block>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="task.status === 'OPEN'; else textTaskNotOpen">
                                    <l7-text-block *ngIf="task.stepType !== 'CREDIT_CHECK'"
                                                   mat-line
                                                   class="block-with-margin"
                                                   [text]="'quote.retailers.quoteDetails.tasks.labels.' + task.stepType | translate">
                                    </l7-text-block>
                                </ng-container>
                                <ng-template #textTaskNotOpen>
                                    <ng-container [ngSwitch]="task.stepType">
                                        <ng-container *ngSwitchCase="'IDENTIFICATION'">
                                            <l7-text-block *ngIf="task.status === 'COMPLETED'"
                                                           mat-line
                                                           class="block-with-margin"
                                                           [wrap]="true"
                                                           [text]="'quote.retailers.quoteDetails.tasks.identification.success' | translate">
                                            </l7-text-block>
                                            <l7-text-block *ngIf="task.status === 'IN_PROGRESS'"
                                                           mat-line
                                                           class="block-with-margin"
                                                           [wrap]="true"
                                                           [text]="'quote.retailers.quoteDetails.tasks.identification.inProgress' | translate">
                                            </l7-text-block>
                                            <l7-text-block *ngIf="task.status === 'ERROR'"
                                                           mat-line
                                                           class="block-with-margin"
                                                           [wrap]="true"
                                                           [maxLines]="2"
                                                           [text]="'quote.retailers.quoteDetails.tasks.identification.error' | translate">
                                            </l7-text-block>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'CREATE_CONTRACT'">
                                            <l7-text-block *ngIf="task.status === 'PENDING'"
                                                           mat-line
                                                           class="block-with-margin"
                                                           [wrap]="true"
                                                           [maxLines]="3"
                                                           [color]="'bold'"
                                                           [text]="'quote.retailers.quoteDetails.tasks.createContract.pending' | translate: {email: (presenter.emailInRemoteLink | async)}"></l7-text-block>
                                            <l7-text-block *ngIf="task.status === 'ERROR'"
                                                           mat-line
                                                           class="block-with-margin"
                                                           [wrap]="true"
                                                           [maxLines]="2"
                                                           [color]="'bold'"
                                                           [text]="'quote.retailers.quoteDetails.tasks.createContract.error' | translate"></l7-text-block>
                                            <l7-text-block *ngIf="task.status === 'COMPLETED'"
                                                           mat-line
                                                           class="block-with-margin"
                                                           [wrap]="true"
                                                           [maxLines]="2"
                                                           [color]="'bold'"
                                                           [text]="'quote.retailers.quoteDetails.tasks.createContract.completed' | translate"></l7-text-block>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                            </div>
                            <mat-icon *ngIf="(task.status === 'OPEN' || (task.status === 'IN_PROGRESS' && task.stepType === 'IDENTIFICATION')) && task.startable"
                                      [fontSet]="'l7'"
                                      [color]="'primary'"
                                      [fontIcon]="'Chevron_Right'">
                            </mat-icon>
                            <mat-icon *ngIf="task.status === 'ERROR' && task.actions.length === 0"
                                      [fontSet]="'l7'"
                                      [color]="'warn'"
                                      [fontIcon]="'Alert_Triangle'">
                            </mat-icon>
                            @if ((task.status === 'COMPLETED' || task.status === 'PENDING') && task.actions.length > 0 && !task.actions.includes('ACTION_IDENTIFICATION_COPY_LINK')) {
                                <mat-icon [matMenuTriggerFor]="menu"
                                          [matMenuTriggerData]="{taskType: task.stepType, actions: task.actions, stepId: task.id, metaInformation: task.metaInformation}"
                                          [fontSet]="'l7'"
                                          [color]="'primary'"
                                          [fontIcon]="'More_Horiz_Filled'">
                                </mat-icon>
                            }

                            @if (task.actions.includes('ACTION_IDENTIFICATION_COPY_LINK')) {
                                <button mat-icon-button
                                        [cdkCopyToClipboard]="task.metaInformation?.createContractRemoteSigningLink"
                                        (cdkCopyToClipboardCopied)="presenter.onLinkCopy($event)">
                                    <mat-icon [fontSet]="'l7'" [fontIcon]="'Copy'"
                                              [matTooltip]="'quote.retailers.quoteDetails.tasks.actions.ACTION_IDENTIFICATION_COPY_LINK' | translate"
                                              [color]="'info'" >
                                    </mat-icon>
                                </button>
                            }

                            <mat-menu #menu="matMenu">
                                <ng-template let-taskType="taskType"
                                             let-actions="actions"
                                             let-stepId="stepId"
                                             let-metaInformation="metaInformation"
                                             matMenuContent>
                                    <ng-container [ngSwitch]="taskType">
                                        <ng-container *ngSwitchCase="'CREATE_CONTRACT'">
                                            <ng-container *ngFor="let action of actions">
                                                <ng-container [ngSwitch]="action">
                                                    <ng-container *ngSwitchCase="'ACTION_IDENTIFICATION_COPY_LINK'">
                                                        <button mat-menu-item
                                                                [cdkCopyToClipboard]="metaInformation?.createContractRemoteSigningLink"
                                                                (cdkCopyToClipboardCopied)="presenter.onLinkCopy($event)">
                                                            {{'quote.retailers.quoteDetails.tasks.actions.' + action | translate}}
                                                        </button>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        <button mat-menu-item
                                                                (click)="presenter.triggerAction(action, stepId)">
                                                            {{'quote.retailers.quoteDetails.tasks.actions.' + action | translate}}
                                                        </button>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                            </mat-menu>
                        </div>
                    </l7-tile>
                </mat-list-item>
            </mat-nav-list>
        </mat-card-content>
    </mat-card>
    <mat-card *ngIf="presenter.quoteDocuments | async"
              class="small-card">
        <mat-card-header>
            <mat-card-title fxFlex
                            fxLayout="row"
                            fxLayoutAlign="space-between center">
                <div>{{ 'Dokumente' | translate }}</div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="presenter.quoteDocuments | async"
                          fxLayout="column">
            <mat-table fxFill
                       matSort
                       [dataSource]="presenter.quoteDocuments | async"
                       [attr.test-id]="'QuoteDocumentsTable'">
                <ng-container *ngFor="let column of columns"
                              [matColumnDef]="column">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>
                        <span *ngIf="column !== 'actions'"
                              class="text-truncate">
                            {{ 'quote.retailers.quoteDetails.documents.' + column | translate }}
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row ; let i = index;">
                        <ng-container [ngSwitch]="column">
                            <ng-container *ngSwitchCase="'creation_date'">
                                <span class="text-truncate">{{ row[column] | date }}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'type'">
                                <mat-chip-listbox>
                                    <mat-chip class="text-truncate"
                                              [color]="'primary'">{{ 'contract_management.documents.' + row[column] | translate }}
                                    </mat-chip>
                                </mat-chip-listbox>
                            </ng-container>
                            <ng-container *ngSwitchCase="'actions'">
                                <div fxFlex
                                     fxLayout="row"
                                     fxLayoutAlign="end center" fxLayoutGap="8px">
                                    <mat-icon [fontSet]="'l7'"
                                              [color]="'primary'"
                                              [fontIcon]="'External_Link'" (click)="this.callAction(row, quoteDocumentTableAction.PREVIEW, $event)"></mat-icon>
                                    <mat-icon [fontSet]="'l7'"
                                              [color]="'primary'"
                                              [fontIcon]="'Printer'" (click)="this.callAction(row, quoteDocumentTableAction.PRINT, $event)"></mat-icon>
                                    <mat-icon *ngIf="row['type'] === 'QUOTE' && isDraftLessee"
                                              [fontSet]="'l7'"
                                              [color]="'primary'"
                                              [fontIcon]="'Send_2'" (click)="this.callAction(row, quoteDocumentTableAction.SEND, $event)"></mat-icon>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <span class="text-truncate">{{ row[column] }}</span>
                            </ng-container>
                        </ng-container>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns; let i = index"
                         [attr.test-id]="'OverviewDocumentsTableRow' + i">
                </mat-row>
            </mat-table>
        </mat-card-content>
    </mat-card>
    <div fxLayout="row"
         fxLayoutAlign="flex-end center"
         fxLayout.lt-sm="column"
         fxLayoutGap="16px">
        <button mat-stroked-button
                color="primary"
                (click)="navigateTo('QUOTES')">{{'quote.retailers.quoteDetails.buttons.quotesForLessee' | translate}}</button>
        <button mat-stroked-button
                color="primary"
                (click)="navigateTo('NEW_VERSION')">{{'quote.retailers.quoteDetails.buttons.newVersion' | translate}}</button>
    </div>
</div>
