<!--#region Templates -->

<ng-template #normalNavigationItemTemplate
             let-item
             let-sidenav>
    <mat-list-item #rla="routerLinkActive"
                   matRipple
                   class="sidenav-list-item"
                   [routerLink]="item.route"
                   [l7RouterLinkActive]="'active'"
                   [routerLinkActiveOptions]="{ matrixParams: 'subset', queryParams: 'ignored', paths: 'subset', fragment: 'ignored', relatedTo: item.relatedTo }"
                   [attr.test-id]="item.icon"
                   [matTooltipPosition]="'after'"
                   [matTooltip]="(presenter.navigationIsCompactMode | async) ? item.label : ''"
                   (mouseover)="hoverEffect($event)"
                   (mouseleave)="hoverEffect($event)"
                   (click)="presenter.onHandleNavigation(sidenav)">
        <div *ngIf="(presenter.navigationIsCompactMode | async) === false; else matIconCompact"
             fxLayout="row"
             fxLayoutAlign="start center"
             fxLayoutGap="16px"
             fxFlex
             matListItemTitle>
            <mat-icon matListItemIcon
                      [fontSet]="'l7'"
                      [fontIcon]="rla.isActive ? item.icon + '_Filled' : item.icon"></mat-icon>
            <p>{{ item.label }}</p>
            <span fxFlex></span>
            <span class="badge"
                  [matBadge]="item.badge?.text | of | async"
                  [matBadgeHidden]="!item.badge"
                  [matBadgeColor]="'warn'"></span>
        </div>
        <ng-template #matIconCompact>
            <mat-icon matListItemIcon
                      [fontSet]="'l7'"
                      [fontIcon]="rla.isActive ? item.icon + '_Filled' : item.icon"
                      [matBadge]="item.badge?.text | of | async"
                      [matBadgeSize]="'small'"
                      [matBadgeHidden]="!item.badge"
                      [matBadgeColor]="'warn'"></mat-icon>
        </ng-template>
    </mat-list-item>
</ng-template>

<ng-template #actionItemTemplate
             let-item>
    <!-- button template -->
    <ng-container *ngIf="item.kind === 'button'">
        <button mat-icon-button
                [matTooltipPosition]="'below'"
                [matTooltip]="item.label"
                [attr.order]="item.order"
                (click)="item.command.execute()">
            <mat-icon [fontSet]="'l7'"
                      [fontIcon]="item.icon"
                      [matBadge]="item.badge?.text | of | async"
                      [matBadgeSize]="'small'"
                      [matBadgeHidden]="!(item.badge?.text | of | async)"
                      [matBadgeColor]="'warn'"></mat-icon>
        </button>
    </ng-container>
    <!-- menu template -->
    <ng-container *ngIf="item.kind === 'menu'">
        <button mat-icon-button
                [matTooltipPosition]="'below'"
                [matTooltip]="item.label"
                [matMenuTriggerFor]="menu"
                [attr.test-id]="item.testId"
                [attr.order]="item.order">
            <mat-icon [fontSet]="'l7'"
                      [fontIcon]="item.icon"
                      [matBadge]="item.badge?.text | of | async"
                      [matBadgeSize]="'small'"
                      [matBadgeHidden]="!(item.badge?.text | of | async)"
                      [matBadgeColor]="'warn'"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <ng-container *ngFor="let group of item.groups | orderBy:'order':false; let last = last">
                <l7-menu-group [legend]="group.label">
                    <!-- list template -->
                    <div *ngIf="group.kind === 'list'"
                         fxFlex
                         fxLayout="column">
                        <ng-container *ngFor="let child of group.items">
                            <a *ngIf="!child.isHidden"
                               mat-menu-item
                               [attr.test-id]="child.testId"
                               (click)="child.command.execute()">
                                <div fxLayoutAlign="start center">
                                    <mat-icon *ngIf="child.icon"
                                              [fontIcon]="child.icon"
                                              [fontSet]="'l7'"></mat-icon>
                                    <span fxFlex>{{ child.label }}</span>
                                </div>
                            </a>
                        </ng-container>
                    </div>
                    <!-- persona template -->
                    <l7-persona-card *ngIf="group.kind === 'persona'"
                                     [name]="group.persona.name"
                                     [role]="group.persona.role"
                                     [email]="group.persona.email"
                                     [phone]="group.persona.phone"
                                     [color]="group.persona.priority === 'primary' ? 'primary' : 'accent'"></l7-persona-card>
                    <!-- <div *ngIf="group.kind === 'persona'"
                         fxFlex
                         fxLayout="column"
                         fxLayoutAlign="center"
                         mat-menu-item
                         style="height: initial;">
                        <div fxLayout="row"
                             fxLayoutGap="8px"
                             fxLayoutAlign="start center">
                            <l7-persona [color]="group.persona.priority === 'primary' ? 'primary' : 'accent'"
                                        [text]="group.persona.name"></l7-persona>
                            <div fxFlex
                                 fxLayout="column"
                                 fxLayoutAlign="center">
                                <l7-text-block *ngIf="group.persona?.name"
                                               [truncate]="true"
                                               [text]="group.persona.name"></l7-text-block>
                                <l7-text-block *ngIf="group.persona?.role"
                                               [truncate]="true"
                                               [text]="group.persona?.role"></l7-text-block>
                            </div>
                        </div>
                        <div *ngIf="group.persona?.email"
                             fxLayout="row"
                             fxLayoutGap="8px">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Email'"></mat-icon>
                            <l7-text-block [truncate]="true"
                                           [text]="group.persona.email"
                                           [readonly]="true"></l7-text-block>
                        </div>
                        <div *ngIf="group.persona?.phone"
                             fxLayout="row"
                             fxLayoutGap="8px">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Phone'"></mat-icon>
                            <l7-text-block [truncate]="true"
                                           [text]="group.persona?.phone"
                                           [readonly]="true"></l7-text-block>
                        </div>
                    </div> -->
                    <!-- tags template -->
                    <div *ngIf="group.kind === 'tags'"
                         fxFlex
                         fxLayout="row wrap"
                         fxLayoutGap="8px"
                         style="padding: 0 8px; margin: 4px;">
                        <ng-container *ngFor="let child of group.items">
                            <l7-tag [color]="'primary'"
                                    [text]="'roles.' + child.label | translate"></l7-tag>
                        </ng-container>
                    </div>
                    <!-- text template -->
                    <div *ngIf="group.kind === 'text'"
                         fxFlex
                         fxLayout="row wrap"
                         fxLayoutGap="8px"
                         style="padding: 0 8px; margin: 4px;">
                        <l7-text-block [text]="group.text"></l7-text-block>
                    </div>
                </l7-menu-group>
                <mat-divider *ngIf="group.divided && !last"></mat-divider>
            </ng-container>
        </mat-menu>
    </ng-container>
</ng-template>

<!-- #endregion -->

<!-- <ng-container *ngIf="this.presenter.hasSession | async"> -->
<mat-sidenav-container fxFlex
                       [autosize]="true">
    <mat-sidenav #sidenav
                 class="sidenav mat-elevation-z0"
                 [ngClass]="(this.presenter.navigationIsCompactMode | async) ? 'collapse' : 'expand'"
                 [opened]="this.presenter.navigationIsOpen | async"
                 [mode]="this.presenter.navigationMode | async"
                 [fixedInViewport]="false"
                 [fixedTopGap]="0"
                 [fixedBottomGap]="0">
        <ng-container *l7observeBreakpoint="let bp">
            <button *ngIf="!bp('lt-lg')"
                    mat-mini-fab
                    class="sidenav-toggle mat-elevation-z2 bg-white"
                    [color]="'neutral'"
                    [matTooltip]="(this.presenter.navigationIsCompactMode | async) === false ? 'Menü zuklappen' : 'Menü aufklappen'"
                    [attr.aria-label]="(this.presenter.navigationIsCompactMode | async) === false ? 'Menü zuklappen' : 'Menü aufklappen'"
                    (click)="this.presenter.onToggleNavigationMode()">
                <mat-icon matListItemIcon
                          [fontSet]="'l7'"
                          [fontIcon]="(this.presenter.navigationIsCompactMode | async) ? 'Chevrons_Right' : 'Chevrons_Left'"></mat-icon>
            </button>
        </ng-container>
        <div *ngIf="presenter.hasSession | async"
             fxFlex
             fxLayout="column"
             style="overflow: hidden;">

            <mat-toolbar class="sidenav-toolbar"
                         ngClass.xs="xs"
                         ngClass.sm="sm">
                <l7-brand style="margin-top: 48px;"
                          [compact]="(this.presenter.navigationIsCompactMode | async)"></l7-brand>
            </mat-toolbar>
            <div fxFlex
                 fxLayout="column"
                 style="overflow: auto;">
                <mat-nav-list class="sidenav-list"
                              [ngClass]="(this.presenter.navigationIsCompactMode | async) ? 'collapse' : 'expand'">
                    <ng-container *ngFor="let nav of this.presenter.navigationItems | async | orderBy:'order':false">
                        <ng-container *ngTemplateOutlet="normalNavigationItemTemplate; context: { $implicit: nav, sidenav: sidenav }"></ng-container>
                    </ng-container>
                </mat-nav-list>

            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content style="overflow: auto;"
                         fxLayout="column">
        <!-- header -->
        <mat-toolbar fxLayout="row"
                     fxLayoutAlign="start center"
                     class="content-toolbar">
            <ng-container *l7observeBreakpoint="let bp">
                <button *ngIf="bp('lt-lg')"
                        mat-icon-button
                        [matTooltipPosition]="'below'"
                        [matTooltip]="'Hauptmenü'"
                        (click)="sidenav.toggle()">
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Menu'"></mat-icon>
                </button>
            </ng-container>
            <div fxFlex
                 fxLayoutAlign="center center">
                <a class="stage"
                   fxLayoutGap="8px"
                   [routerLink]="['/presentation']">
                    <img src="assets/images/abc_logo_color.svg"
                         alt="abcfinance Logo">
                    <sup *ngIf="this.presenter.stage !== 'prod'"
                         class="stage">{{ this.presenter.stage }}</sup>
                </a>
            </div>
            <ng-container *ngFor="let action of this.presenter.actionItems | async | orderBy:'order':false">
                <ng-container *ngTemplateOutlet="actionItemTemplate; context: { $implicit: action }"></ng-container>
            </ng-container>
        </mat-toolbar>

        <!-- content -->
        <div fxFlex
             style="overflow: overlay; position: relative;">
            <mat-progress-bar *ngIf="(presenter.isLoading | async)"
                              class="busy-bar"
                              mode="indeterminate"></mat-progress-bar>
            <div fxFlex
                 fxLayout="column"
                 class="content"
                 ngClass.xs="xs"
                 ngClass.sm="sm"
                 [ngClass]="(this.presenter.navigationIsCompactMode | async) ? 'more-padding' : ''"
                 [ngClass.md]="(this.presenter.navigationIsCompactMode | async) ? 'more-padding md' : 'md'"
                 [ngClass.lg]="(this.presenter.navigationIsCompactMode | async) ? 'more-padding lg' : 'lg'"
                 [ngClass.xl]="(this.presenter.navigationIsCompactMode | async) ? 'more-padding xl' : 'xl'">
                <div fxFlex>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
<!-- </ng-container> -->
