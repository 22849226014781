<div class="gwg-confirmation-container">
  <p>{{ 'gwg.confirmation.agreement' | translate }}</p>
  <form [formGroup]="confirmationGroup">
    <div class="checkbox-container">
      <mat-checkbox id="checkbox_GDPR-agreement" formControlName="gdprConfirmation" [name]="'checkbox_GDPR'">
        {{ 'gwg.confirmation.gdpr_agreement_part1' | translate }} <a rel="noopener noreferrer" id="anchor_download-gdpr" (click)="openDocumentViewer(options?.GDPR, $event)">
          {{ 'gwg.confirmation.gdpr_agreement_link' | translate }}</a>{{ 'gwg.confirmation.gdpr_agreement_part2' | translate }}
      </mat-checkbox>
    </div>
    <div class="checkbox-container">
        <mat-checkbox formControlName="agbConfirmation"
                      id="checkbox_AGB-agreement"
                      [name]="'checkbox_AGB'">
            <a rel="noopener noreferrer" id="anchor_download-agb" (click)="openDocumentViewer(options?.AGB, $event)">{{ 'gwg.confirmation.agb_agreement_link' | translate }}</a>
            <ng-container *ngIf="contractType !== 'MIETKAUF' && contractType !== 'MKN'; else isMietkauf">
                {{ 'gwg.confirmation.agb_agreement_part2' | translate }}
            </ng-container>
            <ng-template #isMietkauf>
                {{ 'gwg.confirmation.agb_agreement_part2_mk' | translate }}
            </ng-template>
        </mat-checkbox>
    </div>
    <div *ngIf="condition === 'USED'" class="checkbox-container">
      <mat-checkbox id="checkbox_accept-eow" formControlName="eowConfirmation" [name]="'checkbox_EOW'">
          <a rel="noopener noreferrer"id="anchor_download-eow"
             (click)="openDocumentViewer(options?.EOW, $event)">{{ 'gwg.confirmation.eow_agreement' | translate }}</a>{{ 'gwg.confirmation.accept' | translate }}
      </mat-checkbox>
    </div>
    <div class="checkbox-container">
      <mat-checkbox id="checkbox_SEPA-agreement" formControlName="sepaConfirmation" [name]="'checkbox_SEPA'">
        {{ 'gwg.confirmation.sepa_agreement' | translate }}
      </mat-checkbox>
    </div>
    <div *ngIf="(hasSlbConfirmation | async)" class="checkbox-container">
      <mat-checkbox id="checkbox_SLB-agreement" formControlName="slbConfirmation" [name]="'checkbox_SLB'">
          <a rel="noopener noreferrer" id="anchor_download-slb"
             (click)="openDocumentViewer(options?.SLB, $event)">{{ 'gwg.confirmation.slb_agreement' | translate }}</a>{{ 'gwg.confirmation.accept' | translate }}
      </mat-checkbox>
    </div>
  </form>
</div>
