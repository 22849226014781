
<div class="confirmation-summary-component py-24" ngClass.xs="px-8">
  <div fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between stretch"> <!-- parent headline flex container -->
    <div>
      <h2 class="font-semi-bold text-xl mb-24">{{"gwg.contract_data" | translate}}</h2>
      <div>{{ quoteSummary.lessee.name }}</div>
      <div class="font-semi-bold text-lg">{{ quoteSummary.quoteName }}</div>
    </div>
    <ng-content select="[salesContact]"></ng-content> <!-- slot for sales contact information -->
  </div>
  <l7-text-block class="my-32"
    [wrap]="true"
    [text]="'gwg.confirmation.subTitle' | translate">
  </l7-text-block>
  <div fxLayout.gt-xs="row" fxLayoutGap.gt-xs="32px" fxLayoutAlign.gt-xs="space-around stretch"> <!-- container -->
    <div class="bg-white"  fxFlex.gt-xs="50%"> <!-- left -->
      <div class="border rounded p-24 h-full" ngClass.xs="mb-24">
        <p class="font-semi-bold text-lg mb-12">
            {{"gwg.objectNameTitle" | translate}}
        </p>
        <div class="bg-white mt-24"  fxLayout.gt-xs="row wrap" fxLayoutAlign.gt-xs="space-between stretch"> <!-- iteration container for object properties -->
          <div fxFlex.gt-xs="50%" class="mb-24">
            <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{ 'quote.summary.objectName' | translate }}</span>
            <l7-text-block class="mt-8" [wrap]="true" [text]="leasingObject.name"></l7-text-block>
          </div>
          <div fxFlex.gt-xs="50%" class="mb-24">
            <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{ 'quote.summary.contractType' | translate }}</span>
            <l7-text-block class="mt-8" [wrap]="true" [text]="contractTypeText"></l7-text-block>
          </div>
          <div fxFlex.gt-xs="50%" class="mb-24">
            <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{ 'quote.summary.condition' | translate }}</span>
            <l7-text-block class="mt-8" [wrap]="true" [text]="condition"></l7-text-block>
          </div>
          <div fxFlex.gt-xs="50%" class="mb-24">
            <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{ 'quote.summary.objectValue' | translate }}</span>
            <l7-text-block class="mt-8" [wrap]="true" [text]="leasingObject.value | currency"></l7-text-block>
          </div>
          <div fxFlex.gt-xs="50%" class="mb-24">
            <span *ngIf="contractType !== iContractTypeDto.Mkn && contractType !== iContractTypeDto.Mietkauf" class="pb-8 text-grey-400 text-sm font-semi-bold">{{ 'quote.summary.leasingPeriod' | translate }}</span>
            <span *ngIf="contractType === iContractTypeDto.Mkn || contractType === iContractTypeDto.Mietkauf" class="pb-8 text-grey-400 text-sm font-semi-bold">
                {{ 'quote.summary.basicPeriod' | translate }}
            </span>
            <l7-text-block class="mt-8" [wrap]="true" [text]="calculation.quoteCalculation.totalTerms + ' Monate'"></l7-text-block>
          </div>
          <div fxFlex.gt-xs="50%" class="mb-24">
            <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{ 'quote.summary.down_payment' | translate }}</span>
            <l7-text-block class="mt-8" [wrap]="true" [text]="calculation.quoteCalculation.downPaymentValue | currency"></l7-text-block>
          </div>
          <div *ngIf="!!calculation.quoteCalculation.residualValue" fxFlex.gt-xs="50%" class="mb-24">
            <!-- Quotes of type *MIETKAUF* does not have residual_value`s -->
            <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{ 'quote.summary.residual_value' | translate }}</span>
            <l7-text-block class="mt-8" [wrap]="true" [text]="calculation.quoteCalculation.residualValue | currency"></l7-text-block>
          </div>
          <div fxFlex.gt-xs="50%" class="mb-24">
            <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{'calculation.first_instalment_complete' | translate}}</span>
            <l7-text-block *ngIf="calculation.quoteCalculation.firstInstalmentValue > 0; else noFirstInstalment" class="mt-8" [wrap]="true" [text]="calculation.quoteCalculation.firstInstalmentValue | currency"></l7-text-block>
              <ng-template #noFirstInstalment>
                  <l7-text-block class="mt-8" [wrap]="true" [text]="'global.no' | translate"></l7-text-block>
              </ng-template>
          </div>
          <div fxFlex.gt-xs="50%" class="mb-24">
            <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{'calculation.last_instalment_complete' | translate}}</span>
            <l7-text-block *ngIf="calculation.quoteCalculation.lastInstalmentValue > 0; else noLastInstalment" class="mt-8" [wrap]="true" [text]="calculation.quoteCalculation.lastInstalmentValue | currency"></l7-text-block>
              <ng-template #noLastInstalment>
                  <l7-text-block class="mt-8"  [wrap]="true" [text]="'global.no' | translate"></l7-text-block>
              </ng-template>
          </div>
          <div *ngIf="contractType === iContractTypeDto.Mkn || contractType === iContractTypeDto.Mietkauf" fxFlex.gt-xs="50%" class="mb-24">
                <span class="pb-8 text-grey-400 text-sm font-semi-bold">
                    {{ 'calculation.total_claim' | translate }}
                </span>
                <l7-text-block class="mt-8" [wrap]="true" [text]="quoteSummary.inhouseQuoteCalculation.quoteCalculation.totalInstalments | currency"></l7-text-block>
          </div>
          <div fxFlex.gt-xs="50%" class="mb-24">
            <span class="pb-8 text-grey-400 text-sm font-semi-bold">
                {{ 'quote.summary.instalment' | translate }}
                <span *ngIf="calculation?.quoteCalculation.firstInstalmentValue > 0; else startFromBegin">(2-</span>
                <ng-template #startFromBegin>
                    <span>(1-</span>
                </ng-template>
                <span *ngIf="calculation?.quoteCalculation.lastInstalmentValue > 0; else endAtTheEnd">
                    {{calculation.quoteCalculation.totalTerms - 1}})
                </span>
                <ng-template #endAtTheEnd>
                    <span>{{calculation.quoteCalculation.totalTerms}})</span>
                </ng-template>
            </span>
            <l7-text-block class="mt-8" [wrap]="true" [text]="calculation.quoteCalculation.instalment | currency"></l7-text-block>
          </div>
          <div *ngIf="contractType === iContractTypeDto.Mkn" fxFlex.gt-xs="50%" class="mb-24">
                <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{ 'calculation.total_claim_taxes' | translate }}</span>
                <l7-text-block class="mt-8" [wrap]="true" [text]="'quote.summary.totalInstalmentsVatMKN' | translate"></l7-text-block>
          </div>
            <div *ngIf="contractType === iContractTypeDto.Mietkauf" fxFlex.gt-xs="50%" class="mb-24">
                <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{ 'calculation.total_claim_taxes' | translate }}</span>
                <l7-text-block class="mt-8" [wrap]="true" [text]="quoteSummary.inhouseQuoteCalculation.quoteCalculation.totalInstalmentsVat | currency"></l7-text-block>
            </div>
          <div fxFlex.gt-xs="50%">
            <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{ 'quote.summary.handlingFeeValue' | translate }}</span>
            <l7-text-block class="mt-8" [wrap]="true" [text]="quoteSummary.inhouseQuoteCalculation.insuranceAndHandlingFee.handlingFeeValue | currency"></l7-text-block>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex.gt-xs="50%"> <!-- right -->
      <div fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px" fxlayoutAlign.gt-sm="space-between"> <!-- right top -->
        <div class="border bg-white rounded mb-24 p-24"fxFlex.gt-sm="50%">
          <p class="font-semi-bold text-lg mb-12">
              {{ 'quotes.object_location_details_title' | translate }}
          </p>
          <l7-text-block [wrap]="true" [text]="lesseeName"></l7-text-block>
          <l7-text-block [wrap]="true" [text]="lesseeAddress1"></l7-text-block>
          <l7-text-block [wrap]="true" [text]="lesseeAddress2"></l7-text-block>
        </div>
        <div class="border bg-white rounded mb-24 p-24" fxFlex.gt-sm="50%">
          <!-- inline style fix for two liner -->
          <p class="font-semi-bold text-lg mb-12" style="padding-right: 24px;">
              {{ 'quotes.delivery_information_details' | translate }}
          </p>
          <l7-text-block [wrap]="true" [text]="vendorName"></l7-text-block>
          <l7-text-block [wrap]="true" [text]="vendorAddress1"></l7-text-block>
          <l7-text-block [wrap]="true" [text]="vendorAddress2"></l7-text-block>
        </div>
      </div>
      <div class="bg-white" fxlayoutAlign.gt-xs="none stretch"> <!-- right bottom -->
        <div class="border rounded p-24">
          <p class="font-semi-bold text-lg mb-12">
              {{ 'quotes.BankDataTitle2' | translate }}
          </p>
          <div fxLayout.gt-xs="row wrap" fxLayoutAlign.gt-xs="space-between"> <!-- container for bank info layout -->
            <div fxFlex.gt-xs="50%" class="mb-24">
              <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{'configuration.settings.bank_account_owner' | translate}}</span>
              <l7-text-block class="mt-8" [wrap]="true" [text]="quoteSummary.bankAccount.bank_account_name"></l7-text-block>
            </div>
            <div fxFlex.gt-xs="100%" class="mb-24">
              <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{'configuration.settings.iban' | translate}}</span>
              <l7-text-block class="mt-8" [wrap]="true" [text]="quoteSummary.bankAccount.iban"></l7-text-block>
            </div>
            <div fxFlex.gt-xs="50%" class="pr-12" ngClass.xs="mb-24">
              <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{'global.bank_name' | translate}}</span>
              <l7-text-block class="mt-8" [wrap]="true" [text]="quoteSummary.bankAccount.bank_name"></l7-text-block>
            </div>
            <div fxFlex.gt-xs="50%">
              <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{'configuration.settings.bic' | translate}}</span>
              <l7-text-block class="mt-8" [wrap]="true" [text]="quoteSummary.bankAccount.bic"></l7-text-block>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout.gt-xs="row" fxLayoutGap.gt-xs="32px" fxLayoutAlign.gt-xs="space-around stretch" class="mt-24">
    <div *ngIf="hasAdditionalCosts" fxFlex.gt-xs="50%" class="bg-white"> <!-- additional costs / information container -->
      <div class="border rounded p-24 h-full" ngClass.xs="mb-24">
        <p class="font-semi-bold text-lg mb-12">
          {{'states.insurance.' + quoteSummary.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceType | translate}}
        </p>
        <div class="bg-white"  fxLayout.gt-xs="row wrap" fxLayoutAlign.gt-xs="space-between stretch"> <!-- iteration container for object properties -->
          <div fxFlex.gt-xs="100%" class="mb-24">
            <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{'global.month_rate' | translate}}</span>
            <l7-text-block class="mt-8" [wrap]="true" [text]="quoteSummary.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceValue | currency"></l7-text-block>
          </div>
        </div>
        <div *ngIf="quoteSummary.inhouseQuoteCalculation.contractType === 'MIETKAUF' || quoteSummary.inhouseQuoteCalculation.contractType === 'MKN'"
             class="bg-white"
             fxLayout.gt-xs="row wrap"
             fxLayoutAlign.gt-xs="space-between stretch">
          <div fxFlex.gt-xs="100%" class="mb-24">
              <span class="pb-8 text-grey-400 text-sm font-semi-bold">{{'calculation.total_claim_taxes' | translate}}</span>
              <l7-text-block *ngIf="quoteSummary.inhouseQuoteCalculation.contractType === 'MIETKAUF' || (quoteSummary.inhouseQuoteCalculation.contractType === 'MKN' && quoteSummary.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceType === 'STANDARD'); else noVat"
                             class="mt-8"
                             [wrap]="true"
                             [text]="quoteSummary.inhouseQuoteCalculation.insuranceAndHandlingFee.totalInsuranceInstalmentsVat | currency">
              </l7-text-block>
              <ng-template #noVat>
                  <p>
                      {{ 'quote.summary.totalInstalmentsVatMKN' | translate }}
                  </p>
              </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex.gt-xs="50%"> <!-- empty extension container -->
    </div>
  </div>
</div>
