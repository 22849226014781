<mat-table fxFill
           [dataSource]="dataSource"
           [attr.test-id]="'QuoteVersionsTable'">
    <ng-container *ngFor="let column of columns"
                  [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef>
                                    <span *ngIf="column !== 'menu' && column !== 'progress'"
                                          class="text-truncate">
                                        {{ 'quote.retailers.quotesList.versions.' + column | translate }}
                                    </span>
                                    <span *ngIf="column === 'progress'"
                                          class="text-truncate"
                                          [matTooltip]="'quote.retailers.quotesList.versions.tooltips.status' | translate">
                                          {{ 'quote.retailers.quotesList.versions.' + column | translate }}
                                    </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index;"
                  [attr.test-id]="'versionColumn'+row.id"
                  (click)="goToDetailView($event, row, column)">
            <ng-container [ngSwitch]="column">
                <ng-container *ngSwitchCase="'versionNumber'">
                    {{ row.versionNumber}}
                </ng-container>
                <ng-container *ngSwitchCase="'creationDate'">
                    <span class="text-truncate">{{ row[column] | date: 'shortDate' }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'objectValue'">
                    {{row.objectValue | currency}}
                </ng-container>
                <ng-container *ngSwitchCase="'term'">
                    {{row.term}} Monate
                </ng-container>
                <ng-container *ngSwitchCase="'progress'">
                    <mat-chip-listbox>
                        <mat-chip class="text-truncate quote-state"
                                  fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px" [ngClass]="'quote-state-'+ row.workflowState">
                            <mat-icon *ngIf="row.workflowState === 'OPEN'"
                                      matChipAvatar
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Checkbox_Square'">
                            </mat-icon>
                            <mat-icon *ngIf="row.workflowState === 'COMPLETED'"
                                      matChipAvatar
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Checkbox_Square'">
                            </mat-icon>
                            <mat-icon *ngIf="row.workflowState === 'PENDING'"
                                      matChipAvatar
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Time'">
                            </mat-icon>
                            <mat-icon *ngIf="row.workflowState === 'ERROR' || row.workflowState === 'DECLINED'"
                                      matChipAvatar
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Cross_Circle'">
                            </mat-icon>
                            <l7-text-block [truncate]="true"
                                           [text]="row.tasksCompleted + '/'+ row.tasksTotal">
                            </l7-text-block>
                        </mat-chip>
                    </mat-chip-listbox>
                </ng-container>
                <ng-container *ngSwitchCase="'instalment'">
                    {{row.instalment | currency}}
                </ng-container>
                <ng-container *ngSwitchCase="'contractType'">
                    <ng-container *ngIf="row.contractType === 'MIETKAUF'; else notMiekauf">
                        MK
                    </ng-container>
                    <ng-template #notMiekauf>
                        {{row.contractType}} <ng-container *ngIf="row.sale_and_lease_back">+ SLB</ng-container>
                    </ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="'menu'">
                    <l7-spacer style="flex: 1;" [thickness]="'all'"></l7-spacer>
                    <mat-icon [matMenuTriggerFor]="menu"
                              [fontSet]="'l7'"
                              [color]="'primary'"
                              [fontIcon]="'More_Vert_Filled'"
                              (mouseover)="disableRipple = true"
                              (mouseout)="disableRipple = false">
                    </mat-icon>
                    <mat-menu #menu="matMenu">
                        <button *ngFor="let menuAction of menuActions" mat-menu-item  (click)="onMenuItemClick(menuAction, row.id)">
                            <mat-icon [fontSet]="'l7'"
                                      [fontIcon]="menuAction.icon"></mat-icon>
                            {{ menuAction.label | translate}}
                        </button>
                    </mat-menu>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <span class="text-truncate"
                          [matTooltip]="row[column]">{{ row[column] }}</span>
                </ng-container>
            </ng-container>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns; let i = index"
             matRipple
             [matRippleDisabled]="disableRipple"
             [attr.test-id]="'quoteVersionTableRow' + row.object_name">
    </mat-row>
</mat-table>
