/**
 * retailer-service
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type IdType = 'CONTACT' | 'PARTNER' | 'KEYCLOAK' | 'USER_ID';

export const IdType = {
    Contact: 'CONTACT' as IdType,
    Partner: 'PARTNER' as IdType,
    Keycloak: 'KEYCLOAK' as IdType,
    UserId: 'USER_ID' as IdType
};

