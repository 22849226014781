// #region Imports

import { ICommand } from '@abcfinlab/core';
import { Injectable } from '@angular/core';

// #endregion

/**
 * @public
 */
@Injectable()
export class CommandAggregator {

    // #region Fields

    private readonly _registrations: Map<string, ICommand>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `CommandAggregator` class.
     *
     * @public
     */
    public constructor() {
        this._registrations = new Map();
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public register(key: string, command: ICommand): void {
        this._registrations.set(key, command);
    }

    /**
     * @public
     */
    public unregister(key: string): void {
        this._registrations.delete(key);
    }

    /**
     * @public
     */
    public get(key: string): ICommand {
        return this._registrations.get(key);
    }

    /**
     * @public
     */
    public all(): ReadonlyMap<string, ICommand> {
        return this._registrations;
    }

    // #endregion

}
