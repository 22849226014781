<div class="invoice-container">
  <div *ngIf="!documents.length" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
        <l7-spacer [thickness]="'all'" [density]="'comfortable'">
            <mat-spinner *ngIf="loading"></mat-spinner>
        </l7-spacer>
  </div>
  <div *ngIf="!documents.length && !loading"
       class="invoice-upload-container">
    <div *ngIf="!canUploadFiles"
         class="disable-upload"></div>
    <div fxLayout="row">
      <h2 fxFlex.lt-md="100"
          fxFlex="100">{{title | translate}}</h2>
    </div>
    <div class="text-purchase-entry"
         fxLayout="row">
        <l7-text-block [text]="'contract_management.invoice_upload.view_text' | translate"></l7-text-block>
    </div>
    <div fxLayout="row">
      <l7-banner *ngIf="(this.filesCount$ | async) > 1"
                 [appearance]="'textual'"
                 [color]="'warn'"
                 [iconFontSet]="'l7'"
                 [icon]="'Alert_Triangle'"
                 [content]="'contract_management.invoice_upload.warning_message' | translate">
      </l7-banner>
    </div>
    <l7-file-uploader [useUploadButton]="true"
                      [maxFiles]="1"
                      [allowedFileTypes]="['pdf']"
                      [canUpload]="(canUpload$ | async)"
                      (filesChanged)="filesToUpload($event)"
                      (filesForUpload)="uploadFiles($event)">
    </l7-file-uploader>
  </div>
  <ng-container *ngIf="documents.length && !loading">
    <div class="invoice-confirmation-container">
      <div fxLayout="column">
          <l7-banner [appearance]="'outlined'"
                     [color]="'success'"
                     [iconFontSet]="'l7'"
                     [icon]="'Check_Circle'"
                     [content]="confirmationText | translate:{param1: documents[0].creationDate | date: 'longDate', param2: documents[0].createdBy}">
          </l7-banner>
      </div>
    </div>
  </ng-container>
</div>
