<l7-page>
    <l7-page-header [title]="'calculation.headline_overview' | translate"
                    [subTitle]="(selectedLessee$ | async)?.name + ' | ' +(selectedLessee$ | async)?.nav_contact_number"
                    [supportiveTitle]="'please.select.calculation' | translate"></l7-page-header>
    <l7-page-content>
        <ng-container *ngIf="(selectedLessee$ | async)?.id; else loading">
            <l7-quote-list [overviewDto]="lesseeQuotes | async"></l7-quote-list>
        </ng-container>

        <ng-template #loading>
            <p>{{"global.no.results" | translate}}</p>
        </ng-template>
    </l7-page-content>

    <div fxLayoutAlign="center center">
        <l7-spacer [thickness]="'all'" [multiplier]="24">
            <button mat-button
                    id="button_back-to-start"
                    [color]="'accent'"
                    (click)="onBackToStart()">{{"global.start.page" | translate}}</button>
        </l7-spacer>
    </div>
</l7-page>
