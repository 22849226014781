/* libs/ui/src/lib/skeleton/skeleton-carousel/skeleton-carousel.compent.scss */
@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}
.skeleton-grid {
  width: 100%;
  max-width: 38rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  justify-self: center;
}
.grid-container {
  display: grid;
  height: 15rem;
  margin: 0 auto;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
}
.grid-container .skeleton-tile:not(:first-child) {
  display: none;
}
@media screen and (min-width: 600px) {
  .grid-container {
    max-height: 12rem;
    grid-template-columns: repeat(3, 1fr);
    max-width: 30rem;
  }
  .grid-container .skeleton-tile {
    max-height: 10rem;
  }
  .grid-container .skeleton-tile:nth-child(2),
  .grid-container .skeleton-tile.skeleton-tile:nth-child(3) {
    display: flex;
  }
}
@media screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(5, 1fr);
    max-height: 10rem;
    max-width: 45rem;
  }
  .grid-container .skeleton-tile {
    max-height: 8rem;
  }
  .grid-container .skeleton-tile:nth-child(4),
  .grid-container .skeleton-tile:nth-child(5) {
    display: flex;
  }
}
.skeleton-tile {
  animation: pulse 1.5s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 12rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
/*# sourceMappingURL=skeleton-carousel.compent.css.map */
