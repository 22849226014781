<form ngxsForm="calculation.slbCodeForm" class="form form-slb-code" [formGroup]="form">
  <!-- KEIN MIETKAUF -->
  <ng-container *ngIf="(canHaveSlb$ | async) && (calculationType$ | async) !== calculationUiState.TRANSIENT">
    <div class="input-container">
      <div class="slide-toggle">
        <mat-slide-toggle id="toggle_slb"
                          labelPosition="before" color="primary" formControlName="active"
                          (ngModelChange)="handleToggleChanged($event)">
          <span class="checkbox-label">
            {{"calculation.slb_toggle_label" | translate}}
          </span><br>
        </mat-slide-toggle>
      </div>
      <div class="input">
        <mat-form-field class="block select-slb-code" appearance="outline"  [ngClass]="{'error': form.get('code').errors}">
          <mat-label>{{'calculation.slb_select_label' | translate}}</mat-label>
          <mat-select id="select_slb_code"
                      name="code" ngDefaultControl
                      formControlName="code"
                      [formControl]="code">
            <mat-select-trigger>
              <span matTooltip="{{ 'slb.codes.' + code.value | translate }}">
                {{'slb.codes.new.' + code.value | translate}}
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let code of (slbCodes$ | async); let i = index" [value]="code">
              <p>{{ 'slb.codes.new.' + code | translate}}</p>
              <p class="code-description">{{ 'slb.codes.' + code | translate }}</p>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
</form>
