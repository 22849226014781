<div *ngIf="this.presenter.data | async"
     fxLayout="column"
     fxLayoutGap="16px">
    <l7-spacer [thickness]="['top']"
               [density]="'comfortable'"></l7-spacer>
    <l7-banner *ngIf="(presenter.showWarningBanner | async)"
               [appearance]="'outlined'"
               [color]="'warn'"
               [icon]="'Alert_Triangle'"
               [iconFontSet]="'l7'"
               [content]="'contract_management.kuev.signedKuevAlreadyExistsWarning' | translate"></l7-banner>

    <l7-banner *ngIf="(presenter.showSuccessBanner | async)"
               [appearance]="'outlined'"
               [color]="'success'"
               [icon]="'Check_Circle'"
               [iconFontSet]="'l7'"
               [content]="'contract_management.kuev.signedKuevSuccessMessage' | translate: {lessee: (presenter.lessee | async)?.name, date: (presenter.data | async)?.activeSlbResponse?.slbDocumentDate | date}"></l7-banner>
    <div fxLayout="column"
         fxLayoutGap="16px">
        <div *ngIf="(presenter.slbDone | async) && (presenter.signingLink | async)"
             fxFlex="100"
             fxFlex.gt-md="50"
             fxLayout="column"
             fxLayoutGap="16px">
            <mat-card appearance="outlined" class="mat-card-outlined">
                <mat-card-header>
                    <mat-card-title fxFlex fxLayout="row" fxLayoutAlign="space-between center" class="padding-top">
                        <div>{{ 'contract_management.kuev.signing_link.title' | translate }}</div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column">
                <div fxFill
                     fxLayout="column">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'contract_management.takeover_confirmation.signing_link.link' | translate }}</mat-label>
                        <input matInput
                               type="text"
                               name="link"
                               readonly
                               [attr.test-id]="'KuevLinkInput'"
                               [disabled]="(this.presenter.signingLink | async)?.invalid_at || this.overview?.ready?.sent_to_accounting || this.ready && !(this.presenter.data | async)?.inactiveSlbResponse ? true : false"
                               [class]="(this.presenter.signingLink | async)?.invalid_at ? 'strikethrough' : ''"
                               [value]="(this.presenter.signingLink | async)?.link">
                        <button matSuffix
                                mat-icon-button
                                [attr.test-id]="'KuevLinkCopyToClipboardButton'"
                                [disabled]="(this.presenter.signingLink | async)?.invalid_at || this.overview?.ready?.sent_to_accounting || this.ready && !(this.presenter.data | async)?.inactiveSlbResponse ? true : false"
                                [cdkCopyToClipboard]="(this.presenter.signingLink | async)?.link"
                                (cdkCopyToClipboardCopied)="this.onLinkCopied()">
                            <mat-icon matSuffix
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Copy'"></mat-icon>
                        </button>
                        <mat-hint *ngIf="(!((this.presenter.signingLink | async)?.invalid_at)) && ((this.presenter.signingLink | async)?.link ? true : false)" class="disabled">
                            {{ 'contract_management.takeover_confirmation.signing_link.expires_at' | translate:{ date: (this.presenter.signingLink | async).expires_at | date: 'short' } }}
                        </mat-hint>
                        <mat-hint *ngIf="((this.presenter.signingLink | async)?.invalid_at)" class="disabled">
                            {{ 'contract_management.takeover_confirmation.signing_link.invalid_at' | translate:{ date: (this.presenter.signingLink | async).invalid_at | date:'short' } }}
                        </mat-hint>
                    </mat-form-field>
                    <div *ngIf="(this.presenter.data | async)?.inactiveSlbResponse"
                         fxLayout="row"
                         fxLayoutAlign="end"
                         fxLayoutGap="16px">
                        <button *ngIf="(this.presenter.signingLink | async)?.link && !((this.presenter.signingLink | async)?.invalid_at)"
                                mat-flat-button
                                [attr.test-id]="'KuevDeactivateLinkButton'"
                                [color]="'accent'"
                                [disabled]="this.overview?.ready?.sent_to_accounting"
                                (click)="this.presenter.onDeactivateLink()">{{ 'contract_management.takeover_confirmation.signing_link.deactivate' | translate }}</button>
                        <button *ngIf="(this.presenter.signingLink | async)?.invalid_at || (!((this.presenter.signingLink | async)?.invalid_at) && !((this.presenter.signingLink | async)?.link))"
                                mat-flat-button
                                [attr.test-id]="'KuevGenerateLinkButton'"
                                [color]="'accent'"
                                [disabled]="this.overview?.ready?.sent_to_accounting"
                                (click)="this.presenter.onGenerateLink()">{{ 'contract_management.takeover_confirmation.signing_link.generate' | translate }}</button>
                    </div>
                </div>
                </mat-card-content>
            </mat-card>
        </div>
        <l7-banner *ngIf="(presenter.showPriceChangedBanner | async)"
                   [appearance]="'outlined'"
                   [color]="'info'"
                   [icon]="'Info'"
                   [iconFontSet]="'l7'"
                   [content]="'contract_management.kuev.priceChangedInfo' | translate: {originalPrice: (presenter.originalPurchasePrice | async) | currency, changedPrice: (presenter.data | async)?.inactiveSlbResponse?.calculation.totalLeasingValueNet | currency}"></l7-banner>
        <mat-card *ngIf="(presenter.editMode | async) === false" appearance="outlined" class="mat-card-outlined">
            <mat-card-header>
                <h3 matCardTitle class="padding-top">{{ 'slb.contractManagement.summaryTitle' | translate }}</h3>
            </mat-card-header>
            <mat-card-content *ngIf="presenter.data | async" fxLayout="column">
                <div fxFlex
                     fxLayout="row"
                     fxLayoutGap="16px">
                <l7-kuev-comparison [objectCondition]="(presenter.data | async).objectCondition"
                                    [isMKN]="(presenter.isMKN | async)"
                                    [inactiveVersion]="(presenter.data | async).inactiveSlbResponse"
                                    [activeVersion]="(presenter.data | async).activeSlbResponse">
            </l7-kuev-comparison>
            </div>
                <div fxFlex
                     fxLayout="row"
                     fxLayoutGap="16px"
                     [style.padding-left]="'16px'">
                    <fieldset
                        [fxFlex]="'0 1 calc(50% - 16px)'"
                        [fxFlex.lt-md]="'calc(100% - 16px)'">
                        <legend *ngIf="!(presenter.isMKN | async); else smbCode">{{ 'slb.contractManagement.code' | translate }}</legend>
                        <ng-template #smbCode>
                            <legend>{{ 'slb.contractManagement.smbCode' | translate }}</legend>
                        </ng-template>
                        <p>{{ 'slb.codes.contract_management.' + (presenter.data | async).code | translate}}</p>
                    </fieldset>
                </div>
            </mat-card-content>
        </mat-card>
        <div *ngIf="(presenter.editMode | async) === false"
             fxLayout="row"
             fxLayoutAlign="end"
             fxLayoutGap="16px">
            <button mat-flat-button
                    color="primary"
                    [attr.test-id]="'createNewKuev'"
                    (click)="this.presenter.onEdit()">
                {{'Neuen KÜV erstellen' | translate}}
            </button>
        </div>
        <l7-slb-details-form *ngIf="(presenter.editMode | async) && (presenter.creditCheck | async)"
                             [creditCheck]="presenter.creditCheck | async"
                             [insuranceType]="presenter.insuranceType |async"
                             [originalPurchasePrice]="presenter.originalPurchasePrice | async"
                             [kuevComponentDataQuoteId]="(presenter.quoteId | async)"
                             [kuevComponentDataContractId]="(overview.contract_number)"
                             [kuevComponentDataContractType]="overview.contract_type"
                             [kuevComponentData]="(presenter.data | async)"
                             (slbUpdated)="presenter.initialize(presenter.quoteId.value, overview.contract_number)">
        </l7-slb-details-form>
    </div>
</div>
